import React, { useEffect } from 'react';
import { PaymentLinkTable } from '../components/modules/payment-link-table';
import {useRouteMatch} from 'react-router-dom';

import './payment-dashboard-frame.css'

/**
 * Website frame for all payment pages
 */
export default function PaymentDashboardFrame(props) {
  let match = useRouteMatch();
  let url = match.url.substring(0, match.url.lastIndexOf('payment') + 7);

  let links = [
    {
      link: `${url}/home`,
      label: 'Home'
    },
    {
      link: `${url}/incoming`,
      label: 'Incoming Requests'
    },
    {
      link: `${url}/inprogress`,
      label: 'In Progress Requests'
    },
    {
      link: `${url}/completed`,
      label: 'Completed Requests'
    },
  ];

  for (let i = 0; i < links.length; i++) {
    if (i === props.current) {  // current should represent the index of the selected tab going up to down starting at 0
      links[i]['current'] = true;
    }
    else {
      links[i]['current'] = false;
    }
  }

  return (
    <div>
      <div className='payment-db-title'>
        Payment Dashboard
      </div>
      <div className='payment-db-body'>
        <PaymentLinkTable
          className="link-table"
          labelAttribute='label'
          linkAttribute='link'
          links={links}
        />
        {props.body}
      </div>
    </div>
  )
}
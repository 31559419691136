import React from 'react';
import { Route, Switch } from "react-router-dom";
import { DEBUG, MOCK_DATA } from '../apis/landsteward/urlconfig';


import {
  createProject, getProjects, getReports, deleteProject, deleteReport, getSatelliteImageDetails, getDataForRegion,
  downloadSatelliteImage, getProjectRegions, getProjectSatelliteImages, getProjectReports, getReportImages, getImageDetails, getLandowners, getPropertiesForLandowner, getProjectDetails, getLandownerDetails
} from '../apis/landsteward/accounts'
import { ReportList } from '../components/projects/report';

import { SelectionGallery, SelectionGalleryCollection } from '../components/modules/selection-gallery'

import MaryCreek from '../images/sample_region_image.png';
import { ProjectManagementRouting } from './project-management-flow';

import ObservationExample from '../images/icons/observation-example-icon.png'
import useFetch from '../apis/landsteward/useFetch';
import { getLandownersMock, getProjectDetailsMock, getProjectsMock, getPropertiesForLandownerMock } from '../apis/landsteward/mock_accounts';


export class ProjectManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      projects: [],
      landowners: [],

      reports: MOCK_DATA ? [
        { reportID: 'eter', name: 'ang', reportType: 'floodrisk' },
        { reportID: 'onroy', name: 'rinh', reportType: 'lulc' },
        { reportID: 'eug', name: 'ong', reportType: 'ndvi' },
        { reportID: 'achel', name: 'hang', reportType: 'autodetection' },
        { reportID: 'ara', name: 'adwan', reportType: 'carbon' }
      ] : [],

      regions: MOCK_DATA ? [
        { regionID: '12345', name: 'MaryCreek' },
        { regionID: '456', name: 'Etobicoke' },
        { regionID: '789', name: 'Pigeon Lake' },
      ] : [],

      satelliteImages: MOCK_DATA ? [
        { imageID: '765b8cdba3384a9f9b8cb5702e131e93', name: 'efgh', png: MaryCreek },
        { imageID: 'a49708a924dc4f289cff9471a1b9583b', name: 'qrst', png: MaryCreek },
      ] : [],

    };

    this.state.currProject = null;
    this.state.currReport = null;
    this.state.currRegion = null;
    this.state.currSatelliteImage = null;
    this.state.currPaymentRequest = null;
    this.state.reportRegions = []
    

    this.addProject = this.addProject.bind(this);
    this.changeProject = this.changeProject.bind(this);
    this.removeProject = this.removeProject.bind(this);
    // this.updateProject = this.updateProject.bind(this);
    this.addReport = this.addReport.bind(this);
    this.changeReport = this.changeReport.bind(this);
    this.removeReport = this.removeReport.bind(this);
    this.addRegion = this.addRegion.bind(this);
    this.changeRegion = this.changeRegion.bind(this);
    this.addSatelliteImage = this.addSatelliteImage.bind(this);
    this.changeSatelliteImage = this.changeSatelliteImage.bind(this);
    this.getFilterListForObject = this.getFilterListForObject.bind(this);
    this.changePaymentRequest = this.changePaymentRequest.bind(this);
    this.changePaymentStatus = this.changePaymentStatus.bind(this);
    this.changeNotifRead = this.changeNotifRead.bind(this);
    this.changeCurrentState = this.changeCurrentState.bind(this);
    this.computeCentroid = this.computeCentroid.bind(this);
    this.changeReportRegions = this.changeReportRegions.bind(this)
  }

  async componentDidMount() {
    // console.log('GETTING PROJECTS');
    let projectList = MOCK_DATA ? getProjectsMock() : await getProjects(this.props.state.account_id);
    if (projectList === null) {
      // console.error('Retrieving the Project IDs failed');
    } else {
      // console.log('PROJECT LIST: ', projectList);
      projectList.reverse();
      let regionList = await getRegionsFromProjects(projectList);
      this.setState({
        projects: projectList,
        regions: regionList
      });
    }

    this.setState({ loading: false })

    async function getRegionsFromProjects(projectList) {
      
      let regionIds = projectList.map(project => project.region);
      let regionList = [];
      for (let i = 0; i < regionIds.length; i++) {
        // console.log("get region", regionIds[i])
        if (regionIds[i] == null) {
          continue
        }
        let regionObj = await getDataForRegion(regionIds[i]);
        if (regionObj != null) {
          regionList.push(regionObj);
        }
      }
      // console.log('REGION LIST: ', regionList);
      return regionList;
    }
  }

  setLoading() {
    this.setState({ loading: true })
  }

  finishLoading() {
    this.setState({ loading: false })
  }


  /** */

  /**
   *  Simple Filter Function for project management arrays
   * @param {list from project management state to filter through} array
   * @param {attribute to filter over} objectAttribute 
   * @param {value of attribute to look for} attributeValue 
   */
  getFilterListForObject(array, objectAttribute, attributeValue) {
    // console.log('filtering for:',array, objectAttribute, attributeValue);
    let targetObject = null;

    for (let i = 0; i < this.state[array].length; i++) {
      /** Filter for object with such attribute set at desired value */
      ///console.log(this.state[array][i][objectAttribute]);
      if (this.state[array][i][objectAttribute] === attributeValue) {
        /** return that object and end loop */
        targetObject = this.state[array][i];
        //console.log('target',targetObject)
        i = this.state[array].length;
      }
    }

    // console.log("FILTERING WEBSTATE:", array, objectAttribute, attributeValue);
    // console.log(this.state);

    return targetObject;
  }

  /** PROJECTS */
  /*************/

  /** Updates the state of projects on client, must be called after the
   *  project has successfully been created in the backend
   * 
   * @param {New Project to add to user's list} projectObject 
   */
  async addProject(projectObject) {
    // console.log("ADD PROJECT", projectObject);
    this.setState((state, props) => ({
      projects: [projectObject, ...state.projects],
      currProject: projectObject
    }));

    console.log("STATE AFTER ADD PROJECT", this.state);


    return true;
  }

  /**
   *  Change the project to the one with the given ID if it exists
   * @param {string} projectID 
   */
  async changeProject(projectID) {
    this.setLoading();
    let project = null;
    project = this.getFilterListForObject('projects', 'projectID', projectID)


    if (project === null) {
      /** Missing Project */
      console.error('Attempted to Change to Project not listed in state');
      this.finishLoading();
      return false;
    }
    else {
      /** Set Project */
      if (this.state.currProject && this.state.currProject.projectID == projectID) {
        this.finishLoading();
        return true;
      }

      const project_details = MOCK_DATA ? getProjectDetailsMock(projectID) : await getProjectDetails(projectID);
      if (project_details) {
        this.setState(() => ({
          currProject: project_details
        }));
      }
      else {
        console.error("Error getting project details for".projectID);
      }


      // let landowners = MOCK_DATA ? getLandownersMock(projectID) : await getLandowners(projectID);

      // let landowners_details = []

      // if (MOCK_DATA) {
      //   landowners_details = landowners;
      // }
      // else {
      //   landowners.forEach(async (landowner) => {
      //     let landowner_detail = await getLandownerDetails(projectID, landowner.profileID);
      //     landowners_details.push(landowner_detail);
      //   })
      // }

      // this.setState(() => ({
      //   landowners: landowners_details
      // }))

      // console.log("STATE AFTER RESET", this.state);
      this.finishLoading();
      return true;
    }
  }


  /** Removes the react state for a given project 
   * 
   * @param {*} projectID 
   */
  async removeProject(projectID) {
    let indexOfProject = -1;

    /** searches for index of project */
    console.log('length', this.state.projects.length)
    for (let i = 0; i < this.state.projects.length; i++) {
      console.log('index',i)
      console.log('element', this.state.projects[i].projectID)
      if (this.state.projects[i].projectID === projectID) {
        indexOfProject = i;
        i = this.state.projects.length;
      }
    }

    if (indexOfProject === -1) {
      console.error('Attempted to Delete Project not listed in state');
      return false;
    } else {

      console.log('old projects', this.state.projects)
      /** removes 1 object at index of project */
      console.log("REMOVE PROJECT at", indexOfProject);
      let newProjects = this.state.projects;
      newProjects.splice(indexOfProject, 1);
      
      this.setState((state, props) => ({
        projects: newProjects,
      }));
      console.log('new projects', this.state.projects)

      // deletion of projects is not yet implemented
      // deleteProject(projectID);

      /** If there are no projects  set current project to null*/
      if (this.state.projects.length === 0 || this.state.currProject === null || this.state.currProject.projectID === projectID) {
        this.setState({ currProject: null })
      }

      console.log(this.state.projects)
      return true;
    }
  }

  // TODO: delete it if other approaches can work to update ProjectList page
  // async updateProject(projectID) {
  //   this.setLoading();
  //   let project = null;
  //   project = this.getFilterListForObject('projects', 'projectID', projectID)

  //   if (this.state.currProject && this.state.currProject.projectID == projectID && this.state.currProject.name == project.name) {
  //     this.finishLoading();
  //     return true;
  //   }

  //   const project_details = await getProjectDetails(projectID);
  //   if (project_details) {
  //     this.setState(() => ({
  //       currProject: project_details
  //     }));
  //   } else {
  //     console.error("Error getting project details for".projectID);
  //   }

  //   this.finishLoading();
  //   return true;
    
  // }


  /** REPORTS */
  /************/

  async addReport(reportObject) {
    console.log('ADD REPORT', reportObject)
    this.setState((state, props) => ({
      reports: [reportObject, ...state.reports],
      currReport: reportObject
    }));

    return true;
  }

  async changeReport(reportID) {
    let report = null;
    report = this.getFilterListForObject('reports', 'reportID', reportID)

    if (report === null) {
      console.error('Attempted to Change to Report not listed in state');
      return false;
    } else {
      this.setState((state, props) => ({
        currReport: report
      }));
      return true;
    }
  }

  async removeReport(reportID) {
    let indexOfReport = -1;
    /** Find index of the report to remove */
    for (let i = 0; i < this.state.reports.length; i++) {
      if (this.state.reports[i].reportID === reportID) {
        indexOfReport = i;
        i = this.state.reports.length;
      }
    }


    if (indexOfReport === -1) {
      /** Report Not Found */
      console.error('Attempted to Delete Report not listed in state');
      return false;
    } else {
      /** Remove report from state */
      this.setState((state, props) => {
        let reportsCopy = [...state.reports];
        reportsCopy.splice(indexOfReport, 1);
        return { reports: reportsCopy };
      }
      );

      /** If there are no projects  set current project to null*/
      if (this.state.reports.length === 0 || this.state.currReportID === reportID) {
        this.setState({ currReport: null });
      }
      return true;
    }
  }


  /** REGIONS */
  /**############################################################################## */

  async addRegion(regionObject) {
    console.log('ADD REGION', regionObject);
    this.setState((state, props) => ({
      regions: [regionObject, ...state.regions],
      currRegion: regionObject
    }));

    return true;
  }

  async changeRegion(regionID) {
    let region = null;
    region = this.getFilterListForObject('regions', 'regionID', regionID);
    console.log('region:',region)

    if (region === null) {
      console.error('Attempted to Change to Region not listed in state');
      return false;
    } else {
      console.log('Updating state with new region:', region);
      this.setState((state, props) => ({
        currRegion: region
      }));
      return true;
    }

  }


  async changeRegions(regionIDs) {
    let regions = [];
    regionIDs.forEach(regionID => {
      let region = this.getFilterListForObject('regions', 'regionID', regionID);
      if (region !== null) {
        regions.push(region);
      } else {
        console.error(`Region with ID ${regionID} not listed in state`);
      }
    });
  
    if (regions.length > 0) {
      console.log('Updating state with new regions:', regions);
      this.setState((state, props) => ({
        currRegion: regions
      }));
      return true;
    } else {
      console.error('No valid regions found to update');
      return false;
    }
  }


  async changeReportRegions(regionIDs) {
    console.log('ADD REPORT REGIONS', regionIDs)
    this.setState((state, props) => ({
      reportRegions: regionIDs
    }));
    console.log("state", this.state)
  }


  /** Satellite Images */
  /**############################################################################## */
  async addSatelliteImage(satelliteImageObject) {
    console.log('ADD Satellite Image');
    this.setState((state, props) => ({
      satelliteImages: [satelliteImageObject, ...state.satelliteImages],
      currSatelliteImage: satelliteImageObject
    }));

    return true;
  }

  async changeSatelliteImage(imageID) {
    let image = null;
    image = this.getFilterListForObject('satelliteImages', 'imageID', imageID);

    if (image === null) {
      console.error('Attempted to Change to SatelliteImage not listed in state');
      return false;
    } else {
      this.setState((state, props) => ({
        currSatelliteImage: image
      }));
      return true;
    }
  }

  /** PAYMENTS */
  /**############################################################################## */
  async changePaymentRequest(paymentID) {
    let req = null;
    req = this.getFilterListForObject('paymentRequests', 'paymentID', paymentID);

    if (req === null) {
      console.error('Attempted to Change to PaymentRequest not listed in state');
      return false;
    } else {
      this.setState((state, props) => ({
        currPaymentRequest: req
      }));
      return true;
    }
  }

  async changePaymentStatus(paymentID, status, note_to_landowner, note_to_buyer, note_to_landtrust) {
    let indexOfPayment = -1;
    for (let i = 0; i < this.state.paymentRequests.length; i++) {
      if (this.state.paymentRequests[i].paymentID === paymentID) {
        indexOfPayment = i;
        i = this.state.paymentRequests.length;
      }
    }
    if (indexOfPayment === -1) {
      console.error('Attempted to Change status of PaymentRequest not listed in state');
      return false;
    } else {
      let newReq = { ...this.state.paymentRequests[indexOfPayment] };
      newReq.status = status
      if (note_to_buyer !== null) {
        newReq.note_to_buyer = note_to_buyer
      }
      if (note_to_landowner !== null) {
        newReq.note_to_landowner = note_to_landowner
      }
      if (note_to_landtrust !== null) {
        newReq.note_to_landtrust = note_to_landtrust
      }
      this.state.paymentRequests[indexOfPayment] = newReq
      this.setState((state, props) => ({
        paymentRequests: state.paymentRequests
      }));
      return true;
    }

  }

  async changeNotifRead(notifId) {
    let indexOfNotif = -1;
    for (let i = 0; i < this.state.paymentNotifs.length; i++) {
      if (this.state.paymentNotifs[i].notifId === notifId) {
        indexOfNotif = i;
        i = this.state.paymentNotifs.length;
      }
    }
    if (indexOfNotif === -1) {
      console.error('Attempted to Change \'read\' of notification not listed in state');
      return false;
    } else {
      let newNotif = { ...this.state.paymentNotifs[indexOfNotif] };
      newNotif.read = true;
      this.state.paymentNotifs[indexOfNotif] = newNotif;
      this.setState((state, props) => ({
        paymentNotifs: state.paymentNotifs
      }));
      return true;
    }

  }


  /** ALL State*/
  /**###################################################################### */
  async changeCurrentState(metaObject) {
    console.log("CHANGE STATE TO:", metaObject);
    console.log(DEBUG);
    let correctState = {};
    //console.log ('change current state start:');
    /** PROJECTS */
    if (metaObject.projectID &&
      ((this.state.currProject === null) ||
        (this.state.currProject &&
          metaObject.projectID !== this.state.currProject.projectID))) {
      correctState.project = await this.changeProject(metaObject.projectID);
      //console.log ('project chang curr state:',currProject.projectID);
    } else {
      console.log('not changing:');
      correctState.project = true;
    }
    console.log('STATE:', this.state);

    /** REGIONS */
    if (metaObject.regionID &&
      ((this.state.currRegion === null) ||
        (this.state.currRegion &&
          metaObject.regionID !== this.state.currRegion.regionID))) {
      correctState.region = await this.changeRegion(metaObject.regionID);
      // console.log ('change curr region:',currRegion.regionID );
    } else {
      correctState.region = true;
    }

    /** SATELLITE IMAGES */
    if (metaObject.satelliteImageID &&
      ((this.state.currSatelliteImage === null) ||
        (this.state.currSatelliteImage &&
          metaObject.satelliteImageID !== this.state.currSatelliteImage.imageID))) {
      correctState.satelliteImage = await this.changeSatelliteImage(metaObject.satelliteImageID);
    } else {
      correctState.satelliteImage = true;
    }

    /** REPORTS */
    if (metaObject.reportID &&
      ((this.state.currReport === null) ||
        (this.state.currReport &&
          metaObject.reportID !== this.state.currReport.reportID))) {
      correctState.report = await this.changeReport(metaObject.reportID);
    } else {
      correctState.report = true;
    }

    /** PAYMENT REQUESTS */
    if (metaObject.paymentID &&
      ((this.state.currPaymentRequest === null) ||
        (this.state.currPaymentRequest &&
          metaObject.paymentID !== this.state.currPaymentRequest.paymentID))) {
      correctState.payment = await this.changePaymentRequest(metaObject.paymentID);
    } else {
      correctState.payment = true;
    }

    console.log('CORRECT STATE', correctState);
    console.log(this.state);
    return correctState;
  }

  computeCentroid(arr) {
    let minX, maxX, minY, maxY;
    if (arr.length === 1) {
      arr = arr.flat();
    }
    for (let i = 0; i < arr.length; i++) {
      minX = (arr[i][0] < minX || minX == null) ? arr[i][0] : minX;
      maxX = (arr[i][0] > maxX || maxX == null) ? arr[i][0] : maxX;
      minY = (arr[i][1] < minY || minY == null) ? arr[i][1] : minY;
      maxY = (arr[i][1] > maxY || maxY == null) ? arr[i][1] : maxY;
    }
    return [(minX + maxX) / 2, (minY + maxY) / 2];
  }

  render() {
    return (
      this.state.loading ?
        <div>Loading</div>  // maybe make a loading page later?
        :
        <div className='body'>
          <ProjectManagementRouting
            account_id = {this.props.state.account_id}
            projects={this.state.projects}
            landowners={this.state.landowners}
            reports={this.state.reports}
            regions={this.state.regions}
            reportRegions={this.state.reportRegions}
            satelliteImages={this.state.satelliteImages}
            paymentRequests={this.state.paymentRequests}
            paymentNotifs={this.state.paymentNotifs}
            currProject={this.state.currProject}
            currRegion={this.state.currRegion}
            currReport={this.state.currReport}
            currSatelliteImage={this.state.currSatelliteImage}
            currPaymentRequest={this.state.currPaymentRequest}

            addProject={this.addProject}
            changeProject={this.changeProject}
            removeProject={this.removeProject}
            // updateProject={this.updateProject}
            addReport={this.addReport}
            changeReport={this.changeReport}
            removeReport={this.removeReport}
            addRegion={this.addRegion}
            changeRegion={this.changeRegion}
            changeReportRegions={this.changeReportRegions}
            addSatelliteImage={this.addSatelliteImage}
            changeSatelliteImage={this.changeSatelliteImage}
            getFilterListForObject={this.getFilterListForObject}
            changePaymentRequest={this.changePaymentRequest}
            changePaymentStatus={this.changePaymentStatus}
            changeNotifRead={this.changeNotifRead}
            changeCurrentState={this.changeCurrentState}
            computeCentroid={this.computeCentroid}
          />
          {/** *}
        <SelectionGallery
          contentType='image'
          collectionList={
            [
              {
                id: 'Mary Creek River',
                caption: 'watershed',
                content: MaryCreek,
                link: '/login/'
              },
            ]
          }

          createOption={true}
          createLink='/login/'
        />


<SelectionGallery
          contentType='image'
          collectionList={
            [
              {
                id: 'Mary Creek River',
                caption: 'watershed',
                content: MaryCreek,
                link: '/login/'
              },
            ]
          }

          createOption={true}
          createLink='/login/'
        />
        <SelectionGallery
          contentType='image'
          collectionList={
            [
              {
                id: 'Etobocoke Creek',
                caption: 'watershed',
                content: MaryCreek,
                link: '/login/'
              },
            ]
          }

          createOption={true}
          createLink='/login/'
        />
        <Dropdown
          value={this.state.currProjectID}
          setValue={(value) => this.changeProject(value)}
          valueList={this.state.projects}
          valueProperty='projectID'
          nameProperty='name'
        />
        <MapboxInterface
        />

        <ProjectDetailsPage
          project={{projectID: '', name: ''} }
          regions={this.state.regions}
          region={this.state.currRegion}
          getRegion={this.getRegion}
          setRegion={this.setRegion}
        />

        <SatelliteImageForm
          region={this.state.currRegion}
        />

        <ReportCreationPage
          project={this.state.currProject}
          region={this.state.currRegion}
        />

      
      <ProjectSelect
        projectList={this.state.projects}
        currProjectID={this.state.currProjectID}
        changeProjectState={this.changeProject}
      />
      
      <NewProjectForm
        addProject={this.addProject}

      />
      

      <MapboxInterface
      />

      <ReportList
        changeCurrReport={this.changeReport}
        reportList={this.state.reports}
        currReportID={this.state.currReportID}

      />
      
      <MapboxForm
        currProjectID={this.state.currProjectID}
        currReportID={this.state.currReportID}
        addProject={this.addProject}
        addReport={this.addReport}

      />

      

      <MapboxCompareSlider/>
      {/** */}

        </div>
    );


  }
}
import React from 'react';
import NDVIDisplay from '../components/reports/ndvi-display.js';
import './report-display-page.css'

export function NDVIDisplayPage(props) {
  return (
    <div className='report-display'>
      <NDVIDisplay searchState={props.searchState}/>
    </div>
  );
}
import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import {
  createPropertyForLandowner,
  createCustomRegion,
  getPropertiesForLandowner,
  getLandownerDetails,
  getLandowners,
} from "../apis/landsteward/accounts";
import PropertyDrawPage from "./property-draw-page";
import { PageHeader } from "../components/modules/page-header";
import { createCustomRegionMock } from "../apis/landsteward/mock_accounts";
import { MOCK_DATA } from "../apis/landsteward/urlconfig";
import polylabel from "polylabel";
import DragAndDrop from "../components/modules/drag-and-drop";

// import CreateIcon from "../images/create-icon.png";
// import UsersIcon from "../images/users.png";
import MapPinIcon from "../images/map-pin-icon.png";

import "./property-new-page.css";

/**
 * @param {Object} project  current project object
 *
 * @author [Leo Wang](https://github.com/Korotu-Leo-Wang)
 */
export default function PropertyNewPage(props) {
  const params = useParams();
  let match = useRouteMatch();
  let history = useHistory();

  const [address, setAddress] = useState("");
  const [long, setLong] = useState("");
  const [lat, setLat] = useState("");
  const [name, setName] = useState("");
  const [regionID, setRegionID] = useState("");
  const [regionName, setRegionName] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [landownerName, setLandownerName] = useState("");
  const [features, setFeatures] = useState([]);
  const [usingMap, setUsingMap] = useState(false);

  const [fileName, setFileName] = useState(null);
  const [warning, setWarning] = useState("");
  const [projectDoesNotExist, setProjectFail] = useState(false);

  const [addAnotherProperty, setAddAnotherProperty] = useState(false);

  const [nameSet, setNameSet] = useState(new Set());

  const addressMaxLength = 70;
  const cityMaxLength = 30;
  const regionMaxLength = 30;
  const postCodeMaxLength = 30;
  const propertyNameMaxLength = 30;

  useEffect(async () => {
    let success = await props.changeProject(params.projectID);
    if (!success) {
      setProjectFail(true);
    }
  }, []);

  useEffect(() => {
    getLandownerDetails(params.projectID, params.landownerID).then((data) => {
      setLandownerName(data["name"]);
    });
  }, []);

  useEffect(() => {
    async function populateNameSet() {
      const propertyNames = new Set();
      const landowners = await getLandowners(params.projectID);

      const propertyPromises = landowners.map((landowner) => {
        const id = landowner.profileID;
        return getPropertiesForLandowner(id);
      });

      const propertiesForLandowner = await Promise.all(propertyPromises);

      propertiesForLandowner.forEach((properties) => {
        properties.forEach((property) => {
          propertyNames.add(property.property_name);
        });
      });

      setNameSet(propertyNames); 
    }
    
    populateNameSet();
  }, []);

  const [success, setSuccess] = useState(false);
  const newPropertyHandleSubmit = async (e) => {
    e.preventDefault();
    if (
      // !address ||
      !name ||
      !features ||
      features.length == 0 //||
      // !postalCode ||
      // !regionName ||
      // !city
    ) {
      let missing = [];
      // if (!address) {
      //   missing.push("Address");
      // }
      if (!name) {
        missing.push("Property Name");
      }
      if (!features || features.length == 0) {
        missing.push("Region on Map");
      }
      // if (!postalCode) {
      //   missing.push("Postal Code");
      // }
      // if (!regionName) {
      //   missing.push("Property Region");
      // }
      // if (!city) {
      //   missing.push("City");
      // }
      
      // for (let i = 0; i < data.length; i++) {
      //   console.log(data[i]);
      // }
      setWarning(`Missing required information: ${missing.join(", ")}`);
    } 

    else if(nameSet.has(name)){
      setWarning("Field name already exists");
    }
    
    else {
      features[0]["name"] = name;
      features[0]["landowner"] = landownerName;
      const region = MOCK_DATA
        ? createCustomRegionMock()
        : await createCustomRegion(name, features[0]);
      if (!region) {
        alert("Error creating custom region!");
        return;
      }
      let coords =
        features[0]["geometry"]["type"] === "MultiPolygon"
          ? features[0]["geometry"]["coordinates"][0]
          : features[0]["geometry"]["coordinates"];
      // console.log(features[0]["geometry"]["coordinates"][0]);
      let center = polylabel(coords, 1.0);

      const body = {
        property_name: name,
        address: address,
        postal_code: postalCode,
        geographical_region: regionName,
        city: city,
        longitude: center[0].toFixed(2),
        latitude: center[1].toFixed(2),
        perimeter_meter: 100, // placeholder
        area_hectares: 400, // placeholder
        region: region.regionID,
      };
      console.log(body);

      const property = await createPropertyForLandowner(
        params.landownerID,
        body
      );
      if (!property) {
        alert("Error creating property!");
      } else {
        alert("Successfully created property for landowner!");
        setSuccess(true);
      }
    }
  };

  // const propertyNameDuplicate = (project_id, propName) => {


  // }

  const openMap = () => {
    if ( !name ){//|| !city || !postalCode || !regionName || !address ) {
      setWarning(
        "Some fields are unfilled. Please fill them before proceeding"
      );
    } else {
      setWarning("");
      setUsingMap(true);
    }
  };

  const checkValidFileType = (name) => {
    var allowedExtensions = /(\.geojson)$/i;
    return allowedExtensions.exec(name);
  };

  const handleFileParsing = (files) => {
    let file = files[0];
    console.log("got to parse function");
    console.log(file);
    if (!checkValidFileType(file.name)) {
      alert("Invalid file type");
      return;
    }
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      console.log(binaryStr);
      // working with assumption that it'll come straight from geojson.io or smth

      let enc = new TextDecoder("utf-8");
      let data = JSON.parse(enc.decode(binaryStr));
      console.log(data.features[0]);
      setFeatures(data.features);
      setFileName(file.name);
    };
    reader.readAsArrayBuffer(file);
  };

  if (projectDoesNotExist) {
    return <Redirect to={"/project"} />;
  } 
  else if (success) {
    if (addAnotherProperty) {
      window.location.reload()
    } 
    else {
      return (
        <Redirect
        to={`/project/${params.projectID}/landowner/${params.landownerID}`}
        />
      )
    }
  } 
  else {
    return (
      <div className="property-new-body">
        <form className="property-new-form" onSubmit={newPropertyHandleSubmit}>
          {usingMap ? (
            <>
              <PageHeader
                headerText="Select a new Property Area"
                buttonsNeeded={false}
                headerIconNeeded={false}
              />

              <PropertyDrawPage
                setFeatures={setFeatures}
                features={features}
                submit={newPropertyHandleSubmit}
                regionID={regionID}
                setRegionID={setRegionID}
                setRegionName={setRegionName}
              />
            </>
          ) : (
            <>
              <PageHeader
                headerText="Create New Property/Field"
                buttonsNeeded={false}
                headerIconNeeded={false}
              />
              <p>Add a property / field to a landowner</p>
              <div className="property-new-inputs">
                {" "}
                <div className="property-label-inputs-container">
                  <div className="property-new-form-body">
                    <div className="property-input-and-label">
                      <label>Address:<span className="property-new_optonal-text"> (optional)</span>                        
                      </label>
                      <input
                        type="text"
                        name="property-new-address"
                        className="property-new-text-input"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        maxLength={addressMaxLength}
                      />
                    </div>
                    <div className="property-input-and-label">
                      <label>City:<span className="property-new_optonal-text"> (optional)</span>
                      </label>
                      <input
                        type="text"
                        name="property-new-city"
                        className="property-new-text-input"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        maxLength={cityMaxLength}
                      />
                    </div>
                    <div className="property-input-and-label">
                      <label>Region:<span className="property-new_optonal-text"> (optional)</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Region Name (Province/Territory/State)"
                        name="property-new-region"
                        className="property-new-text-input"
                        value={regionName}
                        onChange={(e) => setRegionName(e.target.value)}
                        maxLength={regionMaxLength}
                      />
                    </div>
                    <div className="property-input-and-label">
                      <label>Postal Code:<span className="property-new_optonal-text"> (optional)</span>
                      </label>
                      <input
                        type="text"
                        name="property-new-postal-code"
                        className="property-new-text-input"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        maxLength={postCodeMaxLength}
                      />
                    </div>
                    <div className="property-input-and-label">
                      <label>Property Nickname:</label>
                      <input
                        type="text"
                        name="property-new-address"
                        className="property-new-text-input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        maxLength={propertyNameMaxLength}
                      />

                    </div>
                    <p 
                      className={name.length===propertyNameMaxLength ? 'property-new_text-limit-colour' : undefined}
                      style={{ textAlign: 'right'}}
                    >
                      {name.length}/{propertyNameMaxLength}
                    </p>
                  </div>
                </div>
                <div className="property-new-boundary">
                  <DragAndDrop
                    handleFiles={handleFileParsing}
                    fileName={fileName}
                    buttonText="import property boundary (geoJSON)"
                  />
                  <p>Don't have a property boundary on file?</p>
                  <div
                    className="property-new-draw-button-container"
                    onClick={openMap}
                  >
                    <button className="btn choose-map-btn">
                      <img src={MapPinIcon} />
                      Choose on map
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="property-new-button-container">
            <p
              style={warning == "" ? { display: "none" } : { display: "block" }}
              className="property-new__warning-text"
            >
              {warning}
            </p>
            <input
              type="submit"
              value="Create and finish"
              className="property-new-submit-btn btn"
            />

            <input
              type="submit"
              value="Create and add another"
              className="property-new-submit-btn btn"
              onClick={() => setAddAnotherProperty(true)}
            />
            <Link
              to={`/project/${params.projectID}/landowner/${params.landownerID}`}
            >
              <input
                type="submit"
                value="Cancel"
                className="property-new-btn btn"
              />
            </Link>
          </div>
        </form>
        <br/>
        <br/>
        <br/>
      </div>
    );
  }
}

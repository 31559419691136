import { url, MONITORING_PORT } from "./urlconfig";
import useFetch from "./useFetch";

/** Satellite Downloads and Interations API
 * 
*/



/**
 * 
 * @param {Name of the report to generate} name 
 * @param {ID of the project to associate the report and satellite image} projectID 
 * @param {Earliest Acceptable Date for a satellite image} startDate 
 * @param {Latest Acceptable Date for a satellite image} endDate 
 * @param {pixel resolution for the satellite image, side length of a pixel
 *    in the image measured in meters} spatialResolution 
 * @param {Spectrum of EM Waves to record for the satellite image} satelliteBandType 
 * @param {Maximum Percentage of Cloud Coverage over satellite image} maxCloudCoverage 
 * @returns 
 */
export async function downloadSentinelSat2(
  name,
  propertyID,
  projectID,
  startDate,
  endDate,
  spatialResolution,
  satelliteBandType,
  maxCloudCoverage
) {
  const path = '/sentinel/download/';
  let options = {
    method: 'POST',
    mode: 'cors',
    'Content-Type': 'application/json',
    body: ({
      'name' : name,
      'propertyID': propertyID,
      'projectID': projectID,
      'date': startDate,
      'start_date': startDate,
      'end_date': endDate,
      'spatial_res': spatialResolution,
      'band': satelliteBandType,
      'max_cloud_coverage': maxCloudCoverage
      })
  };


  // FETCH
  console.log(options);
  let response = await useFetch(url + ":" + MONITORING_PORT + path, 'POST', options.body, true);


  let reportData;
  if (response.hasError == false) {
    console.log(response);
    reportData = response.data.reportID;
  } else {
    console.error('Satellite Download or Report Generation Failed');
    console.error(response.errorMessage)
    reportData = null;
  }

  return reportData;  
}

/**
 * 
 * @param {Name of the report to generate} name 
 * @param {ID of the project to associate the report and satellite image} projectID 
 * @param {Earliest Acceptable Date for a satellite image} startDate 
 * @param {Latest Acceptable Date for a satellite image} endDate 
 * @returns 
 */
export async function downloadHarmonized(
  name,
  propertyID,
  projectID,
  startDate,
  endDate,
) {
  const path = '/harmonized/download/';
  let options = {
    method: 'POST',
    mode: 'cors',
    'Content-Type': 'application/json',
    body: ({
      'name' : name,
      'propertyID': propertyID,
      'projectID': projectID,
      'start_date': startDate,
      'end_date': endDate,
      })
  };


  // FETCH
  console.log(options);
  let response = await useFetch(url + ":" + MONITORING_PORT + path, 'POST', options.body, true);


  let reportData;
  if (response.hasError == false) {
      console.log(response);
      reportData = response.data.reportID;
  } else {
    console.error('Satellite Download or Report Generation Failed');
    console.error(response.errorMessage)
    reportData = null;
  }

  return reportData;  
}
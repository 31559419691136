import React, { useState } from "react";
import { useRouteMatch, useParams, Link } from "react-router-dom";

import {
    downloadSatelliteImage,
    getMDReport,
    getMDReportCSV,
    getSatelliteImageDetails,
} from "../apis/landsteward/accounts";
import {
    downloadHarmonized,
    downloadSentinelSat2,
} from "../apis/landsteward/satellite";
import { CreateMowingReport } from "../apis/landsteward/mowing";
import {UpdateMowingReport} from "../apis/landsteward/mowing";

import { Dropdown } from "../components/modules/satellite-dropdown";
import { useLocation } from "react-router-dom";

import "./satellite-image-create-page.css";
import "./mowing-update-page.css";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import SimpleImageSlider from "react-simple-image-slider";
import { AutoDisplayPage } from "./automated-display-page";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    LineElement,
} from "chart.js";
import { Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export function MowingUpdatePage(props) {
    const { state } = useLocation()
    var reportName = state.state.mowingName 
    let match = useRouteMatch();
    let params = useParams();
    const [name, setName] = useState(reportName + " Copy");
    let [cloudMask, setCloudMask] = useState(false);
    const reportNameMaxLength = 30;

    console.log(
        `URL params SatelliteCreate: ${params.projectID}||${params.propertyID}`
    );
    let project = props.searchState("projects", "projectID", params.projectID);
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        PointElement,
        LineController,
        LineElement
    );

    return (
        <div className="satellite-image-create-page">
            <div className="satellite-image-create-page-title" id="mowing-title">
                <h2> Update a Mowing Report</h2>
            </div>

            <p className="metadata-headings" id="mowing-headings">
                {" "}
                <b>Project:</b> {project ? project.name : ""}
            </p>
            <label
                className="name-label metadata-headings"
                id="mowing-name"
                htmlFor="newName"
            >
                <span>Report Name:</span>
                <br></br>
            </label>
            <input
                id="input1"
                className="metadata-headings-name-input"
                name="newName"
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
                maxLength={reportNameMaxLength}
            />
            <p 
            className={name.length===reportNameMaxLength ? 'mowing_text-limit-colour' : undefined}
            style={{ marginLeft: '155px', marginTop: '10px' }}
            >
            {name.length}/{reportNameMaxLength}
            </p>

            <MowingReportForm
                newName={name}
                addSatelliteImage={props.addSatelliteImage}
            />
        </div>
    );
}
/**
 * Form to download a satellite image, allows user to specify satellite image
 * details (e.g. date, cloud coverage). Downloaded image is associate with
 * a specific region and project.
 * @param {*} props
 *    <region>: region metadata object, necessary for fetch API
 *    <newName>:
 *    <addSatelliteImage>:
 */
export function MowingReportForm(props) {
    const { state } = useLocation()
    var projectID = state.state.projectID
    var mowingID = state.state.mowingID
    var initstartDate = state.state.startDate

    var convertedstartDate = initstartDate.slice(0,10)
    var convertedstartDate = convertedstartDate.split('/').reverse().join('-');
    //("2021-12-01"), 10 

    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; 
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    if (month < 10) {
        month = '0' + month 
    }

    let newdate = year + "-" + month + "-" + day;

    let params = useParams();
    const history = useHistory();
    const [satBand, setSatBand] = useState("TCI");
    const [startDate, setStartDate] = useState(convertedstartDate);
    const [endDate, setEndDate] = useState(newdate);
    //   const [pixelRes, setPixelRes] = useState('10m');
    const [newImageID, setNewImageID] = useState("");
    const [downloadStatus, setDownloadStatus] = useState("");
    const [csvString, setcsvString] = useState("");
    const [mowingReportInProgress, setMowingReportInProgress] = useState(false);
    const [warning, setWarning] = useState("")
    async function triggerDownload(e) {
        setMowingReportInProgress(true)
        e.preventDefault();
        setDownloadStatus("pending");
        let reportID;
        let cloudMask = true;
        let spatialResolution = 30;
        let update = true; 

        // let element3 = document.getElementById("mowing-headings");
        // element3.style.display = "none"

        let email_storage = JSON.parse(sessionStorage.getItem('webState')).email
        console.log(email_storage)
        
        reportID = await CreateMowingReport(props.newName, projectID, startDate, endDate, spatialResolution, cloudMask, mowingID, email_storage, update);
        if (reportID == null) {
            console.log('Mowing failed')
            setWarning('Report creation failed')
            setMowingReportInProgress(false)
        } else {
            // if everything goes well up until the job is queued, redirect to the project details page
            // the project details page shows the live status of the report
            history.push(`/project/${params.projectID}`)
        }
        // }else{
        //   imageID = await downloadSentinelSat2(
        //     props.newName,
        //     params.propertyID,
        //     params.projectID,
        //     startDate,
        //     endDate,
        //     pixelRes,
        //     satBand,
        //     cloudMask,
        //     '10'
        //   );
        // }
        console.log(reportID);

    }

    return (
        <form className="satellite-image-form">
            {/**<label htmlFor='satellite-image-name'> Satellite Image Name: </label>
      <input 
        type='text'
        value={name}
        name='satellite-image-name'
        onChange={(event) => setName(event.target.value)}
      /> {/** */}

            {/** Satellite Form Details */}
            <div className="satellite-image-form__details" id="mowing-details">
                <label htmlFor="satellite-image-band">
                    {" "}
                    <b>Specify Image Bands:</b>
                    <br></br>

                </label>
                <input
                    className="ayo"
                    type="text"
                    value={satBand}
                    name="newName"
                    onChange={(event) => setSatBand(event.target.value)}
                    disabled
                />

                <br />

                <label htmlFor="satellite-image-band">
                    {" "}
                    <b>Resolution</b>
                    <br></br>

                </label>
                <input
                    className="ayo"
                    type="text"
                    value="30"
                    name="newName"
                    onChange={(event) => setSatBand(event.target.value)}
                    disabled
                />

                <br />

                <label htmlFor="satellite-image-start-date">
                    {" "}
                    <b>Updated Satellite Image Start Date:</b>{" "}
                    <br></br>

                </label>
                <input
                    className="ayo"

                    type="date"
                    value={startDate}
                    name="satellite-image-start-date"
                    onChange={(event) => setStartDate(event.target.value)}
                    disabled 
                />
                <br />

                <label htmlFor="satellite-image-end-date">
                    {" "}
                    <b>Updated Satellite Image End Date:</b>{" "}
                    <br></br>

                </label>
                <input
                    className="ayo"

                    type="date"
                    value={endDate}
                    name="satellite-image-end-date"
                    onChange={(event) => setEndDate(event.target.value)}
                />
                <br />

                {/* <label htmlFor='satellite-image-pixel-res'> <b>Resolution Preference (if any) :</b> </label>
        <Dropdown
          name='satellite-image-pixel-res'
          value={pixelRes}
          setValue={(value) => setPixelRes(value)}
          valueList={[{value:'10m', name:'10m'}, {value:'30m', name:'30m'}, {value:'60m', name:'60m'},]}
          valueProperty='value'
          nameProperty='name'
        /> */}
            </div>
            <div className={(mowingReportInProgress)
            ? "button-for-mowing-disabled"
            : "button-for-mowing"
            }>
                <button
                    onClick={triggerDownload}
                    disabled={mowingReportInProgress}
                    // style={{
                    //     visibility:
                    //         (downloadStatus === "pending" && "hidden") ||
                    //         (newImageID && "hidden"),
                    // }}
                >
                    {" "}
                    Update Report{" "}
                </button>
            </div>
            <br></br><br></br><br></br>
            <p className="mowing-information-text">
                    NOTE: Any new fields that have been <br></br>
                    added to this project will be included in <br></br>
                    this report update, but will only include <br></br>
                    data from after the report is updated 
            </p>
            <span className={`loader ${(mowingReportInProgress) ? "" : "hidden"}`}></span>
            <br></br>
            <p className="mowing-warning-text">{warning}</p>
            {/* <p className='metadata-headings'> <b>Start Date:</b> {startDate}</p>
            <p className='metadata-headings'> <b>End Date:</b> {endDate}</p> */}
            <br />
            <br />
            {/* 
            <div className='satellite-image-create-page-title'id="mowing-title">
                <h1> NDVI Maps of Q-AL-1 </h1>
            </div> 
             <div>
      <SimpleImageSlider
      right={100}
        width={1420}
        height={904}
        images={slideImages2}
        showBullets={true}
        showNavs={true}
        
      />
    </div>  */}

            {downloadStatus === "failed" && (
                <p className="processing-text">
                    {" "}
                    Oh no! Something went wrong, please try again later
                </p>
            )}
            {/* {(downloadStatus === 'pending') && <p className="processing-text"> The download you triggered is processing</p>} */}
            {newImageID && (
                <span>
                    <p className="processing-text">
                        Your satellite image has finished downloading!
                    </p>
                    <Link
                        to={`/project/${params.projectID}/property/${params.propertyID}/satellite_image/${newImageID}`}
                    >
                        <div className="create-satellite-button">
                            <button> View Image </button>
                        </div>
                    </Link>
                </span>
            )}
        </form>
    );
}

async function useFetchCSV(url, method, body=null, with_auth=false) {
    let data = {};
    let hasError = false;
    let errorMessage = '';
  
    try {
      let options = {
        method: method,
        mode: 'cors',
        headers: {
          'Content-Type': 'text/csv',
          
        }
      }
  
      if (body !== null) {
        options.body = JSON.stringify(body);
      }
  
      if (with_auth) {
        const state = JSON.parse(sessionStorage.getItem("webState"));
        if (state && state.token) {
          options.headers["Authorization"] = "Token " + state.token;
        }
        else {
          console.log("No auth token or no state:", state);
        }
      }
  
      // console.log("API call to", url, ":", options);
  
      const res = await fetch(url, options);
      const text = await res.text();
      if (res.ok) {
       data = text;
        
      }
      else {
    
        hasError = true;
        errorMessage = res;
      }
    } catch (err) {
      hasError = true;
      errorMessage = err.message
    }
  
    return { data, hasError, errorMessage }
  }
  
  export default useFetchCSV;
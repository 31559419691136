import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom';

import './payment-notifs.css';
import Down from '../../images/icons/simple-down-icon.png'
import Up from '../../images/icons/simple-up-icon.png'


/**
 * notification Item component that can be clicked to expand
 * and show more details
 * @param {string} props.notifs the collection of notification
 */
export function NotifCollection(props) {
  return (
    <div className='notifs-collection'>

      { /** request Items */
        props.notifs.map(
        (item) => (
          <NotifItem
            changeNotifRead = {props.changeNotifRead}
            key={item[props.IDAttribute]}
            id={item[props.IDAttribute]}
            title = {item[props.titleAttribute]}
            content={item[props.contentAttribute]}
            dateTime={item[props.dateTimeAttribute]}
            read={item[props.readAttribute]}
            link={item[props.linkAttribute]}
          />
        )
      )}
    </div>
  )
}

/**
 * notification Item component that can be clicked to expand
 * and show more details
 * @param {string} props.id the notification id
 * @param {date} props.dateTime the time this notif was created
 * @param {string} props.title the message that will be shown in the notif box
 * @param {string} props.content the content that will be shown when expanded
 * @param {boolean} props.read has this notif been read
 */
export function NotifItem(props) {

  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  
  const boxRef = useRef();

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);

    if (isOpen && !props.read){
      props.changeNotifRead(props.id);
    }

  };

  const d = new Date(props.dateTime);

  useEffect(() => {
    if (isOpen) setHeight(boxRef.current.getBoundingClientRect().height + 20);
    else setHeight(0);
  }, [isOpen]);

  const bgcolor = (props.read === false)? 'rgb(186, 199, 167)': '#e7e7e7';
  const contentcolor = (props.read === false)? '#3d3d3d': '#757575';

  return (
    <div className="notif-card-container" style={{background:bgcolor, transition: 'all 0.75s ease-in-out 0s'}}>
        <div>
          {props.read === false && <div className='notif-newlabel'>new</div>}
          <div className='notif-date'>{d.toDateString()}</div>
          <div className='notif-content'>
            <div className='notif-title' style={{color:contentcolor}}>
              {props.title} 
            </div>
            <button type="button" className='expand-btn' onClick={handleFilterOpening}>
              {!isOpen ? (
                <img className='btn-icon' src = {Down}/>
              ) : (
                <img className='btn-icon' src = {Up}/>
              )}
            </button>
          </div>
        </div>

        <div className="notif-collapse" style={{ height:height, marginBottom:height>0?'10px':0 }}>
          <div ref ={boxRef}>
            <div className="notif-detail"onClick={() => {
              if (props.link) window.location.href = props.link;
            } }>{props.content}</div>
          </div>
        </div>
      </div>
  )
}

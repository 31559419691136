import React, {useState, useEffect} from 'react';

import './payment-dashboard-detail-page.css'
import PaymentDashboardFrame from './payment-dashboard-frame';
import { Redirect, useParams, useRouteMatch } from 'react-router';
import { TabGroup } from '../components/modules/tab-group-incoming';
import { Link, useHistory } from 'react-router-dom';
import { LandownerDetails, PropertyDetails, RequestDetails } from '../components/modules/payment-request-detail-tabs';
import Back from '../images/icons/simple-back-icon.png'
import {TrackStatus} from '../components/modules/payment-track-status';
import { PaymentStatus } from './payment-management';

/**
 * This function handles the incoming request page and functionality of 
 * incoming requests
 * @param {JSON}      currPaymentRequest    Current payment request object
 * @param {function}  changePaymentRequest  Function to change current request object
 */
export default function PaymentDashboardDetailPage(props) {
  let match = useRouteMatch();
  let params = useParams();
  const history = useHistory()
  
  const goBack = () => {
    history.goBack()
  }

  let tabs = ["About the landowner", "About the property", "About the request"];
  let tabColours = {"About the landowner": "#DCE1CE", "About the property": "CFE1CE", "About the request": "E1DECE"}; // not in use (yet)

  if (props?.processed){
    tabs.push("Tracking status");
    tabColours["Tracking status"] = "?";
  }

  const requestTabPages = ["Invoice Information", "Payment Calculation"]
  const propertyTabPages = ["General Information", "Landowner Observations", "Monitoring Information"]

  let [paymentFailed, setPaymentFailed] = useState(false);
  let [currTab, changeTab] = useState(tabs[0]);

  let [currTabPageIndex, changeTabPageIndex] = useState(0);

  useEffect(async () => {
    let paymentSuccess = await props.changePaymentRequest(params.paymentID);
    if (!paymentSuccess) {
      setPaymentFailed(true);
    }
  }, []);

  if (paymentFailed) {
    let url = match.url.substring(0, match.url.lastIndexOf('incoming') + 8);
    return <Redirect to={url}/>
  } else {
    let body = (
      <div className='tab-body-wrapper'>
        <button onClick={goBack} className="back-btn"><img src={Back}></img></button>
        <div className='tab-body'>
          
          <h1>Viewing payment request #{props.currPaymentRequest !== null ? props.currPaymentRequest.id : null}</h1>
          <TabGroup
            tabs={tabs}
            currTab={currTab}
            changeTab={changeTab}
            tabColour={tabColours[currTab]}
            changeTabPageIndex={changeTabPageIndex}
          />
          {/* About the landowner */}
          {currTab === tabs[0] ?
            <LandownerDetails
              currPaymentRequest={props.currPaymentRequest}
              tabPages={requestTabPages}
            /> : null
          }

          {/* About the property */}
          {currTab === tabs[1] ?
            <PropertyDetails
              currPaymentRequest={props.currPaymentRequest}
              tabPages={propertyTabPages}
              currTabPageIndex={currTabPageIndex}
              changeTabPageIndex={changeTabPageIndex}
            /> : null
          }

          {/* About the request */}
          {currTab === tabs[2] ?
            <RequestDetails
              currPaymentRequest={props.currPaymentRequest}
              tabPages={requestTabPages}
              currTabPageIndex={currTabPageIndex}
              changeTabPageIndex={changeTabPageIndex}
            /> : null
          }

          {/* Tracking status */}
          {currTab === tabs[3] ?
              <TrackStatus
                currPaymentRequest = {props.currPaymentRequest}
              />
            : null
          }

          {(!('processed' in props) || props.processed === false) &&(
          <div className="button-box">
            <Link to={match.url + "/accept"} className="accept-btn">
              Accept Request
            </Link>
            <Link to={match.url + "/decline"} className="decline-btn">
              Decline Request
            </Link>
          </div>)}
        </div>
      </div>
    )
    let curr = 1;
    if (props.currPaymentRequest !== null && props.currPaymentRequest.status === PaymentStatus.InProgress){
      curr = 2;
    } else if (props.currPaymentRequest !== null && (props.currPaymentRequest.status === PaymentStatus.Accepted || props.currPaymentRequest.status === PaymentStatus.Declined)){
      curr = 3;
    }
    return (
      <PaymentDashboardFrame
        body={body}
        current={curr}
      />
    )
  }
}
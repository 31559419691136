import React from 'react';
import LULCDisplay from '../components/reports/lulc-display.js';
import './report-display-page.css'

export function LULCDisplayPage(props) {
  return (
    <div className='report-display'>
      <LULCDisplay searchState={props.searchState}/>
    </div>
  );
}
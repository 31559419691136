import React, {useState} from "react";
import './tab-group-incoming.css'

/**
 * A horizonal group of buttons that can function as a selector menu
 * 
 * @param {Array<string>}  tabs       Array of tab names
 * @param {string}         currTab    Name of the current selected tab (must be in props.tabs)
 * @param {function}       changeTab  Function to change currTab
 */
export function TabGroup(props) {
  const tabWidth = {
    width: 13.7 * props.tabs.length + "em"
  };

  return (
    <div className='tab-group' style={tabWidth}>
      {
        props.tabs.map((tab, idx) => (
          <button className={'tab-item' + (tab == props.currTab ? ' selected-tab':'')}
            style={{
              borderTopLeftRadius: (idx === 0)? '20px':'0px' ,
              borderBottomLeftRadius: (idx === 0)? '20px':'0px',
              borderTopRightRadius: (idx === props.tabs.length - 1)? '20px':'0px' ,
              borderBottomRightRadius: (idx === props.tabs.length - 1)? '20px':'0px'
            }}
            key={tab}
            // active={props.currTab === tab}
            onClick={() => {
                props.changeTab(tab);
            }}
          >
            {tab}
          </button>
        ))
      }
    </div>
  )
}
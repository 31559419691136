import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";

import {ProjectManagement} from './project-management';
import PaymentManagement from './payment-management';
import LoginPage from './login-page';
import {WebsiteFrame} from '../components/modules/website-frame'
import ContactForm from './contact';
import {NDVIReportChart} from './ndvi-report-chart'

/**
 * Website Component which stores the state related to landsteward
 * and performs client-side routing. State and conditions are passed down
 * to routed components and user is fetched when this component loads
 */
export class Landsteward extends React.Component {
  constructor(props){
    super(props);
    let state = sessionStorage.getItem("webState")  //gets the webstate
    if (state) {                          //checks to see if thre
      this.state = JSON.parse(state);     //parses this state 
    }
    else {
      this.state = {
        account_id: '',
        email: '',
        first_name: '',
        last_name: '',
        token: '',
        user_type: '',
      }
    }
    
    this.changeState = this.changeState.bind(this);
  }

  changeState(state) {
    this.setState(state)
    // console.log("changed state:", this.state)
  }

  componentDidUpdate(prevState) {
    if (this.state.token !== prevState.token) {
      sessionStorage.setItem("webState", JSON.stringify(this.state));
    }
  }

  render() {
    return (
        <Switch>
          <Route path='/login' render = {(props) => (
            this.state.token !== "" ? 
            <Redirect to="/project"></Redirect>
            :
            <LoginPage
              changeState={this.changeState}
              state={this.state}
            />
          )}/>
          
          <Route path='/project' render = {(props) => (
            <WebsiteFrame
              pathList={[{name: 'project', instance: 'Korotu'}]}
              {...this.state}
              changeState={this.changeState}
            >
              <ProjectManagement
                state={this.state}
              />
            </WebsiteFrame>
          )}/>

          <Route path='/payment' render = {(props) => (
            <WebsiteFrame
              pathList={[{name: 'payment', instance: 'Korotu'}]}
              {...this.state}
              changeState={this.changeState}
            >
              <PaymentManagement
                
              />
            </WebsiteFrame>
          )}/>

          <Route path='/contact' render = {(props) => (
            // this.state.token !== '' ? 
            // <Redirect to="/project"></Redirect>
            // :
            <WebsiteFrame
              pathList={[{name: 'contact', instance: 'Korotu'}]}
              {...this.state}
              changeState={this.changeState}
            >
              <ContactForm
                
              />
            </WebsiteFrame>
          )}/>

          <Route path='/' render = {(props) => (
            <Redirect to="/login"></Redirect>
          )}/>

        </Switch>
    )
  }
}
import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import { PaymentStatus } from '../../webpages/payment-management';


/** APIs*/


import './payment-request-finish-form.css'

/**
 * 
 * @param {*} props 
 *    <paymentID>
 *    <owner>: landowner
 *    <ownerOrg>: the organization the landowner belongs to
 *    <status>: accept if the request is being accepted, decline if the request is being rejected 
 *    <changePaymentStatus>: change request's status in state data
 *    <isBuyer>: true if it is buyer viewing this form
 * @returns 
 */
export function PaymentRequestFinishForm(props) {

  const [landownerNote, setLandownerNote] = useState('');                   // note to landowner
  const [buyerNote, setBuyerNote] = useState('');     // note to buyer
  const [ownerLandtrustNote, setOwnerLandtrustNote] = useState('');     // note to landtrust and owner
  const [redirect, setRedirect] = useState(false); 

  /**
   * change status of current request to either accepted or declined
   * @param {*} e 
   */
  async function completeRequest(e) {
    e.preventDefault();
    let success = true;
/*
    if (newProjectID !== null) {
      await setProjectID(newProjectID);
      success = true;
    }*/

    /** update status in website State */
    if (success) {
      /** Updated Website State and then Route back to incomeing request page */
      if (props.status === PaymentStatus.Declined && !props.isBuyer){
        await props.changePaymentStatus(props.paymentID, PaymentStatus.Declined, landownerNote, null, null);
      } else if (props.status === PaymentStatus.Declined){
        await props.changePaymentStatus(props.paymentID, PaymentStatus.Declined, null, null, ownerLandtrustNote);
      } else if (!props.isBuyer) {
        await props.changePaymentStatus(props.paymentID, PaymentStatus.InProgress, landownerNote, buyerNote, null);
      } else{
        await props.changePaymentStatus(props.paymentID, PaymentStatus.Accepted, null, null, ownerLandtrustNote);
      }
      
      await setRedirect(true);
    } else {
      console.error('Complete Payment Request Failed');
    }
  }

  if (redirect) {
    return (<Redirect to={window.location.pathname.split('/').slice(0,-2).join('/')}/>)
  } else {
    return (
      <React.Fragment>
        <div className='display-payment-info' style={{textAlign:'left'}}>
          You have  {props.status==PaymentStatus.Accepted ? <span className='accepted'>accepted </span>:<span className='declined'>declined </span>}{props.owner}'s payment request for {props.ownerOrg}.
        </div>

        <form className='request-finish__form'>
          <div className="request-finish__form__body">

          {props.isBuyer === true &&(
            <div className='request-form-field'>
              <label htmlFor='project-new-name'> Note to landtrust and landowner({props.owner})</label>
              
              <textarea
                className = 'request-form-textarea'
                name='note-to-landowner'
                value={ownerLandtrustNote}
                onChange={(event => setOwnerLandtrustNote(event.target.value))}
              />
            </div>
          )
          }


          {props.isBuyer === false &&(
            <div className='request-form-field'>
              <label htmlFor='project-new-name'> Note to landowner({props.owner})</label>
              
              <textarea
                className = 'request-form-textarea'
                name='note-to-landowner'
                value={landownerNote}
                onChange={(event => setLandownerNote(event.target.value))}
              />
            </div>
          )
          }

          {props.status === PaymentStatus.Accepted && props.isBuyer === false &&(
            <div className='request-form-field'>
              <label htmlFor='note-to-buyer'> Note to buyer</label>
              
              
              <textarea
                className = 'request-form-textarea'
                name='note-to-buyer'
                value={buyerNote}
                onChange={(event => setBuyerNote(event.target.value))}
              />
            </div>
          )
          }
          </div>

          <div className= "finish-form-button">
            <button onClick={completeRequest}>Finish</button>
          </div>
        </form>
      </React.Fragment>

    )
  }
}
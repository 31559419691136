import React, { useContext } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ProjectDetailsPage } from "./project-details-page";
import { ProjectCarbonDetails } from "./project-carbon-reports";
import { ProjectNewPage } from "./project-new-page";
import { RegionNew } from "./region-new";
import { RegionNewForest } from "./region-new-forest";
import { RegionNewForestCustom } from "./region-new-forest-custom";
import { ProjectRegionPage } from "./project-region-page";

import { ProjectSelectionPage } from "./project-selection-page";
import { SatelliteImageSelectionPage } from "./satellite-image-selection-page";
import { ReportDisplayPage } from "./report-display-page";
import { ReportSelectionPage } from "./report-selection-page";
import { SatelliteImageCreatePage } from "./satellite-image-create-page";
import { ReportCreationPage } from "./report-create-page";

import { SatelliteImageDisplayPage } from "./satellite-image-display-page";
import { LandCoverCreationPage } from "./land-cover-create-page";
import { LandCoverSelectionPage } from "./land-cover-selection-page";
import { LULCDisplayPage } from "./land-cover-display-page";

import { ManualChangeDetectionPage } from "./manual-change-detection-page";

import { AutoSelectionPage } from "./automated-selection-page";
import { AutoCreatePage } from "./automated-create-page";
import { AutoDisplayPage } from "./automated-display-page";

import { CarbonDisplayPage } from "./carbon-display-page";
import { GEDIDetailsPage } from "./gedi-details";

import ProjectLandownerPage from './project-landowner-page';
import LandownerNewPage from './landowner-new-page';
import PropertyNewPage from './property-new-page';
import LandownerSelectPage from './landowner-select-page';
import { NDVICreationPage } from './ndvi-create-page';
import { NDVISelectionPage } from './ndvi-selection-page';
import { NDVIDisplayPage } from './ndvi-display-page';
import { NDVIPropertyPage } from "./ndvi-property-page";
import { NDVIEditPropertyPage } from "./ndvi-edit-property-page";
import { NDVIReportChart } from './ndvi-report-chart'
import { NDVIReportChartDelta } from './ndvi-report-chart-delta'
import AllPropertiesPage from './all-properties-page';
import MowingBrowsePage from './mowing-browse-page';
import { MowingCreatePage } from './mowing-create-page';
import { MowingDisplayPage } from './mowing-display-page';
import { GEDIPage } from './gedi-page';
import { ForestPage } from './forest-page';
import { ForestPageCustom } from './forest-page-custom';
import { CarbonReportPage } from './carbon-report-page';
import { GEDIViewPage } from './gedi-view-page';
import { GEDIPageMultiple } from "./gedi-page-multiple";
import { MowingUpdatePage } from './mowing-update-page';
import { NDVIComparePage } from './ndvi-map-compare-page';
import { ViewForestChangePage } from './view-forestchange-page';
import { AutoDetectionPage } from "./automatic-detection-page";
import { SummaryPage } from "./summary-page";



/**
 *  Performs Routing for the ProjectManagement and passes down props from
 *  the ProjectManagement State Component down to children. Also passes
 *  down user information from the website component
 * @param {*} props
 *    <webState>:
 *    <webStateMutate>:
 */
export function ProjectManagementRouting(props) {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:projectID/gediView`} render={(routeProps) => (
        <GEDIViewPage
          project={props.currProject}
          changeProject={props.changeProject}
          region={props.currRegion}
        />
      )} />
      
      <Route path={`${match.path}/:projectID/view_carbon_report/:reportName`} render={(routeProps) => (
        <GEDIViewPage
          project={props.currProject}
          changeProject={props.changeProject}
          region={props.currRegion}
          report={props.currReport}
          changeReport={props.changeReport}
        />
      )} />

      <Route path={`${match.path}/:projectID/reports`} render={(routeProps) => (
        <ProjectCarbonDetails
          project={props.currProject}
          landowners={props.landowners}
          regions={props.regions}
          region={props.currRegion}
          changeRegion={props.changeRegion}
          changeProject={props.changeProject}
          addRegion={props.addRegion}
          reports={props.reports}
          reportRegions={props.reportRegions}
          changeReportRegions={props.changeReportRegions}
        />
      )} />

      <Route path={`${match.path}/:projectID/new-region`} render={(routeProps) => (
        <RegionNew
          account_id = {props.account_id}
          project={props.currProject}
          addProject={props.addProject}
          currRegion={props.currRegion}
          changeRegion={props.changeRegion}
        />
      )} />

      <Route path={`${match.path}/:projectID/new-region-forest`} render={(routeProps) => (
        <RegionNewForest
          account_id = {props.account_id}
          project={props.currProject}
          addProject={props.addProject}
          currRegion={props.currRegion}
          changeRegion={props.changeRegion}
        />
      )} />

      <Route path={`${match.path}/:projectID/new-region-forest-custom`} render={(routeProps) => (
        <RegionNewForestCustom
          account_id = {props.account_id}
          project={props.currProject}
          addProject={props.addProject}
          currRegion={props.currRegion}
          changeRegion={props.changeRegion}
          changeProject={props.changeProject}
        />
      )} />

      

      <Route path={`${match.path}/:projectID/property/:propertyID/satellite_image/new`} render={(routeProps) => (
        <SatelliteImageCreatePage
          addSatelliteImage={props.addSatelliteImage}
          searchState={props.getFilterListForObject}
          satelliteImage={props.currSatelliteImage}
        />
      )} />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/satellite_image/:satelliteImageID`}
        render={(routeProps) => (
          <SatelliteImageDisplayPage
            satelliteImages={props.satelliteImages}
            satelliteImage={props.currSatelliteImage}
            project={props.currProject}
            region={props.currRegion}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/satellite_image`}
        render={(routeProps) => (
          <SatelliteImageSelectionPage
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/report/new`}
        render={(routeProps) => (
          <ReportCreationPage
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            region={props.currRegion}
            satelliteImage={props.currSatelliteImage}
            changeCurrentState={props.changeCurrentState}
            addReport={props.addReport}
            changeSatelliteImage={props.changeSatelliteImage}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/report/:reportID`}
        render={(routeProps) => (
          <ReportDisplayPage searchState={props.getFilterListForObject} />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/report`}
        render={(routeProps) => (
          <ReportSelectionPage
            reports={props.reports}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/lulc/new`}
        render={(routeProps) => (
          <LandCoverCreationPage
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            region={props.currRegion}
            changeCurrentState={props.changeCurrentState}
            addLandCover={props.addReport}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/lulc/:landcoverID`}
        render={(routeProps) => (
          <LULCDisplayPage searchState={props.getFilterListForObject} />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/lulc`}
        render={(routeProps) => (
          <LandCoverSelectionPage
            reports={props.reports}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/ndvi/new`}
        render={(routeProps) => (
          <NDVICreationPage
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
            addNdvi={props.addReport}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/ndvi/:ndviID`}
        render={(routeProps) => (
          <NDVIDisplayPage searchState={props.getFilterListForObject} />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/ndvi`}
        render={(routeProps) => (
          <NDVISelectionPage
            reports={props.reports}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/manual-detection`}
        render={(routeProps) => (
          <ManualChangeDetectionPage
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
            computeCentroid={props.computeCentroid}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/:mowingID/ndvi-compare`}
        render={(routeProps) => (
          <NDVIComparePage
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
            computeCentroid={props.computeCentroid}
          />
        )}
      />
      <Route
        path={`${match.path}/:projectID/:mowingID/ndvi-report-chart`}
        render={(routeProps) => (
          <NDVIReportChart
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
            computeCentroid={props.computeCentroid} />
        )}
      />

      <Route
        path={`${match.path}/:projectID/:mowingID/ndvi-report-chart-delta`}
        render={(routeProps) => (
          <NDVIReportChartDelta
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
            computeCentroid={props.computeCentroid} />
        )}
      />

      <Route
        path={`${match.path}/:projectID/:mowingID/ndvi-property-page`}
        render={(routeProps) => (
          <NDVIPropertyPage
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            regions={props.regions}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegions={props.changeRegions}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/ndvi-edit-property-page`}
        render={(routeProps) => (
          <NDVIEditPropertyPage
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            regions={props.regions}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegions={props.changeRegions}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/view-forestchange`}
        render={(routeProps) => (
          <ViewForestChangePage
            project={props.currProject}
            landowners={props.landowners}
            regions={props.regions}
            region={props.currRegion}
            changeRegion={props.changeRegion}
            changeProject={props.changeProject}
            addRegion={props.addRegion}
            reports={props.reports}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/:mowingID/automatic-detection`}
        render={(routeProps) => (
          <AutoDetectionPage 
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
            computeCentroid={props.computeCentroid}
            />
        )}
      />

      <Route
        path={`${match.path}/:projectID/:mowingID/summary-page`}
        render={(routeProps) => (
          <SummaryPage
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            regions={props.regions}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegions={props.changeRegions}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/lulcautodetection/new`}
        render={(routeProps) => (
          <AutoCreatePage
            reports={props.reports}
            satelliteImages={props.satelliteImages}
            project={props.currProject}
            region={props.currRegion}
            changeCurrentState={props.changeCurrentState}
            addAuto={props.addReport}
            searchState={props.getFilterListForObject}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/lulcautodetection/:autoID`}
        render={(routeProps) => (
          <AutoDisplayPage
            searchState={props.getFilterListForObject}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/lulcautodetection`}
        render={(routeProps) => (
          <AutoSelectionPage
            reports={props.reports}
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/carbon-report`}
        render={(routeProps) => (
          <CarbonDisplayPage searchState={props.getFilterListForObject} />
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/carbon-report/:carbonID`}
        render={(routeProps) => (
          // <CarbonSelectionPage
          //   reports={props.reports}
          //   project={props.currProject}
          //   region={props.currRegion}
          //   changeProject={props.changeProject}
          //   changeRegion={props.changeRegion}
          //   changeCurrentState={props.changeCurrentState}
          // />
          <div></div>
        )}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID/carbon-report/new`}
        render={(routeProps) => <div></div>}
      />

      <Route
        path={`${match.path}/:projectID/property/:propertyID`}
        render={(routeProps) => (
          <ProjectRegionPage
            project={props.currProject}
            region={props.currRegion}
            changeProject={props.changeProject}
            changeRegion={props.changeRegion}
            searchState={props.getFilterListForObject}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/landowner/new`}
        render={(routeProps) => (
          <LandownerNewPage
            project={props.currProject}
            changeProject={props.changeProject}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/mowingbrowse`}
        render={(routeProps) => (
          <MowingBrowsePage
            project={props.currProject}
            changeProject={props.changeProject}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />
      <Route
        path={`${match.path}/:projectID/mowingcreate`}
        render={(routeProps) => (
          <MowingCreatePage
            project={props.currProject}
            changeProject={props.changeProject}
            addSatelliteImage={props.addSatelliteImage}
            searchState={props.getFilterListForObject}
            satelliteImage={props.currSatelliteImage}
          />
        )}
      />

      <Route path={`${match.path}/:projectID/mowing-update-page`} render={(routeProps) => (
        <MowingUpdatePage
        project={props.currProject}
        changeProject={props.changeProject}
        addSatelliteImage={props.addSatelliteImage}
        searchState={props.getFilterListForObject}
        satelliteImage={props.currSatelliteImage}
        />
      )} 
      />

      <Route
        path={`${match.path}/:projectID/:mowingID/mowingdisplay`}
        render={(routeProps) => (
          <MowingDisplayPage
            project={props.currProject}
            changeProject={props.changeProject}
            addSatelliteImage={props.addSatelliteImage}
            searchState={props.getFilterListForObject}
            satelliteImage={props.currSatelliteImage}
            changeCurrentState={props.changeCurrentState}
            computeCentroid={props.computeCentroid}
          />
        )}
      />

      <Route
        exact
        path={`${match.path}/:projectID/landowner/:landownerID/new`}
        render={(routeProps) => (
          <PropertyNewPage
            project={props.currProject}
            changeProject={props.changeProject}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/landowner/:landownerID/allproperties`}
        render={(routeProps) => (
          <AllPropertiesPage
            project={props.currProject}
            landowners={props.landowners}
            changeProject={props.changeProject}
            satelliteImages={props.satelliteImages}
            region={props.currRegion}
            changeCurrentState={props.changeCurrentState}
          />
        )}
      />
      {/* 
      <Route
        path={`${match.path}/:projectID/landowner/select`}
        render={(routeProps) => <LandownerSelectPage />}
      /> */}

      <Route
        path={`${match.path}/:projectID/landowner/:landownerID`}
        render={(routeProps) => (
          <ProjectLandownerPage
            project={props.currProject}
            landowners={props.landowners}
            changeProject={props.changeProject}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/gedi`}
        render={(routeProps) => (
          <GEDIPage
            removeProject = {props.removeProject}
            removeReport = {props.removeReport}
            project={props.currProject}
            changeProject={props.changeProject}
            region={props.currRegion}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/gedi-multiple`}
        render={(routeProps) => (
          <GEDIPageMultiple

            removeProject = {props.removeProject}
            removeReport = {props.removeReport}
            project={props.currProject}
            changeProject={props.changeProject}

            regions={props.currRegion}
            reportRegions={props.reportRegions}
            changeReportRegions={props.changeReportRegions}
          />
        )}
      />

      <Route
        path={`${match.path}/:projectID/forest`}
        render={(routeProps) => (
          <ForestPage
            removeProject = {props.removeProject}
            removeReport = {props.removeReport}
            project={props.currProject}
            changeProject={props.changeProject}
            addSatelliteImage={props.addSatelliteImage}
            searchState={props.getFilterListForObject}
            satelliteImage={props.currSatelliteImage}
            region={props.currRegion}
          />
        )}
      />   
            <Route
        path={`${match.path}/:projectID/forest-custom`}
        render={(routeProps) => (
          <ForestPageCustom
            removeProject = {props.removeProject}
            removeReport = {props.removeReport}
            project={props.currProject}
            changeProject={props.changeProject}
            addSatelliteImage={props.addSatelliteImage}
            searchState={props.getFilterListForObject}
            satelliteImage={props.currSatelliteImage}
            region={props.currRegion}
          />
        )}
      />  

      <Route
            path={`${match.path}/:projectID/gedi-multiple`}
            render={(routeProps) => (
              <GEDIPageMultiple
    
                removeProject = {props.removeProject}
                removeReport = {props.removeReport}
                project={props.currProject}
                changeProject={props.changeProject}
    
                regions={props.currRegion}
                reportRegions={props.reportRegions}
                changeReportRegions={props.changeReportRegions}
              />
            )}
          />

          <Route
            path={`${match.path}/:projectID/forest`}
            render={(routeProps) => (
              <ForestPage
                removeProject = {props.removeProject}
                removeReport = {props.removeReport}
                project={props.currProject}
                changeProject={props.changeProject}
                addSatelliteImage={props.addSatelliteImage}
                searchState={props.getFilterListForObject}
                satelliteImage={props.currSatelliteImage}
                region={props.currRegion}
              />
            )}
          />   

      <Route path={`${match.path}/:projectID/carbon_report`} render={(routeProps) => (
        <CarbonReportPage
          longitude={-78.617812}
          latitude={44.15}
          zoom={14}
          style="mapbox://styles/mapbox/streets-v11"
          mapWidth={'100%'}
          mapHeight={'40rem'}
        />
      )} />

      <Route path={`${match.path}/:projectID/gedi_details`} render={(routeProps) => (
        <GEDIDetailsPage
          project={props.currProject}
          changeProject={props.changeProject}
        />
      )} />

      <Route path={`${match.path}/new`} render={(routeProps) => (
        <ProjectNewPage
          account_id = {props.account_id}
          project={props.currProject}
          addProject={props.addProject}
        />
      )} />

      <Route
        path={`${match.path}/:projectID`}
        render={(routeProps) => (
          <ProjectDetailsPage
            project={props.currProject}
            landowners={props.landowners}
            regions={props.regions}
            region={props.currRegion}
            changeRegion={props.changeRegion}
            changeProject={props.changeProject}
            addRegion={props.addRegion}
            reports={props.reports}
          />
        )}
      />

      <Route
        path={`${match.path}/`}
        render={(routeProps) => (
          <ProjectSelectionPage 
            projects={props.projects} 
            removeProject={props.removeProject}
            addProject={props.addProject}
          />
        )}
      />
    </Switch>
  );
}

import React from "react";
import mapboxgl from "!mapbox-gl";
import MapboxCompare from "mapbox-gl-compare";
import SliderArrows from "../../images/slider-arrows.svg";
import HamburgerMenu from '../modules/hamburger-menu';
import RemoteSensingIcon from "../../images/remote-sensing.png"
import TreeIcon from "../../images/Tree-icon.svg"
import LegendImage from "../../images/legend.png"
import "./mapbox-compare.css";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia29yb3R1LWNvbnJveS10cmluaCIsImEiOiJja2pyeTh0cnU2aDViMnFwOTFrZHoxdDU3In0.VEuiNCwqBGL8CidAvTgdjA";

/**
 * MapboxCompare Slider is used to compare two different mapboxes of
 * different styles. Each mapbox may also have a raster image display on
 * them at the *same* location. The slider is used to swipe between the
 * two maps two allow for an avid comparison of the different images/maps
 * Interactable features of each map must be added separately to each
 * map (factor a Component out in this case).
 */
export class MapboxCompareSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayImages: true,
      showLegend: true,
      showLegendButton: true,
       
    };

    /** PROPS
     *  projectName
     *  projectDescription
     *  latitude
     *  longitude
     *  zoom
     *  setRegionID
     *  setRegionName
     *  project
     *  NOTE: Mapbox ID is the same as the UUID used in Django Backend
     *  beforeRaster: The Mapbox ID identifying the before image
     *  beforeStyle: The style on which we wish to place the before image
     *  afterRaster: The Mapbox ID identifying the after image
     *  afterStyle: The style on which we wish to place the after image
     */
  }

  toggleLegend = () => {
    this.setState((prevState) => ({
      showLegend: !prevState.showLegend,
    }));
  };

  componentDidMount() {
    {
      /** Before Side Map*/
    }
    const beforeMap = new mapboxgl.Map({
      container: "before", // 'before' is the id of a div container
      style: this.props.beforeStyle,
      // style: 'mapbox://styles/mapbox/streets-v9',
      // style: 'mapbox://styles/mapbox/dark-v10',
      center: [this.props.longitude, this.props.latitude],
      zoom: this.props.zoom,
      logoPosition: "bottom-left",
      attributionControl: true,
    });

    beforeMap.on("idle", function () {
      beforeMap.resize();
    });
    // beforeMap.addControl(new mapboxgl.AttributionControl(), 'top-left');

    this.props.beforeMap.current = beforeMap;

    {
      /** After Side Map*/
    }
    const afterMap = new mapboxgl.Map({
      container: "after", // 'after' is the id of a div container
      style: this.props.afterStyle,
      // style: 'mapbox://styles/mapbox/streets-v9',
      // style: 'mapbox://styles/mapbox/dark-v10',
      center: [this.props.longitude, this.props.latitude],
      zoom: this.props.zoom,
      attributionControl: true,
    });
    // afterMap.addControl(new mapboxgl.AttributionControl(), "top-right");

    afterMap.on("idle", function () {
      afterMap.resize();
    });

    this.props.afterMap.current = afterMap;

    let container = ".sliderContainer"; // classname of the container


    let compare = new MapboxCompare(beforeMap, afterMap, container, {
      // Set this to enable comparing two maps by mouse movement:
      //mousemove: true,
      //orientation: 'vertical'
    });

    this.props.compare.current = compare;

    // styling the slider arrow
    let slider = compare._swiper;
    slider.style.backgroundColor = "white";
    slider.style.width = "74px";
    slider.style.height = "48px";
    slider.style.borderRadius = "40%";
    slider.style.left = "-34px";
    slider.style.backgroundImage = `url(${SliderArrows})`;
    slider.style.backgroundSize = "50px";
    slider.style.backgroundPosition = "center";
    slider.style.backgroundRepeat = "no-repeat";
    slider.parentElement.style.width = "6px";

  }

  render() {
 const buttonStyle = {
    borderRadius: this.state.showLegend ? '50% 50% 0 0' : '50%',
    border: 'none', 
    bottom: this.state.showLegend ? '100px' : '10px',
  };
  
    return (
      <div id="compare" className="sliderContainer">
        <div id="before" className="sliderHalf"></div>
        <div id="after" className="sliderHalf"></div>

        <button
          className={`legend-toggle-button ${this.state.showLegend ? '' : 'collapsed'}`}
          onClick={() => this.setState((prevState) => ({ showLegend: !prevState.showLegend }))}
          style={{...buttonStyle, display: this.state.showLegendButton ? 'block' : 'none'}}
        />
        
        <div className="legend-image-container" style={{ display: this.state.showLegend ? 'block' : 'none' }}>
          <div className="legend-image">
            <img src={LegendImage} alt="Legend" style={{ width: '100%', height: 'auto' }} />
          </div>
          

        </div>
        <HamburgerMenu 
          projectName={this.props.projectName}
          projectDescription={this.props.projectDescription}
          transparency={this.props.transparency}
          onTransparencyChange={this.props.onTransparencyChange} />
        <div className="items-container">
          <div className="item-wrapper">
            <div className="title-item">
              Overlay
            </div>
          </div>
          <div className="item-wrapper">
            <a
              className="btn-item"
              style={
                this.props.trueColour
                  ? { background: "var(--light-primary)",
                      "border-bottom": "4px solid var(--primary)",
                      "margin-bottom": "-4px" 
                    }
                  : null
              }
              onClick={
                !this.props.trueColour 
                  ? () => {
                      this.props.trueColourToggle();
                      this.setState({ showLegend: false, showLegendButton: false});
                    }
                  : () => { }
              }
            >
              <img className="logo-img" src={RemoteSensingIcon}></img>
              Satellite
            </a>
          </div>
          <div className="item-wrapper">
            <a
              className="btn-item"
              style={
                !this.props.trueColour
                  ? { background: "var(--light-primary)",
                      "border-bottom": "4px solid var(--primary)"
                    }
                  : null
              }
              onClick={
                this.props.trueColour 
                  ? () => {
                      this.props.trueColourToggle();
                      this.setState({ showLegend: true, showLegendButton: true });
                    }
                  : () => { }
              }
            >
              <img className="logo-img" src={TreeIcon}></img>
              Vegetation
            </a>
          </div>
        </div>
      </div>
    );
  }
}

import Back from '../images/icons/back-icon.png'
import { createCustomRegion, createProject, getCustomRegions, getProjectRegions, createCustomProperty } from "../apis/landsteward/accounts"
import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    Component,
   } from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import './project-new-page.css'
import { MapboxRegionSelection } from '../components/mapbox/mapbox-region-selection';
import "./region-new.css"
import { MOCK_DATA } from '../apis/landsteward/urlconfig';
import { MapboxProperty } from '../components/mapbox/mapbox-property';


export function RegionNewForestCustom(props) {
    const history = useHistory()
    const location = useLocation()
    const regionsList = useRef(null)
    const params = useParams()
    const [warning, setWarning] = useState('');
    const [regionID, setRegionID] = useState('');
    const [regionName, setRegionName] = useState('');
    const [service, setService] = useState("forest-change");
    const [features, setFeatures] = useState([]);
    const [projectID, setProjectID] = useState(null);
    const [regions, setRegions] = useState([]);
    const [inputList, setInputList] = useState([]);
    const [currentProject, setCurrentProject] = useState(props.project);

    let name = location.state && location.state.name;
    let description = location.state && location.state.description;


    function calculateCentroid(coordinates, type) {
      let sumX = 0, sumY = 0, numPoints = 0;
  
      if (type === 'Polygon') {
          coordinates[0].forEach(coord => {
              sumX += coord[0];
              sumY += coord[1];
              numPoints++;
          });
      } else if (type === 'MultiPolygon') {
          coordinates.forEach(polygon => {
              polygon[0].forEach(coord => {
                  sumX += coord[0];
                  sumY += coord[1];
                  numPoints++;
              });
          });
      }
      return [sumX / numPoints, sumY / numPoints];
  }


  /**
   * Creates new projects, seperate function from projects of type carbon
   * @param {*} e Event Object
   * @returns void
   */
  async function createNewProject(e) {
    e.preventDefault();
    let region;
    let test;
    let createdRegions = [];
    let success = false;

    //MULTIPROPERTY
    if (features && features.length > 1) {

      for (let i = 1; i < features.length; i++) {
          console.log(features)
          console.log([features[i]]);
          const regionName = `Custom Region ${i}`;

          region = await createCustomRegion(regionName, features[i], props.project.projectID);
          if (!region) {
              setWarning('Please select a geojson file of choose an area on map')
              console.error(`Creating region failed`);
              
              return;
          }
          setWarning(`UPLOADING REGIONS! PLEASE DO NOT LEAVE PAGE! \n
                            Uploaded region ${regionName}`)
          const geojson = JSON.parse(JSON.stringify(features[i]));
          console.log(geojson)
          const coordinates = geojson.geometry.coordinates;
          console.log(coordinates);
          const type = geojson.geometry.type;
          console.log(type)
          createdRegions.push(region);
          console.log(region);
      const centroid = calculateCentroid(coordinates, type);
          console.log(centroid);

          const body = {
              property_name: regionName,
              longitude: centroid[0],
              latitude: centroid[1],
              perimeter_meter: 100,
              area_hectares: 0,
              region: region.regionID,
          };
          console.log(body);

          const property = await createCustomProperty(body);
          if (!property) {
              console.error("Property creation failed");
              return;
          }

      }   
  //SINGLE-PROPERTY
  } else {
    const regionName = `Custom Region`;
    region = await createCustomRegion(regionName, features[0], props.project.projectID);
          if (!region) {
              setWarning('Please select a geojson file of choose an area on map')
              console.error(`Creating region failed`);
              
              return;
          }
          setWarning(`UPLOADING REGIONS! PLEASE DO NOT LEAVE PAGE! \n
                            Uploaded region ${regionName}`)
          const geojson = JSON.parse(JSON.stringify(features[0]));
          console.log(geojson)
          const coordinates = geojson.geometry.coordinates;
          console.log(coordinates);
          const type = geojson.geometry.type;
          console.log(type)
          createdRegions.push(region);
          console.log(region);
      const centroid = calculateCentroid(coordinates, type);
          console.log(centroid);

          const body = {
              property_name: regionName,
              longitude: centroid[0],
              latitude: centroid[1],
              perimeter_meter: 100,
              area_hectares: 0,
              region: region.regionID,
          };
          console.log(body);

          const property = await createCustomProperty(body);
          if (!property) {
              console.error("Property creation failed");
              return;
          }
  }

    success = true;

    /** Add Project to website State */
    if (success) {
      // MULTIPROPERTY
      if (features.length >= 1) {
        /** Updated Website State and then Route to project */
      //console.log(props.project.projectID)
        console.log(props.project.projectID)
        // console.log(newProjectID)
        // let response = await props.addProject({ name: name, description: description, projectID: newProjectID, service: "forest-change", region: region ? region.regionID : null });

        history.push({
          pathname: `/project/${props.project.projectID}/forest-custom`,
          state: { name: name, description: description}
        });
      }
      
    } else {
      console.error('Project Creation Failed');
      alert("Region Creation Failed. Make sure you have a completed boundary!");
    }
  }

    return (
        <div className='project-new__body'>
            <div className='project-new__back-col'>
            </div>

            <div className='project-new__form-col'>
              <div className="project-new-title">
                <h1>Define Project Area</h1>
              </div>
              <div className='mapbox-selection-div'>
                 <MapboxRegionSelection
                    longitude={-63.14}
                    latitude={46.25}
                    zoom={9}
                    style='mapbox://styles/korotu-conroy-trinh/ckwqmzmif0lwq14qtiz1mdc1u'
                    setRegionID={setRegionID}
                    setRegionName={setRegionName}
                    drawnFeatures={features}
                    setDrawnFeatures={setFeatures}
                    mapWidth={"100%"}
                    mapHeight={"30rem"}
                    drawEnabled={true}
                    watershedsEnabled={true}
                    allowOnlySingleDrawnRegion={false}
                    customPreloadedFeatures={false}
                  />
                <script src='https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.0/mapbox-gl-geocoder.min.js'></script>
                <link rel='stylesheet' href='https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.0/mapbox-gl-geocoder.css' type='text/css' />
                <br />
                </div> 
                <h3>
                  {regionName ? `Selected ${regionName}` : ''}
                </h3>
                <div className='project-buttons'>
                  <button className="project-next-button"
                    onClick={createNewProject}
                  >
                    Create and Finish
                  </button>
                  <button className="project-next-button"
                    style={{ background: "#E5F4E4", color: "#357960" }}
                    // onClick={decreasePageIndex}
                  >
                    Cancel
                  </button>

                </div>
                <div className='project-instructions'>
                Drag and click map to frame your property, then use the shape tool on the right to draw the boundary. <br/>  
                Double click to confirm the boundary or use the trash icon to remove.
              </div>
              



            </div>
          </div>

    )
}


import React, {useState, useEffect} from 'react';

import './payment-dashboard-detail-page.css'
import PaymentDashboardFrame from './payment-dashboard-frame';
import {useParams} from 'react-router';
import { Redirect, useHistory } from 'react-router-dom';
import Back from '../images/icons/simple-back-icon.png'
import {PaymentRequestFinishForm} from '../components/modules/payment-request-finish-form';
import { PaymentStatus } from './payment-management';

/**
 * @param {JSON}      props.currPaymentRequest    Current payment request object
 * @param {String}    props.targetStatus          either accepted or declined
 * @param {function}  props.changePaymentRequest  Function to change current request object
 * @param {function}  props.changePaymentStatus   Function to change request object's payment status
 */
export default function PaymentFinishFormPage(props) {
  let body
  const history = useHistory()
  let params = useParams();

  useEffect(async () => {
    props.changePaymentRequest(params.paymentID);
  }, []);

  const goBack = () => {
    history.goBack()
  }
  if (props.currPaymentRequest === null){
    //return (<Redirect to={window.location.pathname.split('/').slice(0,-2).join('/')}/>)
  }
  else if (props.currPaymentRequest.status !== PaymentStatus.Incoming){
    body = (
      <><button onClick={goBack} className='back-btn'><img src={Back} /></button>
      <div className='tab-body'>
        <h1>This payment request has already been processed</h1>
      </div></>
    )
  }else{
    body = (
    <><button onClick={goBack} className='back-btn'><img src={Back} /></button>
    <div className='tab-body'>
        <h1>Payment Request #{props.currPaymentRequest !== null ? props.currPaymentRequest.id : null}</h1>
        <PaymentRequestFinishForm
          isBuyer={false}
          changePaymentStatus={props.changePaymentStatus}
          paymentID={props.currPaymentRequest !== null ? props.currPaymentRequest.id : null}
          status={props.targetStatus}
          owner={props.currPaymentRequest !== null ? props.currPaymentRequest.landowner.name : null}
          ownerOrg={props.currPaymentRequest !== null ? props.currPaymentRequest.landowner.organization : null} />
      </div></>
  )
  }

  return (
    <PaymentDashboardFrame
      body={body}
      current={1}
    />
  )
}
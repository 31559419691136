import React, { useEffect, useState } from 'react'
import "./gedi-details.css"
import { useHistory, useParams } from 'react-router-dom'

import upArrow from '../images/icons/arrow-icon-up.png'
import downArrow from '../images/icons/arrow-icon-down.png'

export function GEDIDetailsPage(props) {

    const [menuPanelOpen, setmenuPanelOpen] = useState("")

    let params = useParams()
    useEffect(() => {
        const proc = async () => await props.changeProject(params.projectID)
        proc().catch(console.error)
    }, [])

    return (
        <div className='gedi-details-body'>
            <h1>Introduction to Carbon Calculation</h1>
            <div className="gedi-details">
                <div className="toggle-row" onClick={() => { menuPanelOpen == "carbon_calculation_process" ? setmenuPanelOpen("") : setmenuPanelOpen("carbon_calculation_process") }}>
                    <span>Carbon Calculation Process</span>
                    <img src={menuPanelOpen == "carbon_calculation_process" ? upArrow : downArrow} className="Arrow"></img>
                </div>
                {menuPanelOpen == "carbon_calculation_process" ?
                    <div className="detail-box">
                        <span><strong>Carbon calculation process:</strong><br /><br />
                            All GEDI level 4A vector data are downloaded, subsetted and merged into one geojson layer. <br />
                            For biomass per area to carbon per area calculation, values are multiplied by 0.5 <br />
                            LiDAR samples are collected into arbitrary grid of 1 hectare squares, those without data are deleted. <br />
                            Using IDW interpolation, a raster surface is generated; unsampled areas are populated with data <br />
                            Zonal statistics for the area of interest overlapping the raster are calculated, with implicit per hectare summary statistics included</span>
                    </div> : null}
                <div className="toggle-row" onClick={() => { menuPanelOpen == "biomass_definition" ? setmenuPanelOpen("") : setmenuPanelOpen("biomass_definition") }}>
                    <span>GEDI Definition of Aboveground Biomass</span>
                    <img src={menuPanelOpen == "biomass_definition" ? upArrow : downArrow} className="Arrow"></img>
                </div>
                {menuPanelOpen == "biomass_definition" ?
                    <div className="detail-box">
                        <span><strong>GEDI Definition Aboveground Biomass:</strong><br /><br />
                            GEDI only samples 4% of the Earth’s land, between 51.6 ° North and South with 25m shots that are 60m apart <br />
                            GEDI only collects above ground biomass density data in Mg/ha by precisely measuring forest canopy height, canopy vertical structure and surface elevation</span>
                    </div> : null}
                <div className="toggle-row" onClick={() => { menuPanelOpen == "interpolation_methods" ? setmenuPanelOpen("") : setmenuPanelOpen("interpolation_methods") }}>
                    <span>Interpolation Methods</span>
                    <img src={menuPanelOpen == "interpolation_methods" ? upArrow : downArrow} className="Arrow"></img>
                </div>
                {menuPanelOpen == "interpolation_methods" ?
                    <div className="detail-box">
                        <span><strong>Interpolation Methods:</strong><br /><br />
                            <strong>Inverse Distance Weighting:</strong> Unknown cell values are estimated by averaging near by known values. 
                                                                         The significance of known points on the unknown cell values are based on their distance, controlled by the power level. 
                                                                         The carbon reports are calculated with an IDW power level of 1.5. <br />
                                                                         <ul>
                                                                            <li><strong>With All Forest</strong>: Performs Inverse Distance Weighting interpolation for all forest.</li>
                                                                            <li><strong>By Forest Type</strong>: Performs Inverse Distance Weighting interpolation for each forest type: coniferous, broadleaf, mixedwood, and wetland.</li>
                                                                        </ul></span>
                    </div> : null}
                <div className="toggle-row" onClick={() => { menuPanelOpen == "standard_error" ? setmenuPanelOpen("") : setmenuPanelOpen("standard_error") }}>
                    <span>Standard Error</span>
                    <img src={menuPanelOpen == "standard_error" ? upArrow : downArrow} className="Arrow"></img>
                </div>
                {menuPanelOpen == "standard_error" ?
                    <div className="detail-box">
                        <span><strong>Standard Error:</strong><br /><br />
                            Aboveground Biomass from GEDI is estimated at a 90% confidence level by default. Currently, standard errors for GEDI average around 10.3 tonnes per hectare of aboveground biomass in Eastern Canada. Standard Error varies depending on the location on earth that GEDI is surveying as GEDI standard error varies on the type and density of trees analysed. <br />
                            More information can be found on GEDI’s page: https://daac.ornl.gov/cgi-bin/dataset_lister.pl?p=40</span>
                    </div> : null}
                <h1>Troubleshooting</h1>
                <div className="toggle-row" onClick={() => { menuPanelOpen == "gedi_error" ? setmenuPanelOpen("") : setmenuPanelOpen("gedi_error") }}>
                    <span>Common Errors with the GEDI Search</span>
                    <img src={menuPanelOpen == "gedi_error" ? upArrow : downArrow} className="Arrow"></img>
                </div>
                {menuPanelOpen == "gedi_error" ?
                    <div className="detail-box">
                        <span><strong>Common Errors with the GEDI Search:</strong><br /><br />
                            Sometimes, a search for GEDI level 4A vector data fails. There are several possible causes for this including: <br/>
                            <ol>
                                <li>The selected date range is too small. GEDI data is generally available from 2019 to 2022, and users are recommended to select a start and end date that are around a year apart. </li>
                                <li>The area selected is too small or large. Areas less than 10 HA may not have any GEDI data, and areas greater than 40000 HA may have too much data for the application to process. </li>
                                <li>The area selected does not have any forested areas. GEDI data are not available for non-forested areas, especially in cities and areas with water.</li>
                            </ol>
                        </span>
                    </div> : null}
                <div className="toggle-row" onClick={() => { menuPanelOpen == "total_biomass_error" ? setmenuPanelOpen("") : setmenuPanelOpen("total_biomass_error") }}>
                    <span>Caution when Interpreting Total Biomass</span>
                    <img src={menuPanelOpen == "total_biomass_error" ? upArrow : downArrow} className="Arrow"></img>
                </div>
                {menuPanelOpen == "total_biomass_error" ?
                    <div className="detail-box">
                        <span><strong>Caution when Interpreting Total Biomass:</strong><br /><br />
                        As Soil layers are not always available in riparian areas/along water ways, the areas for Soil Biomass and the other carbon layers (AGB, BGB, DPM) may be different. 
                        Caution should be used when interpreting the total biomass for a project as the layers may be of different sizes. 
                        A possible work around is to view the layers in a GIS tool and consider only those areas that fully overlap.  
                        </span>
                    </div> : null}
                <div className="toggle-row" onClick={() => { menuPanelOpen == "area_error" ? setmenuPanelOpen("") : setmenuPanelOpen("area_error") }}>
                    <span>Caution with Boundaries of Project Areas:</span>
                    <img src={menuPanelOpen == "area_error" ? upArrow : downArrow} className="Arrow"></img>
                </div>
                {menuPanelOpen == "area_error" ?
                    <div className="detail-box">
                        <span><strong>Caution with Boundaries of Project Areas:</strong><br /><br />
                        Due to methodology and source inputs, edges of boundary layers may be overlooked during the report generation process and the final carbon layers may be smaller than expected. 
                        It is recommended for users to select a boundary that is greater than the area of interest by around 250m, and to use simple shapes without many corners.                                                                                                                                                                                                                                                                                    
                        </span>
                    </div> : null}
                
            </div>
        </div >
    )

}

import React, { useEffect, useState } from 'react'
import "./gedi-page.css"

import download from '../images/icons/info-icon.png'
import exit from '../images/icons/exit-icon.png'
import { useHistory, useParams, Prompt, useLocation } from 'react-router-dom'
import { CORE_PORT, url } from '../apis/landsteward/urlconfig'
import useFetch, { useFetchBlob } from '../apis/landsteward/useFetch'
import { createCarbonReport, getCarbonReportCSV, getCarbonReportFiles, searchGranules } from '../apis/landsteward/valuation'

export function GEDIPage(props) {


    
    let params = useParams()
    const history = useHistory()
    let reset = new URLSearchParams(useLocation().search).get("reset")

    let date = new Date()
    date = date.toISOString().split('T')[0].replace(/-/g, '')

    const [reportName, setReportName] = useState("")
    const [satelliteStartDate, setSatelliteStartDate] = useState("")
    const [satelliteEndDate, setSatelliteEndDate] = useState("")

    const [granuleSearchInProgress, setGranuleSearchInProgress] = useState(false)
    const [reportCreationinProgress, setReportCreationInProgress] = useState(false)

    const [areGranulesFound, setareGranulesFound] = useState(false)
    const [granulesCount, setGranulesCount] = useState(0)
    const [granulesDateRange, setGranulesDateRange] = useState(0)
    const [granulesSize, setGranulesSize] = useState(0)

    const [warning, setWarning] = useState("")
    const [warning2, setWarning2] = useState("")
    const [showSatellitePopUp, setShowSatellitePopUp] = useState(false)
    const [showInterpolationPopUp, setShowInterpolationPopUp] = useState(false)
    const [showGEDIPopUp, setShowGEDIPopUp] = useState(false)

    const [reportID, setReportID] = useState(null)
    const [isReportCreated, setIsReportCreated] = useState(false)

    const [carbonOutput, setCarbonOutput] = useState(null)
    const [gediOutput, setgediOutput] = useState(null)

    const [calculationMethod, setCalculationMethod] = useState("idw")
    const [linkText, setLinkText] = useState("")
    const [link, setLink] = useState("")

    const [useBuffer, setUseBuffer] = useState(true) 

    useEffect(() => {
        console.log("params", reset )

        console.log("props", props)

        let globalBackButton = document.getElementsByClassName('back-button')[0];
        globalBackButton.style.visibility = 'hidden'
        if (reset) {
            // resetPage()
        }

        // add warning on all possible ways to exit proggram
        async function quitDialog()  {
            let userResponse = window.confirm("Are you sure you want quit? You will lose all progress!");
            console.log("response", userResponse)
            if (userResponse) {
                // let output = await props.removeProject(params.projectID);
                console.log('output', output)
                history.push("/projects")
            } else {
                console.log("exited")
                history.push("/projects")
            }
        }

        let logo = document.getElementsByClassName('logo-img')[0]
        logo.onclick = quitDialog
        let projectbtn = document.getElementsByClassName('projectbutton')[0]
        projectbtn.onclick = quitDialog

        const proc = async () => await props.changeProject(params.projectID)
        proc().catch(console.error)


        const getReports = async () => {
            // console.log(params.projectID)
            let path = `${url}:${CORE_PORT}/projects/${params.projectID}/reports/`
            let httpMethod = "GET"
            let output = await useFetch(path, httpMethod, null, true) // when a get request fails, you get an empty object in the `data` field 
            // console.log(output)
            // console.log(output.data) // {} if request fails }
            // console.log(output.data.reports) //1. undefined if request fails //  2. [] is no reports associated with project
            // handle 1 here 

            return output.data.reports
        }

        const getGediData = async (projectReports) => {
            // console.log(projectReports)
            if (projectReports.length === 0) return
            let gediReports = projectReports.filter(function isGediCalculation(report) {
                return report.reportType === "gedi_calculation";
            })

            let latestGediReport = gediReports[gediReports.length - 1]
            let httpMethod = "GET"
            let path = `${url}:${CORE_PORT}/reports/gedi_calculation/${latestGediReport.reportID}/`
            let output = await useFetch(path, httpMethod, null, true)

            console.log("output DATA", output.data) // {} if request fails

            setGranulesCount(output.data.GEDI_granules_found)
            setGranulesDateRange(output.data.GEDI_granules_found_date_range)
            setGranulesSize(output.data.GEDI_granules_size)
            setReportName(output.data.name)
            setReportID(output.data.reportID)
            setSatelliteStartDate(output.data.start_date)
            setSatelliteEndDate(output.data.end_date)
            setareGranulesFound(true)
            setWarning("")
            setgediOutput(output.data)
            return
        }
        const process = async () => {
            let projectReports = await getReports();
            if (reset) {
                setGranulesCount(0)
                setGranulesDateRange(0)
                setGranulesSize(0)
                setReportName("")
                setReportID(null)
                setSatelliteStartDate("")
                setSatelliteEndDate("")
                setareGranulesFound(false)
                setWarning("")
                setgediOutput(null)
                history.push()
            } else {
                // getGediData(projectReports);
            }
            
            

        }
        process()
    }, [])

    const search = async () => {
        let missing = []
        let start = new Date(satelliteStartDate)
        let end = new Date(satelliteEndDate)

        if (!reportName || !satelliteStartDate || !satelliteEndDate) {
            if (!reportName) missing.push("Carbon Report Name")
            if (!satelliteStartDate) missing.push("Satellite Start Date")
            if (!satelliteEndDate) missing.push("Satellite End Date")
            setWarning("Missing required field(s): " + missing.join(", ") + " ")
        } else if (start > end) {
            setWarning("Satellite End Date must occur after Satellite Start Date")
        } else {
            setWarning("")
            setGranuleSearchInProgress(true)
            let output = await searchGranules(params.projectID, satelliteStartDate, satelliteEndDate, props.project.region, reportName)
            setGranuleSearchInProgress(false)
            if (output !== null) {
                setGranulesCount(output.GEDI_arr.GEDI_granules_found)
                setGranulesDateRange(output.GEDI_arr.GEDI_granules_found_date_range)
                setGranulesSize(output.GEDI_arr.GEDI_granules_size)
                setReportID(output.reportID)
                setareGranulesFound(true)
                setgediOutput(output.GEDI_arr)
                setWarning("")
            } else {
                // console.log("GEDI Search Fail")
                setWarning("GEDI Search Failed")
                setLinkText("View Methodology")
                setLink(`/project/${params.projectID}/gedi_details`)
            }
        }
    }

    const createReport = async () => {
        // console.log("reportID", reportID)
        setReportCreationInProgress(true)
        setWarning2("")
        let email_storage = JSON.parse(sessionStorage.getItem('webState')).email
        
        let output = await createCarbonReport(params.projectID, reportID, props.project.region, calculationMethod, email_storage, useBuffer)
        setReportCreationInProgress(false)
        if (output !== null) {
            setCarbonOutput(output)
            setIsReportCreated(true)
            setWarning2(output.error_msg)

            let logo = document.getElementsByClassName('logo-img')[0]
            logo.onclick = () => {console.log("do nothing")}
            let projectbtn = document.getElementsByClassName('projectbutton')[0]
            projectbtn.onclick = () => {console.log("do nothing")}
            history.push(`/project/${params.projectID}/reports`)
        } else {
            console.log("Carbon Report Fail")
            setWarning2("Carbon Report Failed")
        }
    }

    window.onbeforeunload = function() {
        return "Hey, you're leaving the site. Bye!";
    };


    function resetPage() {
            
            setReportName("")
            setSatelliteStartDate("")
            setSatelliteEndDate("")

            setGranuleSearchInProgress(false)
            setReportCreationInProgress(false)

            setareGranulesFound(false)
            setGranulesCount(0)
            setGranulesDateRange(0)
            setGranulesSize(0)

            setWarning("")
            setWarning2("")
            setShowSatellitePopUp(false)
            setShowInterpolationPopUp(false)
            setShowGEDIPopUp(false)

            setReportID(null)
            setIsReportCreated(false)

            setCarbonOutput(null)
            setgediOutput(null)

            setCalculationMethod("idw")
            setLinkText("")
            setLink("")
            console.log("reset")
    }

    return (
        
        <div className='gedi-body'>
            
            <div className='gedi-back-col'>
                <button className='quit-btn'>
                    <img src={exit} onClick={ async () => {
                        let userResponse = window.confirm("Are you sure you want quit? You will lose all progress!");
                        // console.log("response", userResponse)
                        if (userResponse) {
                            // let output = await props.removeProject(params.projectID);
                            // console.log('output', output)
                            history.goBack()
                        } else {
                            history.goBack()
                        }
                        
                        }}></img>
                </button>
            </div>
            <div className='gedi-form-col'>
                <div className="gedi-title">
                    <h1>Add Satellite Data</h1>
                    <img
                        src={download}
                        onMouseOver={() => setShowSatellitePopUp(true)}
                        onMouseOut={() => setShowSatellitePopUp(false)}
                    />
                    {showSatellitePopUp ?
                        <div className='color_box'>
                            Granules contain information on estimated AGB for the selected
                            region (from the GEDI instrument on the International Space Station)
                        </div> : null
                    }
                </div>

                <form className='gedi-form' >

                    <div className="gedi-form-body">

                        <div className='gedi-input-block'>
                            <label>Project:</label>

                            <div className="form-input-const">
                                {props.project ? props.project.name : ""}
                            </div>

                        </div>

                        <div className='gedi-input-block'>
                            <label>Image Source:</label>

                            <div className="form-input-const">
                                GEDI Lidar Sensor
                            </div>

                        </div>

                        <div className='gedi-input-block'>
                            <label>Carbon Report Name:</label>
                            <div className="form-input">
                                <input
                                    name='gedi-carbon_report_name'
                                    value={reportName}
                                    onChange={(event => setReportName(event.target.value))}
                                    type='text'
                                    style={{ height: "1.5em" }}
                                />
                            </div>
                        </div>

                        <div className='gedi-input-block'>
                            <label>Satellite Start Date:</label>
                            <div className="form-input">
                                <input
                                    name='gedi-satellite_start_date'
                                    value={satelliteStartDate}
                                    onChange={(event => setSatelliteStartDate(event.target.value))}
                                    type='date'
                                    style={{ height: "1.5em" }}
                                />
                            </div>
                        </div>

                        <div className='gedi-input-block'>
                            <label>Satellite End Date:</label>
                            <div className="form-input">
                                <input
                                    name='gedi-satellite_end_date'
                                    value={satelliteEndDate}
                                    onChange={(event => setSatelliteEndDate(event.target.value))}
                                    type='date'
                                    style={{ height: "1.5em" }}
                                />
                            </div>
                        </div>

                        <div className={`gedi-input-block`}>
                            <button
                                type="button"
                                className={(granuleSearchInProgress || areGranulesFound)
                                    ? "gedi-button-disabled"
                                    : "gedi-button"
                                }
                                onClick={search}
                                disabled={granuleSearchInProgress || areGranulesFound}
                            >

                           
                                {!granuleSearchInProgress ?
                                    !areGranulesFound ? "Search GEDI Granules" : "Granules Found" :
                                    "Search in Progress..."
                                }
                            </button>
                            <span className={`loader ${(granuleSearchInProgress) ? "" : "hidden"}`}></span>
                        </div>
                        {warning == "GEDI Search Failed" ? 
                            <p className="gedi-warning-text">
                                {warning} <br></br>
                                See <a href={link} target="_blank">{linkText}</a> for more details. 
                            </p>
                            :<p className="gedi-warning-text">
                                {warning} </p>
                        }
                    </div>


                    {areGranulesFound ?
                        <div className="gedi-form-body">
                            <div className='gedi-input-block'>
                                <label>GEDI Granules Were Found...</label>
                            </div>

                            <div className='gedi-input-block'>
                                <label>Total granules found:</label>
                                <div className="form-input-const">
                                    {granulesCount}    
                                </div>
                            </div>

                            <div className='gedi-input-block'>
                                <label>Total granules within date range:</label>
                                <div className="form-input-const">
                                    {granulesDateRange}
                                </div>
                            </div>

                            <div className='gedi-input-block'>
                                <label>Granules Size (MB):</label>
                                <div className="form-input-const">
                                    {Math.round(granulesSize * 100) / 100}
                                </div>
                            </div>

                        </div>
                        : null}


                    {areGranulesFound ?
                        <>
                            <div className="carbon-form-body">
                                <div className='gedi-interpolation'>
                                    <label>Interpolation Method:</label>
                                    <img
                                        src={download}
                                        onMouseOver={() => setShowInterpolationPopUp(true)}
                                        onMouseOut={() => setShowInterpolationPopUp(false)}
                                    />
                                    {showInterpolationPopUp ?
                                        <div>
                                            Method of estimating data in areas that do not have any data. If unsure, use Inverse Distance Weighting with all forest.
                                        </div> : null
                                    }

                                </div>
                                <div className='gedi-input-block' style={{ left: 0 }}>
                                    <div style={{ height: "1.5em" }}>
                                        <select id="calculation_method" onChange={event => setCalculationMethod(event.target.value)}>
                                            <option value="idw">Inverse Distance Weighting with all forest</option>
                                            <option value="avg">Inverse Distance Weighting by forest type</option>
                                        </select >
                                    </div>
                                </div>

                                <div className='gedi-input-block'>
                                    <label>Advanced:</label>
                                </div>

                                <div className='gedi-input-block'>

                                    <div className="gedi-buffer">
                                        <input type ="checkbox" id="rectangle" name="rectangle" value="rectangle"
                                        onChange={event => useBuffer ? setUseBuffer(false) : setUseBuffer(true)}></input>
                                        
                                        <label for="rectangle">Limit GEDI data to area of interest</label>
                                        <img
                                        src={download}
                                        onMouseOver={() => setShowGEDIPopUp(true)}
                                        onMouseOut={() => setShowGEDIPopUp(false)}
                                        />
                                        {showGEDIPopUp ?
                                            <div>
                                                By default, our GEDI search looks slightly outside of your selected region (~200 meters) to help find more granules for accuracy. If this is impacting your search negatively, you may limit the search to only within your region.
                                            </div> : null
                                        }
                                        <p>We recommend you leave this toggle unselected if you are unsure</p>
                                    </div>
                                </div>

                            </div>
                            <div className='gedi-button-box'>
                                <button
                                    type="button"
                                    className={(reportCreationinProgress || isReportCreated)
                                        ? "gedi-button-disabled"
                                        : "gedi-button"
                                    }
                                    onClick={createReport}
                                    disabled={reportCreationinProgress}
                                >
                                    {!reportCreationinProgress ?
                                        'Create Report' : 
                                        'Creating Report...'
                                    }
                                </button>
                                <span className={`loader-2 ${(reportCreationinProgress) ? "" : "hidden"}`}></span> 
                            </div>
                            
                        </>

                        : null}
                </form>
            </div>
        </div >
    )

}

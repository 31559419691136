import React, { useState, useEffect } from "react";
import X from '../../images/icons/exit-icon.png'
import Popup from 'reactjs-popup';
import './new-report-popup.css'
import { PopupMonitoringCard } from "./monitoring-gallery";
import Report from "../../images/icons/report-example-icon.png"
import { Redirect, useRouteMatch, useHistory } from "react-router-dom";

/**
 * 
 * @param {boolean}   showPopup     Determines whether the popup is visible 
 * @param {function}  setShowPopup  Function to change the stateful value of showPopup
 * @param {string}    propertyName  String of the property name of the current payment
 */
export const NewReportPopup = (props) => {
  const [selectedCardLink, changeSelectCardLink] = useState("");
  const [cardDescriptor, changeDescriptor] = useState("");

  let history = useHistory();
  let match = useRouteMatch();
  // set up project base url (likely not optimal or needed after moving payment dashboard up)
  let url = match.url.split("/");
  url.splice(5, 3);
  url = url.join("/");

  useEffect(() => {
    changeDescriptor("");
    changeSelectCardLink("");
  }, [props.setShowPopup])

  const closePopup = () => {
    props.setShowPopup(false);
    changeDescriptor("");
    changeSelectCardLink("");
  }

  const innerBox = {
    margin: 0,
    padding: 0,
    border: 0,
    borderRadius: "20px",
    cursor: "pointer"
  };

  const handleCardClick = (desc, link) => {
    changeDescriptor(desc);
    changeSelectCardLink(link);
  }

  const handleGoBtn = () => {
    if (selectedCardLink === "") {
      changeDescriptor("Select a type of report to add");
    }
    else {
      history.push(url + selectedCardLink)
    }
  }

  return (
    <Popup
      open={props.showPopup}
      position="center center"
      onClose={closePopup}
      className="monitor-popup"
      closeOnDocumentClick
    >

      <a onClick={closePopup} className="exit-btn"><img src={X}></img></a>
      <div className="popup-body">
        <h1>Select an item to add to {props.propertyName}</h1>
      </div>
      <div className="popup-cards">
        <PopupMonitoringCard
          content={
            <button style={innerBox} onClick={() => handleCardClick("Quickly determine LULC across an area", "/land-cover/new")}>
              <img src={Report}></img>
            </button>
          }
          text={"Land Use Land Cover Report"}
        />
        <PopupMonitoringCard
          content={<img src={Report}></img>}
          text={"Automated Change Detection Report"}
        />
        <PopupMonitoringCard
          content={<img src={Report}></img>}
          text={"Flood Risk Report"}
        />
        <PopupMonitoringCard
          content={<img src={Report}></img>}
          text={"Carbon Report"}
        />
        <PopupMonitoringCard
          content={<img src={Report}></img>}
          text={"Manual Change Detection Report"}
        />
        <PopupMonitoringCard
          content={<img src={Report}></img>}
          text={"Create a Custom Report"}
        />
      </div>
      <p className="card-descriptor">{cardDescriptor}</p>
      <button className="popup-btn" onClick={handleGoBtn}>Let's Go!</button>

    </Popup>
  )
}
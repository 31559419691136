import React, {useState, useEffect} from 'react';
import {Redirect, useParams, useRouteMatch} from 'react-router-dom';


import {SelectionGallery} from '../components/modules/selection-gallery';


import MaryCreek from '../images/sample_region_image.png';
import './ndvi-selection-page.css'

import { getAllNDVIReport } from '../apis/landsteward/accounts';

/**
 * 
 * @param {*} props 
 *    <project>: current project
 *    <region>: current region
 *    <reports>: all reports for the current project, including land covers
 *    <changeCurrentState>: sets the current project, region etc...
 */
export function NDVISelectionPage(props) {
  let params = useParams();

  function galleryFormatNDVI (reports) {
    let outputArray = [];
  
    let objCopy
    for (let i=0; i < reports.length; i++) {
      objCopy = { ...reports[i]};
      
      if (!objCopy.png_image) {
        objCopy.png_image = MaryCreek
      }
      if(!objCopy.path) {
        objCopy.path = `/project/${params.projectID}/property/${params.propertyID}/ndvi/${objCopy.reportID}`
      }
      if (objCopy.reportType === 'ndvi') {
        outputArray.push(objCopy);
      }
    }

    return outputArray
  }


  /* ##### URL Parameters ##### */
  let [ndvireports, setndvireports] = useState([]);
  let [projectFailed, setProjectFailed] = useState(false);
  let [regionFailed, setRegionFailed] = useState(false);

  const fetchNDVIreports = async () => {
    const allreports = await getAllNDVIReport(params.projectID)
    return allreports;
    }

    useEffect(async () => {
      let isCorrectState = await props.changeCurrentState({
        projectID: params.projectID,
      });
      if (!isCorrectState.project) {
      console.error('Failing Projects', isCorrectState);
      setProjectFailed(true);
    }
  },[]);

  useEffect( () => {
    fetchNDVIreports().then((data)=>
    {
      setndvireports(data)
     })
    },[]);

  if (projectFailed) {
    return (<Redirect to='/project'/>);
  } else if (regionFailed) {
    return (<Redirect to={`/project/${params.projectID}`}/>);
  } else {



    /* ##### Render ##### */
    return (
      <div className="ndvi-selection-page">
        <div className='ndvi-gallery__title'>
          <h1>Browse or Add Vegetation Reports</h1>
        </div>
        
        <SelectionGallery
        title={props.project && props.region ? `${props.project.name} NDVIs for ${props.region.name}`: 'NDVIs'}
        titleClassName='ndvi-selection__title'
        contentType='image'
        collectionList={galleryFormatNDVI(ndvireports ? ndvireports : [])}
        type="Vegetation Report"
        IDAttribute='reportID'
        captionAttribute='name'
        imageAttribute='png_image'
        linkAttribute='path'
        createOption={true}
        createLink={`/project/${params.projectID}/property/${params.propertyID}/ndvi/new`}
      />
    </div>
    )
  }
}
/*
    Configurations for different servers based on location
*/

var json_data = require('./frontend_settings.json')


// Please provide an area from the list below
let SUPPORTED_AREAS = ["CHARLOTTETOWN", "PETERBOROUGH"]
// Select area from the list above 
let SELECTED_AREA = json_data.MapBox_Default_Location

let config = {}

switch (SELECTED_AREA) {
    case "CHARLOTTETOWN":
        config.defaultLat = 46.238888
        config.defaultLon = -63.129166
        break
    case "PETERBOROUGH":
        config.defaultLat = 44.299999
        config.defaultLon = -78.316666
        break
    default: // default area is PETERBOROUGH
        config.defaultLat = 44.299999
        config.defaultLon = -78.316666
        break
}


export { config }
import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import {getACDFirstImage, getACDSecondImage, getACDChangeImage, getACDFirstSatImage, getACDSecondSatImage, getAutoDetection} from '../apis/landsteward/accounts';
import {getACDFirstLULCID, getACDSecondLULCID, getLULCDetails} from '../apis/landsteward/accounts';
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import MaryCreek from '../images/sample_region_image.png';
import './automated-display-page.css'

export function AutoDisplayPage(props) { 
    let params = useParams();
    let project = props.searchState('projects', 'projectID', params.projectID);
    let firstLULCReport = {};
    let secondLULCReport = {};

    const [firstImage, setFirstImage] = useState(null);
    const [firstSatImage, setFirstSatImage] = useState(null);
    const [firstLandLegend, setFirstLandLegend] = useState([]);
    const [firstLULCID, setFirstLULCID] = useState('');

    const [secondImage, setSecondImage] = useState(null);
    const [secondSatImage, setSecondSatImage] = useState(null);
    const [secondLandLegend, setSecondLandLegend] = useState([]);
    const [secondLULCID, setSecondLULCID] = useState('');

    const [changeImage, setChangeImage] = useState(null);

    let [firstLandcoverLegendClosed, setFirstLandcoverLegendClosed] = useState(true);
    let [secondLandcoverLegendClosed, setSecondLandcoverLegendClosed] = useState(true);
    let [changeLegendClosed, setChangeLegendClosed] = useState(true);

    let [autoDetectionReport, setAutoDetectionReport] = useState(null);


    function openLandLegend(i) {
        if (i == 0) {
            setChangeLegendClosed(false);
        }
        else if (i == 1) {
            setFirstLandcoverLegendClosed(false);
        }
        else if (i == 2) {
            setSecondLandcoverLegendClosed(false);
        }
    }
      
    function closeLandLegend(i) {
        if (i == 0) {
            setChangeLegendClosed(true);
        }
        else if (i == 1) {
            setFirstLandcoverLegendClosed(true);
        }
        else if (i == 2) {
            setSecondLandcoverLegendClosed(true);
        }
    }

    
    useEffect(() => {
        getAutoDetection(params.autoID).then(
            response =>{
                setAutoDetectionReport(response)
                setFirstLandLegend(JSON.parse(response.init_lulc.colour_legend.replace(/'/g, '"')))
                setSecondLandLegend(JSON.parse(response.final_lulc.colour_legend.replace(/'/g, '"')))
                setFirstImage(response.init_lulc.image)
                setSecondImage(response.final_lulc.image)
                setFirstSatImage(response.init_lulc.satellite_image_url)
                setSecondSatImage(response.final_lulc.satellite_image_url)
                setChangeImage(response.image)
            }
        )
    }, []);

    function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
      
    function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    function colourLULCLegend(idx, i) {
        if (i == 1 && firstLandLegend) {
            for (var i = 0; i < firstLandLegend.length; i++) {
                if (idx === i) { 
                    //console.log(rgbToHex(landLegend[i].rgb[0], landLegend[i].rgb[1], landLegend[i].rgb[2]));
                    return rgbToHex(firstLandLegend[i].rgb[0], firstLandLegend[i].rgb[1], firstLandLegend[i].rgb[2]);
                }
            }
        }
        else if (i == 2 && secondLandLegend) {
            for (var i = 0; i < secondLandLegend.length; i++) {
                if (idx === i) { 
                    //console.log(rgbToHex(landLegend[i].rgb[0], landLegend[i].rgb[1], landLegend[i].rgb[2]));
                    return rgbToHex(secondLandLegend[i].rgb[0], secondLandLegend[i].rgb[1], secondLandLegend[i].rgb[2]);
                }
            }
        }
        return "red";
    }
    
    return (
        <div className='report-display-page'>
            <div className='report-display-page__title'>
                <h1>Automatic Change Detection Report</h1>
            </div>
    
            <div className="report-display-page__project__details">
                <p><span className="name">Project Name:</span> {project.name}</p>
                <p><span className="name">Region:</span> {}</p>
                <p><span className="name">Report Name:</span> {}</p>
            </div>
    
            <hr className="line"/>
            <div className="lulc-report-details">
                <div className="report1__details">
                    <h3><span className="name">LULC Report #1:</span> {firstLULCReport ? firstLULCReport.name : ''}</h3>
                    <div className="satellite-image">
                        <img src={firstSatImage ? firstSatImage : MaryCreek}/>
                    </div>
                    
                    <h4>Project #1 Image Details:</h4>
                    <div className="report__satellite__details__image">
                        <p><span className="name">Pixel Resolution:</span> 100</p>
                        <p><span className="name">Area:</span> 100 Sq km</p>
                    </div>
                    
                    <h3>Landcover Image:</h3>
                    <div className="landcover-image">
                        <div className="open-first-legend">
                            <button style={{visibility: !firstLandcoverLegendClosed ? 'hidden' : 'visible' }} onClick={e => openLandLegend(1)}>Legend</button>
                        </div>
                        <img src={firstImage ? firstImage : MaryCreek}/>
                        
                        <div className="acd__lulc1__details" style={{display: firstLandcoverLegendClosed ? 'none' : 'block'}}>
                            <button className="close-btn" onClick={e => closeLandLegend(1)}>&times;</button> 
                            <h4>Legend:</h4>
                            <div className="legend-scale">
                                <ul className="legend-labels">
                                    {firstLandLegend.map((value, index) => (
                                    <li> <span style={{backgroundColor: colourLULCLegend(index, 1)}}></span> {value.classType} </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="report2__details">
                    <h4><span className="name">LULC Report #2:</span> {secondLULCReport ? secondLULCReport.name : ''} </h4>
                    <div className="satellite-image">
                        <img src={secondSatImage ? secondSatImage : MaryCreek}/>
                    </div>
                    
                    <h4>Project #2 Image Details:</h4>
                    <div className="report__satellite__details__image">
                        <p><span className="name">Pixel Resolution:</span> 100</p>
                        <p><span className="name">Area:</span> 100 Sq km</p>
                    </div>
                    
                    <h3>Landcover Image:</h3>
                    <div className="landcover-image">
                        <div className="open-second-legend">
                            <button style={{visibility: !secondLandcoverLegendClosed ? 'hidden' : 'visible' }} onClick={e => openLandLegend(2)}>Legend</button>
                        </div>
                        
                        <img src={secondImage ? secondImage : MaryCreek}/>
                        
                        <div className="acd__lulc2__details" style={{display: secondLandcoverLegendClosed ? 'none' : 'block'}}>
                            <button className="close-btn" onClick={e => closeLandLegend(2)}>&times;</button> 
                            <h4>Legend:</h4>
                            <div className="legend-scale">
                                <ul className="legend-labels">
                                    {secondLandLegend.map((value, index) => (
                                    <li> <span style={{backgroundColor: colourLULCLegend(index, 2)}}></span> {value.classType} </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>            
            <hr className="line"/>

            <h2>Changes Detected:</h2>
            <div className="open-change-legend">
                    <button style={{visibility: !changeLegendClosed ? 'hidden' : 'visible' }} onClick={e => openLandLegend(0)}>Legend</button>
                </div>
                <div className="change-image">
                <TransformWrapper>
                    <TransformComponent>
                        <img src={changeImage ? changeImage : MaryCreek}/>
                    </TransformComponent>
                </TransformWrapper>
                </div>

            <div className="acd__change__details" style={{display: changeLegendClosed ? 'none' : 'block'}}>
                <button className="close-btn" onClick={e => closeLandLegend(0)}>&times;</button> 
                <h4>Legend:</h4>
                <div class='legend-scale'>
                    <ul class='legend-labels'>
                        <li><span style={{backgroundColor: "white"}}></span>Unchanged</li>
                        <li><span style={{backgroundColor: "black"}}></span>Changed</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
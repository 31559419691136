import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  Component,
} from "react";
import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./satellite-image-display-page.css";
import "./mowing-display-page.css";
import info from '../images/icons/info-icon.png';
import CreekSatellite from "../images/creditriver_satellite.png";
import { stringifyNumber } from "../utils/helpers";
import {
  download,
  getMDReport,
  getMDReportCSV,
  getNDVIImages,
  getNDVIMapImageFromIDX,
  getNDVIReport,
  getSatelliteImage,
  getSATImageFromIDX,
} from "../apis/landsteward/accounts";
import remotesensingicon from "../images/remote-sensing.png";
import magnifyingglass from "../images/magnifying-glass-icon.png";
import { CsvToHtmlTable } from "react-csv-to-table";
import { Line } from "react-chartjs-2";
import { MapboxRegionSelection } from "../components/mapbox/mapbox-region-selection";
import {
  getLandownerDetails,
  getLandowners,
  getPropertiesForLandowner,
  getDataForRegion,
} from "../apis/landsteward/accounts";

import {
  CreateMowingReport,
  UpdateMowingReport,
} from "../apis/landsteward/mowing";

import {
  getDataForRegionMock,
  getPropertiesForLandownerMock,
} from "../apis/landsteward/mock_accounts";
import { MOCK_DATA } from "../apis/landsteward/urlconfig";

import SimpleImageSlider from "react-simple-image-slider";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  LineElement,
} from "chart.js";

/**
 * @param {*} props
 *    <project>: currently selected project
 *    <region>:  currently selected region
 *    <satelliteImage>: currently selected satellite image
 */
export function MowingDisplayPage(props) {
  /** ##### URL Param Check ##### */
  let params = useParams();
  let history = useHistory();
  let [projectFailed, setProjectFailed] = useState(false);

  let [mowingData, setMowingData] = useState({});
  let [trimmedMowingData, setTrimmedMowingData] = useState({});
  let [mowingInfo, setMowingInfo] = useState({});
  let [idx, setidx] = useState();
  const [singlefield, setSinglefield] = useState("");
  const [centroidIndex, setCentroidIndex] = useState(0);
  const [centroids, setCentroids] = useState([]);
  // let [CSVData, setCSVData] = useState(''); 
  // let [CSVFile, setCSVFile] = useState('');

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    LineElement
  );

  async function getAllProperties(landowners) {
    let allProperties = [];
    for (const landowner of landowners) {
      // right now just worry about getting all of the properties on there, deal with landowner-specific info after
      let id = landowner.profileID;
      let propertiesForLandowner = MOCK_DATA
        ? getPropertiesForLandownerMock(id)
        : await getPropertiesForLandowner(id);
      let allConcatedProperties = allProperties.concat(propertiesForLandowner);
      allProperties = allConcatedProperties;
    }

    return allProperties;
  }
  async function formatPropertiesForMap(propertiesToFormat) {
    let formattedPropertyArray = [];
    // it's indexing it by landowner, need to extract them and put them into an array that doesn't differentiate it
    let propertiesArr = propertiesToFormat;
    let regionInfo;

    for (let index = 0; index < propertiesArr.length; index++) {
      regionInfo = MOCK_DATA
        ? getDataForRegionMock(propertiesArr[index].region)
        : await getDataForRegion(propertiesArr[index].region);
      if (regionInfo) {
        formattedPropertyArray.push(regionInfo);
      } else {
        console.error(
          "Error getting region info for property",
          propertiesArr[index].id
        );
      }
    }

    return formattedPropertyArray;
  }

  function readCSVFile(response) {
    var lines = response.split("\n");
    // console.log(response)
    let modifiedResponse = "";
    for (var i = 0; i < lines.length; i++) {
      var row = lines[i].split(","); //First column (Split on the separator!)
      if (row[0] == "Date") {
        modifiedResponse = modifiedResponse.concat(row + "\n");
        continue;
      } else if (row[0] != "Date" && row[0] != "") {
        let timeRemoved = row[0].slice(0, 10);
        row[0] = timeRemoved;
        let modifiedRow = row.join(",");
        modifiedResponse = modifiedResponse.concat(modifiedRow + "\n");
      }
      //Do your stuff
    }
    return modifiedResponse;
  }

  function removeIDs(response) {
    var lines = response.split("\n");
    var csvLength = lines.length
    let modifiedResponse = "";

    for (var i = 0; i < csvLength-1; i++) {
      var row = lines[i].split(","); //First column (Split on the separator!)

      row.splice(9, 2)
      modifiedResponse = modifiedResponse.concat(row.join(",") + "\n")

      //Do your stuff 
    }
    console.log(modifiedResponse)
    return modifiedResponse;
  }

  const getGeojsonFromRegionID = (regionID) => {
    let json = null;

    formattedProperties.forEach((item) => {
      if (item.regionID == regionID) {
        json = item.data;
        return;
      }
    });

    return json;
  }; 

  const [, setRegionName] = useState("");
  let [landowners, setLandowners] = useState([]);
  const [regionID, setRegionID] = useState("");

  // for showing all of the properties on the map
  let [properties, setProperties] = useState([]);

  const [formattedProperties, setFormattedProperties] = useState([]);
  let [slideImages, setSlideImages] = useState([]);
  const [loadingFeatures, setLoadingFeatures] = useState(true);
  let [projectDoesNotExist, setProjectFail] = useState(false);
  const [showSatellitePopUp, setShowSatellitePopUp] = useState(false)

  useEffect(() => {
    async function proc2() {
      console.log("proc2 done");

      let slideImages5 = await getSlideImages();
      setSlideImages(slideImages5);

      return;
    }
    proc2();
  }, [mowingData]);

  useEffect(() => {
    getMDReportCSV(params.mowingID, 'mowing_detection').then((response) => {
      let timeRemovedResponse = readCSVFile(response);
      let prunedResponse = removeIDs(timeRemovedResponse)
      console.log(response);
      setMowingData(timeRemovedResponse);
      setTrimmedMowingData(prunedResponse)
    }),
      getMDReport(params.mowingID, 'mowing_detection').then((result) => {
        setMowingInfo(result);
      });

    async function proc() {
      const projectID = params.projectID;
      let success = await props.changeProject(projectID);

      if (!success) {
        setProjectFail(true);
        return;
      }

      setLoadingFeatures(true);
      let landowners = MOCK_DATA
        ? getLandownersMock(projectID)
        : await getLandowners(projectID);
      let allProperties = await getAllProperties(landowners);
      let landowners_details = [];

      if (MOCK_DATA) {
        landowners_details = landowners;
      } else {
        for (let index = 0; index < landowners.length; index++) {
          let landowner_detail = await getLandownerDetails(
            projectID,
            landowners[index].profileID
          );
          if (landowner_detail) {
            landowners_details.push(landowner_detail);
          } else {
            console.error(
              "Failed to get landowner details for",
              landowners[index].profileID
            );
          }
        }
      }

      setProperties(allProperties);
      let allFormattedProperties = await formatPropertiesForMap(allProperties);
      setLandowners(landowners_details);
      setFormattedProperties(allFormattedProperties);

      setLoadingFeatures(false);
    }
    props.changeCurrentState(params.projectID).then((isCorrectState) => {
      if (!isCorrectState.project) {
        console.error("Failing Projects", isCorrectState);
        setProjectFailed(true);
      }
    });

    proc().catch(console.error);
    //clean state on unmount, use to avoid warning
    return () => setMowingData({});
  }, []);

  //once formatted properties have been computed, determine the centroids of all properties
  useEffect(() => {
    if (formattedProperties) {
      let centroidArr = [];
      for (let index = 0; index < formattedProperties.length; index++) {
        let coordinateArr =
          formattedProperties[index].data.geometry.coordinates[0];
        let centroid = props.computeCentroid(coordinateArr);
        centroidArr.push(centroid);
      }
      setCentroids(centroidArr)
      console.log('centroids computed', centroidArr)
    }
  }, [formattedProperties])

  //once a field has been selected, determine the index corresponding to that field 
  useEffect(() => {
    if (singlefield) {
      console.log('singlefield changed', singlefield)
      console.log('formattedProperties', formattedProperties);
      for (let i = 0; i < formattedProperties.length; i++) {
        let property = formattedProperties[i];
        console.log('property name', property.name)
        if (property.name == singlefield) {
          setCentroidIndex(i);
          console.log('centroid of current field: ', centroids[i])
          break;
        }
      }
    }
  }, [singlefield])


  async function getSlideImages() {
    let dataArray = getIDArray();
    const j = dataArray.length;
    let slide = [];
    if (mowingInfo.properties != null) {
      for (let i = 0; i < j; i += mowingInfo.properties.length) {
        // wait for the promise to resolve before advancing the for loop
        let response = await getNDVIReport(dataArray[i]);
        if (response) {
          slide.push({ url: response.image });
        }
      }
    }
    return slide;
  }

  var dataArray2 = [];

  dataArray2 = getIDArray;

  function getIDArray() {
    let i = String(mowingData).split("\n").join(",").split(",").length;
    let str = String(mowingData).split("\n").join(",").split(",");

    let IDArray = [];

    const j = i;

    for (var n = 12; n < j - 1; n += 12) {
      IDArray.push(str[n + 10]);
    }
    return IDArray;
  }

  var singlefieldNDVI = [];
  var singlefielddate = [];
  var dateArray = [];
  var singlefieldsatID = [];
  var mowedFields = [];
  var mowedDate = [];
  var mowedTable = [];

  var strr = String(mowingData).split("\n").join(",").split(",");
  var b = String(mowingData).split("\n").join(",").split(",").length;
  for (var p = 0; p <= b; p++) {
    if (strr[p] == singlefield) {
      singlefieldNDVI.push(strr[p + 3]);

      singlefielddate.push(strr[p - 2]);
      singlefieldsatID.push(strr[p + 7]);
    } else if (strr[p] == "True") {
      mowedFields.push(strr[p - 6]);
      mowedDate.push(strr[p - 8]);
    } else {
      continue;
    }
  }
  for (var i = 0; i < mowedFields.length; i++) {
    mowedTable.push([mowedFields[i], mowedDate[i]]);
  }

  var heading = ["Field Name", "Date Mowed"];
  var bodyDict = {};
  for (let i = 0; i < mowedTable.length; i++) {
    if (mowedTable[i][0] in bodyDict) {
      // if the date is already in the list, skip it
      if (bodyDict[mowedTable[i][0]].includes(mowedTable[i][1])) {
        continue;
      }
      bodyDict[mowedTable[i][0]].push(mowedTable[i][1]);
    } else {
      bodyDict[mowedTable[i][0]] = [mowedTable[i][1]];
    }
  }
  var heading = ["Field Name", ...Object.keys(bodyDict)];

  var compiledBody = [];
  let longestValue = 0;

  for (let key in bodyDict) {
    let value = bodyDict[key];
    if (value.length > longestValue) {
      longestValue = value.length;
    }
  }

  let j = 0;
  while (j < longestValue) {
    let ordinal =
      stringifyNumber(j + 1)
        .charAt(0)
        .toUpperCase() + stringifyNumber(j + 1).slice(1);
    let currDates = [ordinal + " Mowed"];

    for (let i = 1; i < heading.length; i++) {
      let currValue = bodyDict[heading[i]];
      if (currValue.length < j + 1) {
        currDates.push("NA");
      } else {
        currDates.push(currValue[j]);
      }
    }
    compiledBody.push(currDates);
    j++;
  }

  const csv_array = []
  csv_array.push(heading)
  var body = compiledBody;
  for (let i=0; i<body.length; i++) {
    csv_array.push(body[i])
  }
  var CSVData = csv_array

  var CSVFile = CSVData.map(e=>e.join(",")).join("\n"); 
  console.log(trimmedMowingData)

  for (var p = 12; p <= b; p += 12) {
    dateArray.push(strr[p]);
  }


  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  let e = 0;

  const onClickNav = useCallback(() => {
    e += 7;
    setidx(e);
  }, []);

  if (projectFailed) {
    return <Redirect to="/project" />;
  }

  const imgRef = useRef();

  class TableRow extends Component {
    render() {
      var row = this.props.row;
      return (
        <tr>
          {row.map((val, index) => {
            if (index === 0) {
              return <th>{val}</th>;
            } else {
              return <td>{val}</td>;
            }
          })}
        </tr>
      );
    }
  }

  class Table extends Component {
    render() {
      var heading = this.props.heading;
      var body = this.props.body;
      return (
        <table className="mowtable">
          <thead>
            <tr>
              {heading.map((head) => (
                <th>{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map((row) => (
              <TableRow row={row} />
            ))}
          </tbody>
        </table>
      );
    }
  }

  function singleFieldData(trimmedData){
    let allFieldData = ""
    allFieldData = allFieldData.concat(trimmedData)
    var lines = allFieldData.split("\n");
    var csvLength = lines.length
    let singleFieldData = "";

    var attributes = lines[0].split(",");
    singleFieldData = singleFieldData.concat(attributes.join(",") + "\n");

    for (var i = 1; i < csvLength-1; i++) {
      var row = lines[i].split(","); 
      if (row[2] == singlefield) {
        singleFieldData = singleFieldData.concat(row.join(",") + "\n");
      } 
    }
    
    return singleFieldData;
  }

  var SatelliteObservTableData = trimmedMowingData
  var SatelliteObservTableName = 'all fields'
  if (singlefieldNDVI[0] != null){
    SatelliteObservTableData = singleFieldData(trimmedMowingData);
    SatelliteObservTableName = singlefield
  }

  const chartRef = useRef(null)

  function downloadChart(){
    if (singlefieldNDVI[0] != null){
      const link = document.createElement("a");
      link.download = `${mowingInfo.name}_${singlefield}_chart.png`
      link.href=chartRef.current.toBase64Image();
      link.click();
    }
    else if (singlefield.length == 0){
      window.alert("Please enter a field name");
    }
    else{
      window.alert("No average NDVI data for this field, please enter another field name");
    }
  }

  /** ##### RENDER #####  */
  return (
    <div>
      <div className="new-title">
        <h2> Mowing Report/Snapshot {mowingInfo.start_date}</h2>
      </div>
      <div className="main-div-maz">
        <div className="project-details2">
          <p className="meta-data-headings2">
            <h4>Project: {mowingInfo.name} </h4>
          </p>

          <p className="meta-data">
            <span className="notbold">
              {" "}
              This document reports on the level of hay cover on every field
              within the selected region on the map below that belongs to the
              project {mowingInfo.name}
            </span>
          </p>
          <h4 className="meta-data-headings2">Report Details</h4>
          <p className="meta-data-headings2">
            {" "}
            <h4>Date of Creation:</h4> {mowingInfo.creation_date}
          </p>
          <p className="meta-data-headings2">
            {" "}
            <h4>Start Date:</h4> {mowingInfo.start_date}
          </p>
          <p className="meta-data-headings2">
            {" "}
            <h4>End Date:</h4> {mowingInfo.end_date}{" "}
          </p>
        </div>

        <div className="map-box">
          {loadingFeatures ? null : (
            <MapboxRegionSelection
              longitude={-63.1311}
              latitude={46.2382}
              zoom={9}
              style="mapbox://styles/korotu-conroy-trinh/ckwqnggaf0esg14odvehgi6hd"
              setRegionID={setRegionID}
              geoJsonID={regionID ? getGeojsonFromRegionID(regionID).id : ""}
              setRegionName={setRegionName}
              customPreloadedFeatures={formattedProperties}
              selectedPreloadedFeatureRegionID={regionID}
              mapWidth={"364px"}
              mapHeight={"168px"}
              drawEnabled={false}
            />
          )}
        </div>
        </div>
        <div className="button-container"> 
          <div className="button-dow-div">
              <button
                className="download-project"
                onClick={() => download(CSVFile, mowingInfo.name, 'Summary')}
              >
                {" "}
                Download Report (.csv){" "}
              </button>
            </div>
          <div className="button-dow-div">
            <button
              className="download-project"
              onClick={() => history.push(`/project/${params.projectID}/mowing-update-page`, {
                          state: {
                              projectID: params.projectID,
                              mowingID: params.mowingID,
                              startDate: mowingInfo.end_date,
                              mowingName: mowingInfo.name
                          }
            })}
              // onClick={() => UpdateMowingReport('UPDATE CHECK', params.projectID, params.mowingID)}
            >
              {" "}
              Update Report{" "}
            </button>
          </div>

          <div className="info-container">
              <img
                src={info}
                onMouseOver={() => setShowSatellitePopUp(true)}
                onMouseOut={() => setShowSatellitePopUp(false)}
            />
                {showSatellitePopUp ?
                  <div className="mowing-popup">
                  Updating your reports allows you to select a new end date so you may add new data to your existing report
                  </div>: null
                }
          </div>
      </div>

      <div className="satellite-image-create-page-title">
        <h2> Summary of Mowing Events </h2>
      </div>

      <div>
        <table className="mowtable" style={{ marginRight: "30em" }}>
          <thead>
            <tr>
              {heading.map((head) => (
                <th>{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map((row) => (
              <TableRow row={row} />
            ))}
          </tbody>
        </table>
      </div>

      <div className="satellite-image-create-page-title" id="mowing-title">
        <h2> Graph of Average NDVI over time</h2>
      </div>

      <div>
        <label className="name-label" id="mowing-name" htmlFor="newName">
          <span>Enter a Field : </span>
        </label>

        <input
          id="input1"
          name="namefield"
          type="text"
          value={singlefield}
          onChange={(event) => setSinglefield(event.target.value)}
        />

        <button className="text-button" onClick={downloadChart}>
             <h5>Download</h5>
        </button>

        <p>Click on Points to view Image!</p>
      </div>

      <div>
        <Line
          ref={chartRef}
          data={{
            labels: singlefielddate,
            datasets: [
              {
                label: "Average NDVI",
                data: singlefieldNDVI,

                borderColor: ["rgba(0, 0, 0,)"],
                pointBorderColor: ["rgba(0, 0, 0, 5)"],
                pointStyle: "circle",
                pointRadius: 6,
                borderWidth: 3,
                borderDash: [10, 5],
                spanGaps: true,
              },
            ],
          }}
          height={400}
          width={600}
          options={{
            plugins:{
              legend:{
                onClick:()=>{}
              }
            },
            onClick: (e, element) => {
              if (element.length > 0) {
                console.log("index:", centroidIndex);
                console.log(singlefieldsatID[centroidIndex]);
                console.log("Property Centroid: " + centroids[centroidIndex]);

                history.push(
                  `/project/${params.projectID}/property/${params.propertyID}/manual-detection`,
                  {
                    satID: singlefieldsatID[centroidIndex],
                    centroid: centroids[centroidIndex],
                  }
                );
              }
            },
            maintainAspectRatio: false,
            scales: {
              y: {
                min: 0,
                max: 1,
                stepSize: 0.1,
              },
            },
          }}
          plugins={[{
            id: 'lineChartBackground',
            beforeDraw(chart, args, pluginOptions){
              const{ctx} = chart;
              ctx.save;
              ctx.fillStyle = '#ffffff';
              ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
              ctx.restore();
            },
          }]}
        />
      </div>
      <br />

      <div className="compare-button-container">
        <button
          className="compare-button"
          onClick={() =>
            window.open(
              `/project/${params.projectID}/property/${params.propertyID}/manual-detection`
            )
          }
        >
          {" "}
          Manually Compare{" "}
        </button>
      </div>
      <br />
      <br />
      <br />
      {/* <div className='satellite-image-create-page-title' id="mowing-title">
        <h2> NDVI Images of Region over Time </h2>
      </div> */}
      {/* <div className='map-box2'>
        {slideImages.length == 0 ? null :
          (<SimpleImageSlider
            width={1220}
            height={860}
            onClickNav={onClickNav}
            images={slideImages}
            showBullets={true}
            showNavs={true}
          />
        )} */}

      {/* </div> */}
      <div className="satellite-image-create-page-title2" id="mowing-title">
        <h2> Data table of Satellite Observations</h2>
        <button
            className="text-button"
            onClick={() => download(SatelliteObservTableData, mowingInfo.name, `SatelliteObservations_${SatelliteObservTableName}`)}
          >
             <h5>Download</h5>
        </button>
      </div>
      {mowingData && (
        <CsvToHtmlTable
          data={String(SatelliteObservTableData)}
          csvDelimiter=","
          tableClassName="table table-striped table-hover"
        />
      )}
      <br />
      <br />
    </div>
  );
}

import React, { useState } from 'react';
import { useRouteMatch, useParams, Link } from 'react-router-dom';

import { downloadSatelliteImage, getSatelliteImageDetails } from '../apis/landsteward/accounts';
import { downloadHarmonized, downloadSentinelSat2 } from '../apis/landsteward/satellite'

import { Dropdown } from '../components/modules/satellite-dropdown';

import './satellite-image-create-page.css';


export function SatelliteImageCreatePage(props) {
  let match = useRouteMatch();
  let params = useParams();
  const [name, setName] = useState('');

  console.log(`URL params SatelliteCreate: ${params.projectID}||${params.propertyID}`);

  let project = props.searchState('projects', 'projectID', params.projectID);

  return (
    <div className='satellite-image-create-page'>
      <div className='satellite-image-create-page-title'>
        <h1> Add Satellite Image</h1>
      </div>

      <p className='metadata-headings'> <b>Project:</b> {project ? project.name : ''}<br /> <b>Region:</b> {''} </p>
      <label className='name-label' htmlFor='newName'><span>Name Your Image:</span></label>
      <input
        className='name-input'
        name='newName'
        type='text'
        value={name}
        onChange={(event) => setName(event.target.value)}

      />

      <SatelliteImageForm
        newName={name}
        addSatelliteImage={props.addSatelliteImage}
      />


    </div>
  )
}





/**
 * Form to download a satellite image, allows user to specify satellite image
 * details (e.g. date, cloud coverage). Downloaded image is associate with
 * a specific region and project.
 * @param {*} props 
 *    <region>: region metadata object, necessary for fetch API
 *    <newName>:
 *    <addSatelliteImage>: 
 */
export function SatelliteImageForm(props) {
  let params = useParams();

  const [satBand, setSatBand] = useState('TCI');
  const [startDate, setStartDate] = useState('2021-05-01');
  const [endDate, setEndDate] = useState('2021-05-07');
  const [pixelRes, setPixelRes] = useState('10m');
  const [newImageID, setNewImageID] = useState('');

  const [downloadStatus, setDownloadStatus] = useState('');


  async function triggerDownload(e) {
    e.preventDefault();
    setDownloadStatus('pending');
    let imageID;
    if (pixelRes == '30m') {
      imageID = await downloadHarmonized(
        props.newName,
        params.propertyID,
        params.projectID,
        startDate,
        endDate
      );
    } else {
      imageID = await downloadSentinelSat2(
        props.newName,
        params.propertyID,
        params.projectID,
        startDate,
        endDate,
        pixelRes,
        satBand,
        '10'
      );
    }

    console.log('IMAGE ID from Triggered Download', imageID);
    if (imageID === null) {
      // console.error('Satellite Image Download has failed');
      setDownloadStatus('failed');
    } else {
      let imageData = await getSatelliteImageDetails(imageID);
      if (imageData === null) {
        console.error('Image Download succeeded but retrieving details failed');
      } else {

        console.log('image DATA:', imageData);
        imageData.imageID = imageID;
        const imageDataFormatted = {
          imageID: imageID,
          name: props.newName,
          png: imageData.png_image
        }
        props.addSatelliteImage(imageDataFormatted);
      }
      setNewImageID(imageID);
      setDownloadStatus('success');
    }

  }

  return (
    <form className='satellite-image-form'>
      {/**<label htmlFor='satellite-image-name'> Satellite Image Name: </label>
      <input 
        type='text'
        value={name}
        name='satellite-image-name'
        onChange={(event) => setName(event.target.value)}
      /> {/** */}

      {/** Satellite Form Details */}
      <div className='satellite-image-form__details'>

        <label htmlFor='satellite-image-band'> <b>Specify Image Bands:</b></label>
        <input
          className="hi"
          type='text'
          value={satBand}
          name='satellite-image-band'
          onChange={(event) => setSatBand(event.target.value)}
          disabled
        />
        <br />

        <label htmlFor='satellite-image-start-date'> <b>Satellite Image Start Date:</b> </label>
        <input
          type='date'
          value={startDate}
          name='satellite-image-start-date'
          onChange={(event) => setStartDate(event.target.value)}
        />
        <br />

        <label htmlFor='satellite-image-end-date'> <b>Satellite Image End Date:</b> </label>
        <input
          type='date'
          value={endDate}
          name='satellite-image-end-date'
          onChange={(event) => setEndDate(event.target.value)}
        />
        <br />

        <label htmlFor='satellite-image-pixel-res'> <b>Resolution Preference (if any) :</b> </label>
        <Dropdown
          name='satellite-image-pixel-res'
          value={pixelRes}
          setValue={(value) => setPixelRes(value)}
          valueList={[{ value: '10m', name: '10m' }, { value: '30m', name: '30m' }, { value: '60m', name: '60m' },]}
          valueProperty='value'
          nameProperty='name'
        />


      </div>

      <div className="create-satellite-button">
        <button onClick={triggerDownload} style={{ visibility: ((downloadStatus === 'pending') && 'hidden' || newImageID && 'hidden') }}> Create Satellite Image </button>
      </div>

      {(downloadStatus === 'failed') && <p className="processing-text"> Oh no! Something went wrong, please try again later</p>}
      {(downloadStatus === 'pending') && <p className="processing-text"> The download you triggered is processing</p>}

      {newImageID &&
        <span>
          <p className="processing-text" >Your satellite image has finished downloading!</p>
          <Link to={`/project/${params.projectID}/property/${params.propertyID}/satellite_image/${newImageID}`}>
            <div className="create-satellite-button">
              <button> View Image </button>
            </div>
          </Link>

        </span>
      }
    </form>
  )
}
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import './rename-popup.css';

export const RenamePopup = ({ onRenameType, onClose, onChangeName, onRenamingProgress }) => {
    const [newName, setNewName] = useState('');
    const [isRenamingProgress, setRenamingProgress] = useState(false);
    const [mounted, setMounted] = useState(true);

    const maxNameLength = 30;
  
    useEffect(() => {
      setRenamingProgress(onRenamingProgress);

      return () => {
        setMounted(false);
      }
    }, [onRenamingProgress]);

    const handleInputChange = (event) => {
      const inputValue = event.target.value;
      setNewName(inputValue);
    };
  
    const handleRenameClick = async () => {
      if (newName.trim() === '') {
        window.alert("The name cannot be empty.");
        return;
      }

      try {
        setRenamingProgress(true);
        await onChangeName(newName);
      } finally {
        if (mounted) {
          setRenamingProgress(false);
          onClose();
        }
      }
    };
  
    return (
      <Popup 
        open={true} 
        // onClose={onClose} 
        position="center center"
        closeOnDocumentClick={false}
        modal>
        <div className="popup-box">
          <h2 className="popup-heading">Rename {onRenameType}</h2>
          <input
            type="text"
            className="popup-input"
            placeholder={`New ${onRenameType} Name`}
            value={newName}
            onChange={handleInputChange}
            maxLength={maxNameLength}
          />

          <div className={newName.length === maxNameLength ? "character-info-warning" : "character-info"}>
            {`${newName.length}/${maxNameLength}`}
          </div>

          <div className="btn-group">
            <button 
              className="rename-btn" 
              onClick={handleRenameClick}
              disabled={isRenamingProgress}
            >
              Rename</button>
            <button 
              className="cancel-btn" 
              onClick={onClose}
              disabled={isRenamingProgress}
            >
              Cancel</button>
          </div>

        </div>
      </Popup>
    );
  };

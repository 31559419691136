import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import {getSatelliteImage, getFloodRiskMapLandcoverImage, getFloodRiskMapInfraImage, getFloodRiskMapRunoffImage, getFloodRiskDetails} from '../../apis/landsteward/accounts';
import {TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import './flood-risk-display.css'

import MaryCreek from '../../images/sample_region_image.png';

/**
 * 
 * @param {*} props
 *  <searchState>
 * @returns 
 */
export default function FloodRisk(props) {
    let params = useParams();
    let region = props.searchState('regions', 'regionID', params.regionID);
    let report = props.searchState('reports', 'reportID', params.reportID);
    let project = props.searchState('projects', 'projectID', params.projectID);
    let [landcoverLegendClosed, setLandcoverLegendClosed] = useState(true);
    let [infrastructureLegendClosed, setInfrastructureLegendClosed] = useState(true);
    let [runoffLegendClosed, setRunoffLegendClosed] = useState(true);
    const [reportInfo, setReportInfo] = useState({});
    const [runoffValues, setRunoffValues] = useState([]);
    const [runoffColours, setRunoffColours] = useState([]);
    const [landLegend, setLandLegend] = useState([]);
    const [satelliteImage, setSatelliteImage] = useState(null);
    const [landcoverImage, setLandcoverImage] = useState(null);
    const [infraImage, setInfraImage] = useState(null);
    const [runoffImage, setRunoffImage] = useState(null);

    function openLegend(e) {
        if (e === 'landcover') setLandcoverLegendClosed(false);
        else if (e === 'infrastructure') setInfrastructureLegendClosed(false);
        else if (e === 'runoff') setRunoffLegendClosed(false);
    }
      
    function closeLegend(e) {
        if (e === 'landcover') setLandcoverLegendClosed(true);
        else if (e === 'infrastructure') setInfrastructureLegendClosed(true);
        else if (e === 'runoff') setRunoffLegendClosed(true);
    }
    
    useEffect(() => {
        getFloodRiskDetails(params.reportID).then(
            response => {
                setReportInfo(response),
                setRunoffValues(JSON.parse(response.runoff_retention_value)),
                setRunoffColours(JSON.parse(response.runoff_retention_RGBvalue)),
                setLandLegend(JSON.parse(response.landcover_lgd.replace(/'/g, '"')))
            }
        );
    }, [])

    useEffect(() => {
        getSatelliteImage(params.reportID).then(
            response => {
                console.log(response),
                setSatelliteImage(response.png_image)
            }
        );
    }, null)

    useEffect(() => {
        getFloodRiskMapLandcoverImage(params.reportID).then(
            response => {
                console.log(response),
                setLandcoverImage(response.png_image)
            }
        );
    }, null)

    useEffect(() => {
        getFloodRiskMapInfraImage(params.reportID).then(
            response => {
                console.log(response),
                setInfraImage(response.png_image)
            }
        );
    }, null)

    useEffect(() => {
        getFloodRiskMapRunoffImage(params.reportID).then(
            response => {
                console.log("image", response),
                console.log(typeof response),
                setRunoffImage(response.png_image)
            }
        );
    }, null)

    console.log(landcoverImage)

    const roundTwo = (num) => {
        return +(Math.round(num + "e+2")  + "e-2");
    }

    const addPercent = (val) => {
        return roundTwo(val * 100).toString() + "%";
    }

    function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
      
    function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    function colourLegend(idx) {
        if (runoffColours) {
            for (var i = 0; i < runoffColours.length; i++) {
                if (idx === i) { 
                    //console.log(rgbToHex(runoffColours[i][0][0], runoffColours[i][0][1], runoffColours[i][0][2]));
                    return rgbToHex(runoffColours[i][0][0], runoffColours[i][0][1], runoffColours[i][0][2]);
                }
            }
        }
        return "red";
    }

    function colourLULCLegend(idx) {
        if (landLegend) {
            for (var i = 0; i < landLegend.length; i++) {
                if (idx === i) { 
                    //console.log(rgbToHex(landLegend[i].rgb[0], landLegend[i].rgb[1], landLegend[i].rgb[2]));
                    return rgbToHex(landLegend[i].rgb[0], landLegend[i].rgb[1], landLegend[i].rgb[2]);
                }
            }
        }
        return "red";
    }

    return (
    <div className='report-display-page'>
        <div className='report-display-page__title'>
            <h1>Floodrisk Report</h1>
        </div>

        <div className="report-display-page__project__details">
            <p><span className="name">Project Name:</span> {project.name}</p>
            <p><span className="name">Region:</span> {''}</p>
            <p><span className="name">Report Name:</span> {report.name}</p>
        </div>

        <hr className="line"/>
        
        <div className="flood-risk-report">
            <div className="flood-risk-report__details__summary">
                <h2>Floodrisk Summary:</h2>
                <div className="summary__list">
                    <p><span className="name">Average Runoff retention (ratio to precipitation):</span>  {reportInfo.avg_runoff_ret}</p>
                    <p><span className="name">Sum of runoff generated in watershed (m^3):</span>  {reportInfo.total_runoff_ret}</p>
                    <p><span className="name">Total Flood Volume (m^3):</span>  {reportInfo.flood_vol}</p>
                    <p><span className="name">Total Rainfall Volume (m^3):</span>  {reportInfo.rainfall_vol}</p>
                    <p><span className="name">Area:</span> Sq Km (of Watershed)</p>
                </div>
            </div>

            <hr className="line"/>

            <h2>Satellite Image</h2>
            <figure className="flood-risk__report__satellite__details__image">
                <TransformWrapper>
                    <TransformComponent>
                        <img src={satelliteImage ? satelliteImage : MaryCreek}/>
                    </TransformComponent>
                </TransformWrapper>
                <figcaption><span className="name">Description:</span> Summary of flood risk mitigation report for the selected watershed. <br/>
                <span className="name">Source:</span> Copernicus  Sentinel-2 Level 2A </figcaption>
            </figure>

            <div className="flood-risk-report__satellite__details__image">
                <h4>Image Details:</h4>
                <p><span className="name">Spatial Resolution:</span> 10m </p>
                <p><span className="name">Band:</span> True Colour</p>
                <p><span className="name">Date Period:</span> May 2020 - June 2020</p>
            </div>
        </div>

        <hr className="line"/>
        
        <div className="flood-risk-report__lulc">
            <h2>Land Use Land Cover Image</h2>
            <figure className="flood-risk-report__lulc__details__image">
                <div className="open-legend">
                    <button style={{visibility: !landcoverLegendClosed ? 'hidden' : 'visible' }} onClick={e => openLegend('landcover')}>Legend</button>
                </div>

                <TransformWrapper>
                    <TransformComponent>
                        <img src={landcoverImage ? landcoverImage : MaryCreek}/>
                    </TransformComponent>
                </TransformWrapper>
        
                <figcaption><span className="name">Description:</span> {reportInfo.landcover_desc} segregated into five classes: built-up, water, 
                forestry, ground, and unknown.
                </figcaption>
            </figure>
                    
            <div className="flood-risk-report__lulc__details__summary" style={{display: landcoverLegendClosed ? 'none' : 'block' }}>
                <button className="close-btn" onClick={e => closeLegend('landcover')}>&times;</button> 
                <h4>Legend:</h4>
                <div className="legend-scale">
                    <ul className="legend-labels">
                        {landLegend.map((value, index) => (
                        <li> <span style={{backgroundColor: colourLULCLegend(index)}}></span> {value.classType} </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
            
        <hr className="line"/>

        <div className="flood-risk-report__built__infrastructure">
            <h2>Built Infrastructure Image</h2>
            <figure className="flood-risk-report__built__infrastructure__details__image">
                <div className="open-legend">
                    <button style={{visibility: !infrastructureLegendClosed ? 'hidden' : 'visible' }} onClick={e => openLegend('infrastructure')}>Legend</button>
                </div>

                <TransformWrapper>
                    <TransformComponent>
                        <img src={infraImage ? infraImage : MaryCreek}/>
                    </TransformComponent>
                </TransformWrapper>

                <figcaption><span className="name">Description:</span> {reportInfo.infrastructure_desc}.<br/>
                    Dense white areas reflect dense built-up areas.
                </figcaption>
            </figure>
            
            <div className="flood-risk-report__built__infrastructure__details" style={{display: infrastructureLegendClosed ? 'none' : 'block'}}>
                <button className="close-btn" onClick={e => closeLegend('infrastructure')}>&times;</button> 
                <h4>Legend:</h4>
                <div class='legend-scale'>
                    <ul class='legend-labels'>
                        <li><span style={{backgroundColor: "white"}}></span>Undeveloped</li>
                        <li><span style={{backgroundColor: "grey"}}></span>Built-up buildings</li>
                        <li><span style={{backgroundColor: "black"}}></span>Not in scope</li>
                    </ul>
                </div>
            </div>
        </div>

        <hr className="line"/>

        <div className="flood-risk-report__runoff__retention">
            <h2>Runoff Retention Image</h2>
            <figure className="flood-risk-report__runoff__retention__details__image">
                <div className="open-legend">
                    <button style={{visibility: !runoffLegendClosed ? 'hidden' : 'visible' }} onClick={e => openLegend('runoff')}>Legend</button>
                </div>

                <TransformWrapper>
                    <TransformComponent>
                        <img src={runoffImage ? runoffImage : MaryCreek}/>
                    </TransformComponent>
                </TransformWrapper>

                <figcaption><span className="name">Description:</span> {reportInfo.runoff_desc}. High values of runoff retention are less likely to flood 
                    (eg. vegetation, permeable soil). Built-up areas, lakes, and basins have lower runoff 
                    retention and are more likely to flood.
                </figcaption>
            </figure>
            
            <div className="flood-risk-report__runoff__retention__details" style={{display: runoffLegendClosed ? 'none' : 'block'}}>
                <button className="close-btn" onClick={e => closeLegend('runoff')}>&times;</button> 
                <h4>Runoff Retention Index (relative to precipitation volume):</h4>
                <div className="legend-scale">
                    <ul className="legend-labels">
                        {runoffValues.map((value, index) => (
                        <li> <span style={{backgroundColor: colourLegend(index)}}></span> {addPercent(value)} </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    </div>
)}
import { url, CORE_PORT, VALUATION_PORT, MONITORING_PORT } from "./urlconfig";
import useFetch from "./useFetch";

const withauth = true;

/**
 * 
 * @returns An object containing an array of notification metadata objects
 */
export async function getNotifs() {
  const path = '/payments/notifications/';
  const httpMethod = 'GET';
  let output = [];

  /** Retrieves all notifs, will return based on user  */
  const response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, null, withauth);

  /** Fetch JSON response processed into a data object in JS*/
  console.log(response);
  if (!response.hasError) {
    output = response.data.notifications;
  } else {
    output = [];
    console.log(response.errorMessage);
  }

  return output;
}

export async function patchNotifRead(id) {
  const path = '/payments/notifications/' + id + '/read/';
  const httpMethod = 'PATCH';

  /** Retrieves all notifs, will return based on user  */
  const response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, null, withauth);

  /** Fetch JSON response processed into a data object in JS*/
  console.log(response);
  if (response.hasError) {
    console.log(response.errorMessage);
    return false;
  }

  return true;
}

/**
 * 
 * @returns An object containing an array of payment metadata objects
 */
 export async function getPayments() {
  const path = '/payments/';
  const httpMethod = 'GET';
  let output = '';

  /** Retrieves all payment, will return based on user  */
  const response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, null, withauth);

  /** Fetch JSON response processed into a data object in JS*/
  // console.log(response);
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/**
 * @param   {number} id The id of the payment request to query
 * @returns {object} An object of all the data about a payment request
 */
export async function getPaymentDetail(id) {
  const path = '/payments/' + id + '/';
  const httpMethod = 'GET';
  let output = '';

  const response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, null, withauth);

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/**
 * 
 * @param   {number} id                       The id of the payment request to query
 * @param   {string} status                   Status to update payment too (INCOMING/PROGRESS/ACCEPTED/DECLINED)
 * @param   {string} landtrust_to_owner_note  Updated landtrust to landowner note
 * @param   {string} landtrust_to_buyer_note  Updated landtrust to buyer note
 * @returns {object} Message confirming patch success or not
 */
export async function updatePaymentDetail(id, status, landtrust_to_owner_note, landtrust_to_buyer_note) {
  const path = '/payments/' + id + '/';
  const httpMethod = 'PATCH';
  let output = '';
  const body = {
    status: status, 
    landtrust_to_owner_note: landtrust_to_owner_note, 
    landtrust_to_buyer_note: landtrust_to_buyer_note
  }

  const response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, withauth);

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.error(response.errorMessage);
  }

  return output;
}

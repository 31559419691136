import React, {useEffect, useState} from 'react';
import {Route, Switch} from "react-router-dom";
import { DEBUG, MOCK_DATA } from '../apis/landsteward/urlconfig';
import PaymentManagementRouting  from './payment-management-flow';
import ObservationExample from '../images/icons/observation-example-icon.png'
import {getPayments, getNotifs, patchNotifRead, getPaymentDetail, updatePaymentDetail} from '../apis/landsteward/payments';

export const PaymentStatus = {
  Incoming: "INCOMING",
  InProgress: "PROGRESS",
  Accepted: "ACCEPTED",
  Declined: "DECLINED"
}

export default function PaymentManagement(props) {
  const [partialPaymentRequests, setPartialRequests] = useState(MOCK_DATA ? [
    {
      id: '3498',
      status: PaymentStatus.Incoming,
      request_amount: 226,
      landowner: {
        name: 'John Doe',
        organization: 'Doe Strawberry Farms'
      }
    },
    {
      id: '3499',
      status: PaymentStatus.Incoming,
      request_amount: 226,
      landowner: {
        name: 'Jane Doe',
        organization: 'Doe Apple Farm'
      }
    },
  ] : []);

  const [paymentRequests, setPaymentRequests] = useState(MOCK_DATA ? [
        {paymentID: '3498', requestAmount: 226, note: 'test note', status: 'incoming', // change status to enum later, incoming/inprogress/accepted/declined
        
        owner: {
            name: 'John Doe', email: 'john-landowner@gmail.com', organization: 'Doe Strawberry Farms', address: '25 Prince Edward Way'
          },
          property: {
            longitude: 38.8951, latitude: -77.0364, perimeter: 600, hectares: 2, chars: ["Invasive species", "Forestry"], request: "Repairing - Invasive Species Removal"
          },
          request: {
            id: "A-19b294c739v57cer405",
            account_num: "376483439",
            invoice_num: "3740394308",
            monitoring_items: [
              {
                link: "/project/peter/region/456/land-cover/onroy",
                text: "Land Use Land Cover Report"
              },
              {
                link: "/project/peter/region/456/land-cover/onroy",
                text: "Land Use Land Cover Report"
              },
              {
                link: "/project/peter/region/456/land-cover/onroy",
                text: "Land Use Land Cover Report"
              }
            ],
            observations: [ObservationExample],
          }
        },
        {paymentID: '3499', requestAmount: 400, note: 'test note', status: 'incoming', 
        
          owner: {
            name: 'Jane Doe', email: 'jane-landowner@gmail.com', organization: 'Doe Apple Farm', address: '26 Prince Edward Way'
          },
          property: {
            longitude: 38.8951, latitude: -77.0364, perimeter: 600, hectares: 2, chars: ["Invasive species", "Forestry"], request: "Repairing - Invasive Species Removal"
          },
          request: {
            id: "A-19b294c739v57cer405",
            account_num: "376483439",
            invoice_num: "3740394308",
            monitoring_items: [
              {
                link: "/project/peter/region/456/land-cover/onroy",
                text: "Land Use Land Cover Report"
              }
            ],
            observations: [ObservationExample],
          }
        }
      ] : []);

  const [paymentNotifs, setPaymentNotifs] = useState(MOCK_DATA ? [
        {id:1, title: 'Monitoring information for payment request #3498 is now available for viewing.' 
                  , content:'content', dateTime: '2021-09-25T12:00:00Z', read:false},
        {id:2, title: 'John Doe, representing Doe Strawberry Farms, has sent you a payment request.' 
                  , content:'content', dateTime: '2021-09-25T12:00:00Z', read:false},
        {id:3, title: 'Jane Doe, representing Doe Strawberry Farms, has sent you a payment request.' 
                  , content:'content', dateTime: '2021-09-25T12:00:00Z', read:false},
        {id:4, title: 'notif4' 
                  , content:'content', dateTime: '2021-09-25T12:00:00Z', read:true},
        {id:5, title: 'notif5' 
                  , content:'content', dateTime: '2021-09-25T12:00:00Z', read:true}
      ] : []);

  const [currPaymentRequest, setCurrPaymentRequest] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(async() => {
    console.log('payment managment use effect');
    if (!MOCK_DATA){
      // api calls
      let notifs = await getNotifs();
      setPaymentNotifs(notifs);
      console.log('payment notif res', notifs);

      let payments = await getPayments();
      if (payments !== null) {
        setPartialRequests(payments);
        console.log('set partial payments:', payments);
      }
    }
    //await new Promise(resolve => setTimeout(resolve, 5000));
    setLoading(false);
    console.log('payment managment effect done');
  }, [])

  /** PAYMENTS */
/**############################################################################## */
async function changePaymentRequest(paymentID) {
  if (MOCK_DATA) {
    let indexOfPayment = -1;
    for (let i = 0; i < paymentRequests.length; i++){
      if (paymentRequests[i].paymentID === paymentID) {
        indexOfPayment = i;
        i = paymentRequests.length;
      }
    }
    if (indexOfPayment === -1){
      console.error('Attempted to Change to PaymentRequest not listed in state');
      return false;
    }
    console.log(paymentRequests[indexOfPayment])
    setCurrPaymentRequest(paymentRequests[indexOfPayment]); // set state to new object with updated list
    return true;
  }
  else {
    const payment = await getPaymentDetail(paymentID);
    if (payment !== null) {
      setCurrPaymentRequest(payment);
    }
    else {
      console.error("Failed to get payment details");
      return false;
    }
    return true;
  }
}

async function changePaymentStatus(paymentID, status, note_to_landowner, note_to_buyer, note_to_landtrust) {
  // // loop over the todos list and find the provided id.
  // let updatedList = paymentRequests.map((item) => 
  // {
  //   if (item.paymentID == paymentID){
  //     let newReq = {...item}
  //     if (item.note_to_buyer !== null){
  //       newReq.note_to_buyer = note_to_buyer
  //     }
  //     if (item.note_to_landowner !== null){
  //       newReq.note_to_landowner = note_to_landowner
  //     }
  //     if (item.note_to_landtrust !== null){
  //       newReq.note_to_landtrust = note_to_landtrust
  //     }

  //     return newReq; //gets everything that was already in item, and updates "read"
  //   }
  //   return item; // else return unmodified item 
  // });

  // setPaymentRequests(updatedList); // set state to new object with updated list


  // return true;
  console.log(paymentID, partialPaymentRequests);
  let updatePartial = partialPaymentRequests.map((item) => {
    if (item.id === paymentID) {
      let newReq = {...item};
      newReq.status = status;

      return newReq;
    }
    return item;
  });
  console.log("Update partial:", updatePartial);
  setPartialRequests(updatePartial);

  if (currPaymentRequest.id == paymentID) {
    setCurrPaymentRequest({
      status: status,
      landowner_note: note_to_landtrust,
      landtrust_to_buyer_note: note_to_buyer,
      landtrust_to_owner_note: note_to_landowner,
      ...currPaymentRequest
    })
    updatePaymentDetail(paymentID, status, note_to_landowner, note_to_buyer);
  }
  else {
    console.error("Attempting to change payment status of non-current payment!")
  }

}

async function changeNotifRead(notifId) {
  let result = patchNotifRead(notifId);
  if (!DEBUG && !result){
    console.error('Attempted to Change to notif read to true');
    return false;
  }

  // loop over the todos list and find the provided id.
  let updatedList = paymentNotifs.map((item) => 
   {
      if (item.id == notifId){
        return {...item, read: true}; //gets everything that was already in item, and updates "read"
      }
      return item; // else return unmodified item 
    });

  setPaymentNotifs(updatedList); // set state to new object with updated list
    
  return true;
}

  return (
    <div className='body'>
        <PaymentManagementRouting
          paymentRequests={paymentRequests}
          paymentNotifs={paymentNotifs}
          currPaymentRequest={currPaymentRequest}
          partialPaymentRequests={partialPaymentRequests}
          
          changePaymentRequest={changePaymentRequest}
          changePaymentStatus = {changePaymentStatus}
          changeNotifRead = {changeNotifRead}
        />
    </div>
  )
}





import React, { useEffect, useRef, useState } from "react";

import { useParams, Redirect } from "react-router";
import { useLocation, Route, Routes, Link } from "react-router-dom";

import L from 'leaflet';
import { MapContainer, TileLayer, useMap, GeoJSON, LayersControl, LayerGroup } from 'react-leaflet';

import { v4 as uuidv4 } from 'uuid';

import {
  getProjectSatelliteImages,
  getReportSatelliteImages,
  getNDVIFromSatellite,
  getNDVIReport,
  getFCWEventsCSV,
  download,
  getMDReport,
  getProjectRegions,
  getProjectProperties,
  getDataForRegion
} from "../apis/landsteward/accounts";

import { getDNDVIreportID, getDNDVIimage, getHectaresImpacted, getDNDVIVector } from "../apis/landsteward/forest-change"

import { MapboxRegionSelection } from "../components/mapbox/mapbox-region-selection";
import { MapboxAutoDetection } from "../components/mapbox/mapbox-auto-detection";
import { DropdownForest } from "../components/modules/dropdown-forest";
import InfoIconWithTooltip from "../components/modules/info-icon-with-tooltip";

import "./automatic-detection-page.css";
import "./ndvi-map-compare-page.css"
import 'leaflet/dist/leaflet.css';

import InfoIcon from "../images/icons/info-icon-new.svg";
import DownloadIcon from "../images/download-icon.png"
import UpArrowIcon from '../images/icons/arrow-icon-up.png'
import DownArrowIcon from '../images/icons/arrow-icon-down.png'
import MapboxLogo from '../images/mapbox-logo-white.png';

export function DataTable({ fieldName = "FIELD", regionName = "FIELD", regionID, startDate = "2000-1", endDate = "2025-1", reportID, reportName = "report name", tableRowClick }) {
  const [data, setData] = useState([]);
  const [yearOfInterest, setYearOfInterest] = useState(1);
  const [showTooltipInfo, setShowTooltipInfo] = useState(false);
  const [isTableOpen, setIsTableOpen] = useState(true);

  useEffect(() => {
    console.log("Changing dates: ", startDate, endDate);
    // console.log("table fieldName & regionName: ", fieldName, regionName);

    const fetchData = async () => {
      const csvData = await getFCWEventsCSV(reportID); // use reportID from props
      console.log("csvData: ", csvData)

      const rows = csvData.split('\n');
      const data = rows.slice(1).map(row => {
        const fields = row.split(',').map(field => field.trim()); // trim each field
        return fields[0] ? { // check if the row is not empty
          fieldName: fields[0],
          periodOfChange: fields[1],
          dndvi: parseFloat(fields[2]),
          averageDndvi: parseFloat(fields[3]),
          percentageChange: parseFloat(fields[4]),
        } : null;
      })
        .filter(item => {
          if (!item || !item.fieldName || !item.periodOfChange || !startDate || !endDate) {
            return false;
          }

          const [startOfChangePeriod, endOfChangePeriod] = item.periodOfChange.split(' - ');
          console.log("startDateOfChangePeriod, endDateOfChangePeriod: ", [startOfChangePeriod, endOfChangePeriod])

          const startDateArray = startOfChangePeriod.split('-');
          const endDateArray = endOfChangePeriod.split('-');

          // Set to last day of the month
          const startDateOfChangePeriod = new Date(startDateArray[0], startDateArray[1], 0, 23, 59, 59, 999);
          // Set to first day of the month
          const endDateOfChangePeriod = new Date(endDateArray[0], endDateArray[1] - 1, 1, 23, 59, 59, 999);

          const startDateNoTimeZone = startDate.split("T")[0];
          const endDateNoTimeZone = endDate.split("T")[0];

          const startArray = startDateNoTimeZone.split('-');
          const endArray = endDateNoTimeZone.split('-');

          // Set to first day of the month if date is missing (All Properties seleted)
          const startOfSelectedRange = new Date(parseInt(startArray[0]), parseInt(startArray[1] - 1), parseInt(startArray[2]) || 1);
          // Set to last day of the month if date is missing (All Properties seleted)
          const endOfSelectedRange = endArray[2] ? new Date(parseInt(endArray[0]), parseInt(endArray[1] - 1), parseInt(endArray[2])) : new Date(parseInt(endArray[0]), parseInt(endArray[1]), 0);

          console.log("start comparing: ", startDateOfChangePeriod, ">=", startOfSelectedRange, startDateOfChangePeriod >= startOfSelectedRange);
          console.log("end comparing: ", endDateOfChangePeriod, "<=", endOfSelectedRange, endDateOfChangePeriod <= endOfSelectedRange);
          return startDateOfChangePeriod >= startOfSelectedRange && endDateOfChangePeriod <= endOfSelectedRange;
        })

      // get the year of interest
      if (data.length > 0) {
        const yearOfInterest = data[0].periodOfChange.split('-')[0];
        setYearOfInterest(yearOfInterest);
      }
      setData(data);
    };

    fetchData();

    return () => {
      setData([]);
    };
  }, [reportID, startDate, endDate]);

  const toggleTable = () => {
    setIsTableOpen(!isTableOpen);
  };

  const convertDataToCSV = (data) => {
    const headers = [
      'Property Name',
      'Period of Change',
      `${yearOfInterest} DNDVI`,
      'Average DNDVI Across Other Years',
      'Percentage Change'
    ];

    const header = headers.join(',') + '\n';
    const filteredData = regionID === "all_properties" ? data : data.filter(item => item.fieldName === fieldName || item.fieldName === "FIELD");
    const rows = filteredData.map(item => {
      const propertyName = item.fieldName !== "FIELD" ? item.fieldName : (regionName ? regionName : "FIELD");
      return (
        `"${propertyName}",${item.periodOfChange},"${item.dndvi}","${item.averageDndvi}","${item.percentageChange}"\n`
      );
    });

    return header + rows.join('');
  };

  const filteredData = regionID === "all_properties" ? data : data.filter(item => item.fieldName === fieldName || item.fieldName === "FIELD");
  // console.log("table data for", fieldName || regionID, ": ", data);
  // console.log("table data filtered for", fieldName || regionID, ": ", filteredData);

  const handleClick = (periodOfChange, propertyName) => {
    const [startOfChangePeriod, endOfChangePeriod] = periodOfChange.split(' - ');
    tableRowClick(regionID, propertyName, startOfChangePeriod, endOfChangePeriod);
  }

  return (
    <div className="disturbances-table">
      {filteredData.length === 0 ?
        <div className="dndvi-event-no-table"><strong>No detected event in date range.</strong></div> :
        <><div className={`heading-rows disturbances-table-title ${isTableOpen ? '' : 'closed'}`}>
          <span><strong>Large Disturbances Within Selected Range</strong></span>
          <button className="disturbances-csv-download-button">
            <img
              src={DownloadIcon}
              onClick={() => {
                const propertyName = fieldName !== "FIELD" ? fieldName : (regionName ? regionName : "FIELD");
                const formattedStartDate = startDate ? startDate.split("T")[0] : `${yearOfInterest}-01-01`;
                const formattedEndDate = endDate ? endDate.split("T")[0] : `${yearOfInterest}-12-31`;
                const fileName = `${propertyName}_disturbances_${formattedStartDate}_${formattedEndDate}`;
                download(convertDataToCSV(data), reportName, fileName);
              }} />
          </button>
          <button className="disturbances-table-toggle-button" onClick={toggleTable}>
            {isTableOpen ?
              <img className="disturbances-table-icon" src={DownArrowIcon} alt="Close Icon" /> :
              <img className="disturbances-table-icon" src={UpArrowIcon} alt="Open Icon" />}
          </button>
        </div>
          {isTableOpen &&
            <table>
              <thead>
                <tr className="heading-rows">
                  <th>Period of Change</th>
                  <th>Property Impacted</th>
                  <th>
                    <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                      {yearOfInterest} DNDVI
                      <img
                        className='info-icon'
                        alt='info-icon'
                        src={InfoIcon}
                        onMouseOver={() => setShowTooltipInfo(true)}
                        onMouseOut={() => setShowTooltipInfo(false)} />
                    </div>
                    {showTooltipInfo ? (
                      <div className='popup'>
                        This is your selected year of interest from the report creation stage
                      </div>
                    ) : null}
                  </th>
                  <th>Average DNDVI Across Other Years</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => {
                  const propertyName = item.fieldName !== "FIELD" ? item.fieldName : (regionName ? regionName : "FIELD");
                  return (
                    <tr className="event-rows" key={index} onClick={() => handleClick(item.periodOfChange, propertyName)}>
                      <td>{item.periodOfChange}</td>
                      <td>{propertyName}</td>
                      <td>{item.dndvi}</td>
                      <td>{item.averageDndvi}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>}
        </>
      }
    </div>
  );
}

export function AutoDetectionPage(props) {
  /* ##### URL Param Check ##### */
  let params = useParams(); // parameters from url, in this case projectId and mowingID
  const [projectFailed, setProjectFailed] = useState(false); // flag to indicate if project failed to load
  const [forestInfo, setForestInfo] = useState({});
  /** Regions */
  const [projectRegions, setProjectRegions] = useState(null); // all regions for the project
  const [selectedRegion, setSelectedRegion] = useState(null);
  /** Properites */
  const [projectProperties, setProjectProperties] = useState(null); // all properties for the project
  /** DNDVI Image */
  const [dndviImage, setDndviImage] = useState("");
  const [dndviImageUrl, setDndviImageUrl] = useState("");
  const [dndviVectors, setDndviVectors] = useState([]);
  const [dndviTiffs, setDndviTiffs] = useState([]);
  /** Hectares Impacted in DNDVI Image */
  const [highLossHectares, setHighLossHectares] = useState("X");
  const [medLossHectares, setMedLossHectares] = useState("X");
  const [lowLossHectares, setLowLossHectares] = useState("X");
  const [lowGainHectares, setLowGainHectares] = useState("X");
  const [medGainHectares, setMedGainHectares] = useState("X");
  const [highGainHectares, setHighGainHectares] = useState("X");
  /** Satellite Images */
  const [satelliteImages, setSatelliteImages] = useState(null); // all satellite images for the project
  const [satImagesForSelectedRegion, setSatImagesForSelectedRegion] = useState(null); // satellite images filtered by region
  const [leftSatImage, setLeftSatImage] = useState(null); // satellite image report id in the left dropdown
  const [rightSatImage, setRightSatImage] = useState(null); // satellite image report id in the right dropdown
  /** NDVI Report IDs*/
  const [ndviLeft, setNdviLeft] = useState(null); // NDVI report id for the left dropdown
  const [ndviRight, setNdviRight] = useState(null); // NDVI report id for the right dropdown
  /** MISC. Status Checks*/
  const [isLoading, setIsLoading] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(""); // flag to indicate id of NDVI report that needs to be downloaded
  /** Passing radio selection to dropdown display */
  const [dropdownDisplay, setDropdownDisplay] = useState("reportID"); // flag to indicate if dropdown should display reportID, name, or date
  /** Properties gleaned from link with mowing display page chart */
  const location = useLocation(); // location object that represents current url
  const [clickedFromChart, setClickedFromChart] = useState(false); // flag to indicate if the page was clicked from the mowing display page chart
  const [satIdFromChart, setSatIdFromChart] = useState(null); // satellite image report id that was clicked from the mowing display page chart
  const [lat, setLat] = useState(null); // latitude of the satellite image that was clicked from the mowing display page chart
  const [long, setLong] = useState(null); // longitude of the satellite image that was clicked from the mowing display page chart
  /** Fullscreen toggle */
  const [isFullScreen, setFullScreen] = React.useState(false); // flag to indicate if the map is in fullscreen mode
  const [matchImage, setMatchImage] = useState(false);
  /** All Properties Option */
  const ALL_PROPERTIES_OPTION = { name: "All Properties", projectID: params.projectID, regionID: "all_properties" };
  const [propertyOptions, setPropertyOptions] = useState([]); // An array of selectable options in the Property dropdown menu
  const [satImagesForAllProperties, setSatImagesForAllProperties] = useState([]); // Filtered satellite images for All Properties
  const [leftDateOptions, setLeftDateOptions] = useState([]); // An array of objects in the format of YYYY-MM
  const [rightDateOptions, setRightDateOptions] = useState([]); // Same with above
  const [leftDateSelected, setLeftDateSelected] = useState(null);  // A string of the selected date in the format of YYYY-MM
  const [rightDateSelected, setRightDateSelected] = useState(null); // Same with above
  const [leftDisabledItem, setLeftDisabledItem] = useState(null); // An object of the selected date option
  const [rightDisabledItem, setRightDisabledItem] = useState(null); // Same with above
  /** Others */
  const [showInitialPopup, setShowInitialPopup] = useState(true);

  /** Map Display Settings */
  const accessToken = "pk.eyJ1Ijoia29yb3R1LWNvbnJveS10cmluaCIsImEiOiJja2pyeTh0cnU2aDViMnFwOTFrZHoxdDU3In0.VEuiNCwqBGL8CidAvTgdjA";
  const mapboxSatelliteUrl = `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}@2x?access_token=${accessToken}`;
  const INI_CENTROID = [46.2382, -63.1311]; // initially centered on PEI
  const INI_ZOOM_LEVEL = 10;
  const SINGLE_PROPERTY_ZOOM_LEVEL = 15;
  const MULTI_PROPERTIES_ZOOM_LEVEL = 13;
  const INI_OPACITY = 0.5;
  const [logoAdded, setLogoAdded] = useState(false);

  // INITIALIZATION

  /**
   * Once the component mounts, initialize webstate of web application by loading in the project data
   */
  useEffect(() => {
    async function initializeState() {
      let success = await props.changeProject(params.projectID);
      if (!success) {
        setProjectFailed(true);
        return;
      }
    }
    initializeState().catch(console.error);

    getMDReport(params.mowingID, 'forest_detection').then((result) => {
      setForestInfo(result);
    });

    // For single-property projects
    if (props.project) {
      getDataForRegion(props.project.region).then(singleRegion => {
        if (singleRegion) {
          if (location.state && location.state.selectedRgn) {
            console.log("location.state.selectedRgn: ", location.state.selectedRgn);
            setSelectedRegion(location.state.selectedRgn);
          } else {
            console.log("single region: ", singleRegion);
            setSelectedRegion(singleRegion);
          }
        }
      });
    }

    // For multiple properties projects
    getProjectRegions(params.projectID).then((fetchedRegions) => {
      if (fetchedRegions && fetchedRegions.length > 0) {
        setProjectRegions(fetchedRegions);
        if (location.state && location.state.selectedRgn) {
          // console.log("location state: ", location.state);
          // console.log("location.state.selectedRgn: ", location.state.selectedRgn);
          const regionFromMapPage = location.state.selectedRgn;
          if (regionFromMapPage.regionID === "all_properties") {
            setSelectedRegion(ALL_PROPERTIES_OPTION);
          } else {
            setSelectedRegion(regionFromMapPage);
          }
        } else {
          setSelectedRegion(ALL_PROPERTIES_OPTION); // Set All Properties as default
        }
      } else {
        setProjectRegions(null);
        setSelectedRegion(null);
      }
      console.log('fetchedRegions: ', fetchedRegions);
      console.log('selectedRegion: ', selectedRegion);
    });

    getProjectProperties(params.projectID).then((fetchedProperites) => {
      console.log("Project properties: ", fetchedProperites);
      if (fetchedProperites && fetchedProperites.length > 0) {
        setProjectProperties(fetchedProperites);
      }
    })
  }, []);

  /**
   * Once project data is loaded in, load in region data
   *
   * */
  useEffect(() => {
    async function setCurrRegion() {
      if (props.project != null) {
        console.log(props.project);
        console.log("calling changeRegion()");
        let success = await props.changeRegion(props.project.region);
        if (!success) {
          console.log("Region failed to be set");
        }
      }
    }
    setCurrRegion().catch(console.error);
  }, [props.project]);

  // SATELLITE IMAGES

  /**
   *  Once the component mounts, fetch Satellite images
   */
  useEffect(() => {
    const fetchSatelliteImages = async () => {
      let satelliteImageData = await getReportSatelliteImages(
        params.mowingID
      );

      // Filters out null items
      satelliteImageData = satelliteImageData.filter(item => {
        return item !== null;
      });
      // console.log('SatelliteImageData - original: ', satelliteImageData)

      // Filters out duplicate times for any specific region
      const seenDates = new Set();
      const uniqueSatelliteImageData = satelliteImageData.filter(item => {
        const isDuplicated = seenDates.has(item.image_date + item.regionID);
        seenDates.add(item.image_date + item.regionID);
        return !isDuplicated;
      });

      uniqueSatelliteImageData.sort((a, b) => new Date(a.image_date) - new Date(b.image_date));

      console.log('SatelliteImageData - all properties: ', uniqueSatelliteImageData)
      setSatelliteImages(uniqueSatelliteImageData);
    };
    fetchSatelliteImages();
    // Clean state on unmount, use to avoid warning
    return () => setSatelliteImages({});
  }, []);

  /**
   * Set the default dates on dropdowns 
   * */
  useEffect(() => {
    if (satelliteImages) {
      console.log("HERE selectedRegion: ", selectedRegion);
      if (selectedRegion && selectedRegion.regionID === "all_properties") {
        const dateOptionsArray = prepareAllPropertiesData();

        // Get left and right date selections from the Map page
        if (location.state?.leftDateSelected && location.state?.rightDateSelected) {
          setLeftDateSelected(location.state.leftDateSelected);
          setRightDateSelected(location.state.rightDateSelected);
          return;
        }

        setDefaultDatesSelection(dateOptionsArray);

      } else {
        console.log("SatelliteImageData - project: ", satelliteImages);
        const filteredImages = satelliteImages.filter(image => {
          if (selectedRegion && image.hasOwnProperty('regionID')) {
            // Include the images of historical reports with regionID field of null
            return image.regionID === selectedRegion.regionID || image.regionID === null;
          } else {
            return true; // Include the image if it doesn't have a regionID field
          }
        });

        console.log("SatelliteImageData - default region: ", filteredImages);
        setSatImagesForSelectedRegion(filteredImages);

        const mostRecentSatImageForRegion = filteredImages[filteredImages.length - 1];
        const mostRecentSatYearForRegion = new Date(mostRecentSatImageForRegion.image_date).getFullYear();
        const earliestDateInSameYearForRegion = filteredImages.find(item => {
          return new Date(item.image_date).getFullYear() === mostRecentSatYearForRegion;
        });

        if (location.state && location.state.leftSatImg && location.state.rightSatImg) {
          console.log("Got left and right satImgs from map page");
          setLeftSatImage(location.state.leftSatImg);
          setRightSatImage(location.state.rightSatImg);
        } else {
          console.log("Setting earliestDateInSameYearForRegion and mostRecentSatImageForRegion");
          setLeftSatImage(earliestDateInSameYearForRegion);
          setRightSatImage(mostRecentSatImageForRegion);
        }
      }
    }
  }, [satelliteImages]);

  /**
   * Update the Property dropdown
   */
  useEffect(() => {
    if (projectRegions) {
      const updatedOptions = [...projectRegions, ALL_PROPERTIES_OPTION];
      setPropertyOptions(updatedOptions);
    }
  }, [projectRegions]);

  /**
   * Fetch data for All Properties option
   */
  useEffect(() => {
    async function fetchAllPropertiesData() {
      if (!satImagesForAllProperties) {
        console.error("Failed to fetch available satellite images for the All Properties option");
        return;
      }

      if (leftDateSelected && rightDateSelected) {
        const geojsonList = [];
        const vegeChangeDataList = [];
        const dndviTiffList = [];

        for (let i = 0; i < projectRegions.length; i++) {
          const regionID = projectRegions[i].regionID;

          const leftSatImage = satImagesForAllProperties.find(image => image.regionID === regionID && image.image_date.substring(0, 7) === leftDateSelected);
          const leftSatID = leftSatImage.reportID;
          const leftNdviReport = await getNDVIFromSatellite(leftSatID);
          const leftNndvID = leftNdviReport.reportID.replace(/-/g, "");

          const rightSatImage = satImagesForAllProperties.find(image => image.regionID === regionID && image.image_date.substring(0, 7) === rightDateSelected);
          const rightSatID = rightSatImage.reportID;
          const rightNdviReport = await getNDVIFromSatellite(rightSatID);
          const rightNdviID = rightNdviReport.reportID.replace(/-/g, "");

          // Fetch DNDVI GeoJSON images
          let vectorImage = await getDNDVIVector(leftNndvID, rightNdviID);

          if (vectorImage != null) {
            // Assign UUID for each feature of the GeoJSON
            const newFeatures = vectorImage.features.map(feature => {
              const newFeature = { ...feature };
              newFeature.id = uuidv4();
              return newFeature;
            });
            vectorImage.features = newFeatures;
          } else {
            console.error("vectorImage for ", leftNndvID, "and ", rightNdviID, "failed.");
            break;
          }
          console.log(projectRegions[i].name, "geojson fetched for", leftNndvID, "and ", rightNdviID, ": ", vectorImage);
          geojsonList.push(vectorImage);

          // Fetch vegetation change data
          const vegeChangeResponse = await getHectaresImpacted(leftNndvID, rightNdviID);
          if (vegeChangeResponse != null) {
            console.log("vegeChangeData for ", leftNndvID, "and ", rightNdviID, ": ", vegeChangeResponse.data);
            vegeChangeDataList.push(vegeChangeResponse.data);
            console.log("vegeChangeData list: ", vegeChangeDataList);
          } else {
            console.error("vegeChangeData for ", leftNndvID, "and ", rightNdviID, "failed.");
            break;
          }

          // Fetch dndvi tiff images
          const dndviTiffBlob = await getDNDVIimage(leftNndvID, rightNdviID, 1);
          if (dndviTiffBlob != null) {
            console.log("dndviTiffBlob for ", leftNndvID, "and ", rightNdviID, ": ", dndviTiffBlob);
            const dndviTiff = {
              blob: dndviTiffBlob,
              reportName: leftSatImage.name,
              propertyName: projectRegions[i].name,
              leftImageDate: leftSatImage.image_date.substring(0, 10),
              rightImageDate: rightSatImage.image_date.substring(0, 10),
            }
            dndviTiffList.push(dndviTiff);
            console.log("dndviTiffBlob list: ", dndviTiffList);
          } else {
            console.error("dndviTiffBlob for ", leftNndvID, "and ", rightNdviID, "failed.");
            break;
          }
        }


        // Set fetched data for page rendering
        if (geojsonList.length === 0 || geojsonList.length !== projectRegions.length) {
          console.error("Error fetching vector images");
        }
        setDndviVectors(geojsonList);

        if (vegeChangeDataList.length === 0 || vegeChangeDataList.length !== projectRegions.length) {
          console.error("Error fetching vegetation change data");
        }
        const { high_loss_hectares, med_loss_hectares, low_loss_hectares, high_gain_hectares, med_gain_hectares, low_gain_hectares } = vegeChangeDataList.reduce((acc, curr) => {
          acc.high_loss_hectares += parseFloat(curr.high_loss_hectares);
          acc.med_loss_hectares += parseFloat(curr.med_loss_hectares);
          acc.low_loss_hectares += parseFloat(curr.low_loss_hectares);
          acc.high_gain_hectares += parseFloat(curr.high_gain_hectares);
          acc.med_gain_hectares += parseFloat(curr.med_gain_hectares);
          acc.low_gain_hectares += parseFloat(curr.low_gain_hectares);
          return acc;
        }, { high_loss_hectares: 0, med_loss_hectares: 0, low_loss_hectares: 0, high_gain_hectares: 0, med_gain_hectares: 0, low_gain_hectares: 0 });
        setHighLossHectares(high_loss_hectares.toFixed(2));
        setMedLossHectares(med_loss_hectares.toFixed(2));
        setLowLossHectares(low_loss_hectares.toFixed(2));
        setHighGainHectares(high_gain_hectares.toFixed(2));
        setMedGainHectares(med_gain_hectares.toFixed(2));
        setLowGainHectares(low_gain_hectares.toFixed(2));

        if (dndviTiffList.length !== projectRegions.length) {
          console.error("Error fetching tiff images");
        }
        setDndviTiffs(dndviTiffList);
      }
    }

    fetchAllPropertiesData();

    const leftSelected = leftDateOptions.find(dateObject => dateObject.value === leftDateSelected);
    const rightSelected = rightDateOptions.find(dateObject => dateObject.value === rightDateSelected);
    console.log("selected updates: ", leftSelected, rightSelected);

    setLeftDisabledItem(rightSelected);
    setRightDisabledItem(leftSelected);
    console.log("disabled updates: ", leftDisabledItem, rightDisabledItem);

  }, [satImagesForAllProperties, leftDateSelected, rightDateSelected]);

  /**
   * Helper to set the default dates selection for All Properties
   * @param {array} dateOptionsArray 
   */
  function setDefaultDatesSelection(dateOptionsArray) {
    if (dateOptionsArray.length !== 0) {
      const yearOfAnalysis = dateOptionsArray[dateOptionsArray.length - 1].substring(0, 4);
      const endOfYearOfAnalysis = dateOptionsArray[dateOptionsArray.length - 1];
      const startOfYearOfAnalysis = dateOptionsArray.find(date => date.substring(0, 4) === yearOfAnalysis);
      console.log("YOA: ", yearOfAnalysis, "start of YOA: ", startOfYearOfAnalysis, "end of YOA: ", endOfYearOfAnalysis);

      setLeftDateSelected(startOfYearOfAnalysis);
      setRightDateSelected(endOfYearOfAnalysis);
      setLeftSatImage(null);
      setRightSatImage(null);
      console.log("dates selected updates: ", leftDateSelected, rightDateSelected);
    }
  }

  /**
   * Handle Property dropdown
   */
  function handlePropertyOptionChange(regionID) {
    console.log("Changing region/regions to: ", selectedRegion?.name);

    if (regionID != "all_properties") {
      setLeftDateSelected(null);
      setRightDateSelected(null);
      handleRegionChange(regionID);
    } else {
      // Handle All Properties option selected
      setSelectedRegion(ALL_PROPERTIES_OPTION);

      const dateOptionsArray = prepareAllPropertiesData();
      setDefaultDatesSelection(dateOptionsArray);
    }
  }

  /**
   * Handle single region selected on property dropdown
   */
  const handleRegionChange = (regionID) => {
    console.log("Changing to a single region...");
    if (!satelliteImages || !projectRegions) {
      console.error("No satellite images or project regions loaded for the report.");
      return null;
    }

    // Filter sat images to only include ones for the selected region/property
    // const filteredImages = satelliteImages.filter(image => image.regionID === regionID);
    const filteredImages = satelliteImages.filter(image => {
      if (image.hasOwnProperty('regionID')) {
        return image.regionID === regionID;
      } else {
        return true; // Include the image if it doesn't have a regionID field
      }
    });
    console.log("SatelliteImageData - selected region: ", filteredImages);
    setSatImagesForSelectedRegion(filteredImages);

    // Set the selected region based on the regionID
    const firstMatchingRegion = projectRegions.find(region => region.regionID === regionID);
    if (firstMatchingRegion) {
      console.log("Found matching region: ", firstMatchingRegion)
      setSelectedRegion(firstMatchingRegion);
    } else {
      console.log("No matching region found")
    }

    // Set the date dropdowns based on the filteredImages for the selected region
    const mostRecentSatImageForRegion = filteredImages[filteredImages.length - 1];
    const mostRecentSatYearForRegion = new Date(mostRecentSatImageForRegion.image_date).getFullYear();
    const earliestDateInSameYearForRegion = filteredImages.find(item => {
      return new Date(item.image_date).getFullYear() === mostRecentSatYearForRegion;
    });

    console.log("Setting earliestDateInSameYearForRegion and mostRecentSatImageForRegion");
    changeLeftSat(earliestDateInSameYearForRegion.reportID);
    changeRightSat(mostRecentSatImageForRegion.reportID);
  };

  /**
   * Helper to prepare filtered satellite images and correct date dropdown options for All Properties
   * @returns {array} An list of year-month strings representing the available satellite images shared by all properties
   */
  function prepareAllPropertiesData() {
    let dateOptionsArray = [];

    if (satelliteImages !== null && projectRegions !== null) {
      // Filter the satellite images to get a new list to store sat images which all properties share on specific year-month
      const projectRegionsID = projectRegions.map(regionObject => regionObject.regionID);

      const satImagesForAllRegions = projectRegionsID.map(regionID => {
        const satImagesForOneRegion = satelliteImages.filter(image => image.regionID === regionID);
        return satImagesForOneRegion;
      });

      const commonImageYearMonth = new Set(satImagesForAllRegions[0].map(image => image.image_date.substring(0, 7)));
      for (let i = 1; i < satImagesForAllRegions.length; i++) {
        const currentYearMonth = new Set(satImagesForAllRegions[i].map(image => image.image_date.substring(0, 7)));
        for (const yearMonth of commonImageYearMonth) {
          if (!currentYearMonth.has(yearMonth)) {
            commonImageYearMonth.delete(yearMonth);
          }
        }
      }

      const sharedSatImagesForAllRegions = satImagesForAllRegions.map(oneRegionImages => {
        // only include year-month that all regions have avaliable images
        const imageList = oneRegionImages.filter(image => commonImageYearMonth.has(image.image_date.substring(0, 7)));

        // only keep first image for one month
        let seenYearMonth = new Set();
        return imageList.filter(image => {
          const yearMonth = image.image_date.substring(0, 7);
          if (!seenYearMonth.has(yearMonth)) {
            seenYearMonth.add(yearMonth);
            return true;
          }
          return false;
        });
      });

      const sharedDateSatImages = sharedSatImagesForAllRegions.flat();
      console.log("SatelliteImageData - shared dates: ", sharedDateSatImages);
      setSatImagesForAllProperties(sharedDateSatImages);

      // Update the two date dropdown menu options with avaiable images
      dateOptionsArray = Array.from(commonImageYearMonth);
      const dateOptions = dateOptionsArray.map(date => ({
        value: date,
        label: date
      }));

      setLeftDateOptions(dateOptions);
      setRightDateOptions(dateOptions);
    }

    return dateOptionsArray;
  }

  function handleTableClick(regionID, propertyName, startDate, endDate) {
    let tableSelectedRegionID = regionID;
    if (tableSelectedRegionID === "all_properties" && projectRegions) {
      const tableSelectedRegion = projectRegions.find(region => region.name === propertyName);
      console.log("table single region new: ", tableSelectedRegion);
      if (tableSelectedRegion) {
        setSelectedRegion(tableSelectedRegion);
        tableSelectedRegionID = tableSelectedRegion.regionID;
        handleRegionChange(tableSelectedRegionID);
      }
    } else {
      getDataForRegion(tableSelectedRegionID).then(singleRegion => {
        if (singleRegion) {
          console.log("table single region old: ", singleRegion);
          setSelectedRegion(singleRegion);
        }
      });
    }
    // console.log("SatelliteImageData - table region: ", satelliteImages);

    const newLeftSat = satelliteImages.find(image => {
      if (image.hasOwnProperty('regionID')) {
        return (image.regionID === tableSelectedRegionID || image.regionID === null) && image.image_date.substring(0, 7) === startDate;
      }
      return true;
    });
    // console.log("table newLeftSat", newLeftSat);

    const newRightSatList = satelliteImages.filter(image => {
      if (image.hasOwnProperty('regionID')) {
        return (image.regionID === tableSelectedRegionID || image.regionID === null) && image.image_date.substring(0, 7) === endDate;
      }
      return true;
    });
    // console.log("table newRightSatList", newRightSatList);

    if (!newLeftSat || !newRightSatList?.length) {
      window.alert("No match satellite image for your selected period of change");
      return;
    }

    removeLeftSat();
    removeRightSat();
    setLeftSatImage(newLeftSat); // Set to the first available image in the month
    setRightSatImage(newRightSatList[newRightSatList.length - 1]); // Set to the last available image in the month
    window.alert("Vegetation Change image will be updated for your selected period of change");
  }

  function changeLeftAll(dateSelected) {
    setLeftSatImage(null);

    console.log("Changing left all to: ", dateSelected);
    setLeftDateSelected(dateSelected);
  }

  function changeRightAll(dateSelected) {
    setRightSatImage(null);

    console.log("Changing right all to: ", dateSelected);
    setRightDateSelected(dateSelected);
  }

  /**
   * Changes the left satellite image to the one specified by reportID
   */
  const changeLeftSat = (reportID) => {
    setLeftDateSelected(null);

    console.log("Changing LeftSat...")
    if (!satelliteImages) {
      console.error("Satellite Images Do existing in manual change detection");
      return null;
    }

    try {
      for (let i = 0; i < satelliteImages.length; i++) {
        if (satelliteImages[i].reportID === reportID) {
          /** New Satellite Image ID */
          let newSatID;
          newSatID = satelliteImages[i].reportID.replace(/-/g, "");
          console.log('changeLeftSat: newSatID: ', newSatID);
          setLeftSatImage(satelliteImages[i]);
          i = satelliteImages.length;
        }
      }
    } catch (error) {
      window.alert("There was a problem displaying the selected image. Please try another one.");
    }
  };


  /**
   * Changes the right satellite image to the one specified by reportID
   */
  const changeRightSat = (reportID) => {
    setRightDateSelected(null);

    console.log("Changing RightSat...")
    if (!satelliteImages) {
      console.error("Satellite Images Do existing in manual change detection");
      return null;
    }
    try {
      for (let i = 0; i < satelliteImages.length; i++) {
        if (satelliteImages[i].reportID === reportID) {
          /** New Satellite Image ID */
          let newSatID;
          newSatID = satelliteImages[i].reportID.replace(/-/g, "");
          console.log('changeRightSat: newSatID: ', newSatID);
          setRightSatImage(satelliteImages[i]);
          i = satelliteImages.length;
        }
      }
    } catch (error) {
      window.alert("There was a problem displaying the selected image. Please try another one.");
    }
  };

  /**
   * Removes the left satellite image from being display on the map
   */
  const removeLeftSat = () => {
    /** Previous Satellite Image ID */
    console.log('removeLeftSat...')
    console.log("Left Satellite Image", leftSatImage);
    setLeftSatImage(null);
  };

  /**
   * Removes the right satellite image from being display on the map
   */
  const removeRightSat = () => {
    console.log('removeRightSat...')
    /** Previous Satellite Image ID */
    console.log("Right Satellite Image", rightSatImage);
    setRightSatImage(null);
  };

  // NDVI IMAGES

  /**
   * Fetches DNDVI image and hectares impacted from corresponding NDVI reportIDs
   */
  const fetchDNDVIimage = async (ndviLeft, ndviRight, isTiffRequest) => {
    console.log('fetchDNDVIimage...');
    setIsLoading(true);
    // Attempt to fetch
    const ndviIDBefore = ndviLeft.replace(/-/g, "");
    const ndviIDAfter = ndviRight.replace(/-/g, "");
    const dndviImageBlob = await getDNDVIimage(ndviIDBefore, ndviIDAfter, isTiffRequest);
    const hectaresResponse = await getHectaresImpacted(ndviIDBefore, ndviIDAfter);
    let dndviVectorGeoJSON = await getDNDVIVector(ndviIDBefore, ndviIDAfter);

    // Fetch success
    if (dndviImageBlob != null) {
      const dndviImageUrl = URL.createObjectURL(dndviImageBlob);
      setDndviImageUrl(dndviImageUrl);
      setIsLoading(false);
      setDndviImage(dndviImageUrl);
    } else {
      // Fetch failed
      console.log("dndviImageBlob is null. Check API or backend.");
    }

    if (dndviVectorGeoJSON != null) {
      // Assign UUID for each feature of the GeoJSON
      const newFeatures = dndviVectorGeoJSON.features.map(feature => {
        const newFeature = { ...feature };
        newFeature.id = uuidv4();
        return newFeature;
      });
      dndviVectorGeoJSON.features = newFeatures;

      setDndviVectors([dndviVectorGeoJSON]);
    } else {
      console.error("dndviVectorGeoJSON is null. Check API or backend.");
    }

    if (hectaresResponse != null) {
      setHighLossHectares(hectaresResponse.data["high_loss_hectares"]);
      setMedLossHectares(hectaresResponse.data["med_loss_hectares"]);
      setLowLossHectares(hectaresResponse.data["low_loss_hectares"]);
      setLowGainHectares(hectaresResponse.data["low_gain_hectares"]);
      setMedGainHectares(hectaresResponse.data["med_gain_hectares"]);
      setHighGainHectares(hectaresResponse.data["high_gain_hectares"]);
    }
  };

  /**
   * Fetches NDVI ID from corresponding satellite reportID and sets the left/right NDVI map layers with the corresponding NDVI image
   */
  const fetchNDVI = async (reportID, lor) => {
    console.log('fetchNDVI...');
    // Attempt to fetch
    const NDVIReportID = await getNDVIFromSatellite(reportID);
    console.log('NDVIReportID:', NDVIReportID);
    // Fetch success
    if (NDVIReportID != null) {
      if (lor === "left") {
        if (ndviLeft != NDVIReportID.reportID)
          setNdviLeft(NDVIReportID.reportID);
        else fetchDNDVIimage(ndviLeft, ndviRight, 0);
      } else if (lor === "right") {
        if (ndviRight != NDVIReportID.reportID)
          setNdviRight(NDVIReportID.reportID);
        else fetchDNDVIimage(ndviLeft, ndviRight, 0);
      }
    } else {
      // Fetch failed
      console.log("reportID is null. Generating NDVI.");
      triggerNdviCreation(lor).then(() => {
        console.log("NDVI generation success");
      });
    }
  };

  // Helper for fetchNDVI() that downloads the NDVI image if needed
  async function triggerNdviCreation(lor) {
    setDownloadStatus("pending");
    let satelliteImage;
    if (lor === "left") satelliteImage = leftSatImage;
    else if ((lor = "right")) satelliteImage = rightSatImage;

    // Edge case when both left and right sat images are the same.
    let download = true;
    if (leftSatImage == rightSatImage && leftSatImage != null && lor == "right")
      download = false;

    if (download) {
      let ndviData = await createNDVI(
        params.projectID,
        params.propertyID,
        satelliteImage.reportID,
        satelliteImage.name + "_ndvi"
      );
      if (ndviData !== null) {
        console.log("ndvi creation success");
        setDownloadStatus("success");
      } else {
        console.error("NDVI Creation Failed");
        setDownloadStatus("failed");
      }
    }
  }

  /**
   * This makes sure NDVI is fetched AFTER a satellite reportID is provided
   */
  useEffect(() => {
    // If ndvi then layer this on top
    console.log('LEFT:')
    console.log('leftSatImage: ', leftSatImage);
    console.log('ndviLeft: ', ndviLeft);
    console.log('rightSatImage: ', rightSatImage);
    console.log('ndviRight: ', ndviRight);
    if (leftSatImage != null) {
      fetchNDVI(leftSatImage.reportID, "left");
    }
  }, [leftSatImage]);

  /**
   * Fetch right NDVI image after right satellite image is set 
   */
  useEffect(() => {
    // If ndvi then layer this on top
    console.log('RIGHT:')
    console.log('leftSatImage: ', leftSatImage);
    console.log('ndviLeft: ', ndviLeft);
    console.log('rightSatImage: ', rightSatImage);
    console.log('ndviRight: ', ndviRight);
    if (rightSatImage != null) {
      fetchNDVI(rightSatImage.reportID, "right");
    }
  }, [rightSatImage]);

  /**
   * This makes sure DNDVI is fetched AFTER all sats and NDVIs are provided
   */
  useEffect(() => {
    console.log('leftSatImage2: ', leftSatImage);
    console.log('ndviLeft2: ', ndviLeft);
    console.log('rightSatImage2: ', rightSatImage);
    console.log('ndviRight2: ', ndviRight);
    if (leftSatImage != null && rightSatImage != null && ndviLeft != null && ndviRight != null) {
      console.log('Fetching DNDVI with valid sats and ndvis');
      fetchDNDVIimage(ndviLeft, ndviRight, 0);
    }
  }, [ndviLeft, ndviRight]);

  async function downloadHandler() {
    if (selectedRegion === null || selectedRegion.regionID !== "all_properties") {
      // For single property
      if (!leftSatImage || !rightSatImage) {
        window.alert("Please select two dates first");
        return;
      }

      const ndviIDBefore = ndviLeft.replace(/-/g, "");
      const ndviIDAfter = ndviRight.replace(/-/g, "");
      const dndviImageBlob = await getDNDVIimage(ndviIDBefore, ndviIDAfter, 1);
      const dndviImageUrl = URL.createObjectURL(dndviImageBlob);
      const leftDate = leftSatImage['image_date'].split("T")[0];
      const rightDate = rightSatImage['image_date'].split("T")[0];
      const propertyName = selectedRegion ? selectedRegion.name.replace(/\.geojson$/, "") : (props.region ? props.region.name.replace(/\.geojson$/, "") : "FIELD");
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', dndviImageUrl);
      a.setAttribute('download', `${leftSatImage['name']}_${propertyName}_NDVI_${leftDate}_${rightDate}.tiff`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      // For All Properties option
      if (!leftDateSelected || !rightDateSelected) {
        window.alert("Please select two dates first");
        return;
      }

      if (!dndviTiffs.length) {
        window.alert("No images available for download");
        return;
      }

      const downloadDelay = 100; // Setting the download delay time (milliseconds) to avoid page blocking

      function downloadNext(index) {
        if (index >= dndviTiffs.length) {
          return;
        }

        const { blob,
          reportName,
          propertyName,
          leftImageDate,
          rightImageDate } = dndviTiffs[index];
        const fileName = `${reportName}_${propertyName}_NDVI_${leftImageDate}_${rightImageDate}.tiff`;

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTimeout(() => {
          downloadNext(index + 1);
        }, downloadDelay);
      }

      downloadNext(0);
    }
  }

  /** HTML RENDERING */

  /** checking valid state */
  if (projectFailed) {
    return <Redirect to="/project" />;
  }

  /** Sets the Leaflet map center */
  function RegionLocation() {
    const map = useMap();
    let mapCentroid;

    useEffect(() => {
      if (projectProperties != null) {
        // For projects created with multiple properties
        if (projectProperties.length === 1) {
          let singleProperty = projectProperties[0];
          mapCentroid = [singleProperty.longitude, singleProperty.latitude];
          map.flyTo([singleProperty.latitude, singleProperty.longitude], SINGLE_PROPERTY_ZOOM_LEVEL);
        } else {
          const propertiesCoordinates = projectProperties.map(property => [property.longitude, property.latitude]);
          mapCentroid = props.computeCentroid(propertiesCoordinates);
          map.flyTo([mapCentroid[1], mapCentroid[0]], MULTI_PROPERTIES_ZOOM_LEVEL);
        }
      } else {
        // For old projects without associated properties
        if (props.region != null) {
          mapCentroid = props.computeCentroid(
            props.region.data.geometry.coordinates[0]
          );
          map.flyTo([mapCentroid[1], mapCentroid[0]], SINGLE_PROPERTY_ZOOM_LEVEL);
        }
      }
    }, [props.region, map]);

    return null;
  }

  /** Changes the default position of zoom panel */
  function ZoomControlPanelPosition() {
    const map = useMap();
    map.zoomControl.setPosition('bottomleft');

    return null;
  }

  /** Adds Mapbox logo attribution */
  function MapboxLogoSetting() {
    const map = useMap();

    useEffect(() => {
      const WatermarkControl = L.Control.extend({
        onAdd: function () {
          var img = L.DomUtil.create('img');
          img.src = MapboxLogo;
          img.style.height = '20px';
          return img;
        },
        onRemove: function () {
          // Nothing to do here
        }
      });
      const watermark = new WatermarkControl({ position: 'topleft' });
      watermark.addTo(map);
    }, [map]);

    setLogoAdded(true);
    return null;
  }

  /** Sets color style of DNDVI vector image */
  const DNDVI_COLOR_STYLE = (feature) => {
    const { red, green, blue } = feature.properties;
    const color = `rgb(${red}, ${green}, ${blue})`;

    return {
      fillColor: color,
      weight: 0,
      fillOpacity: INI_OPACITY,
    };
  };

  /** renders the webpage components */
  return (
    <div className="page-margins">
      <div className="manual-change-detection__page__header">
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/summary-page`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Summary</h3>
        </Link>
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/ndvi-report-chart`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Chart</h3>
        </Link>
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/ndvi-compare`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Map</h3>
        </Link>
        <h3 className="manual-change-detection__page__header--active">Auto</h3>

        <div className="automatic-detection-page__selections-container">
          {/** ##### Region/property selection ##### */}
          <div className="automatic-detection-page__select-subcontainer">
            <p className="automatic-detection-page__select-label">Property:</p>
            <div className="automatic-detection-page__select">
              {projectRegions && projectRegions.length > 0 && satelliteImages && satelliteImages.length > 0 ? (
                <DropdownForest
                  value={
                    selectedRegion ? selectedRegion.regionID : ""
                  }
                  setValue={handlePropertyOptionChange}
                  removeValue={() => setSelectedRegion(ALL_PROPERTIES_OPTION)}
                  objectList={propertyOptions ? propertyOptions : []}
                  valueProperty="regionID"
                  labelProperty="name"
                  width="200px"
                />
              ) : (satelliteImages && satelliteImages.length > 0) && !projectRegions ||
                (projectRegions && projectRegions.length <= 0) ? (
                <div className="loading-container">
                  <p>{props.region ? props.region.name.replace(/\.geojson$/, '') : "FIELD"}</p>
                </div>
              ) : (
                <div className="loading-container">
                  <p>Loading...</p>
                </div>
              )}
            </div>
          </div>

          {/** ##### Baseline date ##### */}
          <div className="automatic-detection-page__select-subcontainer">
            <p className="automatic-detection-page__select-label">Baseline Date:</p>
            <div className="automatic-detection-page__select">
              {satelliteImages && satelliteImages.length > 0
                ? selectedRegion === null || selectedRegion.regionID !== "all_properties"
                  ? (
                    <DropdownForest
                      key={selectedRegion ? selectedRegion.regionID : ""}
                      value={leftSatImage?.reportID || ""}
                      setValue={changeLeftSat}
                      removeValue={removeLeftSat}
                      disabledItem={rightSatImage}
                      objectList={satImagesForSelectedRegion ? satImagesForSelectedRegion : []}
                      valueProperty="reportID"
                      labelProperty="image_date"
                      width="200px"
                    />
                  )
                  : (
                    <DropdownForest
                      value={leftDateSelected}
                      setValue={changeLeftAll}
                      removeValue={() => setLeftDateSelected(null)}
                      disabledItem={leftDisabledItem}
                      objectList={leftDateOptions}
                      valueProperty="value"
                      labelProperty="label"
                      width="200px"
                    />
                  )
                : (
                  <div className="loading-container">
                    <p>Loading...</p>
                  </div>
                )
              }
            </div>
          </div>

          {/** ##### Comparison date ##### */}
          <div className="automatic-detection-page__select-subcontainer">
            <p className="automatic-detection-page__select-label">Comparison Date:</p>
            <div className="automatic-detection-page__select">
              {satelliteImages && satelliteImages.length > 0
                ? selectedRegion === null || selectedRegion.regionID !== "all_properties"
                  ? (
                    <DropdownForest
                      key={selectedRegion ? selectedRegion.regionID : ""}
                      value={rightSatImage?.reportID || ""}
                      setValue={changeRightSat}
                      removeValue={removeRightSat}
                      disabledItem={leftSatImage}
                      objectList={satImagesForSelectedRegion ? satImagesForSelectedRegion : []}
                      valueProperty="reportID"
                      labelProperty="image_date"
                      width="200px"
                    />
                  )
                  : (
                    <DropdownForest
                      value={rightDateSelected}
                      setValue={changeRightAll}
                      removeValue={() => setRightDateSelected(null)}
                      disabledItem={rightDisabledItem}
                      objectList={rightDateOptions}
                      valueProperty="value"
                      labelProperty="label"
                      onDisplayChange={dropdownDisplay}
                      width="200px"
                    />
                  )
                : (
                  <div className="loading-container">
                    <p>Loading...</p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>

      <div className="automatic-detection-page__content-wrapper">
        <div className="automatic-detection-page__info-wrapper">
          <h4>Legend - Vegetation Change</h4>

          <div className="automatic-detection-page__legend-wrapper">
            <div className="automatic-detection-page__legend-item">
              <div className="automatic-detection-page__legend-item--first-line">
                <div className="automatic-detection-page__legend-icon high-vegetation-loss"></div>
                <h4>High Vegetation Loss</h4>
                <InfoIconWithTooltip
                  text="A high vegetation loss is detected when the NDVI value decreases by >0.2"
                />
              </div>
              <div className="automatic-detection-page__legend-item--second-line">
                <p>{highLossHectares} hectares impacted</p>
              </div>
            </div>
            <hr className="automatic-detection-page__legend-divider" />

            <div className="automatic-detection-page__legend-item">
              <div className="automatic-detection-page__legend-item--first-line">
                <div className="automatic-detection-page__legend-icon med-vegetation-loss"></div>
                <h4>Medium Vegetation Loss</h4>
                <InfoIconWithTooltip
                  text="A medium vegetation loss is detected when the NDVI value decreases by a range of 0.1 - 0.2"
                />
              </div>
              <div className="automatic-detection-page__legend-item--second-line">
                <p>{medLossHectares} hectares impacted</p>
              </div>
            </div>
            <hr className="automatic-detection-page__legend-divider" />

            <div className="automatic-detection-page__legend-item">
              <div className="automatic-detection-page__legend-item--first-line">
                <div className="automatic-detection-page__legend-icon low-vegetation-loss"></div>
                <h4>Low Vegetation Loss</h4>
                <InfoIconWithTooltip
                  text="A low vegetation loss is detected when the NDVI value decreases by <0.1"
                />
              </div>
              <div className="automatic-detection-page__legend-item--second-line">
                <p>{lowLossHectares} hectares impacted</p>
              </div>
            </div>
            <hr className="automatic-detection-page__legend-divider" />

            <div className="automatic-detection-page__legend-item">
              <div className="automatic-detection-page__legend-item--first-line">
                <div className="automatic-detection-page__legend-icon low-vegetation-gain"></div>
                <h4>Low Vegetation Gain</h4>
                <InfoIconWithTooltip
                  text="A low vegetation gain is detected when the NDVI value increases by <0.1"
                />
              </div>
              <div className="automatic-detection-page__legend-item--second-line">
                <p>{lowGainHectares} hectares impacted</p>
              </div>
            </div>
            <hr className="automatic-detection-page__legend-divider" />

            <div className="automatic-detection-page__legend-item">
              <div className="automatic-detection-page__legend-item--first-line">
                <div className="automatic-detection-page__legend-icon med-vegetation-gain"></div>
                <h4>Medium Vegetation Gain</h4>
                <InfoIconWithTooltip
                  text="A medium vegetation gain is detected when the NDVI value increases by a range of 0.1 - 0.2"
                />
              </div>
              <div className="automatic-detection-page__legend-item--second-line">
                <p>{medGainHectares} hectares impacted</p>
              </div>
            </div>
            <hr className="automatic-detection-page__legend-divider" />

            <div className="automatic-detection-page__legend-item">
              <div className="automatic-detection-page__legend-item--first-line">
                <div className="automatic-detection-page__legend-icon high-vegetation-gain"></div>
                <h4>High Vegetation Gain</h4>
                <InfoIconWithTooltip
                  text="A high vegetation gain is detected when the NDVI value increases by >0.2"
                />
              </div>
              <div className="automatic-detection-page__legend-item--second-line">
                <p>{highGainHectares} hectares impacted</p>
              </div>
            </div>
            <hr className="automatic-detection-page__legend-divider" />

          </div>
        </div>

        <div className="dndvi-image-container">
          <button className="automatic-detection-page__download-button">
            <img src={DownloadIcon} onClick={() => downloadHandler()} />
          </button>
          {showInitialPopup && (
            <div className="initial-popup">
              <p>Select two dates to view vegetation changes between those dates </p>
              <button onClick={() => setShowInitialPopup(false)}></button>
            </div>
          )}

          <div className="leaflet-container">
            <MapContainer
              center={INI_CENTROID}
              zoom={INI_ZOOM_LEVEL}
              scrollWheelZoom={true}
            >
              <LayersControl collapsed={false} position='bottomleft'>
                <LayersControl.BaseLayer name="Satellite Map" checked={true}>
                  <TileLayer
                    attribution={`© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>`}
                    url={mapboxSatelliteUrl}
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Street Map" checked={false}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.Overlay name="Vegetation Change" checked={true}>
                  <LayerGroup>
                    {dndviVectors && dndviVectors.map(vector => (
                      <GeoJSON
                        data={vector}
                        style={DNDVI_COLOR_STYLE}
                        key={vector.features[0].id} // unique key to render vector correctly
                      />
                    ))}
                  </LayerGroup>
                </LayersControl.Overlay>
              </LayersControl>

              <RegionLocation />
              <ZoomControlPanelPosition />
              {!logoAdded && (<MapboxLogoSetting />)}

            </MapContainer>
          </div>

          <div className="dndvi-event-table">
            <DataTable
              fieldName={selectedRegion ? selectedRegion.name.replace(/\.geojson$/, "") : "FIELD"}
              regionName={props.region ? props.region.name.replace(/\.geojson$/, "") : "FIELD"}
              regionID={(selectedRegion || props.region || ALL_PROPERTIES_OPTION).regionID}
              reportID={params.mowingID}
              reportName={forestInfo.name}
              startDate={leftDateSelected || (leftSatImage ? leftSatImage.image_date : null)}
              endDate={rightDateSelected || (rightSatImage ? rightSatImage.image_date : null)}
              tableRowClick={handleTableClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
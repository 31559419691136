import Back from '../images/icons/back-icon.png'
import { createCustomRegion, getProjectRegions, createCustomProperty } from "../apis/landsteward/accounts"
import React, {
    useCallback,
    useState,
    useEffect,
    useRef,
    Component,
   } from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import "./region-new.css"
import { MapboxProperty } from '../components/mapbox/mapbox-property';


export function RegionNewForest(props) {
    const history = useHistory()
    const location = useLocation();
    const regionsList = useRef(null)
    const params = useParams()
    const [warning, setWarning] = useState('');
    const [regionID, setRegionID] = useState('');
    const [regionName, setRegionName] = useState('');
    const [features, setFeatures] = useState([]);
    const [regions, setRegions] = useState([]);
    const [currRegion, setCurrRegion] = useState('');
    const [centroids, setCentroids] = useState([]);

    const [address, setAddress] = useState("");
    const [long, setLong] = useState("");
    const [lat, setLat] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [landownerName, setLandownerName] = useState("");
    const [usingMap, setUsingMap] = useState(false);
  
    const [fileName, setFileName] = useState(null);
    const [projectDoesNotExist, setProjectFail] = useState(false);
  
    const [addAnotherProperty, setAddAnotherProperty] = useState(false);
  
    const [nameSet, setNameSet] = useState(new Set());


    function calculateCentroid(coordinates, type) {
        let sumX = 0, sumY = 0, numPoints = 0;
    
        if (type === 'Polygon') {
            coordinates[0].forEach(coord => {
                sumX += coord[0];
                sumY += coord[1];
                numPoints++;
            });
        } else if (type === 'MultiPolygon') {
            coordinates.forEach(polygon => {
                polygon[0].forEach(coord => {
                    sumX += coord[0];
                    sumY += coord[1];
                    numPoints++;
                });
            });
        }
        return [sumX / numPoints, sumY / numPoints];
    }


let name = location.state && location.state.name;
let description = location.state && location.state.description;

    useEffect(() => {
        console.log(name);
        console.log(description);
        console.log(props.project)
      },)



    const fileValidation = () => {
        let fileInput = document.getElementById('uploadButton');
        let filePath = fileInput.value;
        let allowedExtensions = /(\.geojson)$/i
        let CRS = ''
        let containsInvalidPropertyName = false;

        if (!allowedExtensions.exec(filePath)) {
            setWarning('Please select a geojson file')
            fileInput.value = '';

        } else {
            let file = fileInput.files[0]
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {

                const binaryStr = reader.result;
                let enc = new TextDecoder("utf-8");
                var data = JSON.parse(enc.decode(binaryStr));
                // console.log(data.features)

                if (typeof data.crs == 'undefined') {
                    CRS = "urn:ogc:def:crs:OGC:1.3:CRS84"
                } else {
                    CRS = data.crs.properties.name
                }



                if (data.features.length > 0) {
                    data.features.forEach((feature, index) => {
                        if (feature.properties.Name.length > 30) {
                            console.log("too long")
                            containsInvalidPropertyName = true;
                        }

                        if (feature.properties.Name === undefined) {
                            console.log("found undefined")
                            feature.properties.Name = `Feature ${index}`
                        }

                        feature.properties.Name = feature.properties.Name.replace("(","-");
                        feature.properties.Name = feature.properties.Name.replace(")","-")
                        console.log("feature.properties.Name", feature.properties.Name)
                    })
                }
                console.log("data --", data)
                setFeatures(data.features);
                console.log("FEATURES", features)

                if (containsInvalidPropertyName) {
                    fileInput.value = '';
                    setWarning('Property name should be less than 30 characters')
                } else if (CRS !== "urn:ogc:def:crs:OGC:1.3:CRS84") {
                    fileInput.value = '';
                    setWarning('Geojson file should be using a Geographic Coordinate System, where latitude and longitude are in decimal degrees (CRS84)')
                } else {
                    setWarning('')
                    setWarning(`Found ${data.features.length} properties in file`)
                    document.getElementById('fileName').innerHTML = fileInput.files[0].name
                    document.getElementById('submit-file-button').disabled = false
                    // clears inputs if user inputs new file
                    // Array.from(document.getElementsByClassName("region-name-input" )).forEach(input => input.value = "")

                    console.log("File name", fileInput.files[0].name)

                    
                    
                }
            }

            reader.readAsArrayBuffer(file);
            setRegionName(fileInput.files[0].name)
            console.log("Region name", regionName)

        }
    }

    async function createNewRegion(e) {
        e.preventDefault();
        let region;
        let createdRegions = [];
        let success = false

        if (features && features.length > 0) {

            for (let i = 0; i < features.length; i++) {
                console.log([features[i]])

                features[i].properties.Name = features[i].properties.Name.replace(/\(/g, "-").replace(/\)/g, "-");

                region = await createCustomRegion(features[i].properties.Name, [features[i]], params.projectID);
                if (!region) {
                    setWarning('Please select a geojson file of choose an area on map')
                    console.error(`Creating region '${features[i].properties.Name}' failed`);
                    
                    return;
                }
                setWarning(`UPLOADING REGIONS! PLEASE DO NOT LEAVE PAGE! \n
                            Uploaded region ${features[i].properties.Name}`)
                createdRegions.push(region);
            const centroid = calculateCentroid(features[i].geometry.coordinates, features[i].geometry.type);
                console.log(centroid);

                const body = {
                    property_name: features[i].properties.Name,
                    longitude: centroid[0],
                    latitude: centroid[1],
                    perimeter_meter: 100,
                    area_hectares: 0,
                    region: region.regionID,
                };
                console.log(body);

                const property = await createCustomProperty(body);
                if (!property) {
                    console.error("Property creation failed");
                    return;
                }

            }         
            success = true

        }
                    /** Add Project to website State */
            if (success) {
                /** Updated Website State and then Route to project */
                setWarning('Succesfully uploaded ALL regions')
                setRegions(createdRegions);
                history.push({
                    pathname: `/project/${params.projectID}/${params.mowingID}/ndvi-property-page`,
                    state: { features: features }
                });

            } else {
                console.error('Project Creation Failed');
                alert("Region Creation Failed. Make sure you have a completed boundary!");
            }
            console.log("regions", createdRegions)

    }

    const createOwnRegions = () => {

        history.push({
            pathname: `/project/${params.projectID}/new-region-forest-custom`,
            state: {name, description}
        });
      };

    return (
        
        <div className='project-new__body'>

            <div className='project-new__back-col'>
                {/* <button className='back-btn project-new__back-btn'>
                <img src={Back}></img>
            </button> */}
            </div>
            <div className='project-new__form-col'>

                <div className="project-new-title">
                    <h1>Select a Region Area</h1>
                </div>
                <form className='project-new__form' onSubmit={createNewRegion}>
                    <div className="project-new__form__body">

                        <div className='project-new__input-block'>
                            <label htmlFor='project-upload'>Upload an existing shapefile</label>
                            <div className='upload-file-button'>
                                <input
                                    type="file"
                                    accept=".geojson"
                                    id="uploadButton"
                                    onChange={() => fileValidation()}
                                    hidden
                                />
                                <label htmlFor="uploadButton" id="fileName">Upload...</label>
                            </div>
                        </div>

                        <div className='project-new__input-block'>
                            <label htmlFor='project-map'>Choose on map</label>
                            <div className='upload-file-button'>
                                <input
                                    type="submit"
                                    id="map-button"
                                    onClick={() => createOwnRegions()}
                                    hidden
                                />
                                <label htmlFor="map-button">Open Map...</label>
                            </div>
                        </div>

                        <p className='project-new__warning-text'>{warning}</p>

                        <div className='project-new__input-block'>
                            {/* <label htmlFor='project-upload' style={{display: (features.length > 0) ? "block" : "none"}}>Rename Region</label>
                            <div className='regions-list' style={{display: (features.length > 0) ? "block" : "none"}}>
                                    {
                                        features.map((feature, index) => {
                                            if (feature.properties.Name === undefined) {
                                                feature.properties.Name = regionName + `-F${index}`
                                            }
                                            console.log("refreshed map")
                                            console.log(feature.properties.Name)
                                            return (
                                            <input
                                                className="region-name-input"
                                                onChange={(e) => {
                                                    setFeatures((oldFeatures) => {
                                                        oldFeatures[index].properties.Name = e.target.value
                                                        return oldFeatures
                                                    })
                                                }}
                                                defaultValue={feature.properties.Name}
                                            /> )
                                        })
                                    }
                            </div> */}


                        <div className="project-new-button">
                            <input
                                name='submit-btn'
                                type='submit'
                                id='submit-file-button'
                                value='Confirm Area'
                                disabled
                            />
                        </div>

                    </div>
                    </div>
                </form>


            </div>
            <div className='project-new__back-col'></div>

        </div>

    )
}
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  Component,
} from "react";

import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import "./satellite-image-display-page.css";
import "./mowing-display-page.css";
import DeltaIcon from "../images/icons/delta-icon-dark.png";
import DownloadIcon from "../images/download-icon.png";
import { DropdownSearch } from "../components/modules/dropdown-search";
import { Bubble, Line } from "react-chartjs-2";

import {
  getMDReport,
  getMDReportCSV,
  getProjectRegions,
  getProjectProperties,
} from "../apis/landsteward/accounts";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  LineElement,
} from "chart.js";

import "./ndvi-report-chart-delta.css";

const monthLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];
const specialLabelColors = {Jan: '#adacac', Feb: '#adacac', Dec: '#adacac'};
const tickColor = monthLabels.map(label => specialLabelColors[label] || '#4D4D4D');

/**
 *
 * @param {*} props
 */
export function NDVIReportChartDelta(props) {
  /** ##### URL Param Check ##### */
  let params = useParams();
  let [projectFailed, setProjectFailed] = useState(false);
  let [forestData, setForestData] = useState({});
  let [forestInfo, setForestInfo] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [selectedYearLeft, setSelectedYearLeft] = useState("");
  const [selectedYearRight, setSelectedYearRight] = useState("");
  const [chartData, setChartData] = useState({
    labels: monthLabels,
    datasets: [
      {
        label: 'Monthly Difference',
        data: Array(monthLabels.length).fill(null),
        borderColor: '#4579FF',
        pointBorderColor: '#4579FF',
        pointStyle: 'circle',
        pointRadius: 6,
        borderWidth: 3,
        spanGaps: false,
      },
    ],
  });
  const [yearlyAverageMonthlyNDVI, setYearlyAverageMonthlyNDVI] = useState({});
  const [dataForSelectedRegion, setDataForSelectedRegion] = useState(null);
  /** Regions */
  const [projectRegions, setProjectRegions] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  /** Properties */
  const [projectProperties, setProjectProperties] = useState(null);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    LineElement
  );

  // Handle new region selected on property dropdown
  const handleRegionChange = async (regionID) => {
    console.log("handleRegionChange...");
    if (!projectRegions) {
      console.error("No project regions loaded for the report.");
      return null;
    }

    // Set the selected region based on the regionID
    const firstMatchingRegion = projectRegions.find(region => region.regionID === regionID);
    if (firstMatchingRegion) {
      console.log("Found matching region: ", firstMatchingRegion)
      setSelectedRegion(firstMatchingRegion);
    } else {
      console.log("No matching region found")
    }

    // Handle All Properties selected
    if (regionID == "all_properties") {
      console.log("All Properties selected");
      setDataForSelectedRegion(forestData);
      setChartDataBasedOnSelection();
      return;
    }

    // Filter chart data to only include ones for the selected region/property
    const selectedProperty = projectProperties.find(property => property.region === regionID);
    if (!selectedProperty) {
      console.error("No property found for the selected region.");
      return null;
    }
    const propertyName = selectedProperty.property_name;
    console.log("propertyName: ", propertyName);

    const rows = forestData.split('\n');
    const headers = rows[0].split(',');
    const csvObjects = [];
    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(',');
      const obj = {};
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = row[j];
      }
      csvObjects.push(obj);
    }

    // Filter forestData to only include ones for the selected region/property
    let filteredData = [];
    filteredData = csvObjects.filter(entry => entry['Field Name'] === propertyName);
    console.log("filteredData: ", filteredData)

    // Reconstruct the filtered data back into a string with the same formatting as forestData
    let filteredString = "Date,Landowner,Field Name,No. of pixels in field,% of non-cloud data in field,Average NDVI,Max NDVI,Min NDVI,satID,ndviID,Data Source\n";
    filteredData.forEach(entry => {
      filteredString += Object.values(entry).join(",") + "\n";
    });

    console.log("filteredString: ", filteredString)
    setDataForSelectedRegion(filteredString);
  };

  const removeSelectedRegion = () => {
    setSelectedRegion(null);
  };
 
  function readCSVFile(response) {
    var lines = response.split("\n");
    let modifiedResponse = "";
    for (var i = 0; i < lines.length; i++) {
      var row = lines[i].split(","); // First column (Split on the separator!)
      if (row[0] == "Date") {
        modifiedResponse = modifiedResponse.concat(row + "\n");
        continue;
      } else if (row[0] != "Date" && row[0] != "") {
        let timeRemoved = row[0].slice(0, 10);
        row[0] = timeRemoved;
        let modifiedRow = row.join(",");
        modifiedResponse = modifiedResponse.concat(modifiedRow + "\n");
      }
    }
    return modifiedResponse;
  }

  // Initialize state and fetch data on page load
  useEffect(() => {
    getMDReportCSV(params.mowingID).then((response) => {
      let timeRemovedResponse = readCSVFile(response);
      setForestData(timeRemovedResponse);
    })
 
    getMDReport(params.mowingID, 'forest_detection').then((result) => {
      setForestInfo(result);
    });

    async function initializeState() {
      const projectID = params.projectID;
      let success = await props.changeProject(projectID);
      if (!success) {
        setProjectFailed(true);
        return;
      }

      getProjectRegions(params.projectID).then((fetchedRegions) => {
        let modifiedRegions = [...fetchedRegions];
        modifiedRegions.push({ regionID: "all_properties", name: "All Properties" });

        if (modifiedRegions && modifiedRegions.length > 0) {
            setProjectRegions(modifiedRegions);
            setSelectedRegion(modifiedRegions.find(region => region.regionID === "all_properties") || modifiedRegions[0]);
        } else {
            setProjectRegions(null);
            setSelectedRegion(null);
        }
        console.log('fetchedRegions: ', modifiedRegions);
        console.log('selectedRegion: ', selectedRegion);
      });
  
      getProjectProperties(params.projectID).then((fetchedProperties) => {
        console.log("fetchedProperties: ", fetchedProperties);
        setProjectProperties(fetchedProperties);
      });
    }
    props.changeCurrentState(params.projectID).then((isCorrectState) => {
      if (!isCorrectState.project) {
        console.error("Failing Projects", isCorrectState);
        setProjectFailed(true);
      }
    });
 
    initializeState().catch(console.error);
    // Clean state on unmount, use to avoid warning
    return () => setForestData({});
  }, []);

  // Populate chart data
  useEffect(() => {
    let data = forestData; 
    if (dataForSelectedRegion) {
      data = dataForSelectedRegion;
    }

    if (data) {
      const rows = String(data).split('\n');

      // Create object to store NDVI data organized by year and month
      const yearlyMonthlyNDVI = {};

      // Parse CSV data and organize by year and month
      for (let i = 1; i < rows.length; i++) {
        const row = rows[i].split(",");
        const date = new Date(row[0]);
        const year = date.getFullYear().toString();
        const month = date.getMonth() + 1; // Months are zero-indexed
        const ndvi = parseFloat(row[5]);

        if (!isNaN(ndvi)) {
          if (!yearlyMonthlyNDVI[year]) {
            yearlyMonthlyNDVI[year] = {};
          }

          if (!yearlyMonthlyNDVI[year][month]) {
            yearlyMonthlyNDVI[year][month] = [];
          }
 
          yearlyMonthlyNDVI[year][month].push(ndvi);
        }
      }
 
      // Calculate average monthly NDVI for each year
      const calculatedYearlyMonthlyNDVI = {};
 
      for (const year in yearlyMonthlyNDVI) {
        calculatedYearlyMonthlyNDVI[year] = {};
        for (const month in yearlyMonthlyNDVI[year]) {
          const ndviArray = yearlyMonthlyNDVI[year][month];
          const averageNDVI = ndviArray.reduce((sum, ndvi) => sum + ndvi, 0) / ndviArray.length;
          calculatedYearlyMonthlyNDVI[year][month] = averageNDVI;
        }
      }
 
      let years = Object.keys(calculatedYearlyMonthlyNDVI);
      years.sort((a, b) => parseInt(a) - parseInt(b));
      let mostRecentYear = parseInt(years[years.length - 1]);
      console.log('years: ', years);
      console.log("mostRecentYear: ", mostRecentYear);
      calculatedYearlyMonthlyNDVI['Baseline Years Avg'] = {};
 
      // Calculate average for each month across baseline years
      for (let month = 1; month <= 12; month++) {
        const baselineYearNDVI = years
          .filter(year => year !== 'Baseline Years Avg' && parseInt(year) !== mostRecentYear)
          .map(year => {
            const ndvi = calculatedYearlyMonthlyNDVI[year][month];
            return isNaN(ndvi) ? null : ndvi;
          });
     
        const validData = baselineYearNDVI.filter(ndvi => ndvi !== null);
     
        if (validData.length > 0) {
          const avgNDVI = validData.reduce((sum, ndvi) => sum + ndvi, 0) / validData.length;
          calculatedYearlyMonthlyNDVI['Baseline Years Avg'][month] = avgNDVI;
        } else {
          calculatedYearlyMonthlyNDVI['Baseline Years Avg'][month] = null;
        }
      }
      console.log('calculatedYearlyMonthlyNDVI: ', calculatedYearlyMonthlyNDVI)
      setYearlyAverageMonthlyNDVI(calculatedYearlyMonthlyNDVI);
    }
  }, [forestData, dataForSelectedRegion])
 
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
 
  const setChartDataBasedOnSelection = () => {
    if (isMounted.current) {
      if (selectedYearLeft && selectedYearRight && yearlyAverageMonthlyNDVI) {
        let yearNewData = yearlyAverageMonthlyNDVI[selectedYearLeft] || {};
        let yearOldData = yearlyAverageMonthlyNDVI[selectedYearRight] || {};

        // Check which year is more recent
        if (selectedYearLeft === 'Baseline Years Avg') {
          yearNewData = yearlyAverageMonthlyNDVI[selectedYearRight] || {};
          yearOldData = yearlyAverageMonthlyNDVI[selectedYearLeft] || {};
        } else if (selectedYearRight === 'Baseline Years Avg') {
          yearNewData = yearlyAverageMonthlyNDVI[selectedYearLeft] || {};
          yearOldData = yearlyAverageMonthlyNDVI[selectedYearRight] || {};
        } else if (selectedYearRight > selectedYearLeft) {
          yearNewData = yearlyAverageMonthlyNDVI[selectedYearRight] || {};
          yearOldData = yearlyAverageMonthlyNDVI[selectedYearLeft] || {};
        }
        // Get the common months between the left and right years
        const commonMonths = Object.keys(yearNewData)
          .filter(month => Object.keys(yearOldData).includes(month));

        // Filter out months where either year is missing data
        const validMonths = commonMonths.filter(month => yearNewData[month] && yearOldData[month]);
  
        if (yearNewData && yearOldData && validMonths.length > 0) {
          const chartData = {
            labels: monthLabels,
            datasets: [
              {
                label: `${selectedYearLeft} - ${selectedYearRight} Monthly Difference`,
                data: monthLabels.map(
                  (month, index) => (commonMonths.includes(String(index + 1)) ?
                  (yearNewData[index + 1]) - (yearOldData[index + 1]) : null)
                ),
                borderColor: "#4579FF",
                pointBorderColor: "#4579FF",
                pointStyle: "circle",
                pointRadius: 6,
                borderWidth: 3,
                spanGaps: false,
              },
            ],
          };
          setChartData(chartData);
          console.log('chartData:')
          console.log(chartData)
        }
      }
    }
  };
 
  useEffect(() => {
    setChartDataBasedOnSelection();
  }, [selectedYearLeft, selectedYearRight, yearlyAverageMonthlyNDVI, setChartData, setSelectedYearLeft, setSelectedYearRight]);

  if (projectFailed) {
    return <Redirect to="/project" />;
  } 
 
  const downloadHandler = () => {
    if (chartData && selectedYearLeft && selectedYearRight) {
      const csvContent = [];
    
      let year1 = selectedYearLeft;
      let year2 = selectedYearRight;

      if (selectedYearLeft === "Baseline Years Avg" || selectedYearRight === "Baseline Years Avg") {
        year1 = selectedYearLeft === "Baseline Years Avg" ? "avg" : selectedYearLeft;
        year2 = selectedYearRight === "Baseline Years Avg" ? "avg" : selectedYearRight;
      }
      
      const sortedYears = [year1, year2].sort();

      csvContent.push(['Month', `${sortedYears[0]} - ${sortedYears[1]} Monthly Difference`]);
      chartData.labels.forEach((month, index) => {
        const difference = chartData.datasets[0].data[index];
        csvContent.push([month, difference]);
      });
  
      const csvString = csvContent.map(row => row.join(',')).join('\n');

      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      const reportName = forestInfo.name ? forestInfo.name : "report-name";
      const propertyName = selectedRegion ? selectedRegion.name : props.region ? props.region.name.replace(/\.geojson$/, "") : "FIELD";
      a.href = url;
      a.download = `${reportName}_${propertyName}_NDVI_${sortedYears[0]}_${sortedYears[1]}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.alert("Please select two years to download.")
    }
  };

  return (
    <div className="page-margins">
      <div className="manual-change-detection__page__header">
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/summary-page`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Summary</h3>
        </Link>
        <h3 className="manual-change-detection__page__header--active">Chart</h3>
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/ndvi-compare`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Map</h3>
        </Link>
        <Link
            to={`/project/${params.projectID}/${params.mowingID}/automatic-detection`}
            style={{ textDecoration: 'none'}} >
            <h3 className="manual-change-detection__page__header--inactive">Auto</h3>
        </Link>
        <div className="automatic-detection-page__selections-container">
          {/** ##### Region/property selection ##### */}
          <div className="automatic-detection-page__select-subcontainer">
            <p className="automatic-detection-page__select-label">Property:</p>
            <div className="automatic-detection-page__select">
              {projectRegions && projectRegions.length > 0 ? (
                <DropdownSearch
                  value={
                    selectedRegion ? selectedRegion.regionID : ""
                  }
                  setValue={handleRegionChange}
                  removeValue={removeSelectedRegion}
                  objectList={projectRegions ? projectRegions : []}
                  valueProperty="regionID"
                  dateProperty="name" // labels for the dropdown
                  onDisplayChange="regionID"
                  width="200px"
                />
              ) : !projectRegions || ( projectRegions && projectRegions.length <= 0) ? (
                  <div className="loading-container">
                    <p>{props.region ? props.region.name.replace(/\.geojson$/, '') : "FIELD"}</p>
                  </div>
                ) : (
                  <div className="loading-container">
                    <p>Loading...</p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="chart-title-container">
        <h2 className="chart-heading-delta">
          <Link to={`/project/${params.projectID}/${params.mowingID}/ndvi-report-chart`}
            className="chart-heading-delta">NDVI by Month</Link></h2>
        <h2 className="report-chart-delta-delta"> Delta
          <img src={DeltaIcon}
            alt="Delta Icon"
            className="delta-link"
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
          />
        </h2>
        <button className="ndvi-chart-csv-download-button">
          <img
            src={DownloadIcon}
            onClick={() => downloadHandler()}
          />
        </button>
      </div>

      <div className="dropdown-container">
        <select
          className="year-dropdown"
          id="year-dropdown1"
          value={selectedYearLeft}
          onChange={(e) => {
            console.log('Selected Year Left:', e.target.value);
            setSelectedYearLeft(e.target.value)
          }}
        >
          {Object.keys(yearlyAverageMonthlyNDVI).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
          <option value="" selected disabled hidden>yyyy</option>
        </select>
        <label htmlFor="year-dropdown2"> vs </label>
        <select
          className="year-dropdown"
          id="year-dropdown2"
          value={selectedYearRight}
          onChange={(e) => {
            console.log('Selected Year Right:', e.target.value);
            setSelectedYearRight(e.target.value)
          }}
        >
          {Object.keys(yearlyAverageMonthlyNDVI).map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
          <option value="" selected disabled hidden>yyyy</option>
        </select>
      </div>

      {chartData && (
        <div className="chart-container">
          <div className="centered-chart-container">
            <Line
              data={chartData}
              height={500}
              width={500}
              options={{
                maintainAspectRatio: false,
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Month',
                      font: {
                        size: 19,
                        color: '#4D4D4D',
                      },
                    },
                    ticks: {
                      color: tickColor,
                    },
                  },
                  y: {
                    min: -1,
                    max: 1,
                    stepSize: 0.1,
                    title: {
                      display: true,
                      text: 'Difference',
                      font: {
                        size: 19,
                        color: '#4D4D4D',
                      },
                    },
                    grid: {
                      color: (context) => {
                        if(context.tick.value === 0) {
                          return 'black'
                        }
                      }
                    },
                  },
                },
              }}
              plugins={[{
                beforeDatasetsDraw: (chart) => {
                  const {ctx, chartArea: {top, bottom, left, right, width, height}, scales: {x, y}} = chart;
                  ctx.fillStyle = 'rgba(0, 0, 0, 0.30)';
                  ctx.fillRect(x.getPixelForValue(0), top, x.getPixelForValue(3) - x.getPixelForValue(1), height);
                  ctx.fillRect(x.getPixelForValue(10), top, x.getPixelForValue(12) - x.getPixelForValue(11), height);
                }
              }]}
            />
          </div>
          <div className="chart-description">
            <p>Winter months (greyed out) are expected to be inconsistent due to variable snow cover, but snow cover may exist and affect consistency in other months too.</p>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="popup-message active">
          This is the most recent selected year’s NDVI subtracted from the less recent selected year’s NDVI: <strong> A negative value indicates a loss in NDVI value in the most recent year</strong>.
        </div>
      )}
    </div>
  );
} 
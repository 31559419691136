import React, {useEffect} from 'react';
import './acd-dropdown.css'
/**
 * Controlled Dropdown select component
 * @param {*} props 
 *    ?<name>: value to pass to dropdown field, optional
 *    <value>: current value of the dropdown, set to '' if not selected
 *    <setValue>: function passed down to modify <value> state in ancestor
 *      component. Takes in a single new value to replace the old with
 *    <valueList>: array of possible dropdown choices that <value> can become
 *      through the setter function <setValue>. Each list item must have:
 *        - <name> displayed as value on dropdown
 *        - <property> (value) used for React as key and is what is passed
 *          into setValue
 *            this value
 *    <valueProperty>: the string name of the 'value' property
 *    <nameProperty>: the strign name of the 'label' property
 */
export function Dropdown(props){
  return (
    <select
      className='acd-dropdown'
      name={props.name ? props.name :'dropdown'}
      value={props.value ? props.value : ''}
      onChange={(event) =>{
        props.setValue(event.target.value);
        console.log(event.target.value, event.target.name)
      }}
    >
      <option key={'NONE'} default={''} hidden> {'Select a report'}</option>
      {props.valueList.map(
        (listItem) => <option key={listItem[props.valueProperty]} value={listItem[props.valueProperty]}> {listItem[props.nameProperty]}</option>
      )}
    </select>
  )
}
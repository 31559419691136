import React from 'react';

import { createProject } from '../../apis/landsteward/accounts';

export class ProjectSelect extends React.Component {
  constructor(props) {
    super(props);
    /** props
     *  projectList       List of projectIDs and their name
     *  currProject       Current Selected Project
     * 
     *  changeProjectState
     */
    this.changeProject = this.changeProject.bind(this);
    console.log("PROJECT", props.projectList.map((object) => object.projectID))
  }

  async changeProject(event) {
    event.preventDefault();

    console.log("SELECT PROJECT", event.target.value, event.target.name);

    this.props.changeProjectState(event.target.value);
  }

  render() {
    //console.log("PROJECT RENDER", props.projectList.map((object) => object.projectID))
    console.log("PROJECT RENDER", this.props)

    return (
      <form className='selection_form    project_selection_form'>
        <h4 className='project_selection_empty_header'> Currently, there are no Projects {this.props.currProjectID}</h4>
        <select className='project_selection_dropdown' name='project_selection_dropdown' value={this.props.currProjectID}
          onChange={this.changeProject} stuff='hello'>

          {/** */}
          {this.props.projectList.map(
            (project) => <option key={project.projectID} stuff={project.name} name='hi' value={project.projectID}> {project.name}</option>
          )}
          {/** */}
        </select>

        {this.props.children}
      </form>
    );
  }
}

export class NewProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      geoJSON: {}
    };

    /** props
     *  addProjectPageState
     *  
     */

    this.updateName = this.updateName.bind(this);
    this.createNewProject = this.createNewProject.bind(this);
  }

  async updateName(event) {
    this.setState({ name: event.target.value });
  }

  async createNewProject(event) {
    event.preventDefault();
    // Center Island
    let region = { "name": this.state.name, "id": "92eb3ec0a870b43a093f7409b82a2e10", "type": "Feature", "properties": {}, "geometry": { "coordinates": [[[-79.3968729583808, 43.64556862651418], [-79.36374231262913, 43.6458170622974], [-79.34503122254117, 43.62705727107877], [-79.36528726502154, 43.60369252480049], [-79.3984179107737, 43.60468695425641], [-79.41386743469936, 43.62743003918584], [-79.3968729583808, 43.64556862651418]]], "type": "Polygon" } }
    // ALL of Credit Valley:
    // region = {"name": this.state.name, "id":"55531ac0f37605430a11a442554074a9","type":"Feature","properties":{},"geometry":{"coordinates":[[[-79.87849731445377,43.70024469846817],[-79.90458984375071,43.807375555138435],[-80.05839843750059,43.72803778009654],[-80.07213134765664,43.58794879150503],[-79.8839904785159,43.6257354427529],[-79.70546264648499,43.51130832656608],[-79.57088012695331,43.55212901615053],[-79.69722290039107,43.64958846573069],[-79.84828491210986,43.73994515484051],[-79.87849731445377,43.70024469846817]]],"type":"Polygon"}};
    // Mary Creek River Only:
    // region = {"name": this.state.name, "id":"7858a4f5d4fcded1aec776fee58d7f09","type":"Feature","properties":{},"geometry":{"coordinates":[[[-79.84416503906289,43.7349840364281],[-79.88948364257875,43.6724387271816],[-79.86339111328182,43.66846539338519],[-79.85789794921897,43.645613619473124],[-79.88124389648485,43.63468143601543],[-79.81120605468791,43.591927451541636],[-79.79335327148492,43.59988398259085],[-79.73979492187588,43.55212901615053],[-79.73979492187588,43.53221993625223],[-79.69996948242212,43.515292049135326],[-79.64503784179716,43.52724163900419],[-79.64641113281309,43.54317074380464],[-79.61070556640703,43.553124297560515],[-79.57911987304725,43.54516158604903],[-79.59834594726617,43.56705651142309],[-79.67937011718807,43.60286741050524],[-79.7040893554691,43.61977070749299],[-79.68898315429715,43.63666925368821],[-79.84416503906289,43.7349840364281]]],"type":"Polygon"}}
    // Area Around Pigeon Lake:
    region = { "name": this.state.name, "id": "20aef1ce1551a02f54cc8abf527cc9cb", "type": "Feature", "properties": {}, "geometry": { "coordinates": [[[-78.55135677458507, 44.60108784411139], [-78.56694785506717, 44.58637709421794], [-78.54667945044028, 44.57360625501008], [-78.5494078895248, 44.56360972852207], [-78.53459636306656, 44.536388257770085], [-78.52641104581338, 44.529997700623284], [-78.53654524812683, 44.51832617809259], [-78.44572720431707, 44.507208268334665], [-78.42701790773829, 44.54638946113536], [-78.4332543399312, 44.562221186186264], [-78.3654331398333, 44.567219783494835], [-78.36621269385715, 44.602752977379254], [-78.50263464807766, 44.58720987759901], [-78.55135677458507, 44.60108784411139]]], "type": "Polygon" } }
    let projectDataID;
    projectDataID = await createProject(region);

    if (projectDataID === null) {
      console.error('Create project API failed');
    } else {
      let nameUsed = this.state.name;
      this.setState({ name: '' });
      console.log("PROJECT DATA ID", projectDataID);
      this.props.addProjectPageState({ projectID: projectDataID, name: nameUsed });
    }
  }


  render() {
    return (
      <form className='new_project_form'>
        <label htmlFor='new_project_name' >New Project</label> <br />
        <input
          name='new_project_name'
          className='new_project_name'
          type='text'
          value={this.state.name}
          onChange={this.updateName}
        />
        <button className='new_button'
          onClick={this.createNewProject}
        > + </button>
      </form>
    );
  }
}
import React, { useState, useEffect } from 'react';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';

import { getProjectAutoDetection } from '../apis/landsteward/accounts';

import { SelectionGallery } from '../components/modules/selection-gallery';
import MaryCreek from '../images/sample_region_image.png';

/**
 *
 * @param {*} props
 *    <reports>:
 *    <region>:
 *    <project>:
 *
 * @returns
 */
export function AutoSelectionPage(props) {
  let params = useParams();


  function galleryFormatAutoReports(reports) {
    let outputArray = [];
    let objCopy;
    
    for (let i = 0; i < reports.length; i++) {
      objCopy = { ...reports[i] };

      if (!objCopy.png_image) {
        objCopy.png_image = MaryCreek;
      }
      if (!objCopy.path) {
        objCopy.path = `/project/${params.projectID}/property/${params.propertyID}/lulcautodetection/${objCopy.reportID}`;
      }
      if (objCopy.reportType === 'lulcautodetection') {
        outputArray.push(objCopy);
      }
    }
    console.log(outputArray);
    return outputArray;
  }

  /* ##### URL Parameters ##### */
  let [projectFailed, setProjectFailed] = useState(false);
  let [autoDetectionReports, setAutoDetectionReports] = useState(null)
  const fetchAutoDetection = async () => {
    const autoDetectionData = await getProjectAutoDetection(params.projectID)
    setAutoDetectionReports(autoDetectionData);
  }

  useEffect(async () => {
    fetchAutoDetection();

    let isCorrectState = await props.changeCurrentState({
      projectID: params.projectID,
    });
    if (!isCorrectState.project) {
      console.error('Failing Projects', isCorrectState);
      setProjectFailed(true);
    }
  }, []);

  if (projectFailed) {
    return <Redirect to="/project" />;
  } else {
    /* ##### Render ##### */
    return (
      <div className="report-selection-page">
        <div className="report-gallery__title">
          <h1>Browse or Add Automatic Change Detection Reports</h1>
        </div>

        <SelectionGallery
          contentType="image"
          collectionList={galleryFormatAutoReports(
            autoDetectionReports ? autoDetectionReports : []
          )}
          type="Automatic Change Detection Report"
          IDAttribute="reportID"
          captionAttribute="name"
          imageAttribute="png_image"
          linkAttribute="path"
          createOption={true}
          createLink={`/project/${params.projectID}/property/${params.propertyID}/lulcautodetection/new`}
        />
      </div>
    );
  }
}

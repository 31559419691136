import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  Component,
  useReducer,
} from "react";

import { useLocation, Link, Redirect, useParams, useHistory, useRouteMatch } from "react-router-dom";

import { MapboxProperty } from "../components/mapbox/mapbox-property";
import { MapboxRegionSelection } from "../components/mapbox/mapbox-region-selection";
import mapboxgl, { LngLat } from "mapbox-gl";
import { ObservationsGalleryCollection } from "../components/modules/selection-gallery-forestchange";
import { SelectionGalleryProperty2 } from "../components/modules/selection-gallery-property";
import Characteristics from "../components/modules/characteristics"

import RedMarkerIcon from "../images/icons/Comment-Pin.svg"
import RedMarkerIconLegend from "../images/icons/ImpactedLegend.svg"
import BlueMarkerIcon from "../images/icons/Comment-Pin-1.svg"
import BlueMarkerIconLegend from "../images/icons/NotImpactedLegend.svg"
import WhiteMarkerIcon from "../images/icons/Comment-Pin-2.svg"
import InfoIcon from "../images/icons/info-icon-new.svg";
import DownloadIcon from "../images/download-icon.png"
import LeastIcon from "../images/icons/LeastPin.svg"
import MostIcon from "../images/icons/MostPin.svg"
import AlertIcon from "../images/icons/Alert.svg"
import CheckmarkIcon from "../images/icons/Checkmark.svg"
import DownIcon from "../images/icons/arrow-icon-down.png"

import {
  getMDReport,
  getAllForestProperties,
  getAllRegions,
  getCustomRegionDetail,
  getFCWEventsCSV, 
  getMDReportCSV,
  download
} from "../apis/landsteward/accounts";

import { getAllDNDVIData } from "../apis/landsteward/forest-change"

import "./summary-page.css";

export function DataTable({ regionName = "FIELD", startDate = "2000-1", endDate = "2025-1", reportID, reportName = "report name" }) {
  const [data, setData] = useState([]);
  const [yearOfInterest, setYearOfInterest] = useState(1);
  const [showTooltipInfo, setShowTooltipInfo] = useState(false);
  const [isTableOpen, setIsTableOpen] = useState(true);
  const [sortOrder, setSortOrder] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const csvData = await getFCWEventsCSV(reportID); // use reportID from props
      console.log("csvData: ", csvData)

      const rows = csvData.split('\n');
      const data = rows.slice(1).map(row => {
        const fields = row.split(',').map(field => field.trim()); // trim each field
        return fields[0] ? { // check if the row is not empty
          fieldName: fields[0],
          periodOfChange: fields[1],
          dndvi: parseFloat(fields[2]),
          averageDndvi: parseFloat(fields[3]),
          percentageChange: parseFloat(fields[4]),
        } : null;
      })
        .filter(item => {
          if (!item || !item.periodOfChange || !startDate || !endDate) {
            return false;
          }
          return true;
        })
      console.log("data: ", data);
      // get the year of interest
      if (data.length > 0) {
        const yearOfInterest = data[0].periodOfChange.split('-')[0];
        setYearOfInterest(yearOfInterest);
        console.log(yearOfInterest);
      }
      setData(data);
    };

    fetchData();
  }, [reportID, startDate, endDate]);

  const toggleTable = () => {
    setIsTableOpen(!isTableOpen);
  };

  if (data.length === 0) {
    return <p>No detected event in date range</p>;
  }

  const convertDataToCSV = (data) => {
    const headers = [
      'Field Name',
      'Period of Change',
      `${yearOfInterest} DNDVI`,
      'Average DNDVI Across Other Years',
      'Percentage Change'
    ];

    const header = headers.join(',') + '\n';
    const rows = data.map(item => {
      return (
        `"${item.fieldName}",${item.periodOfChange},"${item.dndvi}","${item.averageDndvi}","${item.percentageChange}"\n`
      );
    });

    return header + rows.join('');
  };

  const sortData = (key) => {
    setData([...data].sort((a, b) => {
      const order = sortOrder[key] === 'desc' ? -1 : 1;
      if (typeof a[key] === 'number' && typeof b[key] === 'number') {
        return (a[key] - b[key]) * order;
      } else {
        return a[key].localeCompare(b[key]) * order;
      }
    }));
  
    setSortOrder(prevSortOrder => ({
      ...prevSortOrder,
      [key]: prevSortOrder[key] === 'asc' ? 'desc' : 'asc'
    }));
  };

  console.log("detected dates: ", data)

  return (
    <div className="summary__disturbances-table-container">
      <div className="summary__disturbances-table">
        <div className={`summary__heading-title`}>
          <span><strong>Large Disturbances {yearOfInterest}</strong></span>
          <button className="summary__disturbances-csv-download-button">
            <img
              src={DownloadIcon}
              onClick={() => {
                const formattedStartDate = startDate ? startDate.split("T")[0] : `${yearOfInterest}-01-01`;
                const formattedEndDate = endDate ? endDate.split("T")[0] : `${yearOfInterest}-12-31`;
                const fileName = `disturbances_${formattedStartDate}_${formattedEndDate}`;
                download(convertDataToCSV(data), reportName, fileName)
              }}
            />
          </button>
        </div>
        {isTableOpen && (
          <table>
            <thead>
              <tr class="summary__heading-rows">
                <th onClick={() => sortData('periodOfChange')}>
                  <img className='down-icon detected-dates-table' alt='down-icon' src={DownIcon}/>
                  Period of Change
                </th>
                <th onClick={() => sortData('fieldName')}>
                  <img className='down-icon detected-dates-table' alt='down-icon' src={DownIcon}/>
                  Property Impacted
                </th>
                <th onClick={() => sortData('dndvi')}>
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img className='down-icon detected-dates-table' alt='down-icon' src={DownIcon}/>
                    {yearOfInterest} DNDVI
                    <img
                      className='info-icon'
                      alt='info-icon'
                      src={InfoIcon}
                      onMouseOver={() => setShowTooltipInfo(true)}
                      onMouseOut={() => setShowTooltipInfo(false)}
                    />
                  </div>
                  {showTooltipInfo ? (
                    <div className='popup'>
                      This is your selected year of interest from the report creation stage
                    </div>
                  ) : null}
                </th>
                <th onClick={() => sortData('averageDndvi')}>
                  <img className='down-icon detected-dates-table avg-dndvi' alt='down-icon' src={DownIcon}/>
                  Average DNDVI Across Other Years
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.periodOfChange}</td>
                  <td>{item.fieldName}</td>
                  <td>{item.dndvi}</td>
                  <td>{item.averageDndvi}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

/**
*
* @param {*} props
*/
export function SummaryPage(props) {
  /** ##### URL Param Check ##### */
  let params = useParams(); // parameters from url, in this case projectId and propertyId
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const [projectFailed, setProjectFailed] = useState(false); // flag to indicate if project failed to load
  /** Report Info */
  const [reportName, setReportName] = useState("Report name");
  const [reportStartYear, setReportStartYear] = useState(2000);
  const [reportAnalysisYear, setReportAnalysisYear] = useState(2004);
  const [numImpactedProperties, setNumImpactedProperties] = useState(0);
  const [numTotalProperties, setNumTotalProperties] = useState(0);
  const [numLargeDisturbances, setNumLargeDisturbances] = useState(0);
  const [characteristics, setCharacteristics] = useState([]);
  const [reportStartDate, setReportStartDate] = useState("");
  const [reportEndDate, setReportEndDate] = useState("");
  const [reportSpatialRes, setReportSpatialRes] = useState(30)
  const [showLowestNDVITooltip, setShowLowestNDVITooltip] = useState(false);
  const [showHighestNDVITooltip, setShowHighestNDVITooltip] = useState(false);
  /** Mapbox Reference to map */
  const beforeMap = useRef(null); // mapbox reference to left map
  /** Regions and Properties*/
  const [regionsList, setRegionsList] = useState([]);
  const [propertiesList, setPropertiesList] = useState([]);
  const [propertiesListWithIsImpacted, setPropertiesListWithIsImpacted] = useState([]);
  const [propertiesListWithAddedFields, setPropertiesListWithAddedFields] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [propertiesVersion, setPropertiesVersion] = useState(0);
  const [mostImpactedProperty, setMostImpactedProperty] = useState("");
  const [leastImpactedProperty, setLeastImpactedProperty] = useState("");
  const [propertiesSortedByImpact, setPropertiesSortedByImpact] = useState("");
  /** Detected Disturbances */
  const [detectedDatesData, setDetectedDatesData] = useState(null);
  /** NDVI Data */
  const [allDNDVIData, setAllDNDVIData] = useState([]);
  /** Misc */
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);

  // Hide the global back button on pages with local back button
  useEffect(() => {
    let globalBackButton = document.getElementsByClassName('back-button')[0];
    let localBackButton = document.getElementsByClassName('back-btn')[0];
    if (localBackButton != null) {
      globalBackButton.style.visibility = "hidden"
    } else {
      globalBackButton.style.visibility = "visible"
    }
  })

  // Helper function to read CSV file for ndvi data
  function readCSVFile(response) {
    var lines = response.split("\n");
    let modifiedResponse = "";
    for (var i = 0; i < lines.length; i++) {
      var row = lines[i].split(","); // First column (Split on the separator!)
      if (row[0] == "Date") {
        modifiedResponse = modifiedResponse.concat(row + "\n");
        continue;
      } else if (row[0] != "Date" && row[0] != "") {
        let timeRemoved = row[0].slice(0, 10);
        row[0] = timeRemoved;
        let modifiedRow = row.join(",");
        modifiedResponse = modifiedResponse.concat(modifiedRow + "\n");
      }
    }
    return modifiedResponse;
  }

  /**
  * Once the component mounts, initialize webstate of web application by loading in the project data
  * */
  useEffect(() => {
    async function initializeState() {
      let success = await props.changeProject(params.projectID);
      if (!success) {
        setProjectFailed(true);
        return;
      }

      const reportData = await getMDReport(params.mowingID, 'forest_detection');
      if (reportData) {
        console.log("reportData: ", reportData)
        const startYear = parseInt(reportData.start_date.split("/")[2]);
        const endYear = parseInt(reportData.end_date.split("/")[2]);

        setReportName(reportData.name);
        setReportStartYear(startYear);
        setReportAnalysisYear(endYear);
        setReportStartDate(reportData.start_date);
        setReportEndDate(reportData.end_date);
        setReportSpatialRes(reportData.spatial_res);
      }
    }

    // Get disturbance events CSV to extract impacted fields
    const fetchDetectedDatesData = async () => {
      const csvData = await getFCWEventsCSV(params.mowingID);
      console.log("csvData: ", csvData)

      const rows = csvData.split('\n');
      const data = rows.slice(1).map(row => {
        const fields = row.split(',').map(field => field.trim());
        return fields[0] ? { 
          fieldName: fields[0],
          periodOfChange: fields[1],
          dndvi: parseFloat(fields[2]),
          averageDndvi: parseFloat(fields[3]),
          percentageChange: parseFloat(fields[4]),
        } : null;
      })
        .filter(item => {
          if (!item || !item.periodOfChange) {
            return false;
          }
          return true;
        })
      console.log("data: ", data);
      setDetectedDatesData(data);
      setNumLargeDisturbances(data.length);
    };

    const fetchAllDNDVIData = async () => {
      const allDNDVIData = await getAllDNDVIData(params.mowingID);
      console.log("allDNDVIData: ", allDNDVIData);
      setAllDNDVIData(allDNDVIData);
    };

    initializeState().catch(console.error);
    fetchDetectedDatesData();
    fetchAllDNDVIData();
  }, []);

  // Sort properties by impact and calculate least and most impacted properties
  useEffect(() => {
    const findMostAndLeastImpactedProperties = () => {
      if (!detectedDatesData || !allDNDVIData) return;
      if (detectedDatesData.length === 0 || allDNDVIData.length === 0) {
        setMostImpactedProperty("N/A");
        setLeastImpactedProperty("N/A");
        return;
      }
  
      let mostImpacted = detectedDatesData[0];
      let leastImpacted = {
          property: "",
          pct_change: Infinity
      }; 
  
      detectedDatesData.forEach(item => {
        if (item.percentageChange > mostImpacted.percentageChange) {
          mostImpacted = item;
        }
      });

      const propertyAverages = {};

      for (const key in allDNDVIData) {
        if (Object.hasOwnProperty.call(allDNDVIData, key)) {
          const dataArray = allDNDVIData[key];
          let totalPctChange = 0;
          let count = 0;
          dataArray.forEach(item => {
            if (item.pct_change != null) {
              totalPctChange += item.pct_change;
              count++;
            }
          });
          if (count > 0) {
            propertyAverages[key] = totalPctChange / count;
          }
        }
      }

      const properties = Object.keys(propertyAverages).map(property => ({
        property: property,
        avg_pct_change: propertyAverages[property]
      }));

      properties.sort((a, b) => a.avg_pct_change - b.avg_pct_change);
      console.log("propertiesSortedByImpact: ", properties)

      if (properties.length > 0) {
        leastImpacted = properties[0];
      }
  
      setMostImpactedProperty(mostImpacted.fieldName);
      setLeastImpactedProperty(leastImpacted.property);
      setPropertiesSortedByImpact(properties);
    };
  
    findMostAndLeastImpactedProperties();
  }, [detectedDatesData, allDNDVIData]);

  const toggleSortDropdown = () => {
    setSortDropdownVisible(!sortDropdownVisible);
  };
  
  const handleSortOptionClick = (option) => {
    console.log('Selected sort option:', option);
    if (option === 'alphabetical') {
      const sortedList = propertiesListWithAddedFields.slice().sort((a, b) => {
        return a.property_name.localeCompare(b.property_name);
      });
      setPropertiesListWithAddedFields(sortedList);
    } else if (option === 'least_impacted' && propertiesSortedByImpact) {
      const leastImpactedList = propertiesSortedByImpact.map(item => item.property);
      const sortedList = propertiesListWithAddedFields.slice().sort((a, b) => {
        return leastImpactedList.indexOf(a.property_name) - leastImpactedList.indexOf(b.property_name);
      });
      setPropertiesListWithAddedFields(sortedList);
    } else if (option === 'most_impacted' && propertiesSortedByImpact) {
      const mostImpactedList = propertiesSortedByImpact.map(item => item.property);
      const sortedList = propertiesListWithAddedFields.slice().sort((a, b) => {
        return mostImpactedList.indexOf(b.property_name) - mostImpactedList.indexOf(a.property_name);
      });
      setPropertiesListWithAddedFields(sortedList);
    }
  };

  // Get all NDVI data to extract lowest and highest average NDVI
  useEffect(() => {
    const findLowestAvgNDVI = (fieldName, allNDVIData) => {
      const fieldNDVIData = allNDVIData.filter(item => {
        const date = new Date(item.Date);
        const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
        return item["Field Name"] === fieldName && month >= 3 && month <= 11;
      });
      console.log("fieldNDVIData: ", fieldNDVIData)
      const lowestNDVIItem = fieldNDVIData.reduce((minItem, currentItem) => {
        const minNDVI = parseFloat(minItem["Average NDVI"]);
        const currentNDVI = parseFloat(currentItem["Average NDVI"]);
        return minNDVI < currentNDVI ? minItem : currentItem;
      });
    
      return lowestNDVIItem;
    };

    const findHighestAvgNDVI = (fieldName, allNDVIData) => {
      let highestNDVI = null;
      let highestAverageNDVI = -Infinity;
      allNDVIData.forEach(item => {
        const date = new Date(item.Date);
        const month = date.getMonth() + 1;
        if (item["Field Name"] === fieldName && month >= 3 && month <= 11) {
          const averageNDVI = parseFloat(item["Average NDVI"]);
          if (averageNDVI > highestAverageNDVI) {
            highestAverageNDVI = averageNDVI;
            highestNDVI = item;
          }
        }
      });
      return highestNDVI;
    };

    getMDReportCSV(params.mowingID).then((response) => {
      let timeRemovedResponse = readCSVFile(response);
      console.log("chartData: ", timeRemovedResponse);  

      const rows = String(timeRemovedResponse).split('\n');
      const csvArray = rows.map((row) => row.split(','));
      const headers = csvArray[0];
      const allNDVIData = csvArray.slice(1).map((row) => {
        const csvObject = {};
        headers.forEach((header, index) => {
          csvObject[header] = row[index];
        });
        return csvObject;
      });

      console.log("allNDVIData: ", allNDVIData);
      propertiesListWithAddedFields.forEach(property => {
        const lowestNDVIItem = findLowestAvgNDVI(property.property_name, allNDVIData);
        property.lowest_ndvi = lowestNDVIItem["Average NDVI"];
        property.lowest_ndvi_date = lowestNDVIItem.Date;

        const highestNDVIItem = findHighestAvgNDVI(property.property_name, allNDVIData);
        property.highest_ndvi = highestNDVIItem["Average NDVI"];
        property.highest_ndvi_date = highestNDVIItem.Date;
      });

      console.log("propertiesListWithAddedFields: ", propertiesListWithAddedFields);
      
      setPropertiesListWithAddedFields(propertiesListWithAddedFields);
    });
  }, [propertiesList, propertiesListWithIsImpacted]);

  // Load and set the report statistics
  useEffect(() => {
    async function setCurrRegion() {
      if (props.project != null) {
        console.log(props.project);
        let success = await props.changeRegion(props.project.region);
        if (!success) {
          console.log("Region failed to be set");
        }
      }
    }
    function createCharacteristics() {
      if (props.project != null) {
        let charactersArr = [];

        charactersArr.push({
          header: "Report Range",
          statistic: reportStartYear + " - " + reportAnalysisYear
        });

        charactersArr.push({
          header: "Total Area",
          statistic:
            props.project.area_hectare
              ? props.project.area_hectare.toFixed(0) + " Ha"
              : "N/A"
        });

        charactersArr.push({
          header: "Impacted Properties",
          statistic:
            props.project.properties_num
              ? numImpactedProperties + " of " + props.project.properties_num
              : "N/A"
        });

        charactersArr.push({
          header: "Large Disturbances",
          statistic: numLargeDisturbances
        });
        setCharacteristics(charactersArr);
      }
    }
    setCurrRegion().catch(console.error);
    createCharacteristics();
  }, [props.project, reportStartYear, reportAnalysisYear, numImpactedProperties]);

  // Get all regions for a project
  useEffect(() => {
    // Gets all properties for a project
    async function getProperties(regionIDs) {
      let allProperties = await getAllForestProperties();
      let filteredProperties = allProperties.filter(property =>
        regionIDs.includes(property.region) && !property.is_archived
      );
      setPropertiesList(filteredProperties)
      console.log("Filtered PROPERTIES LIST", filteredProperties);
    }

    async function getRegions() {
      // only continue once props have loaded in
      if (props.project != null) {
        let allRegions = await getAllRegions()

        // Filter for regions linked to project and the initial region created by the project, if it exists
        let projectRegions = allRegions.filter((region) => {
          return (region.projectID == props.project.projectID || region.regionID == props.project.region)
        })

        setRegionsList(projectRegions)
        console.log("REGIONS LIST", projectRegions)
        //  props.changeReportRegions(projectRegions)

        const regionIDs = projectRegions.map((region) => region.regionID);
        console.log(regionIDs);
        getProperties(regionIDs);
        console.log("carbon-report-props", props)
      }
    }
    getRegions()
  }, [props.project])

  // Set propertiesListWithIsImpacted to indicate whether a property was impacted by disturbance
  useEffect(() => {
    if (!detectedDatesData || !propertiesList) return;

    const setIsImpacted = () => {  
      const impactedProperties = new Set(detectedDatesData.map(entry => entry.fieldName));
      console.log("impactedProperties: " , impactedProperties);
      console.log("impactedProperties size: " , impactedProperties.size);
      console.log("propertiesList: " , propertiesList);

      setNumImpactedProperties(impactedProperties.size);

      if (propertiesList && propertiesList.length > 0) {      
        const updatedPropertiesList = propertiesList.map(property => ({
          ...property,
          is_impacted: impactedProperties.has(property.property_name)
        }));

        setPropertiesListWithIsImpacted(updatedPropertiesList);
        setPropertiesListWithAddedFields(updatedPropertiesList);
        console.log("propertiesListWithIsImpacted: " , updatedPropertiesList);
      }
    }
    
    setIsImpacted();
  }, [detectedDatesData, propertiesList]);

  const centroids = propertiesList.map(property => ({
    latitude: property.latitude,
    longitude: property.longitude,
  }));

  const averageCentroid = centroids.reduce(
    (acc, centroid) => {
      acc.latitude += centroid.latitude;
      acc.longitude += centroid.longitude;
      return acc;
    },
    { latitude: 0, longitude: 0 }
  );

  averageCentroid.latitude /= centroids.length;
  averageCentroid.longitude /= centroids.length;

  const markersRef = useRef({});

  // Add markers to the map
  useEffect(() => {
    const addMarkers = () => {
      if (beforeMap.current && beforeMap.current.isStyleLoaded() && propertiesListWithIsImpacted.length > 0) {

        Object.keys(markersRef.current).forEach((region) => {
          if (!propertiesListWithIsImpacted.find(property => property.region === region)) {
            markersRef.current[region].remove();
            delete markersRef.current[region];
          }
        });

        propertiesListWithIsImpacted.forEach(({ property_name, longitude, latitude, region, is_impacted }) => {
          if (longitude && latitude && !isNaN(longitude) && !isNaN(latitude)) {
            const el = document.createElement('div');
            el.className = 'custom-marker';
            el.style.width = '40px';
            el.style.height = '50px';
            el.style.backgroundSize = 'cover';
            el.style.backgroundImage = `url(${selectedItem === region ? WhiteMarkerIcon : 
              property_name === leastImpactedProperty ? LeastIcon :
              property_name === mostImpactedProperty ? MostIcon :
              is_impacted ? RedMarkerIcon : BlueMarkerIcon})`;

            if (!markersRef.current[region]) {
              const marker = new mapboxgl.Marker(el)
                .setLngLat([longitude, latitude])
                .addTo(beforeMap.current);

              markersRef.current[region] = marker;

              el.addEventListener('click', () => setSelectedItem(selectedItem === region ? null : region));
            }
          }
        });
      }
    };

    if (beforeMap.current) {
      if (beforeMap.current.isStyleLoaded()) {
        addMarkers();
      } else {
        beforeMap.current.on('load', addMarkers);
      }
    }

    return () => {
      if (beforeMap.current) {
        beforeMap.current.off('load', addMarkers);
        Object.values(markersRef.current).forEach(marker => marker.remove());
        markersRef.current = {};
      }
    };
  }, [propertiesList, selectedItem, propertiesListWithIsImpacted, leastImpactedProperty, mostImpactedProperty]);

  const toggleSelectedItem = (region) => {
    setSelectedItem(selectedItem === region ? null : region);
  };

  const handleRegionDeletion = (region) => {
    if (markersRef.current[region]) {
      markersRef.current[region].remove();
      delete markersRef.current[region];
    }
    const updatedRegions = propertiesList.filter(property => property.region !== region);
    setPropertiesList(updatedRegions);
  };

  const handleNameChange = (newName, region) => {
    const updatedProperties = propertiesList.map(property => {
      if (property.region === region) {
        return { ...property, property_name: newName };
      }
      return property;
    });
    setPropertiesList(updatedProperties);
    setPropertiesVersion(prevVersion => prevVersion + 1);
  };

  /** HTML RENDERING */
  /** checking valid state */
  if (projectFailed) {
    return <Redirect to="/project" />;
  }

  const Popup = ({ message, header, buttonLabel1, buttonLabel2, onClose, onConfirm }) => {
    const handleConfirmClick = () => {
      if (onConfirm) {
        onConfirm();
      }
    };
    return (
      <div className="popup-container">
        <div className="popup-content">
          <div className="popup-inner">
            <h2>{header}</h2>
            <p>{message}</p>
            <div className="popup-buttons">
              <button className="create-button" onClick={handleConfirmClick}>
                {buttonLabel1}
              </button>
              <button className="create-button-secondary" onClick={onClose}>
                {buttonLabel2}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function SubHeading1(props) {
    const [showPopup, setShowPopup] = useState(false);
    const handleCancelClick = () => {
      setShowPopup(true);
    };

    const handleCloseClick = () => {
      setShowPopup(false);
    };

    return (
      <>
        <div className="project-observation-title-text">
          {props.text}
          {/* <img className="sort-icon" src={SortIcon} /> */}
        </div>
        <Link style={{ textDecoration: "none" }} to={props.href}>
          <button className="create-button">Create New</button>
        </Link>
      </>
    );
  }

  /** renders the webpage components */
  return (
    <div className="page-margins">
      <div className="manual-change-detection__page__header">
        <h3 className="manual-change-detection__page__header--active">Summary</h3>
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/ndvi-report-chart`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Chart</h3>
        </Link>
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/ndvi-compare`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Map</h3>
        </Link>
        <Link
          to={{
            pathname: `/project/${params.projectID}/${params.mowingID}/automatic-detection`,
          }}
          style={{ textDecoration: 'none' }} >
          <h3 className="manual-change-detection__page__header--inactive">Auto</h3>
        </Link>
      </div>
      <div className="summary-header">
          <h3>{reportName} - Summary</h3>
          <h4 style={{fontWeight: 'normal' }}>{reportSpatialRes}m resolution</h4>
      </div>
      <div className="project-characteristics">
        {characteristics.length > 0 ? (
          <Characteristics characteristics={characteristics} />
        ) : null}
      </div>
      <br></br>
      <div className="statistics-container">
        <div className="statistic">
          <h4>
            <span className="statistic-icon-container">
              <img src={CheckmarkIcon} alt="Checkmark Icon"/>
            </span>
            Least Impacted: {leastImpactedProperty}
          </h4>
        </div>
        <div className="statistic">
          <h4>
            <span className="statistic-icon-container">
              <img src={AlertIcon} alt="Alert Icon"/>
            </span>
            Most Impacted: {mostImpactedProperty}
          </h4>
        </div>
        <div className="statistic">
          <h4>
            <span className="statistic-icon-container">
              <img src={RedMarkerIconLegend} alt="Impacted Icon"/>
            </span>
            Impacted
          </h4>
        </div>
        <div className="statistic">
          <h4>
            <span className="statistic-icon-container">
              <img src={BlueMarkerIconLegend} alt="Not Impacted Icon"/>
            </span>
            Not Impacted
          </h4>
        </div>
      </div>

      <div className="summary__map-property-and-buttons-container">
        <div className="summary__map-and-property-container">
          <div className="summary__map-box-container">
            {!isNaN(averageCentroid.longitude) && !isNaN(averageCentroid.latitude) && (
              <MapboxProperty
                // Project info
                projectName={props.project ? props.project.name : "Project name"}
                projectDescription={props.project ? props.project.description : "Project description"}
                // Mapbox centered on PEI or property centroid
                longitude={averageCentroid.longitude}
                latitude={averageCentroid.latitude}
                zoom={10}
                // Map
                beforeStyle={"mapbox://styles/mapbox/satellite-v9"}
                beforeMap={beforeMap}
              />
            )}
          </div>
        </div>
        <div className="summary__property-labels-container">
          <div className="statistic">
            <h4>Property</h4>
          </div>
          <div className="statistic">
            <h4>Impact</h4>
          </div>
          <div className="statistic">
            <h4>Lowest NDVI<br></br>({reportStartYear} - {reportAnalysisYear}) 
              <img
                className='info-icon'
                alt='info-icon'
                src={InfoIcon}
                onMouseOver={() => setShowLowestNDVITooltip(true)}
                onMouseOut={() => setShowLowestNDVITooltip(false)}
              />
            </h4>
            {showLowestNDVITooltip ? (
              <div className='popup' style={{ width: '20%' }}>
                This is the average lowest NDVI across the entire property from March until November. 
                Winter months (December to February) are excluded due to inconsistency from snow cover.
              </div>
            ) : null}
          </div>
          <div className="statistic">
            <h4>Highest NDVI<br></br>({reportStartYear} - {reportAnalysisYear})
              <img
                className='info-icon'
                alt='info-icon'
                src={InfoIcon}
                onMouseOver={() => setShowHighestNDVITooltip(true)}
                onMouseOut={() => setShowHighestNDVITooltip(false)}
              />
            </h4>
            {showHighestNDVITooltip ? (
              <div className='popup' style={{ width: '20%' }}>
                This is the average highest NDVI across the entire property from March until November. 
                Winter months (December to February) are excluded due to inconsistency from snow cover.
              </div>
            ) : null}
          </div>
          <div className="sort-dropdown-container" style={{ position: 'relative' }}>
            <button className="sort-dropdown-toggle" onClick={toggleSortDropdown}>
              <h4 className="sort-text">Sort</h4>
              <img className='down-icon' alt='down-icon' src={DownIcon}/>
            </button>
            {sortDropdownVisible && (
              <div className="sort-dropdown-menu">
                <ul>
                  <li onClick={() => handleSortOptionClick('alphabetical')}><p>Alphabetical</p></li>
                  <li onClick={() => handleSortOptionClick('most_impacted')}><p>Most Impacted</p></li>
                  <li onClick={() => handleSortOptionClick('least_impacted')}><p>Least Impacted</p></li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="summary__property-container">
          {propertiesListWithAddedFields.length > 0 && leastImpactedProperty && mostImpactedProperty ? (
            <SelectionGalleryProperty2
              key={propertiesVersion}
              className="selection-gallery-property"
              collectionList={propertiesListWithAddedFields}
              IDAttribute="region"
              dateCreatedAttribute=""
              areaAttribute="area_hectares"
              isImpactedAttribute="is_impacted"
              lowestNDVIAttribute="lowest_ndvi"
              lowestNDVIDateAttribute="lowest_ndvi_date"
              highestNDVIAttribute="highest_ndvi"
              highestNDVIDateAttribute="highest_ndvi_date"
              leastImpactedPropertyAttribute={leastImpactedProperty}
              mostImpactedPropertyAttribute={mostImpactedProperty}
              captionAttribute="property_name"
              selectedItem={selectedItem}
              onItemSelect={toggleSelectedItem}
              onRegionDelete={handleRegionDeletion}
              onNameChange={handleNameChange}
              displayContextMenu={false}
            />
          ) : null}
        </div>
        <div className="summary__dndvi-event-table">
          <DataTable
            // fieldName={selectedRegion ? selectedRegion.name: "FIELD"}
            regionName={props.region ? props.region.name : "FIELD"}
            reportID={params.mowingID}
            reportName={reportName}
            startDate={reportStartDate ? reportStartDate : null}
            endDate={reportEndDate ? reportEndDate : null}
          />
        </div>
      </div>
      <br />
    </div>
  );
} 
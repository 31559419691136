import mapboxgl from "!mapbox-gl";
import "./carbon-report-page.css"
import react, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from 'react-router-dom'
import { getAllReport } from "../apis/landsteward/accounts";
import { createCarbonReport, getCarbonReportCSV, getCarbonReportFiles, searchGranules } from '../apis/landsteward/valuation'
import { useFetchBlob } from "../apis/landsteward/useFetch";
import { StylesControl } from "mapbox-gl-controls";
import "mapbox-gl-controls/lib/controls.css"

import xIcon from '../images/icons/exit-icon.png'
import forwardArrow from '../images/icons/arrow-icon-right.png'
import backArrow from '../images/icons/arrow-icon-left.png'
import upArrow from '../images/icons/arrow-icon-up.png'
import rightArrow from '../images/icons/arrow-icon-right.png'
import downArrow from '../images/icons/arrow-icon-down.png'
import hamburgerMenu from '../images/hamburger.png'




mapboxgl.accessToken = 'pk.eyJ1Ijoia29yb3R1LWNvbnJveS10cmluaCIsImEiOiJja2pyeTh0cnU2aDViMnFwOTFrZHoxdDU3In0.VEuiNCwqBGL8CidAvTgdjA'

export function CarbonReportPage(props) {

    let date = new Date()
    date = date.toISOString().split('T')[0].replace(/-/g, '')
    const history = useHistory()

    let params = useParams()
    const { state } = useLocation()
    const reportName = state.state.reportName.replace(/_/g,"");
    const carbonOutput = state.state.carbonOutput
    const gediOutput = state.state.gediOutput
    const gediSourceName = reportName + "gedishots"

    // const testTIFF = 'KRP93DMM5HJBMMLP8XIPANV2CPEQQLT8'

    const uploadInfo = {
        "GEDI_count": carbonOutput["GEDI_count"],
        "GEDI_count_avg": carbonOutput["GEDI_count_avg"],
        "use_buffer": carbonOutput["use_buffer"],
        "gedi_shots": carbonOutput["gedi_shots"],
        "AGBvectorupload": carbonOutput["AGB_vector_upload"],
        "BGBvectorupload": carbonOutput["BGB_vector_upload"],
        "DPMvectorupload": carbonOutput["DPM_vector_upload"],
        "SOCvectorupload": carbonOutput["SOC_vector_upload"],
        "Totalvectorupload": carbonOutput["Total_vector_upload"],
        "None": carbonOutput["None_upload"]
    }

    console.log("upload info", uploadInfo)

    console.log(state)

    const layerValues = {
        "None": {
            "mean": 0,
            "total": 0,
            "area": 0
        },
        "AGBvectorupload": {
            "mean": carbonOutput["mean_value_AGB"],
            "total": carbonOutput['total_value_AGB'],
            "area": carbonOutput['area_value_AGB']
        },
        "BGBvectorupload": {
            "mean": carbonOutput["mean_value_BGB"],
            "total": carbonOutput['total_value_BGB'],
            "area": carbonOutput['area_value_BGB']
        },
        "DPMvectorupload": {
            "mean": carbonOutput["mean_value_DPM"],
            "total": carbonOutput['total_value_DPM'],
            "area": carbonOutput['area_value_DPM']
        },
        "SOCvectorupload": {
            "mean": carbonOutput["mean_value_SoilBiomass"],
            "total": carbonOutput['total_value_SoilBiomass'],
            "area": carbonOutput['area_value_SoilBiomass']
        },
        "Totalvectorupload": {
            "mean": carbonOutput["mean_value_TotalBiomass"],
            "total": carbonOutput['total_value_TotalBiomass'],
            "area": carbonOutput['area_value_TotalBiomass']
        },
    }

    console.log("layer_values", layerValues)

    const {
        longitude, latitude, zoom,
        mapWidth, mapHeight, style
    } = props;

    const satelliteStyle =
    "mapbox://styles/mapbox/satellite-streets-v12";

    // street style
    const streetStyle = style;

    const constValues = {
        'biomass': 1,
        'carbon': 0.5,
        'co2': 1.835
    }

    // UI const variables
    const [legendOpen, setlegendOpen] = useState(false)
    const [menuOpen, setmenuOpen] = useState(false)
    const [showGEDI, setshowGEDI] = useState(false)
    const [navClosed, setnavClosed] = useState(true)
    const [menuOutStyle, setmenuOutStyle] = useState({ transform: `translateX(0%)` })
    const [csvDownloadOpen, setcsvDownloadOpen] = useState(false)
    const [menuPanelOpenLayers, setmenuPanelOpenLayers] = useState(false)
    const [menuPanelOpenLC, setmenuPanelOpenLC] = useState(false)
    const [menuPanelOpenUnits, setmenuPanelOpenUnits] = useState(false)
    const [biomassDataOut, setbiomassDataOut] = useState(true)

    // mapbox const variables
    const [lng, setLng] = useState(longitude);
    const [lat, setLat] = useState(latitude);
    const [mapZoom, setZoom] = useState(zoom);
    const [landCover, setLandCover] = useState('forest')
    const [carbonLayer, setCarbonLayer] = useState(uploadInfo["AGBvectorupload"])
    const [carbonLayerSelected, setCarbonLayerSelected] = useState('AGBvectorupload')
    const [carbonLayerName, setCarbonLayerName] = useState("None");
    const [oldcarbonLayer, setoldCarbonLayer] = useState('None')
    const [units, setUnits] = useState('biomass')
    const [unitName, setUnitName] = useState('Biomass')
    const [unitSelected, setUnitsSelected] = useState('None')
    const [gediLayer, setGediLayer] = useState('None')
    const [gediState, setGediState] = useState(false)
    const [AGBDValue, setAGBDValue] = useState('')

    let nav, scale;

    let map = useRef(null);
    let mapContainer = useRef();
    

    // map initialization useEffect
    useEffect(() => {
        // console.log(carbonOutput);
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: style,
            center: [carbonOutput["mean_long"], carbonOutput["mean_lat"]],
            zoom: 14,
            maxzoom: 14,
        });

        /** Coordinate Data */
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });

        /** Mapbox Navigation tools */
        nav = new mapboxgl.NavigationControl();

        /** Mapbox Satelitte and Street View Controls*/
        map.current.addControl(new StylesControl({
            styles: [
              {
                label: 'Street',
                styleName: 'Mapbox Streets',
                styleUrl: streetStyle,
              }, {
                label: 'Satellite',
                styleName: 'Satellite',
                styleUrl: satelliteStyle,
              },
            ],
            onChange: (style) => {
                setGediLayer('None')
                let gediShotsOn = document.getElementsByClassName("view-gedi-shots")[0]
                gediShotsOn.checked = false
                setCarbonLayerSelected("None");
                setCarbonLayer(uploadInfo["None"]);
                let carbonLayerInputs = document.getElementById("carbon-layer-menu").getElementsByTagName("input")
                for (let input of carbonLayerInputs) {
                    input.checked = false
                }
            },
          }), 'top-right');

        /* Adds AGB Carbon Layer on first page */
        // timeout of 1 sec to wait until mapbox intial styles have been applied
        setTimeout(() => {
            console.log("carbonLayer:", carbonLayer)
            map.current.addSource(carbonLayer, {
                'type': "vector",
                'url': `mapbox://korotu-conroy-trinh.${carbonLayer}`,
            })
            console.log("reportName, carbonLayerSelected:", reportName, carbonLayerSelected)
            map.current.addLayer({
                'id': carbonLayer,
                'source': carbonLayer,
                'source-layer': reportName + carbonLayerSelected,
                'type': "fill",
                'paint': {
                    'fill-color': {
                      property: 'DN', // this will be your DN property from your geojson
                      stops: [
                        [0, '#f7fcf5'],
                        [25, '#e8f6e3'],
                        [50, '#d0ecc9'],
                        [75, '#b2e0ab'],
                        [100, '#8ed08c'],
                        [125, '#66bd6f'],
                        [150, '#3da75a'],
                        [175, '#248c45'],
                        [200, '#03702e'],
                        [225, '#00441b']
                      ]
                    },
                }
            });

            map.current.on('mousemove', carbonLayer, (event) => {
                const popup = document.getElementsByClassName('mapboxgl-popup');
                if ( popup.length ) {
                    popup[0].remove();
                }
                var coordinates = event.lngLat
                map.current.getCanvas().style.cursor = 'pointer';
                const AGBD = Math.ceil(event.features[0].properties.DN * constValues[units]);
                const AGBDvalue = AGBD + 'mg/ha ' + units
                if (event.features.length === 0) return;
                const popup_2 = new mapboxgl.Popup( {
                    closeButton: false, 
                    closeOnClick: false
                })
                popup_2.setLngLat(coordinates).setHTML(AGBDvalue).addTo(map.current);
            });
            
        }, 1000)
        let default_button = document.getElementById('default_button')
        default_button.checked = true
        setCarbonLayerName("Above Ground Biomass")


        map.current.addControl(nav);
        // console.log("mapbox-region-selection: Nav control created");
      

        scale = new mapboxgl.ScaleControl({ maxWidth: 100 });
        map.current.addControl(scale, 'bottom-right');



        return () => map.current.remove()   // clean up after unmounting (still causes errors on refresh occasionally)
    }, [])

    useEffect(() => {
        let backButton = document.getElementsByClassName('back-button')[0];
        console.log("back", backButton)
        backButton.style.visibility = "visible"
        backButton.onClick = () => {history.goBack()}
    })

    // gedi layer changes useEffect
    useEffect(() => {
        if (!gediState && gediLayer != 'None') {
            setGediState(true)
            map.current.addSource(gediLayer, {
                'type': "vector",
                'url': `mapbox://korotu-conroy-trinh.${gediLayer}`,
            })
            map.current.addLayer({
                'id': gediLayer,
                'source': gediLayer,
                'source-layer': gediSourceName,
                'type': "circle",
            });

            map.current.on('mousemove', gediLayer, (event) => {
                map.current.getCanvas().style.cursor = 'pointer';
                const gediAGBD = event.features[0].properties.agbd;
                if (event.features.length === 0) return;
                console.log(gediAGBD);
            })

        } else if (gediLayer == 'None' && gediState) {
            map.current.removeLayer(carbonOutput["gedi_shots"])
            map.current.removeSource(carbonOutput["gedi_shots"])
            setGediState(false)
        }

        map.current.flyTo({
            center: [carbonOutput["mean_long"], carbonOutput["mean_lat"]],
            zoom: 14
        })


    }, [gediLayer])


    useEffect(() => {
        if (carbonLayer != 'None' && oldcarbonLayer != "None") {
            if (map.current.getLayer(oldcarbonLayer)) {
                map.current.removeLayer(oldcarbonLayer)
                map.current.removeSource(oldcarbonLayer)
            }

            map.current.addSource(carbonLayer, {
                'type': "vector",
                'url': `mapbox://korotu-conroy-trinh.${carbonLayer}`,
            })
            map.current.addLayer({
                'id': carbonLayer,
                'source': carbonLayer,
                'source-layer': reportName + carbonLayerSelected,
                'type': "fill",
                'paint': {
                    'fill-color': {
                      property: 'DN', // this will be your DN property from your geojson
                      stops: [
                        [0, '#f7fcf5'],
                        [25, '#e8f6e3'],
                        [50, '#d0ecc9'],
                        [75, '#b2e0ab'],
                        [100, '#8ed08c'],
                        [125, '#66bd6f'],
                        [150, '#3da75a'],
                        [175, '#248c45'],
                        [200, '#03702e'],
                        [225, '#00441b']
                      ]
                    },
                }
            });

            map.current.on('mousemove', carbonLayer, (event) => {
                const popup = document.getElementsByClassName('mapboxgl-popup');
                if ( popup.length ) {
                    popup[0].remove();
                }
                var coordinates = event.lngLat
                map.current.getCanvas().style.cursor = 'pointer';
                const AGBD = Math.ceil(event.features[0].properties.DN * constValues[units]);
                const AGBDvalue = AGBD + 'mg/ha ' + units
                console.log(AGBDvalue)
                if (event.features.length === 0) return;
                const popup_2 = new mapboxgl.Popup( {
                    closeButton: false, 
                    closeOnClick: false
                })
                popup_2.setLngLat(coordinates).setHTML(AGBDvalue).addTo(map.current);
            });
        }

        if (gediState) {
            map.current.removeLayer(gediLayer)
            map.current.removeSource(gediLayer)

            map.current.addSource(gediLayer, {
                'type': "vector",
                'url': `mapbox://korotu-conroy-trinh.${gediLayer}`,
            })
            map.current.addLayer({
                'id': gediLayer,
                'source': gediLayer,
                'source-layer': gediSourceName,
                'type': "circle"
            })
        }


        map.current.flyTo({
            center: [carbonOutput["mean_long"], carbonOutput["mean_lat"]],
            zoom: 14
        })
        setoldCarbonLayer(carbonLayer)


        // console.log('test2')
    }, [carbonLayer, unitName])

    const navigateToData = () => {
        history.push(`/project/${params.projectID}/gedi_details`, {
            state: {
                reportName: reportName,
                carbonOutput: carbonOutput,
                gediOutput: gediOutput
            }
        })
    }

    useEffect(() => {
        menuAnimate(1);
        // changeLayerValue("AGB_8bit")
    }, [])

    const changeLayerValue = (layerSelected) => {
        // console.log("Selected layer:", layerSelected);
        setCarbonLayerSelected(layerSelected);
        setCarbonLayer(uploadInfo[layerSelected]);
        // console.log("value layer" , layerValues[layerSelected]);
        if(layerSelected === "AGBvectorupload"){
            setCarbonLayerName("Above Ground Biomass");
        } else if (layerSelected === "BGBvectorupload"){
            setCarbonLayerName("Below Ground Biomass");
        } else if (layerSelected === "DPMvectorupload"){
            setCarbonLayerName("Dead Plant Matter");
        } else if (layerSelected === "SOCvectorupload"){
            setCarbonLayerName("Soil Biomass");
        } else if (layerSelected === "Totalvectorupload"){
            setCarbonLayerName("Total Biomass");
        }

      }


    const menuAnimate = (out) => {
        if (out === 1) {
            setmenuOutStyle({
                transition: 'transform 250ms ease-in-out',
                transform: `translateX(350px)`
            });
            setmenuOpen(true);
        } else {
            setmenuOutStyle({
                transition: 'transform 250ms ease-in-out',
                transform: `translateX(0%)`
            });
        }
        // console.log('menu out')
    }

    const changeUnitName = (units) => {
        setUnits(units)
        if(units === "carbon"){
            setUnitName("Carbon")
        } else if (units === "biomass"){
            setUnitName("Biomass")
        } else if (units === "co2"){
            setUnitName("Carbon Dioxide")
        }
    }


    useEffect(() => {
        // console.log("Layer name:", carbonLayerName);
    }, [carbonLayerName]);

    const changeLegendVale = (valueSelected) => {
        setUnitsSelected(valueSelected)
        setUnits(constValues[valueSelected])
        //testing permissions
        
    }


    function showBioData() {
        setbiomassDataOut(true);
    }

    function hideBioData() {
        setbiomassDataOut(false);
    }


    // useEffect(() => {
    //     changeLayerValue("AGB_8bit")
    // }, [])

    return (
        <><div className='carbon-report_mapbox-container ' style={{ height: mapHeight, width: mapWidth }}>
            <button className="side-menu-open-btn"
                onClick={() => menuAnimate(1)}>
                <img src={hamburgerMenu} style={{width:"30px", marginTop: "4px"}}></img>
            </button>
                 <div className="side-menu-div"
                style={menuOutStyle}>
                <br></br>
                <div className="title">
                    <h3>{reportName}</h3>
                    <img src={xIcon} onClick={() => menuAnimate(0)} className="closeIcon"></img>
                </div>
                <br></br>
                <p>This map shows the estimated above ground biomass for one (1) hectare forest blocks within the region</p>
                <br></br>
                <div className="side-menu-panel"
                    onChange={event => gediState ? setGediLayer('None') : setGediLayer(event.target.value)}
                    style={{ 'height': 'auto' }}>
                    <label>
                        <input className="view-gedi-shots" type="checkbox" value={uploadInfo['gedi_shots']} name="carbonLayer" /> <span>Show GEDI shots</span>
                    </label>
                </div>
                <br></br>
                <div className="side-menu-header" onClick={() => setmenuPanelOpenLC(!menuPanelOpenLC)}>
                    <img src={menuPanelOpenLC ? upArrow : downArrow} className="Arrow"></img><h4>Land Cover Types:</h4>
                </div>
                <div className="side-menu-panel"
                    onChange={event => setLandCover(event.target.value)}
                    style={menuPanelOpenLC ? { "height": "auto" } : { "height": "0px" }}>
                    <br></br>
                    <label>
                        <input type="radio" value="forest" name="landcover" checked="checked" /> <span>Forest</span>
                    </label>
                    <br></br>
                    <label>
                        <input type="radio" value="grassland" name="landcover" disabled={true} /> <span title="This feature isn't available yet" id="disabled-landcover">Grassland</span>
                    </label>
                    <br></br>
                </div>

                <br></br>
                <div className="side-menu-header" onClick={() => setmenuPanelOpenLayers(!menuPanelOpenLayers)}>
                    <img src={menuPanelOpenLayers ? upArrow : downArrow} className="Arrow"></img><h4>Carbon Layers:</h4>
                </div>
                <div className="side-menu-panel" id="carbon-layer-menu"
                    onChange={event => changeLayerValue(event.target.value)}
                    style={menuPanelOpenLayers ? { "height": "auto" } : { "height": "0px" }}>
                    <br></br>
                    <label>
                        <input type="radio" value='AGBvectorupload' name="carbonLayer" id="default_button"/> <span>Above Ground Biomass</span>
                    </label>
                    <br></br>
                    <label>
                        <input type="radio" value='BGBvectorupload' name="carbonLayer" /> <span>Below Ground Biomass</span>
                    </label>
                    <br></br>
                    <label>
                        <input type="radio" value='DPMvectorupload' name="carbonLayer" /> <span>Dead Plant Matter</span>
                    </label>
                    <br></br>
                    <label>
                        <input type="radio" value='SOCvectorupload' name="carbonLayer"/> <span>Soil Biomass</span>
                    </label>
                    <br></br>
                    <label>
                        <input type="radio" value='Totalvectorupload' name="carbonLayer" /> <span>Total Biomass</span>
                    </label>
                    <br></br>
                    {/* <label>
                        <input type="radio" value='None' name="carbonLayer" /> <span>None</span>
                    </label> */}
                    <br></br>
                </div>


                <br></br>
                <div className="side-menu-header" onClick={() => setmenuPanelOpenUnits(!menuPanelOpenUnits)}>
                    <img src={menuPanelOpenUnits ? upArrow : downArrow} className="Arrow"></img><h4>Legend Units:</h4>
                </div>
                <div className="side-menu-panel"
                    onChange={event => changeUnitName(event.target.value)}
                    
                    style={menuPanelOpenUnits ? { "height": "auto" } : { "height": "0px" }}>
                    <br></br>
                    <label>
                        <input type="radio" value="biomass" name="units" defaultChecked/> <span>Biomass</span>
                    </label>
                    <br></br>
                    <label>
                        <input type="radio" value="carbon" name="units" /> <span>Carbon</span>
                    </label>
                    <br></br>
                    <label>
                        <input type="radio" value="co2" name="units" /> <span>Carbon Dioxide</span>
                    </label>
                    <br></br>
                </div>
            </div>

            <div className="legend-div"
                style={menuOutStyle}>
                {!legendOpen ? <button
                    className="legendopenbtn"
                    onClick={() => setlegendOpen(true)}>
                    <img src={rightArrow} style={{width:"10px"}}></img>
                </button>
                    :
                    <div>
                        <div className="legend-content">
                            <div align="left"><span>{unitName} Pixel (Mg/ha)</span></div> <br />
                            <div align="left"><span className="legend-key" style={{ backgroundColor: "#f7fcf5" }} /><span>0 - {Math.ceil(25 * constValues[units])}</span></div>
                            <div align="left"><span className="legend-key" style={{ backgroundColor: "#e8f6e3" }} /><span>{Math.ceil(25 * constValues[units])} - {Math.ceil(50 * constValues[units])}</span></div>
                            <div align="left"><span className="legend-key" style={{ backgroundColor: "#d0ecc9" }} /><span>{Math.ceil(50 * constValues[units])} - {Math.ceil(75 * constValues[units])}</span></div>
                            <div align="left"><span className="legend-key" style={{ backgroundColor: "#b2e0ab" }} /><span>{Math.ceil(75 * constValues[units])} - {Math.ceil(100 * constValues[units])}</span></div>
                            <div align="left"><span className="legend-key" style={{ backgroundColor: "#8ed08c" }} /><span>{Math.ceil(100 * constValues[units])} - {Math.ceil(125 * constValues[units])}</span></div>
                            <div align="left"><span className="legend-key" style={{ backgroundColor: "#66bd6f" }} /><span>{Math.ceil(125 * constValues[units])} - {Math.ceil(150 * constValues[units])}</span></div>
                            <div align="left"><span className="legend-key" style={{ backgroundColor: "#3da75a" }} /><span>{Math.ceil(150 * constValues[units])} - {Math.ceil(175 * constValues[units])}</span></div>
                            <div align="left"><span className="legend-key" style={{ backgroundColor: "#248c45" }} /><span>{Math.ceil(175 * constValues[units])} - {Math.ceil(200 * constValues[units])}</span></div>
                            <div align="left"><span className="legend-key" style={{ backgroundColor: "#03702e" }} /><span>{Math.ceil(200 * constValues[units])} - {Math.ceil(225 * constValues[units])}</span></div>
                            <div align="left"><span className="legend-key" style={{ backgroundColor: "#00441b" }} /><span>{'>'} {Math.ceil(225 * constValues[units])}</span></div>
                        </div>
                        <button
                            className="legendopenbtn"
                            onClick={() => setlegendOpen(false)}
                            style={{ 'marginLeft': '158px' }}>
                            {"<"}
                        </button>
                    </div>
                }


            </div>

            {biomassDataOut ?
                <div className="biomass-data">
                    <div>
                    <table>
                        <tbody>
                            <tr>
                                <td><strong>{carbonLayerName}</strong></td>
                                <th>Average per ha</th>
                                <th>Total for Area</th>
                            </tr>
                            <tr>
                                <td>Area (ha)</td>
                                <td>{(1).toFixed(1)}</td>
                                <td>{layerValues[carbonLayerSelected]['area'].toFixed(1)}</td>
                            </tr>
                            <tr>
                                <td>Biomass (Mg/ha)</td>
                                <td>{layerValues[carbonLayerSelected]['mean'].toFixed(1)}</td>
                                <td>{layerValues[carbonLayerSelected]['total'].toFixed(1)}</td>
                            </tr>
                            <tr>
                                <td>Carbon (Mg/ha)</td>
                                <td>{(layerValues[carbonLayerSelected]['mean'] * constValues['carbon']).toFixed(1)}</td>
                                <td>{(layerValues[carbonLayerSelected]['total'] * constValues['carbon']).toFixed(1)}</td>
                            </tr>
                            <tr>
                                <td>CO2 (Mg/ha)</td>
                                <td>{(layerValues[carbonLayerSelected]['mean'] * constValues['co2']).toFixed(1)}</td>
                                <td>{(layerValues[carbonLayerSelected]['total'] * constValues['co2']).toFixed(1)}</td>
                            </tr>
                            <tr>
                                <td><strong>GEDI Shots</strong></td>
                                <th>Average per ha</th>
                                <th>{uploadInfo['use_buffer'] ? "Total for Area (including buffer)" : "Total for Area"}</th>
                            </tr>
                            <tr>
                                <td>Count</td>
                                <td>{uploadInfo['GEDI_count_avg'].toFixed(1)}</td>
                                <td>{uploadInfo['GEDI_count'].toFixed(1)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                    <button className="close-data" onClick={hideBioData}><img src={xIcon} style={{width: '10px',}} ></img></button>
                </div> : null}

            <div className="mapbox-overlay-buttons">
                <button
                    className="downloadbtnall"
                    style={menuOutStyle}
                    onClick={() => navigateToData()}
                >
                    View Methodology
                </button>
                {!biomassDataOut ?
                    <button
                        className="showbiomassbtn"
                        onClick={showBioData}
                        style={menuOutStyle}
                    >
                        Show biomass data
                    </button> : null}
            </div>
            <div
                className='carbon-report_mapbox'
                style={!navClosed ? { height: mapHeight, width: mapWidth } :
                    { height: mapHeight, width: mapWidth }}
                ref={mapContainer}
                onClick={() => menuAnimate(0)}>
            </div>
        </div>
        </>


    )

}
import React, { useState, useRef, useEffect } from "react";
import {StylesControl} from "mapbox-gl-controls";
import "mapbox-gl-controls/lib/controls.css"


// import mapboxgl from "!mapbox-gl";
import mapboxgl, { LngLat } from "!mapbox-gl";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
// import { SearchBox } from "@mapbox/search-js-react";
import PolygonDraw from "../../images/mapboxgljs-polygon-draw.png";
import Nav from "../../images/mapboxgljs-nav.png";

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
// import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import HamburgerIcon from "../../images/hamburger.svg";
import "./mapbox-region-selection.css";
import polylabel from "polylabel";

// Official Korotu access token (under Conroy's name)
mapboxgl.accessToken =
  "pk.eyJ1Ijoia29yb3R1LWNvbnJveS10cmluaCIsImEiOiJja2pyeTh0cnU2aDViMnFwOTFrZHoxdDU3In0.VEuiNCwqBGL8CidAvTgdjA";

// satellite styles
// mapbox://styles/korotu-conroy-trinh/ckwqnggaf0esg14odvehgi6hd

// street style
// mapbox://styles/korotu-conroy-trinh/ckmqormkv0v8h17nohblktp22
/**
 * Flexible mapbox display container with support for selecting watersheds, custom regions by draw tool, and custom regions loaded by geojsons
 *
 * Known bug: When selecting multiple custom preloaded regions, the previous one will not be deselected by selecting a new one (likely because
 * of the way the layers are made right now)
 *
 * @param {function}        setRegionID
 * @param {function}        setRegionName
 * @param {object}          project
 * @param {number}          longitude
 * @param {number}          latitude
 * @param {number}          zoom
 * @param {number}          mapWidth
 * @param {number}          mapHeight
 * @param {Object}          style
 * @param {Array}           drawnFeatures
 * @param {function}        setDrawnFeatures
 * @param {Array}           customPreloadedFeatures     Custom regions to preload, see API endpoints for format
 * @param {string}          selectedPreloadedFeature    RegionID of current selected preloaded feature
 * @param {Object}          geoJsonID
 * @param {boolean}         drawEnabled
 * @param {boolean}         allowOnlySingleDrawnRegion  Boolean to only allow 1 drawn feature at a time
 * @param {boolean}         watershedsEnabled
 *
 * @author  [Leo Wang](https://github.com/Korotu-Leo-Wang)
 */
export function MapboxRegionSelection(props) {
  // too many props, destructure them for organization
  const {
    setRegionID,
    setRegionName,
    project,
    longitude,
    latitude,
    zoom,
    mapWidth,
    mapHeight,
    style,
    drawnFeatures,
    setDrawnFeatures,
    customPreloadedFeatures,
    selectedPreloadedFeatureRegionID,
    geoJsonID,
    drawEnabled,
    watershedsEnabled,
    allowOnlySingleDrawnRegion,
  } = props;

  let [lng, setLng] = useState(longitude);
  let [lat, setLat] = useState(latitude);
  let [mapZoom, setZoom] = useState(zoom);
  let [streetMap, setStreetMap] = useState(false);
  let [satelliteMap, setSatelliteMap] = useState(true);
  let [isLargeMap, setIsLargeMap] = useState(false);
  // let [region, setRegion] = useState({name: null});
  let draw, nav, scale;
  // satellite styles
  const satelliteStyle =
    "mapbox://styles/mapbox/satellite-streets-v12";

  // street style
  const streetStyle =
    "mapbox://styles/mapbox/streets-v11";
  let map = useRef(null);
  let mapContainer = useRef();

  function computeCentroid(arr) {
    let minX, maxX, minY, maxY;
    for (let i = 0; i < arr.length; i++) {
      minX = arr[i][0] < minX || minX == null ? arr[i][0] : minX;
      maxX = arr[i][0] > maxX || maxX == null ? arr[i][0] : maxX;
      minY = arr[i][1] < minY || minY == null ? arr[i][1] : minY;
      maxY = arr[i][1] > maxY || maxY == null ? arr[i][1] : maxY;
    }
    return [(minX + maxX) / 2, (minY + maxY) / 2];
  }

  // toggle different maps
  useEffect(() => {
    if (!map.current) return; // wait for map render

    if (satelliteMap) {
      map.current.setStyle(satelliteStyle);
    } else if (streetMap) {
      map.current.setStyle(streetStyle);
    }
  }, [streetMap, satelliteMap]);

  // map update useEffect
  useEffect(() => {

    if (!map.current) return; // wait for map render


    if (watershedsEnabled) {
      // TODO: Logic for selecting or selecting watershed regions if selectedRegion passed in
    }

    if (customPreloadedFeatures) {
      if (selectedPreloadedFeatureRegionID && geoJsonID) {
        // update selected preloaded regions
        map.current.setFilter(`${geoJsonID}-selected`, [
          "in",
          "ID",
          selectedPreloadedFeatureRegionID,
        ]);
      } else if (geoJsonID) {
        map.current.setFilter(`${geoJsonID}-selected`, ["in", "ID", ""]);
      } else {
        // pretty bad solution, but catches all other cases of wrong highlighting

        customPreloadedFeatures.forEach((feature) => {
          map.current.setFilter(`${feature.data.id}-selected`, [
            "in",
            "ID",
            "",
          ]);
        });
      }
    }
  });

  // map initialization useEffect
  useEffect(() => {
    if (map.current) return; // only render map once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: style,
      center: [lng, lat],
      zoom: 6,
    });

    /* TAKEN FROM MAPBOX DOCUMENTATION
     Given a query in the form "lng, lat" or "lat, lng"
    * returns the matching geographic coordinate(s)
    * as search results in carmen geojson format,
    * https://github.com/mapbox/carmen/blob/master/carmen-geojson.md */
    const coordinatesGeocoder = function (query) {
      // Match anything which looks like
      // decimal degrees coordinate pair.
      const matches = query.match(
        /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
      );
      if (!matches) {
        return null;
      }

      function coordinateFeature(lng, lat) {
        return {
          center: [lng, lat],
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          },
          place_name: 'Lat: ' + lat + ' Lng: ' + lng,
          place_type: ['coordinate'],
          properties: {},
          type: 'Feature'
        };
      }

      const coord1 = Number(matches[1]);
      const coord2 = Number(matches[2]);
      const geocodes = [];

      if (coord1 < -90 || coord1 > 90) {
        // must be lng, lat
        geocodes.push(coordinateFeature(coord1, coord2));
      }

      if (coord2 < -90 || coord2 > 90) {
        // must be lat, lng
        geocodes.push(coordinateFeature(coord2, coord1));
      }

      if (geocodes.length === 0) {
        // else could be either lng, lat or lat, lng
        geocodes.push(coordinateFeature(coord1, coord2));
        geocodes.push(coordinateFeature(coord2, coord1));
      }

      return geocodes;
    };

    map.current.addControl(new MapboxGeocoder({
      mapboxgl: mapboxgl,
      accessToken: mapboxgl.accessToken,
      localGeocoder: coordinatesGeocoder,
      reverseGeocode: false,
      placeholder: "Search (Select text to delete) "
    }));

    // console.log(map.current);
    /** Coordinate Data */
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });

    /** Mapbox Drawing and Polygon Tools */
    draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
        zoom: true,
      },
      defaultMode: 'draw_polygon'
    });

    /** Mapbox Navigation tools */
    nav = new mapboxgl.NavigationControl();

    /** Full screen map */
    map.current.addControl(new mapboxgl.FullscreenControl());
    // console.log(document.querySelector(".project-region__mapbox"));
    let outerMapContainer = document.querySelector(".project-region__mapbox");
    let outerMapWidth = outerMapContainer.clientWidth;
    // console.log(outerMapWidth)
    if (outerMapWidth < 500) {
      setIsLargeMap(false);
    }
    // console.log(outerMapContainer.clientWidth);
    map.current.on("resize", () => {
      // console.log("got here");
      // console.log(map.current.getContainer());
      // console.log(document.fullscreenElement !== null);
      // console.log(document.querySelector(".project-region__mapbox"));
      // console.log(outerMapContainer.clientWidth);
      setIsLargeMap(outerMapContainer.clientWidth >= 500);
    });

    /** Mapbox Geolocate your location tool */
    // let search = new mapboxgl.GeolocateControl(); map.addControl(search);

    map.current.addControl(nav);
    // console.log("mapbox-region-selection: Nav control created");


    // with custom styles:
    map.current.addControl(new StylesControl({
      styles: [
        {
          label: 'Streets',
          styleName: 'Mapbox Streets',
          styleUrl: streetStyle,
        }, {
          label: 'Satellite',
          styleName: 'Satellite',
          styleUrl: satelliteStyle,
        },
      ],
      onChange: (style) => {
        
      },
    }), 'top-left'); 



    function deselectPredefinedRegion() {
      if (watershedsEnabled) {
        map.current.setFilter("watersheds-selected", ["in", "NAME", ""]);
      }
    }

    function updateCustomRegion() {
      let data = draw.getAll();
      // this doesnt work
      if (allowOnlySingleDrawnRegion) {
        data.features.forEach((value, index) => {
          if (index < data.features.length - 1) {
            draw.delete(value.id);
          }
        });

        data = draw.getSelected();
      }
      setDrawnFeatures(prevFeatures => [...prevFeatures, ...data.features]);

      deselectPredefinedRegion();
    }

    function deleteCustomDrawnRegion(e) {
      const deleted = e.features;
      let allFeatures = drawnFeatures;

      deleted.forEach((feature) => {
        const index = allFeatures.indexOf(feature);
        if (index > -1) {
          allFeatures.splice(index, 1);
        }
      });

      setDrawnFeatures(allFeatures);
    }

    function selectCustomDrawnRegion(e) {
      const selected = draw.getSelected().features;
      // console.log(JSON.stringify(selected));
      if (selected && selected.length == 1) {
        setRegionID(selected[0].id);
        setRegionName("Custom Region");
      }

      deselectPredefinedRegion();
    }

    if (drawEnabled) {
      map.current.addControl(draw);
      // console.log("mapbox-region-selection: Draw control enabled");

      map.current.on("draw.create", updateCustomRegion);
      map.current.on("draw.update", updateCustomRegion);
      map.current.on("draw.delete", deleteCustomDrawnRegion);
      map.current.on("draw.selectionchange", selectCustomDrawnRegion);
    }

    scale = new mapboxgl.ScaleControl({ maxWidth: 100 });
    map.current.addControl(scale, "bottom-right");

    // const search = new MapboxSearchBox();
    // search.accessToken = mapboxgl.accessToken;
    // map.current.addControl(search);

    /** Add interactibility with mapbox watershed regions*/
    map.current.on("load", function () {
      // console.log("Mapbox Layer:");
      // console.log(map.current.getLayer('ontario-watershed-label'));
      // console.log("Mapbox Source:");
      // console.log(map.current.getSource('composite'));
      // console.log(map.current.querySourceFeatures('composite', {sourceLayer: 'OntarioQuarternaryWatersheds--9m960o'}));

      // load custom drawn regions
      if (drawnFeatures) {
        drawnFeatures.forEach((feature) => {
          draw.add(feature);
        });
      }

      /** use to fit mapbox to a bounding box, "pans to the location *
      map.fitBounds([
        [-168, 72],
        [-81, 9]
      ]);
      /** */

      // load custom preloaded regions
      if (customPreloadedFeatures) {
        customPreloadedFeatures.forEach((feature) => {
          if (!map.current.getSource(feature.data.id)) {
            map.current.addSource(feature.data.id, {
              type: "geojson",
              data: feature.data,
            });
          }

          if (!map.current.getLayer(feature.data.id)) {
            map.current.addLayer({
              id: feature.data.id,
              type: "fill",
              source: feature.data.id,
              paint: {
                "fill-outline-color": "#FDFDFD",
                "fill-color": "rgba(175, 225, 111, 0.3)",
              },
            });
          }

          let coords =
            feature.data.geometry.type == "MultiPolygon"
              ? feature.data.geometry.coordinates[0]
              : feature.data.geometry.coordinates;
          let center = polylabel(coords, 1.0);
          console.log(center);
          const marker1 = new mapboxgl.Marker()
            .setLngLat(new LngLat(center[0], center[1]))
            // .addTo(map.current)
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(
                  `<h3>${feature.name}</h3>
                     
                    
                      <p>${feature.data.landowner}</p>`
                )
            )
            .addTo(map.current);

          map.current.addLayer({
            id: `${feature.data.id}-highlighted`,
            type: "fill",
            source: feature.data.id,
            layout: {},
            paint: {
              "fill-outline-color": "#FDFDFD",
              "fill-color": "rgba(175, 225, 111, 0.4)",
            },
            filter: ["in", "ID", ""],
          });

          map.current.addLayer({
            id: `${feature.data.id}-selected`,
            type: "fill",
            source: feature.data.id,
            paint: {
              "fill-outline-color": "#FDFDFD",
              "fill-color": "rgba(175, 225, 111, 0.7)",
            },
            filter: ["in", "ID", ""] /** false by default */,
          });

          map.current.on("mousemove", feature.data.id, function (e) {
            map.current.getCanvas().style.cursor = "pointer";

            let feature = e.features[0];

            map.current.setFilter(`${feature.layer.id}-highlighted`, [
              "in",
              "ID",
              feature.properties.ID,
            ]);
          });

          map.current.on("click", feature.data.id, function (e) {
            map.current.getCanvas().style.cursor = "pointer";

            let feature = e.features[0];

            map.current.setFilter(`${feature.layer.id}-selected`, [
              "in",
              "ID",
              feature.properties.ID,
            ]);

            setRegionID(feature.properties.ID);
          });

          map.current.on("mouseleave", feature.data.id, function () {
            map.current.getCanvas().style.cursor = "";
            map.current.setFilter(`${feature.data.id}-highlighted`, [
              "in",
              "ID",
              "",
            ]);
          });
        });
      }

      // add watershed map layers
      if (watershedsEnabled) {
        map.current.addLayer(
          {
            id: "watersheds",
            type: "fill",
            source: "composite",
            "source-layer": "OntarioQuarternaryWatersheds--9m960o",
            paint: {
              "fill-outline-color": "rgba(0,0,0, 0)",
              "fill-color": "rgba(0, 0, 0, 0)",
            },
            /** Label to place before */
          },
          "ontario-watershed-label"
        );

        map.current.addLayer(
          {
            id: "watersheds-highlighted",
            type: "fill",
            source: "composite",
            "source-layer": "OntarioQuarternaryWatersheds--9m960o",
            paint: {
              "fill-outline-color": "rgba(0,0,0, 0.2)",
              "fill-color": "rgba(25, 176, 235, 0.4)",
            },
            filter: ["in", "NAME", ""] /** false by default */,
            /** Label to place before */
          },
          "ontario-watershed-label"
        );

        map.current.addLayer(
          {
            id: "watersheds-selected",
            type: "fill",
            source: "composite",
            "source-layer": "OntarioQuarternaryWatersheds--9m960o",
            paint: {
              "fill-outline-color": "rgba(0,0,0, 0.8)",
              "fill-color": "rgba(25, 176, 235, 0.7)",
            },
            filter: ["in", "NAME", ""] /** false by default */,
            /** Label to place before */
          },
          "ontario-watershed-label"
        );

        map.current.on("mousemove", "watersheds", function (e) {
          map.current.getCanvas().style.cursor = "pointer";

          /** */
          let feature = e.features[0];

          map.current.setFilter("watersheds-highlighted", [
            "in",
            "NAME",
            feature.properties.NAME,
          ]);
        });

        map.current.on("click", "watersheds", function (e) {
          map.current.getCanvas().style.cursor = "pointer";

          let feature = e.features[0];

          map.current.setFilter("watersheds-selected", [
            "in",
            "NAME",
            feature.properties.NAME,
          ]);

          setRegionID(feature.properties.ID);
          setRegionName(feature.properties.NAME);
          console.log(feature)
        });

        map.current.on("mouseleave", "watersheds", function () {
          map.current.getCanvas().style.cursor = "";
          map.current.setFilter("watersheds-highlighted", ["in", "NAME", ""]);
        });
      }

      if (watershedsEnabled) {
        map.current.setLayoutProperty(`watersheds`, 'visibility', 'none');
      }
    });


    return () => map.current.remove(); // clean up after unmounting (still causes errors on refresh occasionally)
  }, []);



  const [navClosed, setNavClosed] = useState(true);

  function openNav() {
    setNavClosed(false);
  }

  function closeNav() {
    setNavClosed(true);
  }

  function toggleStreetView() {
    setStreetMap(true);
    setSatelliteMap(false);
  }

  function toggleSatelliteView() {
    setStreetMap(false);
    setSatelliteMap(true);
  }

  let token = "pk.eyJ1Ijoia29yb3R1LWNvbnJveS10cmluaCIsImEiOiJja2pyeTh0cnU2aDViMnFwOTFrZHoxdDU3In0.VEuiNCwqBGL8CidAvTgdjA"
  return (
    <div>
      <div
        className="project-region__mapbox-container"
        style={{ height: mapHeight, width: mapWidth }}
      >
        <div className="project-region-add">
          {/* <form className="search-bar"><mapbox-search-box accessToken={token} onClick={console.log(token)}></mapbox-search-box></form> */}
          {/* <button
            className="openbtn"
            style={{ visibility: !navClosed ? "hidden" : "visible" }}
            onClick={(e) => openNav(e)}
          >
            ☰<span className="hover-text">Open settings</span>
          </button> */}
          {/* <div className='project-region-add__sidebar'> Longitude {lng} | latitude {lat} | Zoom {zoom}</div> */}
          <div className="project-region__mapbox" ref={mapContainer}>
            {" "}
            {false ? ( // CUSTOM SIDE BAR NO LONGER USED
              <>
                <button
                  className="openbtn"
                  style={{ visibility: !navClosed ? "hidden" : "visible" }}
                  onClick={(e) => openNav(e)}
                >
                  {" "}
                  <img src={HamburgerIcon} />
                </button>
                <div
                  className="sidenav"
                  style={{ visibility: navClosed ? "hidden" : "visible" }}
                >
                  <div className="mapbox-style-buttons">
                    <button onClick={(e) => toggleStreetView(e)}>Streets</button>
                    <button onClick={(e) => toggleSatelliteView(e)}>
                      Satellites
                    </button>
                  </div>
                  <button className="closebtn map" onClick={(e) => closeNav(e)}>
                    &times;
                  </button>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/* <div id="menu">
        <input id="satellite-streets-v12" type="radio" name="rtoggle" value="satellite" checked="checked"></input>
        <label for="satellite-streets-v12">satellite streets</label>
        <input id="light-v11" type="radio" name="rtoggle" value="light"></input>
        <label for="light-v11">light</label>
      </div> */}
    </div>
  );
}

/**
 * This file is used to hold constants for urls and such to easily port between testing
 * on local machines and on the Virtual Machines
 */
var json_data = require('../../frontend_settings.json')
const run_local = json_data.Run_Local.JavaScript
const mock_data = json_data.Mock_Data.JavaScript
const tcp_protocol = json_data.TCP_Protocol
const public_ip_full = json_data.TCP_Protocol + '://' + json_data.Public_IP

// Set `true` if you want to test on your local machine, and make sure it's `false` when pushing to the github
// Controls whether HTTPRequests goto the localhost or the Virtual Machines
const DEBUG = run_local ;

// Controls whether the app will use predefined mock data or not, this will stop most HTTPRequests from taking place
const MOCK_DATA = mock_data;

let _url = '';
if (DEBUG) {
  _url = tcp_protocol + '://' + 'localhost';
} else {
  _url = public_ip_full; 
}
const url = _url;

// Ports for different microservices
const CORE_PORT = 8005; // previously called "inventory"
const VALUATION_PORT = 8000;
const MONITORING_PORT = 8002;
const EMAIL_PORT = 8006;

export {
  DEBUG,
  MOCK_DATA,
  url,
  CORE_PORT,
  VALUATION_PORT,
  MONITORING_PORT,
  EMAIL_PORT,
};

import React, { useEffect, useState } from 'react';
import { Redirect, useParams, useHistory} from 'react-router-dom';
import Back from '../images/icons/back-icon.png'
import { config } from '../config.js'


/** APIs*/
import { createCustomRegion, createProject, getFloodRiskMapRunoffImage } from '../apis/landsteward/accounts';

import './project-new-page.css'
import { MapboxRegionSelection } from '../components/mapbox/mapbox-region-selection';
import DynamicTextboxListCollaborators from '../components/modules/dynamic-textbox-list-collaborators';
import { MOCK_DATA } from '../apis/landsteward/urlconfig';
import { RegionNew } from './region-new';
import { RegionNewForest } from './region-new-forest';

/**
 * 
 * @param {*} props 
 *    <project>: current project metadata object
 *    <addProject(projObj)>: add new project object to state data
 * @returns 
 */
export function ProjectNewPage(props) {
  const params = useParams();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [name, setName] = useState('');                   // New Project Name
  const [description, setDescription] = useState('');     // Description
  const [inputList, setInputList] = useState([]);
  const [redirect, setRedirect] = useState(false);        // Redirect to project
  const [projectID, setProjectID] = useState(null);
  const [warning, setWarning] = useState('');

  const [team, setTeam] = useState('');
  const [service, setService] = useState("carbon");
  const [propertyFiles, setPropertyFiles] = useState(null);

  const [regionID, setRegionID] = useState('');
  let [regionName, setRegionName] = useState('');
  let [features, setFeatures] = useState([]);

  // disables other ecosystem services besides grasslands
  let IN_BETA = false 

  const descriptionMaxLength = 100;
  const nameMaxLength = 30;

  // starting coordinates 
  let startLon = config.defaultLon
  let startLat = config.defaultLat

  const increasePageIndex = () => {
    setPage(page + 1);
  };

  function decreasePageIndex() {
    setPage(page - 1);

    console.log('refresh')
    setMapBox(<div></div>)
    setMapBox(<MapboxRegionSelection
      longitude={startLon}
      latitude={startLat}
      zoom={9}
      style='mapbox://styles/korotu-conroy-trinh/ckwqmzmif0lwq14qtiz1mdc1u'
      setRegionID={setRegionID}
      setRegionName={setRegionName}
      drawnFeatures={null}
      setDrawnFeatures={setFeatures}
      mapWidth={"100%"}
      mapHeight={"30rem"}
      drawEnabled={true}
      watershedsEnabled={true}
      allowOnlySingleDrawnRegion={false}
      customPreloadedFeatures={false}
    />)
  };

  async function submitProjectInfo(e) {
    e.preventDefault();
    if (name === '') {
      let missing = [];
      missing.push("Project Name")
      setWarning("Missing required field(s): " + missing.join(", "));
    }
    else {
      let region = null;
      let newProjectID;
      newProjectID = await createProject(name, description, inputList, region ? region.regionID : null, service, props.account_id);
      let success = false;

      if (newProjectID !== null) {
        setProjectID(newProjectID);
        success = true;
      }

      /** Add Project to website State */
      if (success) {
        /** Updated Website State and then Route to project */
        await props.addProject({ name: name, description: description, projectID: newProjectID, service: service, region: region ? region.regionID : null });
      } else {
        console.error('Project Creation Failed');
        alert("Region Creation Failed. Make sure you have a completed boundary!");
      }
      setRedirect(true)
      setWarning('');
    }
  }

  const fileValidation = () => {
    let fileInput = document.getElementById('uploadButton');
    let filePath = fileInput.value;
    let allowedExtensions = /(\.geojson)$/i
    let CRS = ''

    if (!allowedExtensions.exec(filePath)) {
      setWarning('Please select a geojson file')
      fileInput.value = '';

    } else {
      let file = fileInput.files[0]
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {

        const binaryStr = reader.result;
        let enc = new TextDecoder("utf-8");
        var data = JSON.parse(enc.decode(binaryStr));
        // console.log(data.features)

        if (typeof data.crs == 'undefined') {
          CRS = "urn:ogc:def:crs:OGC:1.3:CRS84"
        } else {
          CRS = data.crs.properties.name
        }



        if (data.features.length > 1) {
          data.features.forEach((feature, index) => {
            // if (data.crs === undefined) {
            //     data.crs.properties =  {name: 'urn:ogc:def:crs:OGC:1.3:CRS84'}
            //     data.crs.type = "name"
            // }
            if (feature.properties.Name === undefined) {
              console.log("found undefined")
              feature.properties.Name = `Feature ${index}`
            }
          })
        }
        console.log("data --", data)
        setFeatures(data.features);
        console.log("FEATURES", features)

        if (CRS !== "urn:ogc:def:crs:OGC:1.3:CRS84") {
          fileInput.value = '';
          setWarning('Geojson file should be using a Geographic Coordinate System, where Latitude and Longitude are in decimal degrees')
        }

        else {
          setWarning('')
          setWarning(`Found ${data.features.length} properties in file`)
          document.getElementById('fileName').innerHTML = fileInput.files[0].name
          document.getElementById('submit-file-button').disabled = false
          setRegionName(fileInput.files[0].name)

        }
      }

      reader.readAsArrayBuffer(file);


    }
  }

  let mapElement = <MapboxRegionSelection
    longitude={startLon}
    latitude={startLat}
    zoom={9}
    style='mapbox://styles/korotu-conroy-trinh/ckwqmzmif0lwq14qtiz1mdc1u'
    setRegionID={setRegionID}
    setRegionName={setRegionName}
    drawnFeatures={features}
    setDrawnFeatures={setFeatures}
    mapWidth={"100%"}
    mapHeight={"30rem"}
    drawEnabled={true}
    watershedsEnabled={true}
    allowOnlySingleDrawnRegion={false}
    customPreloadedFeatures={false}
  />

  let [mapBox, setMapBox] = useState(mapElement)
  /**
   *  Sends a request to create a new carbon project and if succesful records
   *  its state into the website state
   */
  async function createNewCarbonProject(e) {
    e.preventDefault();

    let region = null;
    let newProjectID;
    newProjectID = await createProject(name, description, inputList, region ? region.regionID : null, service, props.account_id);
    let success = false;
    console.log(newProjectID)

    if (newProjectID !== null) {
      setProjectID(newProjectID);
      success = true;
    }

    // Carbon projects don't need to add to website state, catches success and sends to carbon project page

    /** Add Project to website State */
    if (success) {
      /** Updated Website State and then Route to project */
      await props.addProject({ name: name, description: description, projectID: newProjectID, service: service, region: region ? region.regionID : null });
    } else {
      console.error('Project Creation Failed');
      alert("Region Creation Failed. Make sure you have a completed boundary!");
    }

    let regionSuccess = false
    if (features && Object.keys(features).length == 1) {
      console.log("ONE FEATURE HERE: *****************************");
      console.log("single", features)

      region = await createCustomRegion(regionName, features, newProjectID, "custom-carbon");
      if (!region) {
        setWarning('Please select a geojson file or choose an area on map')
        console.error("Region creation failed");

        return;
      }
      regionSuccess = true
    } else if (features && Object.keys(features).length > 1) {
      console.log("MULTIPLE FEATURES HERE: *****************************");
      //console.log(features)

      for (let i = 0; i < features.length; i++) {
        console.log([features[i]])

        region = await createCustomRegion(`${regionName}-F${i + 1}`, [features[i]], newProjectID, "custom-carbon");
        if (!region) {
          setWarning('Please select a geojson file or choose an area on map')
          console.error(`Creating region '${regionName} - Property ${i + 1}' failed`);

          return;
        }

      }
      regionSuccess = true

    } else {

    }
    /** Add Project to website State */
    if (regionSuccess || service == "carbon") {
      /** Updated Website State and then Route to project */
      setWarning('Succesfully uploaded regions')
      setRedirect(true)
    } else {
      console.error('Project Creation Failed!!!');
      alert("Region Creation Failed. Make sure you have a completed boundary!");
    }
  }

  /**
   *  Sends a request to create a new forest project and if succesful records
   *  its state into the website state
   */
  async function createNewForestProject(e) {
    e.preventDefault();

    let region = null;
    let newProjectID;
    newProjectID = await createProject(name, description, inputList, region ? region.regionID : null, service, props.account_id);
    let success = false;
    console.log(newProjectID)

    if (newProjectID !== null) {
      setProjectID(newProjectID);
      success = true;
    }

    // Carbon projects don't need to add to website state, catches success and sends to carbon project page

    /** Add Project to website State */
    if (success) {
      /** Updated Website State and then Route to project */
      await props.addProject({ name: name, description: description, projectID: newProjectID, service: service, region: region ? region.regionID : null });
    } else {
      console.error('Project Creation Failed');
      alert("Region Creation Failed. Make sure you have a completed boundary!");
    }

    let regionSuccess = false
    if (features && Object.keys(features).length == 1) {
      console.log("ONE FEATURE HERE: *****************************");
      console.log("single", features)

      region = await createCustomRegion(regionName, features, newProjectID, "custom-carbon");
      if (!region) {
        setWarning('Please select a geojson file or choose an area on map')
        console.error("Region creation failed");

        return;
      }
      regionSuccess = true
    } else if (features && Object.keys(features).length > 1) {
      console.log("MULTIPLE FEATURES HERE: *****************************");
      //console.log(features)

      for (let i = 0; i < features.length; i++) {
        console.log([features[i]])

        region = await createCustomRegion(`${regionName}-F${i + 1}`, [features[i]], newProjectID, "custom-forest");
        if (!region) {
          setWarning('Please select a geojson file or choose an area on map')
          console.error(`Creating region '${regionName} - Property ${i + 1}' failed`);

          return;
        }

      }
      regionSuccess = true

    } else {

    }
    /** Add Project to website State */
    if (regionSuccess || service == "forest-change") {
      /** Updated Website State and then Route to project */
      setWarning('Succesfully uploaded regions')
      setRedirect(true)
    } else {
      console.error('Project Creation Failed!!!');
      alert("Region Creation Failed. Make sure you have a completed boundary!");
    }
  }

  /**
   * Creates new projects, seperate function from projects of type carbon
   * @param {*} e Event Object
   * @returns void
   */
  async function createNewProject(e) {
    e.preventDefault();
    let region;

    if (features && Object.keys(features).length != 0) {
      // console.log("FEATURES HERE: *****************************");
      // console.log(features)

      region = MOCK_DATA ? createCustomRegionsMock() : await createCustomRegion(regionName, features[0], service);
      if (!region) {
        setWarning('Please select a geojson file of choose an area on map')
        console.error("Region creation failed");
        
        return;
      }
    }
    let newProjectID;
    newProjectID = await createProject(name, description, inputList, region ? region.regionID : null, service, props.account_id);
    let success = false;

    if (newProjectID !== null) {
      setProjectID(newProjectID);
      success = true;
    }

    /** Add Project to website State */
    if (success) {
      /** Updated Website State and then Route to project */
      await props.addProject({ name: name, description: description, projectID: newProjectID, service: service, region: region ? region.regionID : null });
      setRedirect(true);
    } else {
      console.error('Project Creation Failed');
      alert("Region Creation Failed. Make sure you have a completed boundary!");
    }
  }

  // console.log('PROJECTNEWPAGE:', projectID);
  // console.log('PROJECTNEWPAGE:', `/project/${projectID}`);

  // hides the global back button on pages with local back button
  useEffect(() => {
    let globalBackButton = document.getElementsByClassName('back-button')[0];
    let localBackButton = document.getElementsByClassName('back-btn')[0];
    if (localBackButton != null) {
      globalBackButton.style.visibility = "hidden"
    } else {
      globalBackButton.style.visibility = "visible"
    }
  },)

  /*
    Sends user to project page if selected type is carbon,
    Else sends user to upload region page
  */
  function redirectProjectType(e) {
    if (service == "carbon") {
      createNewCarbonProject(e)
    } else if (service == "forest-change") {
      createNewForestProject(e)
    }
    else {
      increasePageIndex();
      setWarning('');
    }

  }

  // terrible stuff right here, rewrite if possible
  if (redirect) {
    let path = ''
    let redirectState = { name, description};
    switch (service) {
      case "carbon":
        path = `/project/${props.project.projectID}/reports`
        break
      case "forest-change":
        path = `/project/${props.project.projectID}/new-region-forest`
        break 
      case "biodiversity":
      default:
        path = `/project/${props.project.projectID}`
    }
    return (<Redirect to={{pathname: path, state: redirectState}}/>)
  } else {
    return (
      <React.Fragment>
        {page === 0 ?
          <div className='project-new__body'>
            <div className='project-new__back-col'>
            </div>
            <div className='project-new__form-col'>
              <div className="project-new-title">
                <h1>Create a New Project</h1>
              </div>
              <form className='project-new__form' onSubmit={redirectProjectType}>
                <div className="project-new__form__body">
                  <div className='project-new__input-block'>
                    <label htmlFor='project-new-name'>Project Name</label>
                    <br />
                    <div style={{ marginBottom: "0.1%" }}>
                      <input
                        name='project-new-name'
                        value={name}
                        onChange={(event => setName(event.target.value))}
                        type='text'
                        style={{ height: "1.5em" }}
                        placeholder="New Project"
                        maxLength={nameMaxLength}
                      />
                      <p className={name.length===nameMaxLength ? 'project-new__warning-text' : undefined}>{name.length}/{nameMaxLength}</p>
                    </div>
                  </div>

                  <div className='project-new__input-block'>
                    <label htmlFor='project-new-description'>Project Description <span style={{color: '#B5B5B5'}}> (optional)</span>
                    </label>
                    <br />
                    <div style={{ marginBottom: "0.1%" }}>
                      <textarea
                        name='project-new-description'
                        value={description}
                        onChange={(event => setDescription(event.target.value))}
                        type='text'
                        style={{ "minHeight": "5em" }}
                        placeholder="Government program subsidizing farmers for the delay of hay cutting on fields selected for bobolink mating habitat."
                        maxLength={descriptionMaxLength}
                      />
                      <p className={description.length===descriptionMaxLength ? 'project-new__warning-text' : undefined}>{description.length}/{descriptionMaxLength}</p>
                    </div>
                  </div>

                  <div className='project-new__input-block'>
                    <label htmlFor='project-services'>Ecosystem Services</label>
                    <br />
                    <div style={{ marginBottom: "0.1%" }}>
                      { // if beta is enabled, only show carbon
                      (IN_BETA) ? (
                      <select
                        name="project-services"
                        id="project-services"
                        onChange={event => setService(event.target.value)}
                        style={{ height: "1.5em" }}
                      >
                        <option value="biodiversity">Biodiversity - Habitat (Delayed Hay)</option>
                      </select> )
                      : // else show all services
                      ( <select
                      name="project-services"
                      id="project-services"
                      onChange={(event) =>  {
                        setService(() => {
                          let new_service = event.target.options[event.target.options.selectedIndex].value
                          return new_service
                          
                        })

                        console.log("changed service: ", service)
                        
                      }}
                      style={{ height: "1.5em" }}
                      >
                      <option value="carbon">Carbon - Forest Carbon</option>
                      <option value="biodiversity">Biodiversity - Habitat (Delayed Hay)</option>
                      <option value='forest-change'>Forest - Manual Change Detection</option> 
                      <option value="water">Water - Water Supply</option>
                      <option value="stormwater">Stormwater - Urban Stormwater</option>
                      <option value="retainment">Retainment, Urban - Urban Cooling</option>
                    </select> )
                    } 
                    </div>
                  </div>

                  <div className='project-new__input-block'>
                    {/* <label htmlFor='custom-characteristics'>Add Collaborators (optional)</label>
                    <br />
                    <div className='custom-characteristics-container'>
                      <DynamicTextboxListCollaborators
                        inputList={inputList}
                        setInputList={setInputList}
                      />
                    </div> */}

                  </div>

                  <p className='project-new__warning-text'>{warning}</p>
                  <div className="project-new-button">
                    <input
                      name='submit-btn'
                      type='submit'
                      value='Create Project'
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className='project-new__back-col'></div>
          </div> : null
        }

        {page === 1 ?
          <div className='project-new__body'>
            <div className='project-new__back-col'>
              <button onClick={decreasePageIndex} className='back-btn project-new__back-btn'>
                <img src={Back}></img>
              </button>
            </div>
            <div className='project-new__form-col'>
              <div className="project-new-title">
                <h1>Select the Project Area</h1>
              </div>
              <form className='project-new__form' onSubmit={createNewProject}>
                <div className="project-new__form__body">

                  <div className='project-new__input-block'>
                    <label htmlFor='project-upload'>Upload an existing shapefile</label>
                    <div className='upload-file-button'>
                      <input
                        type="file"
                        accept=".geojson"
                        id="uploadButton"
                        onChange={fileValidation}
                        hidden
                      />
                      <label htmlFor="uploadButton" id="fileName">Upload...</label>
                    </div>
                  </div>

                  <div className='project-new__input-block'>
                    <label htmlFor='project-map'>Choose on map</label>
                    <div className='upload-file-button'>
                      <input
                        type="submit"
                        id="map-button"
                        onClick={increasePageIndex}
                        hidden
                      />
                      <label htmlFor="map-button">Open Map...</label>
                    </div>
                  </div>

                  <p className='project-new__warning-text'>{warning}</p>

                  <div className="project-new-button">
                    <input
                      name='submit-btn'
                      type='submit'
                      id='submit-file-button'
                      value='Confirm Area'
                      disabled
                    />
                  </div>

                </div>
              </form>
            </div>
            <div className='project-new__back-col'></div>
          </div> : null
        }

        {page === 2 ?
          <div className='project-new__body'>
            <div className='project-new__back-col'>
              <button onClick={decreasePageIndex} className='back-btn project-new__back-btn'>
                <img src={Back}></img>
              </button>
            </div>

            <div className='project-new__form-col'>
              <div className="project-new-title">
                <h1>Define Project Area</h1>
              </div>
              <div className='mapbox-selection-div'>
                {mapBox}
                 {/* <MapboxRegionSelection
                    longitude={startLon}
                    latitude={startLat}
                    zoom={9}
                    style='mapbox://styles/korotu-conroy-trinh/ckwqmzmif0lwq14qtiz1mdc1u'
                    setRegionID={setRegionID}
                    setRegionName={setRegionName}
                    drawnFeatures={features}
                    setDrawnFeatures={setFeatures}
                    mapWidth={"100%"}
                    mapHeight={"30rem"}
                    drawEnabled={true}
                    watershedsEnabled={true}
                    allowOnlySingleDrawnRegion={false}
                    customPreloadedFeatures={false}
                  /> */}
                <script src='https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.0/mapbox-gl-geocoder.min.js'></script>
                <link rel='stylesheet' href='https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.0/mapbox-gl-geocoder.css' type='text/css' />
                <br />
                </div> 
                <h3>
                  {regionName ? `Selected ${regionName}` : ''}
                </h3>
                <div className='project-buttons'>
                  <button className="project-next-button"
                    onClick={createNewProject}
                  >
                    Create and Finish
                  </button>
                  <button className="project-next-button"
                    style={{ background: "#E5F4E4", color: "#357960" }}
                    onClick={decreasePageIndex}
                  >
                    Cancel
                  </button>

                </div>
                <div className='project-instructions'>
                Drag and click map to frame your property, then use the shape tool on the right to draw the boundary. <br/>  
                Double click to confirm the boundary or use the trash icon to remove.
              </div>
              



            </div>

            <div className='project-new__back-col'></div>
          </div> : null
        }
      </React.Fragment>
    )
  }
}

import React, { useState } from 'react';
import {Link} from 'react-router-dom';

import './payment-track-status.css';
import {PaymentProgressBar} from './payment-progress-bar'
import { PaymentStatus } from '../../webpages/payment-management';

/**
 * A component that shows the progress of a payment request and
 * show the notes given by land trust or the buyer =
 * 
 * @param {JSON} props.currPaymentRequest Current payment request
 *  
 */
export function TrackStatus(props) {
  let noteFromLandtrust = 'no message from landtrust';
  let noteFromLandbuyer = 'no message from buyer';
  let status = 'none';
  const bottomMsg = {
    [PaymentStatus.Incoming]: 'This request is awaiting the approval of the landtrust',
    [PaymentStatus.InProgress]: 'This request is awaiting the approval of the buyer',
    [PaymentStatus.Accepted]: 'This request has been successfully completed',
    [PaymentStatus.Declined]: 'This request has been declined'
  };

  if (props.currPaymentRequest !== null){
    status = props.currPaymentRequest.status;
    if ('note_to_buyer' in props.currPaymentRequest && props.currPaymentRequest.note_to_buyer !== null && props.currPaymentRequest.note_to_buyer !== ''){
      noteFromLandtrust = props.currPaymentRequest.note_to_buyer;
    }
    if ('note_to_landtrust' in props.currPaymentRequest && props.currPaymentRequest.note_to_landtrust !== null && props.currPaymentRequest.note_to_landtrust !== ''){
      noteFromLandbuyer = props.currPaymentRequest.note_to_landtrust;
    }
  }
  

  return (
    <div className='track-status-tab'>
      <PaymentProgressBar status= {status}/>
      <div className="track-status_note-display-tab">
        <div className = 'track-status-note'>
          <span style={{whiteSpace:'nowrap', fontWeight:'bold'}}>Note from Landtrust:</span>
          <div className='track-status-note-content'>{noteFromLandtrust}</div>
        </div>
        <div className = 'track-status-note'>
          <span style={{whiteSpace:'nowrap', fontWeight:'bold'}}>Note from Buyer:</span>
          <div className='track-status-note-content'>{noteFromLandbuyer}
          </div>
        </div>
        
      </div>
      <div className = 'track-bottom-msg'>
          {bottomMsg[status]}
      </div>
    </div>
  );
}

import React from 'react';
import FloodRiskDisplay from '../components/reports/flood-risk-display.js';
import './report-display-page.css'

export function ReportDisplayPage(props) {
  return (
    <div className='report-display'>
      <FloodRiskDisplay searchState={props.searchState}/>
    </div>
  );
}
import {React, useEffect} from 'react';
import {useRouteMatch, Link, useHistory} from 'react-router-dom';
import './path-bar.css'
import Back from '../../images/icons/back-icon.png'

/**
 * Bar that displays the path user is currently at in the website
 * 
 * @param {*} props
 *    pathList: array which contains the names of each path segment
 *      each segment is an object {name: 'projects', instance: 'project_name'} 
 *      NOTE: each name in this list must be unique for this PathBar
 *    debug: if true, display the pattern that is matched with routing
 */
export function PathBar(props) {

  let match = useRouteMatch();

  /** content to display in path bar */
  let content = parsePathList(props.pathList)
  
  const history = useHistory()
  
  const goBack = () => {
    history.goBack()
  }

  function parsePathList(pathList) {
    /** Initialize Output */
    let pathComponents = [];


    /** Each item in path will be added to the PathBar */
    for (let i=0; i < pathList.length; i++) {
      let pathItem = pathList[i];

      /** Construct PathBar with with links*/
      pathComponents.push(<Link to={pathItem.path}> {pathItem.component} </Link>);
      /** Some Items have specific instances to link */
      if (pathItem.instanceComponent) {
        pathComponents.push(<p className='path-bar__text__symbol'>{':'}</p>)
        pathComponents.push(<Link to={pathItem.instancePath}> ({pathItem.instanceComponent}) </Link>)
      }
      /** Add Delimiter */
      if (i < pathList.length - 1) {
        pathComponents.push(<p className='path-bar__text__symbol'>{'>'}</p>)
      }
    }

    return pathComponents;
  }

  /** Component construction */
  return (
    // <div className='path-bar'>
    <div>
      <button className="back-button" onClick={goBack}><img src={Back} className="back"/></button>
      {/* <p className= "path-bar__title">
        &nbsp;&nbsp;You are in:&nbsp;
      </p> */}
      {/* {props.pathList.map(
        (pathItem) =>
          <p className='path-bar__item' key={pathItem.name}>
            pathItem.name
            {pathItem.instance ? `:  (${pathItem.instance}) > ` : ' > '}
          </p>
      )}
      {props.debug && <p>{match.path}</p>}  */}
    </div>
  )
}
import React from "react";
import Add from '../../images/icons/add-icon.png'
import DefaultIcon from '../../images/icons/report-example-icon.png'
import './monitoring-gallery.css'
import { Link } from "react-router-dom";

/**
 * 
 * @param {boolean}   createCard  Boolean to either include or exclude a create new card
 * @param {array}     items       An array of item information for each monitoring item
 * @param {function}  openPopup   Function to open a popup when clicking the create new card
 */
export function MonitoringCardGallery(props) {
  return (
    <div className="monitoring-gallery">
      {
        props.items.map((item) => {
          return(
            <MonitoringCard
              content={item.image ? <img src={item.image}></img> : <img src={DefaultIcon}></img>}
              text={item.text}
              link={item.link}
            />
          )
        })
      }

      {
        props.createCard ?
        <MonitoringCard
          content={
            <button className="new-report-btn" onClick={props.openPopup}>
              <img src={Add}></img>
            </button>
          }
          text={"Create New Report"}
        /> : null
      }
    </div>
  )
}

/**
 * 
 * @param {JSX}     content JSX element to be placed in the body
 * @param {string}  text    String for main text label
 * @param {string}  link    Link to report if applicable  
 */
export function MonitoringCard(props) {
  return (
    props.link ?
    <div className="outer-card">
      <Link to={props.link} style={{textDecoration: 'none'}}>
        <div className="inner-card">
          {props.content}
        </div>
        <div className="bottom-text">
          {props.text}
        </div>
      </Link>
    </div>
    :
    <div className="outer-card">
      <div className="inner-card">
        {props.content}
      </div>
      <div className="bottom-text">
        {props.text}
      </div>
    </div>
  )
}

/**
 * 
 * @param {JSX}     content JSX element to be placed in the body
 * @param {string}  text    String for main text label
 */
 export function PopupMonitoringCard(props) {
  return (
    <div className="outer-card-popup">
      <div className="inner-card">
        {props.content}
      </div>
      <div className="bottom-text-popup">
        {props.text}
      </div>
    </div>
  )
}
// taken from https://www.codemzy.com/blog/react-drag-drop-file-upload
import React, { useRef, useState } from "react";
import "./drag-and-drop.css";
import FileIcon from "../../images/file-icon.png";
import QuestionMarkIcon from "../../images/question-mark-icon.png";

// drag drop file component

/** NOTE THAT THE SURROUNDING ELEMENT IS A DIV INSTEAD OF THE FORM. THIS IS BECAUSE THE DRAG AND DROP
 * FUNCTIONALITY IS USUALLY PART OF A DIFFERENT FORM AND IT'S BAD PRACTICE TO HAVE FORMS WITHIN FORMS.
 * - GURAMRIT BAMRAH
 */

function DragDropFile(props) {
  const { handleFiles, fileName, buttonText } = props;
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <div
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        onChange={handleChange}
        multiple={false}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        <div className="text-icon-container">
          <img className="file-icon" src={FileIcon} />
          {fileName ? (
            <p>{fileName}</p>
          ) : (
            <>
              <span>drag your file here to upload</span>
              <span> or</span>
            </>
          )}
          <button
            type="button"
            className="upload-button"
            onClick={onButtonClick}
          >
            {buttonText}
          </button>
        </div>
        <img className="question-mark" src={QuestionMarkIcon} />
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </div>
  );
}

export default DragDropFile;

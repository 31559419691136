import React, {useState, useEffect} from 'react';
import LeftButton from '../../images/icons/simple-back-icon.png'
import RightButton from '../../images/icons/simple-forward-icon.png'
import { MonitoringCardGallery } from './monitoring-gallery';
import { ObservationCardGallery } from './observation-gallery';
import './payment-request-detail-tabs.css'
import Add from '../../images/icons/add-icon.png'
import { NewReportPopup } from './new-report-popup';


/**
 * Body card for displaying landowner information in a payment request
 * 
 * @param {Object} currPaymentRequest current payment request object
 */
export function LandownerDetails(props) {
  return (
    <div className="tab-detail-body">
      <div className="tab-heading">
        <div className="initial-circle">
          <p>{props.currPaymentRequest !== null ? props.currPaymentRequest.landowner.name.split(" ").map((n)=>n[0]).join("") : null}</p>
        </div>
        <h1>
          {props.currPaymentRequest !== null ? props.currPaymentRequest.landowner.name : null}
        </h1>
      </div>
      <div className="tab-info-body">
        <p>
          <b>Email:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.landowner.email : null}
        </p>
        <p>
          <b>Organization:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.landowner.organization : null}
        </p>
        <p>
          <b>Address:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.landowner.address : null}
        </p>
        <p>
          <b>Note from John Doe:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.landowner_note : null}
        </p>
      </div>
    </div>
  )
}

/**
 * Body card for displaying property information in a payment request
 * 
 * @param {Object}    currPaymentRequest current payment request object
 * @param {number}    currTabPageIndex   stateful number value representing the page number within the property details group
 * @param {function}  changeTabPageIndex function to change currTabPageIndex
 */
export function PropertyDetails(props) {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  }

  useEffect(() => {
    props.changeTabPageIndex(0);
    setShowPopup(false)
  }, [])

  const moveLeft = () => {
    const newIndex = props.currTabPageIndex - 1
    props.changeTabPageIndex(newIndex);
    setShowPopup(false);
  }

  const moveRight = () => {
    const newIndex = props.currTabPageIndex + 1
    props.changeTabPageIndex(newIndex);
    setShowPopup(false);
  }

  return (
    <div className="tab-detail-body">
      <div className="tab-heading">
        <h1>
          {props.currPaymentRequest !== null ? props.currPaymentRequest.landowner.organization : null}
        </h1>
      </div>
      <div className='tab-info-body-wrapper'>
        {props.currTabPageIndex > 0 ?
          <button className="left-btn" onClick={moveLeft}><img src={LeftButton}></img></button> : null
        }

        {/* General Information */}
        {props.currTabPageIndex == 0 ?
          <div className="tab-info-body">
            <p>
              <b>Address:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.landowner.email : null}
            </p> 
            <p>
              <b>Longitude, Latitude:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.property.longitude + ", " + props.currPaymentRequest.property.latitude : null}
            </p>
            <p>
              <b>Perimeter (in meters):</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.property.perimeter : null}
            </p>
            <p>
              <b>Hectares (Acres):</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.property.hectares + " (" + Math.round(2.47105381 * props.currPaymentRequest.property.hectares * 100) / 100 + ")" : null}
            </p>
            <p>
              <b>Characteristics of property:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.property.chars.join(", "): null}
            </p>
            <p>
              <b>Type of preservation requested:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.property.request: null}
            </p>
          </div> : null
        }

        {/* Landowner Observations */}
        {props.currTabPageIndex == 1 ?
          <div className="landowner-observations-body">
            <ObservationCardGallery
              items={props.currPaymentRequest.request.observations}
            />
            {/* {props.currPaymentRequest.request.observations.map((image) => ((
              <ObservationCard image={image} />
            )))}

            <ObservationCard/>
            <ObservationCard/> */}
          </div> : null
        }

        {/* Monitoring Information */}
        {props.currTabPageIndex == 2 ?
          <div className="monitoring-info-body">
            {/* You can only add monitoring info before accepting the request
            {props.currPaymentRequest.status === "incoming" ?
              <MonitoringCard
                text={"Create New Report"}
                content={createNewReportBtn}
              /> : null
            } */}
            <MonitoringCardGallery
              createCard={props.currPaymentRequest.status === "incoming"}
              openPopup={openPopup}
              items={props.currPaymentRequest.request.monitoring_items}
            />
          </div> : null
        }
        <NewReportPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          propertyName={props.currPaymentRequest.owner.organization}
        />
        
        {props.currTabPageIndex < 2 ?
          <button onClick={moveRight} className="right-btn"><img src={RightButton}></img></button> : null
        }
      </div>
      <TabPageIndicator
        currTabPage={props.tabPages[props.currTabPageIndex]}
        tabs={props.tabPages}
      />
    </div>
  )
}

/**
 * Body card for displaying payment information in a payment request
 * 
 * @param {Object}    currPaymentRequest current payment request object
 * @param {number}    currTabPageIndex   stateful number value representing the page number within the payment details group
 * @param {function}  changeTabPageIndex function to change currTabPageIndex
 */
export function RequestDetails(props) {
  useEffect(() => {
    props.changeTabPageIndex(0);
  }, [])

  const moveLeft = () => {
    const newIndex = props.currTabPageIndex - 1
    props.changeTabPageIndex(newIndex);
  }

  const moveRight = () => {
    const newIndex = props.currTabPageIndex + 1
    props.changeTabPageIndex(newIndex);
  }

  return (
    <div className="tab-detail-body">
      <div className="tab-heading">
        <h1>
          Invoice Details
        </h1>
      </div>
      <div className="tab-info-body-wrapper">
        
        {props.currTabPageIndex > 0 ?
          <button className="left-btn" onClick={moveLeft}><img src={LeftButton}></img></button> : null
        }

        {/* Invoice Information */}
        {props.currTabPageIndex == 0 ?
          <div className="tab-info-body">
            <p>
              To be filled with proper payment information later
            </p>
            {/* <p>
              <b>Transaction ID:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.request.id : null}
            </p>
            <p>
              <b>Account Number:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.request.account_num : null}
            </p>
            <p>
              <b>VAT invoice number:</b> {props.currPaymentRequest !== null ? props.currPaymentRequest.request.invoice_num : null}
            </p> */}
          </div> : null
        }

        {/* Payment Calculation */}
        {props.currTabPageIndex == 1 ?
          <div className="tab-info-body">
            <p>
              Add payment calculation later
            </p>
          </div> : null
        }

        {props.currTabPageIndex < 1 ?
          <button onClick={moveRight} className="right-btn"><img src={RightButton}></img></button> : null
        }
      </div>
      <TabPageIndicator
        currTabPage={props.tabPages[props.currTabPageIndex]}
        tabs={props.tabPages}
      />
    </div>
  )
}

/**
 * Text indicator for tab pages
 * @param {number} currTabPage stateful number
 */
function TabPageIndicator(props) {

  let tabElements = []
  props.tabs.map((tab, i) => {
    if (tab == props.currTabPage) {
      tabElements.push(<p><b>{tab}</b></p>)
    }
    else {
      tabElements.push(<p>{tab}</p>)
    }
    if (i < props.tabs.length - 1) {
      tabElements.push(<b> • </b>)
    }
  })
  
  return(
    <div className="tab-page-footer">
      {tabElements.map((e) => {
        return e;
      })}
    </div>
  )
}
export default function Characteristics(props) {
  // const { header, statistic } = props;
  const { characteristics } = props;
  // console.log("got into characteristics");
  return characteristics.map((e) => {
    return (
      <div className="project-characteristic-child">
        <h4>{e.header}</h4>
        <p className="project-characteristic-info">{e.statistic}</p>
      </div>
    );
  });
}

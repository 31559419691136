import React, { useEffect, useState } from 'react';
import {useParams, Redirect} from "react-router-dom";
import {getNDVIDetails, getNDVISatelliteImage, getNDVIMapOutputImage} from '../../apis/landsteward/accounts';
import {TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {getNDVIReport} from '../../apis/landsteward/accounts';

import './ndvi-display.css'


import MaryCreek from '../../images/sample_region_image.png';
/**
 * 
 * @param {*} props
 *  <searchState>
 * @returns 
 */
export default function NDVIDisplay(props) {
    let params = useParams();
    let region = props.searchState('regions', 'regionID', params.regionID);
    let project = props.searchState('projects', 'projectID', params.projectID);
    let report = props.searchState('reports', 'reportID', params.ndviID);
    let ndviID = params.ndviID

    let [ndviLegendClosed, setndviLegendClosed] = useState(true);
    let [legendDesc, setLegendDesc] = useState(2);
    const maxLegendDesc = 2;
    const [reportInfo, setReportInfo] = useState({});
    const [ndviLegend, setNdviLegend] = useState([]);
    const [satelliteImage, setSatelliteImage] = useState(null);
    const [ndviImage, setNdviImage] = useState(null);

    function openNdviLegend() {
        setndviLegendClosed(false);
    }
      
    function closeNdviLegend() {
        setndviLegendClosed(true);
    }

    useEffect(() => {
        
        getNDVIReport(ndviID).then(
            
            response => {
                
                setReportInfo(response)
                
                setSatelliteImage(response.satellite_image_url)
                
                setNdviImage(response.image)
                
                console.log(response.colour_legend)
                setNdviLegend(JSON.parse(response.colour_legend.replace(/'/g, '"')))
               
                
            }
        );
    }, [])

    function switchLegendDesc() {
        if (legendDesc + 1> maxLegendDesc){
            setLegendDesc(1);
        } else {
            setLegendDesc(legendDesc + 1);
        }
    }
    function vigorLegend(index) {
        if (index === 0){
            return 'Most Vigor';
        } else if (index >= ndviLegend.length - 1){
            return 'Least Vigor'
        } else {
            return '';
        }
        
    }

    function gradientLegend() {
        let gradientcss = 'linear-gradient(0deg';
        let step = 100/(ndviLegend.length-1);
        for (var i = 0; i < ndviLegend.length; i++) {
            gradientcss += ', ' + colourNdviLegend(i) + ' ' + (i*step>100?100:i*step) + '%';
        }
        gradientcss += ')';
        return gradientcss;
    }

    const nl = [
        {
            "classType": "<0.0",
            "rgb": [0,0,0]
        },{
            "classType": "0.0-0.1",
            "rgb": [128,128,128]
        },{
            "classType": "0.1-0.2",
            "rgb": [159.65625,111.984375,96.09375]
        },{
            "classType": "0.2-0.3",
            "rgb": [191.375,127.75,64.125]
        },{
            "classType": "0.3-0.4",
            "rgb": [223.15625,175.39062499999997,32.09375]
        },{
            "classType": "0.4-0.5",
            "rgb": [255,254.99999999999994,0]
        },{
            "classType": "0.5-0.6",
            "rgb": [255,254.99999999999994,0]
        },{
            "classType": "0.6-0.7",
            "rgb": [167.4375,223.25,0]
        },{
            "classType": "0.7-0.8",
            "rgb": [95.75000000000004,191.5,0]
        },
        {
            "classType": "0.8-0.9",
            "rgb": [39.937499999999964,159.75,0]
        },{
            "classType": "0.9-1.0",
            "rgb": [0,128,4.263256414560601e-14]
        }
    ];


    

    

    function colourNdviLegend(idx) {
        if (ndviLegend) {
            for (var i = 0; i < ndviLegend.length; i++) {
                if (idx === i) { 
                    //console.log(rgbToHex(ndviLegend[i].rgb[0], ndviLegend[i].rgb[1], ndviLegend[i].rgb[2]));
                    return 'rgb('+ ndviLegend[i].rgb[0]+','+ ndviLegend[i].rgb[1]+','+ ndviLegend[i].rgb[2]+')';
                }
            }
        }
        return "red";
    }

  // if (region === null || report === null || project === null) {
   //     return (<Redirect to='/project'/>)
   // }
    return (
        <div className='report-display-page'>
            <div className='report-display-page__title'>
                <h1>Vegetation Report</h1>
            </div>

            <div className="report-display-page__project__details">
                <p><span className="name">Project Name:</span> {project.name}</p>
                <p><span className="name">Region:</span> {''}</p>
                <p><span className="name">Report Name:</span> {reportInfo.name}</p>
            </div>

            <hr className="line"/>
            
            <div className="ndvi-report">
                <h2>Satellite Image</h2>
                <figure className="ndvi-report__satellite__details__image">
                    <TransformWrapper>
                        <TransformComponent>
                            <img src={satelliteImage ? satelliteImage : MaryCreek}/>
                        </TransformComponent>
                    </TransformWrapper>
                    <figcaption><span className="name">Description:</span> Summary of vegetation. <br/>
                    <span className="name">Source:</span> Copernicus  Sentinel-2 Level 2A </figcaption>
                </figure>
                
                <div className="ndvi-report__satellite__details__image">
                    <h4>Image Details:</h4>
                    <p><span className="name">Spatial Resolution:</span> 10m </p>
                    <p><span className="name">Band:</span> True Colour</p>
                    <p><span className="name">Date Period:</span> May 2020 - June 2020</p>
                </div>

                <hr className="line"/>
                
                <div className="ndvi-report__ndvi">
                    <h2>Vegetation Image</h2>
                    <figure className="ndvi-report__ndvi__details__image">
                        <div className="open-legend">
                            <button style={{visibility: !ndviLegendClosed ? 'hidden' : 'visible' }} onClick={e => openNdviLegend(e)}>Legend</button>
                        </div>

                        <TransformWrapper>
                            <TransformComponent>
                                <img src={ndviImage ? ndviImage : MaryCreek }/>
                            </TransformComponent>
                        </TransformWrapper>
                        
                        <figcaption><span className="name">Description:</span> This map shows an vegetation index (based on NDVI) with green showing higher levels of vegetation. <br/>
                            
                        </figcaption>
                    </figure>
 
                    <div className="ndvi-report__lulc__details" style={{display: ndviLegendClosed ? 'none' : 'block'}}>
                        <button className="close-btn" onClick={e => closeNdviLegend(e)}>&times;</button> 
                        <h4>Legend:</h4>
                        <div className="legend-scale" style={{display: (legendDesc!==2) ? 'none' : 'block'}}>
                            <div className="legend-subcontainer">
                                <div className="legend-gradient" style={{background: gradientLegend()}}/>
                                <ul className="legend-labels" style={{paddingInlineStart:5}}>
                                    {ndviLegend?.map((value, index) => (
                                    //<li> <span style={{backgroundColor: colourNdviLegend(index)}}></span> {value.classType} </li>
                                    <li>{vigorLegend(index)} </li> 
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="legend-scale" style={{display: (legendDesc!==1) ? 'none' : 'block'}}>
                            <div className="legend-subcontainer">
                                <ul className="legend-labels" style={{padding:0}}>
                                    {ndviLegend?.map((value, index) => (
                                    /*<li> <span style={{backgroundColor: colourNdviLegend(index)}}></span> {value.classType} </li>*/ 
                                    <li> <span style={{backgroundColor: colourNdviLegend(ndviLegend.length - 1 - index)}}></span> {ndviLegend[ndviLegend.length - 1 - index].classType} </li> 
                                    ))}
                                </ul>
                            </div>
                        </div>
                        
                        <button className="legend-desc-switch-btn" style={{color: (legendDesc === 1)?'#c4c4c4':'#3d3d3d'}} onClick={e => switchLegendDesc(e)}>Detail</button>
                        

                    </div>
                </div>
            </div> 
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

/** ##### Components ##### */
import { Dropdown } from '../components/modules/satellite-dropdown';
import { ImageContainer } from '../components/modules/image-container';

/** ##### Visual Assets ##### */
import './land-cover-create-page.css';
import MaryCreek from '../images/sample_region_image.png';

/** ##### API ##### */
import { createLandCover } from '../apis/landsteward/valuation';
import { getProjectSatelliteImages } from '../apis/landsteward/accounts';

/**
 *
 * @param {*} props
 *    <project>: current project
 *    <region>: current region
 *    <satelliteImages>: satellite Images of the current project
 *    <addLandCover>: function which adds the LULC to the web state
 *    <changeCurrentState>: Function to set the website's current state.
 */
export function LandCoverCreationPage(props) {
  /** ##### Variables ##### */
  let params = useParams();
  let [satelliteImage, setSatelliteImage] = useState(null); 
  let [satelliteImages, setSatelliteImages] = useState(null);
  let [landCoverName, setLandCoverName] = useState('');
  let [landCoverType, setLandCoverType] = useState('With Buildings');
  let landCoverTypes = [
    {
      title: 'With Building Footprints',
      source: MaryCreek,
      click: () => setLandCoverType('With Buildings'),
    },
    {
      title: 'Without Building Footprints',
      source: MaryCreek,
      click: () => setLandCoverType('Without Buildings'),
    },
  ];
  const fetchSatelliteImages = async () => {
    const satelliteImageData = await getProjectSatelliteImages(params.projectID)
    setSatelliteImages(satelliteImageData);
  }


  useEffect(() => {
    fetchSatelliteImages()
   
    

    //clean state on unmount, use to avoid warning
    return () => setSatelliteImages({});
  }, []);
  /* ##### Initial Paramater Checks and Satellite Initialization ##### */
  /* Initial Selection of the Satellite Image */
  useEffect(() => {
    if (satelliteImages && satelliteImages.length > 0) {
      setSatelliteImage(satelliteImages[0]);
    }
  }, [satelliteImages]);
  
  /* Change Satellite on Land Cover Page given dropdown selection */
  async function changeSatelliteImage(reportID) {
    for (let i = 0; i < satelliteImages.length; i++) {
      if (satelliteImages[i].reportID === reportID) {
        setSatelliteImage(satelliteImages[i]);
        i = satelliteImages.length;
      }
    }
  }

  /* ##### Creation of LULC Reports ##### */
  async function triggerLandCoverCreation() {
    //value checks not currently on
    let valid = props.project && props.region && satelliteImage;
    
    if (true) {
      let landCoverData = await createLandCover(
        props.project.projectID,
        params.propertyID,
        satelliteImage.reportID,
        landCoverName,
        landCoverType === 'With Buildings'
      );
      if (landCoverData !== null) {
        landCoverData.name = landCoverName;
        landCoverData.reportType = 'lulc';
        props.addLandCover(landCoverData);
      } else {
        console.error('Land Cover Creation Failed');
      }
    }
  }

  /* ##### URL Parameters ##### */
  let [projectFailed, setProjectFailed] = useState(false);
  /* Updates the current state using URL parameters */
  useEffect(async () => {
    let isCorrectState = await props.changeCurrentState({
      projectID: params.projectID,
    });
    if (!isCorrectState.project) {
      console.error('Failing Projects', isCorrectState);
      setProjectFailed(true);
    }
  }, []);
  if (projectFailed) {
    return <Redirect to="/project" />;
  } else {
    /* ##### Render ##### */
    return (
      <div className="land-cover-creation">
        <div className="lulc-creation__title">
          <h1>Create New Land Use Land Cover Report</h1>
        </div>

        {/* Headings + Land Cover Name */}
        <p>
          <span className="name">Project:</span>{' '}
          {props.project ? props.project.name : ''}
        </p>
        <p>
          <span className="name">Region:</span>{' '}
          {props.region ? props.region.name : ''}
        </p>

        <label
          htmlFor="land-cover-creation-name"
          className="land-cover-creation__name-label"
        >
          <span className="name">Land Cover Name:</span>
        </label>
        <input
          type="text"
          name="land-cover-creation-name"
          value={landCoverName}
          onChange={(event) => setLandCoverName(event.target.value)}
        />

        <br />

        <p className="land-cover-creation__subtitle">
          <span className="name"> Select Land Cover Version: </span>
        </p>
        <div className="land-cover-creation__types">
          <div className="landcover-buttons">
            <div onClick={() => setLandCoverType('With Buildings')}>
              <button
                style={{
                  backgroundColor:
                    landCoverType === 'With Buildings'
                      ? 'rgba(148, 148, 148, 0.19)'
                      : 'rgba(210, 210, 210, 0.19)',
                }}
              >
                {'With Buildings'}
              </button>
            </div>

            <div onClick={() => setLandCoverType('Without Buildings')}>
              <button
                style={{
                  backgroundColor:
                    landCoverType === 'Without Buildings'
                      ? 'rgba(148, 148, 148, 0.19)'
                      : 'rgba(210, 210, 210, 0.19)',
                }}
              >
                {'Without Buildings'}
              </button>
            </div>
          </div>
        </div>

        <br />

        <div className="image-selection">
          {/* Satellite Selection Image + Dropdown */}
          <p className="land-cover-creation__subtitle">
            <span className="name"> Satellite Image:</span>
          </p>
          <Dropdown
            name="select-satellite-dropdown"
            value={satelliteImage ? satelliteImage.reportID : ''}
            setValue={(reportID) => changeSatelliteImage(reportID)}
            valueList={satelliteImages ? satelliteImages : []}
            valueProperty="reportID"
            nameProperty="name"
          />
        </div>

        <ImageContainer
          imageSource={satelliteImage ? satelliteImage.png_image : MaryCreek}
          className="land-cover-creation__satellite-image"
          title={satelliteImage ? satelliteImage.name : 'Satellite Image:'}
        />

        <cite className="land-cover-create__note">
          {' '}
          Note: Building footprints applied to landcovers are buildings from the
          year 2019{' '}
        </cite>

        <div className="report-new-button">
          <button onClick={triggerLandCoverCreation}>
            {' '}
            Create Report {landCoverType}{' '}
          </button>
        </div>
      </div>
    );
  }
}

import React from 'react';

export class ReportList extends React.Component {
  constructor(props){
    super(props);

    /** PROPS
     *  changeCurrReport
     */

    this.changeReport = this.changeReport.bind(this)
  }


  async changeReport(event) {
    event.preventDefault();
    
    this.props.changeCurrReport(event.target.value)
  }

  render() {
    return (
      <form className='selection_form    report_selection_form'>
        <h4 className='report_selection_empty_header'> Currently, there are no reports for this project</h4>
        <h4 className='report_selection_no_project'> You have not selected an existing project </h4>
        <select className='project_selection_dropdown' value={this.props.currReportID}
          onChange={this.changeReport}>
          {this.props.reportList.map(
            (report) => <option key={report.reportID} value={report.reportID}> {report.name}</option>
          )}
        </select>

        {/*this.props.children*/}
      </form>
    );
  }
}
import Select, { components } from "react-select";
import React, { useEffect, useState } from "react";
import { ReactComponent as DropdownArrow } from "../../images/dropdownIndicator.svg";

/**
 * @param {*} this.props
 *    <value>: current value of the dropdown, set to '' if not selected
 *    <setValue>: function passed down to modify <value> state in ancestor
 *      component. Takes in a single new value to replace the old with
 *    <removeValue>: function passed down to set the action when clicking the Clear icon
 *    <disabledItem>: the item that should be filtered out from the option list
 *    <objectList>: a list of objects for options, which will be transfered to a standard option list here
 *    <valueProperty>: the string name of the 'value' property
 *    <labelProperty>: the string name of the 'label' property
 *    <width>: width of the dropdown
 */

export function DropdownForest(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState("Select");

  const IMAGE_DATE_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/;

  // dropdown styling (namely the width)
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: props.width,
    }),
    control: (provided) => ({
      ...provided,
      width: props.width,
    }),
    menu: (provided) => ({
      ...provided,
      width: props.width,
      zIndex: 10,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#4D4D4D",
    }),
  };


  // converts the list of satellite images to a list of objects that can be used by the Select component
  useEffect(() => {
    if (props.objectList !== null) {
      let labelProperty = props.labelProperty;
      let valueProperty = props.valueProperty;

      // filter out null images
      let filteredList = props.objectList.filter((obj) => {
        return obj !== null && obj !== props.disabledItem;
      });
      // sort list alphabetically
      filteredList.sort((a, b) => (a[labelProperty] > b[labelProperty] ? 1 : -1));
  
      const options = filteredList.map((obj) => ({
        label: obj[labelProperty]
                ? IMAGE_DATE_REGEX.test(obj[labelProperty])
                  ? obj[labelProperty].substring(0, 10)
                  : obj[labelProperty]
                : "N/A",
        value: obj[valueProperty],
      }));

      setOptions(options);
    }

    return () => setOptions(null);
  }, [props.objectList, props.disabledItem]);

  useEffect(() => {
    if (props.value !== "") {
      const option = options.find((obj) => obj.value === props.value);
      setSelectedOption(option);

      let inputVal = option?.label || "Select";
    
      if (IMAGE_DATE_REGEX.test(inputVal)) {
        inputVal = inputVal.substring(0, 10);
      };
      setPlaceholder(inputVal);
      // console.log("selected option updated: ", option);
      // console.log("inputVal updated: ", inputVal);
    }

  }, [props]);

  const handleSelectChange = (option) => {
    if (option != null) {
      setSelectedOption(option);
      props.setValue(option.value);
    } else {
      setSelectedOption(null);
      setPlaceholder("Select");
      props.removeValue();
    }
  };


  return (
    <Select
      value={selectedOption}
      className="basic-single"
      classNamePrefix="select"
      styles={customStyles}
      isSearchable={true}
      isClearable={true}
      options={options}
      placeholder={placeholder}
      components={{ DropdownIndicator }}
      onChange={handleSelectChange}
    />
  );
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow />
    </components.DropdownIndicator>
  );
};


import React, { useState, useEffect, useRef } from "react";
import { getFieldObservationDetailMock } from "../../apis/landsteward/mock_accounts";
import { getFieldObservationDetail } from "../../apis/landsteward/accounts";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MOCK_DATA, url, CORE_PORT } from "../../apis/landsteward/urlconfig";

import ArrowLeft from "../../images/icons/arrow-icon-left.png";
import "./field-detail-display-card.css";

export function FieldObservationDisplayCard(props) {
  let [getReportFail, setGetReportFail] = useState(false);
  let [fieldObservation, setFieldObservation] = useState();
  useEffect(async () => {
    let result = MOCK_DATA
      ? getFieldObservationDetailMock(props.reportID)
      : await getFieldObservationDetail(props.reportID);
    console.log(result);
    if (!result) {
      setGetReportFail(true);
    } else {
      setFieldObservation(result);
    }
  }, [props.reportID]);

  if (getReportFail) {
    return (
      <DetailDisplayCard title={"cannot access observation"}>
        <img
          className="display-card-back-btn"
          onClick={props.backBtnOnClick}
          src={ArrowLeft}
        />
      </DetailDisplayCard>
    );
  } else if (!fieldObservation) {
    return (
      <DetailDisplayCard title={"..."}>
        <img
          className="display-card-back-btn"
          onClick={props.backBtnOnClick}
          src={ArrowLeft}
        />
      </DetailDisplayCard>
    );
  }

  return (
    <>
      <DetailDisplayCard
        title={fieldObservation?.name}
        dateTime={fieldObservation?.creation_date}
      >
        <p>submitted by: {fieldObservation?.submitted_by}</p>
        <p>Headline: {fieldObservation?.headline}</p>
        <p>
          Location: {fieldObservation?.latitude}, {fieldObservation?.longitude}
        </p>
        {fieldObservation?.landowner_note && (
          <p>Landowner Notes: {fieldObservation?.landowner_note}</p>
        )}
        {fieldObservation?.landtrust_note && (
          <p>Landtrust Notes: {fieldObservation?.landtrust_note}</p>
        )}
        <img
          className="display-card-back-btn"
          onClick={props.backBtnOnClick}
          src={ArrowLeft}
        />
        <div className="observations-imgs">
          {fieldObservation?.images?.map((item) => (
            <>
              <TransformWrapper>
                <TransformComponent>
                  <img className="observation-img" src={item} />
                </TransformComponent>
              </TransformWrapper>
            </>
          ))}
        </div>
        <div className="observation-add-item">
          <p>click to add attachment</p>
        </div>
        <div className="observation-btns">
          <button className="big-primary-button">Contact Landowner</button>
          <button className="big-primary-button">Copy Location</button>
        </div>
      </DetailDisplayCard>
    </>
  );
}

export function DetailDisplayCard(props) {
  const d = new Date(props.dateTime);
  const formatedD = props.dateTime ? d.toDateString() : "";
  console.log(props);
  return (
    <>
      <div className="detail-display-card">
        <div className="detail-display-data">
          <h5 className="detail-display-card-timestamp">{formatedD}</h5>
          <h4>{props.title}</h4>
          {props.desc?.split("\n").map((item) => (
            <p>{item}</p>
          ))}
        </div>
        {props.children}
      </div>
    </>
  );
}

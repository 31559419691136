import React from "react";
import './observation-gallery.css'

/**
 * 
 * @param {array} items Array of image references to display 
 */
export function ObservationCardGallery(props) {
  const remainder = [];
  if (props.items.length < 3) {
    for (let i = 0; i < 3 - props.items.length; i++) {
      remainder.push("");
    }
  }

  return (
    <div className="observation-gallery">
      {props.items.map((image) => (
        <ObservationCard image={image}/>
      ))}
      {remainder.map((value, index) => (
        <ObservationCard/>
      ))}
    </div>
  )
}

/**
 * Card for displaying images of landowner observations for payment requests
 * @param {string} image Reference to the image to display on card
 */
export function ObservationCard(props) {
  return (
    <div className="background-card">
      {props.image ?
        <img src={props.image} className="observation-image"></img> : null
      }
    </div>
  )
}
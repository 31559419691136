import React, {useEffect, useState} from 'react';

import './payment-dashboard-home-page.css'
import PaymentDashboardFrame from './payment-dashboard-frame';
import {PaymentProgressBar} from '../components/modules/payment-progress-bar';
import {NotifItem, NotifCollection} from '../components/modules/payment-notifs';
import { PaymentStatus } from './payment-management';

import {getNotifs} from '../apis/landsteward/payments';
import { DEBUG } from '../apis/landsteward/urlconfig';

export default function PaymentDashboardHomePage(props) {

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    //setNotifications(formatNotifs(props.paymentNotifs)[2])
    /*
    if (true || (!DEBUG && !MOCK_DATA)){
      // if not using mockdata, API CALL
      getNotifs().then(
        response => {
            console.log(response)
            setNotifications(formatNotifs(response)[2])
        }
      );
    }*/
    
  }, [])


  function formatNotifs (notifs) {
    let readArray = [];
    let unreadArray = [];
    let array = [];
    if (notifs === null){
      return [[],[],[]]
    }
    let objCopy
    for (let i=0; i < notifs.length; i++) {
      objCopy = { ...notifs[i]};
      array.push(objCopy);
      if  (notifs[i]?.read){
        readArray.push(objCopy);
      } else{
        unreadArray.push(objCopy);
      }
    }
    //return array
    return [readArray, unreadArray, array]
  }

  let notifs = formatNotifs(props.paymentNotifs)[2];
  let body = (
    <div className='tab-body'>
      <h1>Notifications</h1>
      
      <PaymentProgressBar status={PaymentStatus.Incoming} />
      <PaymentProgressBar status={PaymentStatus.InProgress} />
      <PaymentProgressBar status={PaymentStatus.Accepted}  />
      <PaymentProgressBar status={PaymentStatus.Declined}  />
      <NotifCollection
        notifs = {notifs}
        changeNotifRead = {props.changeNotifRead}

        IDAttribute='id'
        titleAttribute='title'
        contentAttribute='content'
        dateTimeAttribute='dateTime'
        readAttribute='read'
        linkAttribute='link'
      />

      {/*<div style={{display:'flex', maxWidth: '200%'}}>
        <NotifCollection
          notifs = {notifs[0]}
          changeNotifRead = {props.changeNotifRead}

          IDAttribute='notifId'
          titleAttribute='title'
          contentAttribute='content'
          dateTimeAttribute='dateTime'
          readAttribute='read'
          
        />
        <NotifCollection
          notifs = {notifs[1]}
          changeNotifRead = {props.changeNotifRead}

          IDAttribute='notifId'
          titleAttribute='title'
          contentAttribute='content'
          dateTimeAttribute='dateTime'
          readAttribute='read'
          
        />

      </div>

       Insert unread notifs 
      
      <h2>Read</h2>
      <NotifCollection
        notifs = {notifs[0]}
        changeNotifRead = {props.changeNotifRead}

        IDAttribute='notifId'
        titleAttribute='title'
        contentAttribute='content'
        dateTimeAttribute='dateTime'
        readAttribute='read'
        
      />*/}
    </div>
  )

  return (
    <PaymentDashboardFrame
      body={body}
      current={0}
    />
  )
}
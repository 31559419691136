import React, { useEffect, useRef, useState } from 'react';

import { useParams, Redirect } from 'react-router';

import Dropdown from '../components/modules/dropdown';
import { ImageContainer } from '../components/modules/image-container';

import { getProjectSatelliteImages, getNDVIFromSatellite } from '../apis/landsteward/accounts';
import { createNDVI } from '../apis/landsteward/valuation';

import { MapboxCompareSlider } from '../components/mapbox/mapbox-compare';
import MaryCreek from '../images/sample_region_image.png';

import './manual-change-detection-page.css';
import remotesensingicon from '../images/remote-sensing.png';
import { MapboxRegionSelection } from '../components/mapbox/mapbox-region-selection';
import magnifyingglass from '../images/magnifying-glass-icon.png';
import './all-properties-page.css';

/**
 *
 * @param {*} props
 */
export default function AllPropertiesPage(props) {
  /** ##### URL Param Check ##### */
  let params = useParams();
  const [projectFailed, setProjectFailed] = useState(false);
  /** Satellite Images */
  const [loadingFeatures, setLoadingFeatures] = useState(true);
  const [satelliteImages, setSatelliteImages] = useState(null);
  const [leftSatImage, setLeftSatImage] = useState(null);
  const [rightSatImage, setRightSatImage] = useState(null);
  /** NDVI Report IDs*/
  const [ndviLeft, setNdviLeft] = useState(null);
  const [ndviRight, setNdviRight] = useState(null);
  const [oldNdviLeft, setOldNdviLeft] = useState('');
  const [oldNdviRight, setOldNdviRight] = useState('');
  /** Mapbox Reference to left and right maps */
  const beforeMap = useRef(null);
  const afterMap = useRef(null);
  /** MISC. Status Checks*/
  const [trueColour, setTrueColour] = useState(true);
  const [downloadStatus, setDownloadStatus] = useState('');
  const isMounted1 = useRef(false);
  const isMounted2 = useRef(false);
  let [navClosed, setNavClosed] = useState(true);
  let [navClosed2, setNavClosed2] = useState(true);


  /** 
   * Initialize Satellite images 
   * 
   * */
  const fetchSatelliteImages = async () => {
    const satelliteImageData = await getProjectSatelliteImages(params.projectID)
    setSatelliteImages(satelliteImageData);
  }

  useEffect(() => {
    fetchSatelliteImages()
    //clean state on unmount, use to avoid warning
    return () => setSatelliteImages({});
  }, []);
  function openNav() {
    setNavClosed(false);
  }
  function closeNav() {
    setNavClosed(true);
  }
  function openNav2() {
    setNavClosed2(false);
  }

  function closeNav2() {
    setNavClosed2(true);
  }

  /** 
   * Initialize Webstate of web application 
   * 
   * */
  useEffect(() => {
    props.changeCurrentState(params.projectID).then((isCorrectState) => {
      if (!isCorrectState.project) {
        console.error('Failing Projects', isCorrectState);
        setProjectFailed(true);
      }
    });
  }, []);

  /** 
   * Function that downloads the NDVI .
   * 
   * */
  async function triggerNdviCreation(lor) {
    setDownloadStatus('pending')
    let satelliteImage;
    if (lor === 'left')
      satelliteImage = leftSatImage
    else if (lor = 'right')
      satelliteImage = rightSatImage

    //edge case when both left and right sat images are the same.
    let download = true;
    if (leftSatImage == rightSatImage && leftSatImage != null && lor == 'right')
      download = false;

    if (download) {
      let ndviData = await createNDVI(
        params.projectID,
        params.propertyID,
        satelliteImage.reportID,
        satelliteImage.name + '_ndvi'
      );
      if (ndviData !== null) {
        console.log('ndvi creation success');
        setDownloadStatus('success');
      } else {
        console.error('NDVI Creation Failed');
        setDownloadStatus('failed');
      }
    }
  }

  /** 
   * NDVI/TRUE COLOUR toggle handler
   *  
   */
  useEffect(() => {
    if (trueColour) {
      //remove ndvi map layers if they exists
      if (beforeMap.current.getLayer(oldNdviLeft)) {
        beforeMap.current.removeLayer(oldNdviLeft);
        beforeMap.current.removeSource(oldNdviLeft);
      }
      if (afterMap.current.getLayer(oldNdviRight)) {
        afterMap.current.removeLayer(oldNdviRight);
        afterMap.current.removeSource(oldNdviRight);
      }
    }

    if (!trueColour) {
      //attempt to fetch NDVI ID if Satellite Image already selected
      if (leftSatImage) {
        fetchNDVI(leftSatImage.reportID, 'left')
      }
      if (rightSatImage) {
        fetchNDVI(rightSatImage.reportID, 'right')
      }
    }
  }, [trueColour])

  /** 
   * Fetches NDVI ID  
   * 
   * */
  const fetchNDVI = async (reportID, lor) => {
    //attempt to fetch
    const NDVIReportID = await getNDVIFromSatellite(reportID);
    console.log(NDVIReportID);
    //fetch success
    if (NDVIReportID != null) {
      //sets either left or right map
      if (lor === 'left') {
        if (ndviLeft != NDVIReportID.reportID)
          setNdviLeft(NDVIReportID.reportID)
        else
          changeLeftNDVI()
      } else if (lor === 'right') {
        if (ndviRight != NDVIReportID.reportID)
          setNdviRight(NDVIReportID.reportID)
        else
          changeRightNDVI()
      }
    } else {
      //fetch failed
      console.log('reportID is null. Generating NDVI.');
      triggerNdviCreation(lor)
    }
  }
  /** 
   * Callback for setNDVI 
   * 
   * */
  useEffect(() => {
    //isMounted prevents code from running on first render
    if (isMounted1.current) {
      changeLeftNDVI()
    } else {
      isMounted1.current = true
    }
  }, [ndviLeft])

  useEffect(() => {
    if (isMounted2.current) {
      changeRightNDVI()
    } else {
      isMounted2.current = true;
    }
  }, [ndviRight])

  /**
   *  Adds NDVI image layer to map
   * 
   * */
  const changeLeftNDVI = () => {
    let newSatID = ndviLeft.replace(/-/g, '');
    //Remove old layer if exists
    if (beforeMap.current.getLayer(oldNdviLeft)) {
      beforeMap.current.removeLayer(oldNdviLeft);
      beforeMap.current.removeSource(oldNdviLeft);
    }
    //Add the new layer
    beforeMap.current.addSource(newSatID, {
      type: 'raster',
      url: 'mapbox://korotu-conroy-trinh.' + newSatID,
    });
    beforeMap.current.addLayer({
      id: newSatID,
      source: newSatID,
      type: 'raster',
    });

    setOldNdviLeft(newSatID)
  }

  const changeRightNDVI = () => {
    let newSatID = ndviRight.replace(/-/g, '');

    if (afterMap.current.getLayer(oldNdviRight)) {
      afterMap.current.removeLayer(oldNdviRight);
      afterMap.current.removeSource(oldNdviRight);
    }

    afterMap.current.addSource(newSatID, {
      type: 'raster',
      url: 'mapbox://korotu-conroy-trinh.' + newSatID,
    });
    afterMap.current.addLayer({
      id: newSatID,
      source: newSatID,
      type: 'raster',
    });

    setOldNdviRight(newSatID)
  }

  /** Set dropdown values */
  const changeLeftSat = (reportID) => {
    if (!satelliteImages) {
      console.error(
        'Satellite Images Do existing in manual change detection'
      );
      return null;
    }
    for (let i = 0; i < satelliteImages.length; i++) {
      if (satelliteImages[i].reportID === reportID) {

        /** New Satellite Image ID */
        let newSatID;
        newSatID = satelliteImages[i].reportID.replace(
          /-/g,
          ''
        );
        /** Previous Satellite Image ID */
        console.log('Left Satellite Image', leftSatImage);
        let oldSatID = leftSatImage
          ? leftSatImage.reportID.replace(/-/g, '')
          : '';
        /** Remove Mapbox source and layer for previous satellite image */
        if (oldSatID != '') {
          beforeMap.current.removeLayer(oldSatID);
          beforeMap.current.removeSource(oldSatID);
        }
        /** Add Mapbox source and layer for next satellite image */
        beforeMap.current.addSource(newSatID, {
          type: 'raster',
          url: 'mapbox://korotu-conroy-trinh.' + newSatID,
        });
        beforeMap.current.addLayer({
          id: newSatID,
          source: newSatID,
          type: 'raster',
        });

        setLeftSatImage(satelliteImages[i]);
        i = satelliteImages.length;
      }
    }
  }
  /**
   * This makes sure NDVI is fetched AFTER a satellite reportID is provided
   * 
   */
  useEffect(() => {
    //if ndvi then layer this on top
    if (!trueColour) {
      fetchNDVI(leftSatImage.reportID, 'left')
    }
  }, [leftSatImage])

  const changeRightSat = (reportID) => {
    if (!satelliteImages) {
      console.error(
        'Satellite Images Do existing in manual change detection'
      );
      return null;
    }
    for (let i = 0; i < satelliteImages.length; i++) {
      if (satelliteImages[i].reportID === reportID) {
        /** New Satellite Image ID */
        let newSatID = satelliteImages[i].reportID.replace(
          /-/g,
          ''
        );
        /** Previous Satellite Image ID */
        console.log('Right Satellite Image', rightSatImage);
        let oldSatID = rightSatImage
          ? rightSatImage.reportID.replace(/-/g, '')
          : '';

        /** Remove Mapbox source and layer for previous satellite image */
        if (oldSatID != '') {
          afterMap.current.removeLayer(oldSatID);
          afterMap.current.removeSource(oldSatID);
        }

        /** Add Mapbox source and layer for next satellite image */
        afterMap.current.addSource(newSatID, {
          type: 'raster',
          url: 'mapbox://korotu-conroy-trinh.' + newSatID,
        });
        afterMap.current.addLayer({
          id: newSatID,
          source: newSatID,
          type: 'raster',
        });

        setRightSatImage(satelliteImages[i]);
        i = satelliteImages.length;
      }
    }
  }
  useEffect(() => {
    //if ndvi then layer this on top
    if (!trueColour) {
      fetchNDVI(rightSatImage.reportID, 'right')
    }
  }, [rightSatImage])

  /** checking valid state */
  if (projectFailed) {
    return <Redirect to="/project" />;
  }

  return (
    <div className="manual-change-detection-page">



      <div className="manual-change-detection__selection">
        <div className="manual-change-detection__left-select">


          <Dropdown
            name="manual-change-detection__left-image-select"
            value={leftSatImage ? leftSatImage.reportID : ''}
            setValue={changeLeftSat}
            valueList={satelliteImages ? satelliteImages : []}
            valueProperty="reportID"
            nameProperty="name"
          />
        </div>

        {/* <ImageContainer
        className='manual-change-detection__left-image'
        imageSource={leftSatImage ? leftSatImage.png : MaryCreek}
        title={leftSatImage ? leftSatImage.name : 'Mary Creek Floodrisk'}
      />
      </div> */}

        {/** ##### RIGHT SATELLITE IMAGE ##### */}

        <div className="manual-change-detection__right-select">


          <Dropdown
            name="manual-change-detection__right-image-select"
            value={rightSatImage ? rightSatImage.reportID : ''}
            setValue={changeRightSat}
            valueList={satelliteImages ? satelliteImages : []}
            valueProperty="reportID"
            nameProperty="name"
          />
        </div>
      </div>
      <div className='openimage'>
        <button
          style={{ visibility: !navClosed ? 'hidden' : 'visible' }} onClick={e => openNav(e)}>
          <img src={magnifyingglass} className='magnifying-glass-icon' />Open Image Details
        </button>
      </div>
      <div className='satellite-image-display__details' style={{ visibility: navClosed ? 'collapse' : 'visible' }}>
        <div className='satellite-image-display__details-frame'>
          <div className='cancel-box'>
            <button className="canceltext"
              style={{ visibility: !navClosed ? 'visible' : 'hidden' }} onClick={e => closeNav(e)}>Cancel
            </button>
            {/* <span className="canceltext">Cancel </span> */}
          </div>
          <div className='shapefileinfo-box'>
            <button className='shapefileinfobutton'></button>
            <div className='shapefileinfotextbox'>
              <span className='shapefileinfotext'>Download Shapefile</span>
            </div>
          </div>
          <div className='details-box'>
            <div className='satellite-image-display__details__properties'>
              <span className="section">Image Source:</span> Sentinel-2
            </div>
          </div>
          <div className='details-box'> <div className='satellite-image-display__details__properties'>
            <span className="section">Date of Image:</span>
          </div></div>
          <div className='details-box'> <div className='satellite-image-display__details__properties'>
            <span className="section">Image Type:</span> TCI (True Color)
          </div></div>
          <div className='details-box'> <div className='satellite-image-display__details__properties'>
            <span className="section">Image Size:</span> 3M
          </div></div>
          <div className='details-box'> <div className='satellite-image-display__details__properties'>
            <span className="section">Pixel Resolution:</span> 100
          </div></div>
          <div className='details-box'> <div className='satellite-image-display__details__properties'>
            <span className="section">Cloud Coverage :</span> 2%
          </div></div>
          <div className='details-box'> <div className='satellite-image-display__details__properties'>
            <span className="section">Area:</span> 98 Sq km
          </div></div>
          <div className='satellite-image-display__details__header'>
            <span className='satellite-image-display-title'>Satellite Image Details</span>
          </div>
          <div className='details-picture-box'>
            <div>
              <img src={remotesensingicon} className='remote-sensing-icon' />
            </div>
          </div>
        </div>
      </div>
      <button className='generate-report-button'
        style={{ visibility: !navClosed2 ? 'hidden' : 'visible' }} onClick={e => openNav2(e)}>Generate Report
      </button>
      <div className='generate-report-tab' style={{ visibility: navClosed2 ? 'collapse' : 'visible' }}>
        <div className='satellite-image-display__details-frame'>
          <div className='cancel-box'>
            <button className="canceltext"
              style={{ visibility: !navClosed2 ? 'visible' : 'hidden' }} onClick={e => closeNav2(e)}>Cancel
            </button>
            {/* <span className="canceltext">Cancel </span> */}
          </div>
          <div className='generate-title'>Generate a Report for this Area</div>



          <div className='details-picture-box2'>

          </div>

          <div className="snap-compare">
            <button className="snapshot">Snapshot</button>
            <button className='compare'>Compare</button>
          </div>
          <div className="generate-list">
            <button className='list'>Vegetation</button>
            <button className='list'>Carbon</button>
            <button className='list'>Flood</button>
            <button className='list'>Land Cover</button>
            <button className='list'>Simple</button>
          </div>
        </div>
      </div>





      {/* <ImageContainer
        className='manual-change-detection__right-image'
        imageSource={rightSatImage ? rightSatImage.png : MaryCreek}
        title={rightSatImage ? rightSatImage.name : 'Mary Creek Floodrisk'}
      /> */}
      <MapboxCompareSlider


        // Navigation
        longitude={-79.1}
        lattitude={43.9}
        zoom={10}
        /** Example satellite image ID
          765b8cdba3384a9f9b8cb5702e131e93
          a9ab666652324be582f6f72f856db898
          c098d9af9fdd4d1f9709b5896f971a46
        */

        // Before
        beforeRaster={
          leftSatImage ? leftSatImage.reportID.replace(/-/g, '') : ''
        }
        beforeStyle={'mapbox://styles/mapbox/streets-v11'}
        beforeMap={beforeMap}
        // After
        afterRaster={
          rightSatImage ? rightSatImage.reportID.replace(/-/g, '') : ''
        }
        afterStyle={'mapbox://styles/mapbox/streets-v11'}
        afterMap={afterMap}
        //NDVI & True Colour Toggle
        trueColour={trueColour}
        trueColourToggle={trueColour ? () => setTrueColour(false) : () => setTrueColour(true)}
      />




      {/* Satellite Image Details */}

    </div>

  );
}


import { useHistory } from 'react-router-dom';
import './landowner-gallery.css'
import LandownerIcon from "../../images/landowner-icon.png";
import { getLandowners} from '../../apis/landsteward/accounts';
import { getAllLandownersMock} from '../../apis/landsteward/mock_accounts';
import { MOCK_DATA } from '../../apis/landsteward/urlconfig';

export const VisitPreference = {
  Anytime: "ANY",       // visit anytime
  Present: "PRESENT",   // visit when the landowner is present
  Contact: "CONTACT"    // contact before landowner before visiting
}

/**
 * Gallery for landowner cards
 * @param   {Array}   landowners    Array of landowner objects
 * 
 * @author  [Leo Wang](https://github.com/Korotu-Leo-Wang)
 */
export function LandownerGallery(props) {
  console.log(props.landowners)
  return (
    <div className='landowner-gallery-container'>
      {props.landowners.map(landowner => (
        <LandownerCard
          landowner={landowner}
          key={landowner.profileID}
        />
      ))}
    </div>
  )
}

/**
 * Clickable card displaying landowner information within a project
 * 
 * @param   {object}  landowner
 * 
 * @author  [Leo Wang](https://github.com/Korotu-Leo-Wang)
 */
export function LandownerCard(props) {
  let history = useHistory();

  const handleClick = () => {
    history.push(`${history.location.pathname}/landowner/${props.landowner.profileID}`);
  }
  console.log(props.landowner)
  return (
    <div className='landowner-card' onClick={handleClick}>
      {props.landowner.has_notif ?
        <span className='landowner-card-notif'></span> : null
      }
      <div className='landowner-card-details'>
        <h3>{props.landowner.name}</h3>
        <img src={LandownerIcon} />
        <span>
          <div style={{float: "left"}}>{props.landowner.organization_name}</div>
          <div style={{float: "right"}}>Fields: {props.landowner.properties_num}</div>
        </span>

        <span>
          <div style={{float: "left"}}>{props.landowner.email}</div>
          {/* <div style={{float: "right"}}>Hectares: 10.7</div> */}
        </span>
        

      </div>

      <div className='landowner-card-observations'>
        {Object.entries(props.landowner.characteristics).map(([key, value]) => (
          <div className='landowner-characteristic-child' key={key}>
            <h4>{key}</h4>
            <p>
              {value}
            </p>
          </div>
        ))}
      </div>
      
    </div>
  );
}

/**
 * Gallery for landowner cards in list form
 * @param   {Array}   landowners    Array of landowner objects
 * 
 * @author  [Eugene Wong]
 */
export function LandownerGalleryList(props) {

  return (
    <div className='landowner-gallery-list-container'>
      <p>selected landowner{props.selectedRow}</p>
      {props.landowners.map(landowner => (
        <ListRow
          selected = {(props.selectedRow === landowner.email)?true:false}
          title = {landowner.name}
          subtitle = {landowner.email}
          onClick = {() =>(
            props.setSelectedRow(landowner.email)
          )}
          key={landowner.email}
        />
      ))}
    </div>
  )
}

export function ListRow(props) {
  
  return (
    <div className={'list-row-'+((props.selected)?'selected':'unselected')} onClick={props.onClick}>
      <h4>{props.title}</h4>
      <h5>{props.subtitle}</h5>
    </div>
  );
}
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { useParams, Redirect } from "react-router";

import polylabel from "polylabel";
import {
  getProjectSatelliteImages,
  getReportSatelliteImages,
  getNDVIFromSatellite,
  getNDVIReport,
  getSatelliteImage,
  getNDVIDownloadTiff,
  getSatDownloadTiff,
  getProjectRegions,
  getProjectProperties,
  getMDReport
} from "../apis/landsteward/accounts";
import { createNDVI } from "../apis/landsteward/valuation";
import { useFetchBlob } from "../apis/landsteward/useFetch.js";

import { MapboxCompareSlider } from "../components/mapbox/mapbox-compare";
import mapboxgl, { LngLat } from "mapbox-gl";

import "./ndvi-map-compare-page.css";
import RadioGroup from "../components/modules/radio-group";
import { DropdownSearch } from "../components/modules/dropdown-search";
import { useLocation, Link, Router, Route } from "react-router-dom";

import {
  getLandowners,
  getDataForRegion,
  getPropertiesForLandowner,
} from "../apis/landsteward/accounts";

import DownloadIcon from "../images/download-icon.png"
import LegendImage from "../images/legend.png"

/**
 *
 * @param {*} props
 */
export function NDVIComparePage(props) {
  /** ##### URL Param Check ##### */
  let params = useParams(); // parameters from url, in this case projectId and propertyId
  const history = useHistory();
  const [projectFailed, setProjectFailed] = useState(false); // flag to indicate if project failed to load
  /** Regions */
  const [projectRegions, setProjectRegions] = useState(null); // all regions for the project
  const [selectedRegion, setSelectedRegion] = useState(null);
  /** Properties */
  const [projectProperties, setProjectProperties] = useState(null); // all properties for the project
  /** Satellite Images */
  const [satelliteImages, setSatelliteImages] = useState(null); // all satellite images for the project
  const [satImagesForSelectedRegion, setSatImagesForSelectedRegion] = useState(null); // satellite images filtered by region
  const [leftSatImage, setLeftSatImage] = useState(null); // satellite image report id in the left map
  const [rightSatImage, setRightSatImage] = useState(null); // satellite image report id in the right map
  const [leftSatImages, setLeftSatImages] = useState([]); // holds multiple satellite image report IDs for the left map
  const [rightSatImages, setRightSatImages] = useState([]); // holds multiple satellite image report IDs for the right map
  /** NDVI Report IDs*/
  const [ndviLeft, setNdviLeft] = useState(null); // NDVI report id for the left map
  const [ndviRight, setNdviRight] = useState(null); // NDVI report id for the right map
  const [ndviLeftImages, setNdviLeftImages] = useState([]); // NDVI report ids for the left map
  const [ndviRightImages, setNdviRightImages] = useState([]); // NDVI report ids for the right map
  const [oldNdviLeft, setOldNdviLeft] = useState(""); // previous NDVI report id for the left map
  const [oldNdviRight, setOldNdviRight] = useState(""); // previous NDVI report id for the right map
  const [oldNdviLeftImages, setOldNdviLeftImages] = useState([]); // previous NDVI report ids for the left map
  const [oldNdviRightImages, setOldNdviRightImages] = useState([]); // previous NDVI report ids for the right map
  /** Landowners Info */
  const [landowners, setLandowners] = useState(null); // all landowners for the project
  /** Region Info */
  const [rawProperties, setRawProperties] = useState([]); // property information without the coordinates
  const [parsedProperties, setParsedProperties] = useState([]); // property information with the coordinates
  /** Report Info */
  const [reportSpatialRes, setReportSpatialRes] = useState(null);
  /** Property Layer IDs */
  const [neutralIDs, setNeutralIDs] = useState([]); // ID when the boundary hasn't been selected
  const [selectedIDs, setSelectedIDs] = useState([]); // ID when the boundary has been selected
  const [highlightedIDs, setHighlightedIDs] = useState([]); // ID when the boundary is being hovered over
  const [outlineIDs, setOutlineIDs] = useState([]); // ID for the outline specifically (this is how I set the thickness of the boundary)
  /** Mapbox Reference to left and right maps */
  const beforeMap = useRef(null); // mapbox reference to left map
  const afterMap = useRef(null); // mapbox reference to right map
  const compare = useRef(null); // mapbox reference to compare component that shows the two maps side by side
  /** MISC. Status Checks*/
  const [trueColour, setTrueColour] = useState(false); // flag to indicate if the map is in true colour (satellite image) mode
  const [showBoundaries, setShowBoundaries] = useState(true); // flag to indicate if property boundaries are being shown
  const [downloadStatus, setDownloadStatus] = useState(""); // flag to indicate id of NDVI report that needs to be downloaded
  const [loadedLandowners, setLoadedLandowners] = useState(false); // flag to indicate if landowners have been loaded
  const [useVector, setUseVector] = useState(false) // flag to indicate if raster or vector file should be displayed
  const isMounted1 = useRef(false); // flag to indicate if left map is mounted (if false, the map is on its first render)
  const isMounted2 = useRef(false); // flag to indicate if right map is mounted (if false, the map is on its first render)
  /** Passing radio selection to dropdown display */
  const [dropdownDisplay, setDropdownDisplay] = useState("reportID"); // flag to indicate if dropdown should display reportID, name, or date
  /** Properties gleaned from link with mowing display page chart */
  const location = useLocation(); // location object that represents current url
  const [clickedFromChart, setClickedFromChart] = useState(false); // flag to indicate if the page was clicked from the mowing display page chart
  const [satIdFromChart, setSatIdFromChart] = useState(null); // satellite image report id that was clicked from the mowing display page chart
  const [lat, setLat] = useState(null); // latitude of the satellite image that was clicked from the mowing display page chart
  const [long, setLong] = useState(null); // longitude of the satellite image that was clicked from the mowing display page chart
  /** Fullscreen toggle */
  const [isFullScreen, setFullScreen] = React.useState(false); // flag to indicate if the map is in fullscreen mode
  /** Map Display Settings */
  const [transparency, setTransparency] = useState(0.5); // fill-opacity levels for images
  const [reportCreationDate, setReportCreationDate] = useState(null); // report creation date
  const ZOOM_LEVEL = 15;
  const MULTIPROPERTY_ZOOM_LEVEL = 12;
  const ZOOM_LEVEL_10M = 15;
  const MULTIPROPERTY_ZOOM_LEVEL_10M = 15;
  const SATELLITE_COLOR_STYLE = {
    "fill-color": ["rgb", ["get", "red"], ["get", "green"], ["get", "blue"]],
    "fill-opacity": transparency,
    "fill-outline-color": "rgba(0, 0, 0, 0)",
  };
  const handleTransparencyChange = (newValue) => {
    setTransparency(newValue);
  };

  const NDVI_COLOR_STYLE = {
    "fill-color": [
      "step",
      ["get", "DN"],
      "#000000",
      0.0, "#757574",
      0.1, "#966556",
      0.2, "#B9763F",
      0.3, "#DCAC33",
      0.4, "#FFFF3D",
      0.5, "#FFFF3D",
      0.6, "#A5E632",
      0.7, "#5EC228",
      0.8, "#2F9E20",
      0.9, "#177B19"
    ],
    "fill-opacity": transparency,
    "fill-outline-color": "rgba(0, 0, 0, 0)",
  };

  const [showInitialPopup, setShowInitialPopup] = useState(true)

  useEffect(() => {
    console.log(reportCreationDate)
    // disables transparency feature if report was made before the blurry image fix
    if (reportCreationDate && reportCreationDate >= new Date("2024/03/01")) {
    if (beforeMap.current && beforeMap.current.isStyleLoaded()) {
      leftSatImages.forEach(image => {
        const layerID = image.reportID.replace(/-/g, "");
        if (beforeMap.current.getLayer(layerID)) {
          beforeMap.current.setPaintProperty(layerID, 'fill-opacity', transparency);
        }
      });
    }
    if (afterMap.current && afterMap.current.isStyleLoaded()) {
      rightSatImages.forEach(image => {
        const layerID = image.reportID.replace(/-/g, "");
        if (afterMap.current.getLayer(layerID)) {
          afterMap.current.setPaintProperty(layerID, 'fill-opacity', transparency);
        }
      });
    }
  }
  }, [transparency, leftSatImages, rightSatImages]);

  useEffect(() => {
    if (reportCreationDate && reportCreationDate >= new Date("2024/03/01")) {
    if (beforeMap.current && beforeMap.current.isStyleLoaded()) {
      ndviLeftImages.forEach(image => {
        const layerID = image.replace(/-/g, "");
        if (beforeMap.current.getLayer(layerID)) {
          beforeMap.current.setPaintProperty(layerID, 'fill-opacity', transparency);
        }
      });
    }
    if (afterMap.current && afterMap.current.isStyleLoaded()) {
      ndviRightImages.forEach(image => {
        const layerID = image.replace(/-/g, "");
        if (afterMap.current.getLayer(layerID)) {
          afterMap.current.setPaintProperty(layerID, 'fill-opacity', transparency);
        }
      });
    }
  }
  }, [transparency, ndviLeftImages, ndviRightImages]);

  useEffect(() => {
    if (reportCreationDate && reportCreationDate >= new Date("2024/03/01")) {
    if (beforeMap.current && beforeMap.current.isStyleLoaded() && leftSatImage && beforeMap.current.getLayer(leftSatImage.reportID.replace(/-/g, ""))) {
      beforeMap.current.setPaintProperty(leftSatImage.reportID.replace(/-/g, ""), 'fill-opacity', transparency);
    }
    if (afterMap.current && afterMap.current.isStyleLoaded() && rightSatImage && afterMap.current.getLayer(rightSatImage.reportID.replace(/-/g, ""))) {
      afterMap.current.setPaintProperty(rightSatImage.reportID.replace(/-/g, ""), 'fill-opacity', transparency);
    }
    console.log(transparency)
  }
  }, [transparency, leftSatImage, rightSatImage]);

  useEffect(() => {
    if (reportCreationDate && reportCreationDate >= new Date("2024/03/01")) {
    if (beforeMap.current && beforeMap.current.isStyleLoaded() && ndviLeft && beforeMap.current.getLayer(ndviLeft.replace(/-/g, ""))) {
      beforeMap.current.setPaintProperty(ndviLeft.replace(/-/g, ""), 'fill-opacity', transparency);
    }
    if (afterMap.current && afterMap.current.isStyleLoaded() && ndviRight && afterMap.current.getLayer(ndviRight.replace(/-/g, ""))) {
      afterMap.current.setPaintProperty(ndviRight.replace(/-/g, ""), 'fill-opacity', transparency);
    }
  }
  }, [transparency, ndviRight, ndviLeft]);
  // INITIALIZATION

  /**
   * Once the component mounts, initialize webstate of web application by loading in the project data
   *
   * */
  useEffect(() => {
    async function initializeState() {
      /*       let isCorrectState = await props.changeCurrentState(params.projectID);
            if (!isCorrectState.project) {
              console.error('Failing Projects', isCorrectState);
              setProjectFailed(true);
            } */
      let success = await props.changeProject(params.projectID);
      if (!success) {
        setProjectFailed(true);
        return;
      }
    }
    initializeState().catch(console.error);
      
    getProjectRegions(params.projectID).then((fetchedRegions) => {
      let modifiedRegions = [...fetchedRegions];
      if (fetchedRegions.length > 1) {
        modifiedRegions.push({ regionID: "all_properties", name: "All Properties" });
        modifiedRegions.push({ regionID: "no_properties", name: "No Properties" });
      }
      console.log(modifiedRegions)
      if (modifiedRegions && modifiedRegions.length > 0) {
        setProjectRegions(modifiedRegions);
        let newSelectedRegion = modifiedRegions.find(region => region.regionID === "all_properties") || modifiedRegions[0];
        if (newSelectedRegion) {
          setSelectedRegion(newSelectedRegion);
        } else {
          console.error('No region selected');
        }
      } else {
        setProjectRegions(null);
        setSelectedRegion(null);
      }
      console.log('fetchedRegions: ', modifiedRegions);
      console.log('selectedRegion: ', selectedRegion);
    });

    getProjectProperties(params.projectID).then((fetchedProperties) => {
      console.log("fetchedProperties: ", fetchedProperties);
      setProjectProperties(fetchedProperties);
    });

  }, []);


useEffect(() => {
  if (projectProperties) {
    console.log("Updated projectProperties: ", projectProperties);
  }
}, [projectProperties]);


useEffect(() => {
  if (selectedRegion) {
    console.log("Updated selected region:", selectedRegion)
  }
}, [selectedRegion]);
  /**
   * Once project data is loaded in, load in region data
   *
   * */
  useEffect(() => {
    async function setCurrRegion() {
      if (props.project != null) {
        console.log(props.project);
        console.log("calling changeRegion()");
        let success = await props.changeRegion(props.project.region);
        if (!success) {
          console.log("Region failed to be set");
        }
      }
    }
    setCurrRegion().catch(console.error);
  }, [props.project]);

  /**
   * Once the region is loaded in, center the map on the region centroid
   * Do this if the user entered the page from the Manual Compare button  
   * OR from the graph but the centroid of the property hasn't finished computing yet
   */


  useEffect(() => {
    if (projectProperties != null) {
      let regionCenter
      if (projectProperties.length === 1) {
        let singleProperty = projectProperties[0];
        regionCenter = [singleProperty.longitude, singleProperty.latitude];
        console.log(regionCenter)
        beforeMap.current.flyTo({
          center: [regionCenter[0], regionCenter[1]],
          zoom: reportSpatialRes == 30 ? ZOOM_LEVEL : ZOOM_LEVEL_10M,
        });
        afterMap.current.flyTo({
          center: [regionCenter[0], regionCenter[1]],
          zoom: reportSpatialRes == 30 ? ZOOM_LEVEL : ZOOM_LEVEL_10M,
        });
      } else {
        let regionCenter;
        const allCoordinates = projectProperties.reduce((acc, property) => {
          if (property.longitude && property.latitude) {
            acc.push([property.longitude, property.latitude]);
          }
          return acc;
        }, []);
        regionCenter = props.computeCentroid(allCoordinates);
        console.log(regionCenter);
        beforeMap.current.flyTo({
          center: [regionCenter[0], regionCenter[1]],
          zoom: reportSpatialRes == 30 ? MULTIPROPERTY_ZOOM_LEVEL : MULTIPROPERTY_ZOOM_LEVEL_10M,
        });
        afterMap.current.flyTo({
          center: [regionCenter[0], regionCenter[1]],
          zoom: reportSpatialRes == 30 ? MULTIPROPERTY_ZOOM_LEVEL : MULTIPROPERTY_ZOOM_LEVEL_10M,
        });
      }
    } else {
      // For old projects without associated properties
      if (props.region != null) {
        let regionCenter = props.computeCentroid(props.region.data.geometry.coordinates[0]);
            console.log("regionCenter: ", regionCenter)
            beforeMap.current.flyTo({
              center: [regionCenter[0], regionCenter[1]],
              zoom: reportSpatialRes == 30 ? ZOOM_LEVEL : ZOOM_LEVEL_10M,
            });
            afterMap.current.flyTo({
              center: [regionCenter[0], regionCenter[1]],
              zoom: reportSpatialRes == 30 ? ZOOM_LEVEL : ZOOM_LEVEL_10M,
            });
      }
    }
  }, [props.region, projectProperties, reportSpatialRes]);
  
  /**
   * Determine whether raster or vector images should be displayed on Mapbox
   */

  useEffect(() => {
    const fetchReportData = async () => {
      const reportType = 'forest_detection';
      const reportData = await getMDReport(params.mowingID, reportType);

      console.log(reportData.creation_date)
      const [day, month, year] = reportData.creation_date.split("/");
      const correctedDate = `${month}/${day}/${year}`;
      console.log(correctedDate)
      const creationDate = new Date(correctedDate)
      console.log(creationDate)
      console.log(creationDate.getMonth())
      await setReportCreationDate(creationDate)
      await setUseVector(reportData.use_vector)
      setReportSpatialRes(reportData.spatial_res)
    };

    fetchReportData()
  }, []);
  
  // SATELLITE IMAGES

  /**
   *  Once the component mounts, fetch Satellite images
   *
   * */
  useEffect(() => {
    const fetchSatelliteImages = async () => {
      let satelliteImageData = await getReportSatelliteImages(
        params.mowingID
      );

      // console.log('SatelliteImageData - unfiltered: ', satelliteImageData)
      // filters out null items
      satelliteImageData = satelliteImageData.filter(item => {
        return item !== null;
      });

      // Filters out duplicate times for any specific region
      const seenDates = new Set();
      const uniqueSatelliteImageData = satelliteImageData.filter(item => {
        const isDuplicated = seenDates.has(item.image_date + item.regionID);
        seenDates.add(item.image_date + item.regionID);
        return !isDuplicated;
      });

      // initialises the dropdown menus with images of the year of analysis
      uniqueSatelliteImageData.sort((a, b) => new Date(a.image_date) - new Date(b.image_date));
      console.log('SatelliteImageData - all properties: ', uniqueSatelliteImageData)

      setSatelliteImages(uniqueSatelliteImageData);
    };

    fetchSatelliteImages();
    //clean state on unmount, use to avoid warning
    return () => setSatelliteImages({});
  }, []);

  // Filter sat images once they're loaded and set the default dates on dropdowns
  useEffect(()=> {
    if (satelliteImages) {
      console.log("HERE selectedRegion: ", selectedRegion);
      console.log(satelliteImages)
      // const filteredImages = satelliteImages.filter(image => image?.regionID === selectedRegion.regionID);
      let filteredImages;
      if (selectedRegion && selectedRegion.regionID === "all_properties") {
            // filters for first image of every month
      const seenMonthYearPropertyCombos = new Set();
      const firstOfMonthImages = satelliteImages.filter(item => {
        const date = new Date(item.image_date);
        const monthYearPropertyKey = `${date.getFullYear()}-${date.getMonth() + 1}-${item.regionID}`;
  
        if (!seenMonthYearPropertyCombos.has(monthYearPropertyKey)) {
          seenMonthYearPropertyCombos.add(monthYearPropertyKey);
          return true;
        }
        return false;
      });
  
      // ensures the filtered images are sorted by date
      firstOfMonthImages.sort((a, b) => new Date(a.image_date) - new Date(b.image_date));
      console.log('Filtered satellite images for first of each month for each property: ', firstOfMonthImages);
  
      const uniqueDates = firstOfMonthImages.reduce((acc, image) => {
        const date = new Date(image.image_date).toDateString();
        if (!acc.some(item => new Date(item.image_date).toDateString() === date)) {
          acc.push(image);
        }
        return acc;
      }, []);

      const modifiedUniqueDates = uniqueDates.map(image => {
        return {
          ...image,
          image_date: image.image_date.substring(0, 7),
        };
      });
    
      console.log("Unique Dates for All Properties: ", modifiedUniqueDates);
        filteredImages = modifiedUniqueDates;
      } else {
        filteredImages = satelliteImages.filter(image => {
          if (selectedRegion && image.hasOwnProperty('regionID')) {
              return image.regionID === selectedRegion.regionID;
          } else {
              return true; // Include the image if it doesn't have a regionID field
          }
        })
      }
      console.log("SatelliteImageData - default region: ", filteredImages);
      setSatImagesForSelectedRegion(filteredImages);
      console.log(satImagesForSelectedRegion);

      const mostRecentSatImageForRegion = filteredImages[filteredImages.length - 1];
      const mostRecentSatYearForRegion = new Date(mostRecentSatImageForRegion.image_date).getFullYear();
      const earliestDateInSameYearForRegion = filteredImages.find(item => {
        return new Date(item.image_date).getFullYear() === mostRecentSatYearForRegion;
      });

      if (location.state && location.state.leftSatImg && location.state.rightSatImg) {
        // console.log(location.state)
        // console.log(regionCenter)
        console.log("Got left and right satImgs from map page");
        setLeftSatImage(location.state.leftSatImg);
        setRightSatImage(location.state.rightSatImg);
      } else {
        console.log("Setting earliestDateInSameYearForRegion and mostRecentSatImageForRegion");
        changeLeftSat(earliestDateInSameYearForRegion.reportID);
        changeRightSat(mostRecentSatImageForRegion.reportID);
      }
    }
  }, [satelliteImages]);

  useEffect(() => {
    console.log("Satellite images updated:", satelliteImages);
  }, [satelliteImages]);
  

  useEffect(() => {
    return () => setSelectedRegion(null);
  }, []);

  // Handle new region selected on property dropdown
  const handleRegionChange = async (regionID) => {
    console.log("handleRegionChange...");
    if (!satelliteImages || !projectRegions) {
      console.error("No satellite images or project regions loaded for the report.");
      return null;
    }
    if (regionID === "no_properties") {
      console.log("No Properties selected");
      setSatImagesForSelectedRegion([]);
      setSelectedRegion({regionID: "no_properties"});
      await Promise.all([
        removeLeftSat(),
        removeRightSat(),
        removeLeftSatAllProperties(),
        removeRightSatAllProperties(),
        removeLeftNDVI(),
        removeRightNDVI(),
        removeLeftNDVIAllProperties(),
        removeRightNDVIAllProperties(),
      ]);
      return;
    }
     if (regionID == "all_properties") {
      console.log("All Properties selected");
      console.log(satelliteImages);
      // setSatImagesForSelectedRegion(satelliteImages);

            // filters for first image of every month
      const seenMonthYearPropertyCombos = new Set();
      const firstOfMonthImages = satelliteImages.filter(item => {
        const date = new Date(item.image_date);
        const monthYearPropertyKey = `${date.getFullYear()}-${date.getMonth() + 1}-${item.regionID}`;
  
        if (!seenMonthYearPropertyCombos.has(monthYearPropertyKey)) {
          seenMonthYearPropertyCombos.add(monthYearPropertyKey);
          return true;
        }
        return false;
      });
  
      // ensures the filtered images are sorted by date
      firstOfMonthImages.sort((a, b) => new Date(a.image_date) - new Date(b.image_date));
      console.log('Filtered satellite images for first of each month for each property: ', firstOfMonthImages);
  
      const uniqueDates = firstOfMonthImages.reduce((acc, image) => {
        const date = new Date(image.image_date).toDateString();
        if (!acc.some(item => new Date(item.image_date).toDateString() === date)) {
          acc.push(image);
        }
        return acc;
      }, []);

      const modifiedUniqueDates = uniqueDates.map(image => {
        return {
          ...image,
          image_date: image.image_date.substring(0, 7),
        };
      });
    
      console.log("Unique Dates for All Properties: ", modifiedUniqueDates);
    
      setSatImagesForSelectedRegion(modifiedUniqueDates);
      console.log(satImagesForSelectedRegion);

      const firstMatchingRegion = projectRegions.find(region => region.regionID === regionID);
      if (firstMatchingRegion) {
        console.log("Found matching region: ", firstMatchingRegion)
        await setSelectedRegion(firstMatchingRegion);
      } else {
        console.log("No matching region found")
      }
      // Center map on selected region
      if (projectProperties && reportSpatialRes) {
        let regionCenter;
        const allCoordinates = projectProperties.reduce((acc, property) => {
          if (property.longitude && property.latitude) {
            acc.push([property.longitude, property.latitude]);
          }
          return acc;
        }, []);
        regionCenter = props.computeCentroid(allCoordinates);
        console.log(regionCenter);
        beforeMap.current.flyTo({
          center: [regionCenter[0], regionCenter[1]],
          zoom: reportSpatialRes == 30 ? MULTIPROPERTY_ZOOM_LEVEL : MULTIPROPERTY_ZOOM_LEVEL_10M,
        });
        afterMap.current.flyTo({
          center: [regionCenter[0], regionCenter[1]],
          zoom: reportSpatialRes == 30 ? MULTIPROPERTY_ZOOM_LEVEL : MULTIPROPERTY_ZOOM_LEVEL_10M,
      });
      } else {
        console.log("No projectProperties loaded")
      }
      
      return;
    } else {
        console.log(satelliteImages)

            // Filter sat images to only include ones for the selected region/property
        // const filteredImages = satelliteImages.filter(image => image.regionID === regionID);
        const filteredImages = satelliteImages.filter(image => {
          if (image.hasOwnProperty('regionID')) {
              return image.regionID === regionID;
          } else {
              return true; // Include the image if it doesn't have a regionID field
          }
        });
        console.log("filteredImages: ", filteredImages);
        setSatImagesForSelectedRegion(filteredImages);
        console.log(satImagesForSelectedRegion);

        // Set the selected region based on the regionID
        const firstMatchingRegion = projectRegions.find(region => region.regionID === regionID);
        if (firstMatchingRegion) {
          console.log("Found matching region: ", firstMatchingRegion)
          setSelectedRegion(firstMatchingRegion);
        } else {
          console.log("No matching region found")
        }

        // Center map on selected region
        if (projectProperties) {
          console.log("projectProperties: ", projectProperties)
          console.log(projectProperties)
          const matchingProperty = projectProperties.find(property => property.region === regionID);
          if (matchingProperty) {
            const regionCenter = [matchingProperty.longitude, matchingProperty.latitude];
            console.log("regionCenter: ", regionCenter);
            beforeMap.current.flyTo({
                center: regionCenter,
                zoom: 13,
            });
            afterMap.current.flyTo({
                center: regionCenter,
                zoom: 13,
            });
          } else {
            console.log("No project property found with matching regionID");
          }
        } else {
          console.log("No projectProperties loaded")
        }
    }
  
  };
  useEffect(() => {
    if (selectedRegion && selectedRegion.regionID !== 'all_properties' && satelliteImages) {
      const filteredImages = satelliteImages.filter(image => {
        if (image.hasOwnProperty('regionID')) {
            return image.regionID === selectedRegion.regionID;
        } else {
            return true; // Include the image if it doesn't have a regionID field
        }
      });
  
      if (filteredImages.length > 0) {
        const mostRecentSatImageForRegion = filteredImages[filteredImages.length - 1];
        const mostRecentSatYearForRegion = new Date(mostRecentSatImageForRegion.image_date).getFullYear();
        const earliestDateInSameYearForRegion = filteredImages.find(item => {
          return new Date(item.image_date).getFullYear() === mostRecentSatYearForRegion;
        });
    
        changeLeftSat(earliestDateInSameYearForRegion.reportID);
        changeRightSat(mostRecentSatImageForRegion.reportID);
      }
    }
  }, [selectedRegion, satelliteImages]);

  /**
   * Removes the selected region
   * */
  const removeSelectedRegion = () => {
    setSelectedRegion(null);
  };

  async function downloadHandler(side) {
    if (selectedRegion && selectedRegion.regionID === "all_properties") {
      const satImages = side === 'left' ? leftSatImages : rightSatImages;
      const ndviImages = side === 'left' ? ndviLeftImages : ndviRightImages;
      for (let i = 0; i < satImages.length; i++) {
        await downloadImage(satImages[i], ndviImages[i], side);
      }
    } else {
      const selectedSatImage = side === 'left' ? leftSatImage : rightSatImage;
      const selectedNDVIImage = side === 'left' ? ndviLeft : ndviRight;
      await downloadImage(selectedSatImage, selectedNDVIImage, side);
    }
  }
  
  async function downloadImage(selectedSatImage, selectedNDVIImage, side) {
    if (selectedSatImage == null) {
      window.alert("Please select a date");
      return;
    }
    const date = selectedSatImage['image_date'].split("T")[0];
    let metaDataResponse = null;
    let imageType = '';
    let downloadResponse = null;
    let propertyName;
  
    if (trueColour) {
      metaDataResponse = await getSatelliteImage(selectedSatImage['reportID']);
      downloadResponse = await getSatDownloadTiff(selectedSatImage['reportID']);
      imageType = 'SAT'
    } else {
      metaDataResponse = await getNDVIReport(selectedNDVIImage);
      downloadResponse = await getNDVIDownloadTiff(selectedNDVIImage);
      imageType = 'NDVI'
    }
  
    if (downloadResponse == null) {
      window.alert("There is no image to download for this date. Please select another date or run the report again.");
      return;
    }
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(downloadResponse);
    if (selectedRegion && selectedRegion.regionID === "all_properties") {
      propertyName = projectRegions.find(region => region.regionID === selectedSatImage.regionID).name;
    } else {
      propertyName = selectedRegion ? selectedRegion.name : props.region ? props.region.name.replace(/\.geojson$/, "") : "FIELD";
    }
    // const propertyName = selectedRegion ? selectedRegion.name : props.region ? props.region.name.replace(/\.geojson$/, "") : "FIELD";
    const downloadFileName = metaDataResponse['name'] ? `${metaDataResponse['name']}_${propertyName}_${imageType}_${date}.tiff` : `${imageType}_${date}.tiff`;
    downloadLink.download = downloadFileName;
    downloadLink.click();
    window.URL.revokeObjectURL(downloadLink.href);
  
  }

  /**
   * Changes the left satellite image to the one specified by reportID
   * */
  const changeLeftSat = async (reportID) => {
    if (!satelliteImages) {
      console.error("Satellite Images Do existing in manual change detection");
      return null;
    }
    if (selectedRegion && selectedRegion.regionID === "no_properties") {
      await removeLeftSatAllProperties();
      await removeLeftSat();
      return;
    }
    if (selectedRegion && selectedRegion.regionID === "all_properties") {
      await changeLeftSatAllProperties(reportID);
    } else {
      
      try {
        await removeLeftSatAllProperties()
        await removeLeftSat();
        for (let i = 0; i < satelliteImages.length; i++) {
          if (satelliteImages[i].reportID === reportID) {
            /** New Satellite Image ID */
            let newSatID;
            const dynamicStyle = {
              ...SATELLITE_COLOR_STYLE,
              'fill-opacity': transparency
            };
            newSatID = satelliteImages[i].reportID.replace(/-/g, "");
            /** Finds old left Satellite Image ID and removes its layers */
            // await removeLeftSat();
            /** Add Mapbox source and layer for next satellite image */
            if (!beforeMap.current.getSource(newSatID)) {
              if (useVector) {
                beforeMap.current.addSource(newSatID, {
                  type: "vector",
                  url: "mapbox://korotu-conroy-trinh." + newSatID,
                });
                beforeMap.current.addLayer({
                  id: newSatID,
                  source: newSatID,
                  "source-layer": "satellite_image",
                  type: "fill",
                  filter: ["!=", "rgb", 0],
                  paint: dynamicStyle,
                });
              } else {
                beforeMap.current.addSource(newSatID, {
                  type: "raster",
                  url: "mapbox://korotu-conroy-trinh." + newSatID,
                });
                beforeMap.current.addLayer({
                  id: newSatID,
                  source: newSatID,
                  type: "raster",
                });
              }
            }
            setLeftSatImage(satelliteImages[i]);
            movePropertiesToTop(beforeMap.current);
            i = satelliteImages.length;
          }
        }
      } catch (error) {
        window.alert("There was a problem displaying the selected image. Please try another one.");
      }
    }

  };

  const changeLeftSatAllProperties = async (reportID) => {
    const selectedImage = satelliteImages.find(image => image.reportID === reportID);
    if (!selectedImage) {
        console.error("Selected image not found.");
        return;
    }
    const selectedDate = new Date(selectedImage.image_date).toDateString();

    const allPropertyImages = satelliteImages.filter(image => {
        const imageDate = new Date(image.image_date).toDateString();
        return imageDate === selectedDate;
    });
    console.log(allPropertyImages);

    try {
        await removeLeftSat();
        await removeLeftSatAllProperties();
        allPropertyImages.forEach(image => {
            const newSatID = image.reportID.replace(/-/g, "");
            const dynamicStyle = {
              ...SATELLITE_COLOR_STYLE,
              'fill-opacity': transparency
            };
            if (!beforeMap.current.getSource(newSatID)) {
              if (useVector) {
                beforeMap.current.addSource(newSatID, {
                  type: "vector",
                  url: "mapbox://korotu-conroy-trinh." + newSatID,
                });
                beforeMap.current.addLayer({
                  id: newSatID,
                  source: newSatID,
                  "source-layer": "satellite_image",
                  type: "fill",
                  filter: ["!=", "rgb", 0],
                  paint: SATELLITE_COLOR_STYLE,
                });
              } else {
                beforeMap.current.addSource(newSatID, {
                  type: "raster",
                  url: "mapbox://korotu-conroy-trinh." + newSatID,
                });
                beforeMap.current.addLayer({
                  id: newSatID,
                  source: newSatID,
                  type: "raster",
                });
              }
            }
        });
        setLeftSatImages(allPropertyImages);
        console.log(leftSatImages);
        movePropertiesToTop(beforeMap.current);
    } catch (error) {
        console.error("There was a problem displaying the selected image. Please try another one.");
    }

  };
  /**
   * Changes the right satellite image to the one specified by reportID
   * */
  const changeRightSat = async (reportID) => {
    if (!satelliteImages) {
      console.error("Satellite Images Do existing in manual change detection");
      return null;
    }
    
    if (selectedRegion && selectedRegion.regionID === "no_properties") {
      await removeRightSatAllProperties();
      await removeRightSat();
      return;
    }

    if (selectedRegion && selectedRegion.regionID === "all_properties") {
      await changeRightSatAllProperties(reportID);
    } else {
      try {
        await removeRightSatAllProperties()
        await removeRightSat()
        for (let i = 0; i < satelliteImages.length; i++) {
          if (satelliteImages[i].reportID === reportID) {
            /** New Satellite Image ID */
            let newSatID;
            const dynamicStyle = {
              ...SATELLITE_COLOR_STYLE,
              'fill-opacity': transparency
            };
            newSatID = satelliteImages[i].reportID.replace(/-/g, "");
            /** Finds old right sat ID and removes its layer */
            // await removeRightSat();
            /** Add Mapbox source and layer for next satellite image */
            if (!afterMap.current.getSource(newSatID)) {
              if (useVector) {
                afterMap.current.addSource(newSatID, {
                  type: "vector",
                  url: "mapbox://korotu-conroy-trinh." + newSatID,
                });
                afterMap.current.addLayer({
                  id: newSatID,
                  source: newSatID,
                  "source-layer": "satellite_image",
                  type: "fill",
                  filter: ["!=", "rgb", 0],
                  paint: dynamicStyle,
                });
              } else {
                afterMap.current.addSource(newSatID, {
                  type: "raster",
                  url: "mapbox://korotu-conroy-trinh." + newSatID,
                });
                afterMap.current.addLayer({
                  id: newSatID,
                  source: newSatID,
                  type: "raster",
                });
              }
            }
            setRightSatImage(satelliteImages[i]);
            movePropertiesToTop(afterMap.current);
            i = satelliteImages.length;
          }
        }
      } catch (error) {
        window.alert("There was a problem displaying the selected image. Please try another one.");
      }
    }
  };

  const changeRightSatAllProperties = async (reportID) => {
    const selectedImage = satelliteImages.find(image => image.reportID === reportID);
    if (!selectedImage) {
        console.error("Selected image not found.");
        return;
    }
    const selectedDate = new Date(selectedImage.image_date).toDateString();

    const allPropertyImages = satelliteImages.filter(image => {
        const imageDate = new Date(image.image_date).toDateString();
        return imageDate === selectedDate;
    });
    console.log(allPropertyImages);

    try {
      await removeRightSat();
      await removeRightSatAllProperties();
        allPropertyImages.forEach(image => {
            const newSatID = image.reportID.replace(/-/g, "");
            const dynamicStyle = {
              ...SATELLITE_COLOR_STYLE,
              'fill-opacity': transparency
            };
            if (!afterMap.current.getSource(newSatID)) {
              if (useVector) {
                afterMap.current.addSource(newSatID, {
                  type: "vector",
                  url: "mapbox://korotu-conroy-trinh." + newSatID,
                });
                afterMap.current.addLayer({
                  id: newSatID,
                  source: newSatID,
                  "source-layer": "satellite_image",
                  type: "fill",
                  filter: ["!=", "rgb", 0],
                  paint: dynamicStyle,
                });
              } else {
                afterMap.current.addSource(newSatID, {
                  type: "raster",
                  url: "mapbox://korotu-conroy-trinh." + newSatID,
                });
                afterMap.current.addLayer({
                  id: newSatID,
                  source: newSatID,
                  type: "raster",
                });
              }
            }
        });
        setRightSatImages(allPropertyImages);
        movePropertiesToTop(afterMap.current);
    } catch (error) {
        console.error("There was a problem displaying the selected image. Please try another one.");
    }
  }

  /**
   * Removes the left satellite image from being display on the map
   * */
  const removeLeftSat = async () => {
      await removeLeftSatAllProperties()
      /** Previous Satellite Image ID */
      console.log("Left Satellite Image", leftSatImage);
      let oldSatID = leftSatImage ? leftSatImage.reportID.replace(/-/g, "") : "";
      /** Remove Mapbox source and layer for previous satellite image */
      if (oldSatID != "" && beforeMap.current.getSource(oldSatID)) {
        beforeMap.current.removeLayer(oldSatID);
        beforeMap.current.removeSource(oldSatID);
      }
  };

  const removeLeftSatAllProperties = async () => {
    console.log("Left Satellite Images", leftSatImages);
    for (let i = 0; i < leftSatImages.length; i++) {
      let oldSatID = leftSatImages[i] ? leftSatImages[i].reportID.replace(/-/g, "") : "";
      if (oldSatID != "" && beforeMap.current.getSource(oldSatID)) {
        beforeMap.current.removeLayer(oldSatID);
        beforeMap.current.removeSource(oldSatID);
      }
    }
  };

  /**
   * Removes the right satellite image from being display on the map
   * */
  const removeRightSat = async () => {
      await removeRightSatAllProperties()
      /** Previous Satellite Image ID */
      console.log("Right Satellite Image", rightSatImage);
      let oldSatID = rightSatImage
        ? rightSatImage.reportID.replace(/-/g, "")
        : "";

      /** Remove Mapbox source and layer for previous satellite image */
      if (oldSatID != "" && afterMap.current.getSource(oldSatID)) {
        afterMap.current.removeLayer(oldSatID);
        afterMap.current.removeSource(oldSatID);
      }
  };

  const removeRightSatAllProperties = async () => {
    console.log("Right Satellite Images", rightSatImages);
    for (let i = 0; i < rightSatImages.length; i++) {
      let oldSatID = rightSatImages[i] ? rightSatImages[i].reportID.replace(/-/g, "") : "";
      if (oldSatID != "" && afterMap.current.getSource(oldSatID)) {
        afterMap.current.removeLayer(oldSatID);
        afterMap.current.removeSource(oldSatID);
      }
    }
  };

  // NDVI IMAGES

  /**
   * NDVI/TRUE COLOUR toggle handler, displays NDVI layer if false, removes NDVI layer if true
   */
  useEffect(() => {
    async function updateView() {
    if (trueColour) {
      if (selectedRegion && selectedRegion.regionID === "no_properties") {
        console.log("No Properties selected");
        await Promise.all([
          removeLeftNDVI(),
          removeRightNDVI(),
          removeLeftNDVIAllProperties(),
          removeRightNDVIAllProperties(),
        ]);
        return;
      }
      if (selectedRegion && selectedRegion.regionID === "all_properties") {
        if (leftSatImages && leftSatImages.length > 0) {
          console.log("changing left sat images")
          await changeLeftSat(leftSatImages[0].reportID);
          await removeLeftNDVI();
          console.log("successfully changed left sat images")
        }
        if (rightSatImages && rightSatImages.length > 0) {
          console.log("changing right sat images")
          await changeRightSat(rightSatImages[0].reportID);
          await removeRightNDVI();
          console.log("successfully changed right sat images")
        }
      } else {
        if (leftSatImage) {
          await changeLeftSat(leftSatImage.reportID);
          await removeLeftNDVI();
          console.log("successfully changed left")
        }
        if (rightSatImage) {
          await changeRightSat(rightSatImage.reportID);
          await removeRightNDVI();
          console.log("successfully changed right")
        }
      }     
    }
    
    if (!trueColour) {
      if (selectedRegion && selectedRegion.regionID === "no_properties") {
        console.log("No Properties selected");
        await Promise.all([
          removeLeftNDVI(),
          removeRightNDVI(),
          removeLeftNDVIAllProperties(),
          removeRightNDVIAllProperties(),
        ]);
        return;
      }
  
      if (selectedRegion && selectedRegion.regionID === "all_properties") {
        if (leftSatImages && leftSatImages.length > 0) {
          await fetchNDVIAllProperties(leftSatImages[0].reportID, "left").then(() => {
            console.log("success left sat image NDVI");
          });
        }
        if (rightSatImages && rightSatImages.length > 0) {
          await fetchNDVIAllProperties(rightSatImages[0].reportID, "right").then(() => {
            console.log("success right sat image NDVI");
          });
        }
      } else {
        if (leftSatImage && rightSatImage) {
          Promise.all([
            removeLeftNDVI(),
            removeRightNDVI(),
            removeLeftNDVIAllProperties(),
            removeRightNDVIAllProperties(),
            fetchNDVI(leftSatImage.reportID, "left"),
            fetchNDVI(rightSatImage.reportID, "right")
          ]).then(() => {
            console.log("success both left and right sat image NDVI");
          });
        }
      }
    }
  };
    updateView().catch(console.error);
  }, [trueColour, selectedRegion]); 

  /**
   * Fetches NDVI ID from corresponding satellite reportID and sets the left/right NDVI map layers with the corresponding NDVI image
   * */
  const fetchNDVI = async (reportID, lor) => {
    if (selectedRegion && selectedRegion.regionID === "all_properties") {
      fetchNDVIAllProperties(reportID, lor);
    } else {
      //attempt to fetch
      const NDVIReportID = await getNDVIFromSatellite(reportID);
      console.log(reportID);
      console.log(NDVIReportID);
      //fetch success
      if (NDVIReportID != null) {
        //sets either left or right map
        if (lor === "left") {
          if (ndviLeft != NDVIReportID.reportID)
            setNdviLeft(NDVIReportID.reportID);
          else {
            await removeLeftSat();
            await changeLeftNDVI();
          }
        } else if (lor === "right") {
          if (ndviRight != NDVIReportID.reportID)
            setNdviRight(NDVIReportID.reportID);
          else {
            await removeRightSat();
            await changeRightNDVI();
          }
        }
      } else {
        //fetch failed
        console.log("reportID is null. Generating NDVI.");
        triggerNdviCreation(lor).then(() => {
          console.log("NDVI generation success");
        });
      }
    }
  };

  const fetchNDVIAllProperties = async (reportID, lor) => {
    const selectedImage = satelliteImages.find(image => image.reportID === reportID);
    if (!selectedImage) {
        console.error("Selected image not found.");
        return;
    }
    const selectedDate = new Date(selectedImage.image_date).toDateString();
    const allPropertyImages = satelliteImages.filter(image => {
        const imageDate = new Date(image.image_date).toDateString();
        return imageDate === selectedDate;
    });
    console.log(allPropertyImages);
    
    for (const image of allPropertyImages) {
      const NDVIReportID = await getNDVIFromSatellite(image.reportID);
      console.log(NDVIReportID);
      console.log(image.reportID);
      if (NDVIReportID != null) {
        if (lor === "left") {
          setNdviLeftImages(prevImages => {
            if (!prevImages.includes(NDVIReportID.reportID)) {
              return [...prevImages, NDVIReportID.reportID];
            } else {
              return prevImages;
            }
          });
          console.log(ndviLeftImages);
          await changeLeftNDVIAllProperties();
        } else if (lor === "right") {
          setNdviRightImages(prevImages => {
            if (!prevImages.includes(NDVIReportID.reportID)) {
              return [...prevImages, NDVIReportID.reportID];
            } else {
              return prevImages;
            }
          });
          console.log(ndviRightImages);
          await changeRightNDVIAllProperties();
        }
      } else {
        console.log("reportID is null. Generating NDVI.");
        triggerNdviCreation(lor).then(() => {
          console.log("NDVI generation success");
        });
      }
    }
  };


  // Helper for fetchNDVI() that downloads the NDVI image if needed
  async function triggerNdviCreation(lor) {

    if (selectedRegion && selectedRegion.regionID === "all_properties") {
      let allSatelliteImages;
      if (lor === "left") allSatelliteImages = leftSatImages;
      else if ((lor = "right")) allSatelliteImages = rightSatImages;

      for (let image of allSatelliteImages) {
        await createNdviForImage(image, lor);
      }
    } else {
      let satelliteImage;
      if (lor === "left") satelliteImage = leftSatImage;
      else if ((lor = "right")) satelliteImage = rightSatImage;
      if (satelliteImage) {
        await createNdviForImage(satelliteImage, lor);
      }
    }
  }
  async function createNdviForImage(image, lor) {
    setDownloadStatus("pending");
    let download = true;
  
    if (!selectedRegion || selectedRegion.regionID !== "all_properties") {
      if (lor === "right" && leftSatImage === rightSatImage && leftSatImage != null) {
        download = false;
      }
    }

    if (download) {
      let ndviData = await createNDVI(
        params.projectID,
        params.propertyID,
        image.reportID,
        image.name + "_ndvi"
      );
      if (ndviData !== null) {
        console.log("NDVI creation success for", image.reportID);
        setDownloadStatus("success");
      } else {
        console.error("NDVI Creation Failed for", image.reportID);
        setDownloadStatus("failed");
      }
    }
  }
  /**
   * When ndviLeft is set, sets the left NDVI map layer with the corresponding NDVI image
   * When ndviRight is set, sets the right NDVI map layer with the corresponding NDVI image
   * */

  useEffect(() => {
    async function updateView() {
      if (selectedRegion && selectedRegion.regionID === "no_properties") {
        console.log("No Properties selected");
        await removeLeftSat();
        await removeLeftNDVI();
        return;
      }

      if (isMounted1.current) {
      await removeLeftSat();
      await changeLeftNDVI();
    } else {
      isMounted1.current = true;
    }
  }
  updateView().catch(console.error);
  }, [ndviLeft, ndviLeftImages]);

  useEffect(() => {
    async function updateView() {
      if (selectedRegion && selectedRegion.regionID === "no_properties") {
        console.log("No Properties selected");
        await removeRightSat();
        await removeRightNDVI();
        return;
      }

      if (isMounted2.current) {
      await removeRightSat();
      await changeRightNDVI();
    } else {
      isMounted2.current = true;
    }
  }
  updateView().catch(console.error);
  }, [ndviRight, ndviRightImages]);


  /**
   *  Adds NDVI image layer to left map
   * */
  const changeLeftNDVI = async () => {
    console.log("changing left ndvi")
    if (selectedRegion && selectedRegion.regionID === "no_properties") {
      await removeLeftNDVIAllProperties();
      await removeLeftNDVI();
      return;
    }
    if (selectedRegion && selectedRegion.regionID === "all_properties") {
      await changeLeftNDVIAllProperties();
    } else {

      //Remove old layer if exists
      await removeLeftNDVIAllProperties();
      await removeLeftNDVI();
        let newSatID = "";
        const dynamicStyle = {
          ...NDVI_COLOR_STYLE,
          'fill-opacity': transparency
        };
      if (ndviLeft != null) {
        newSatID = ndviLeft.replace(/-/g, "");
      }

      //Add the new layer
      if (!beforeMap.current.getSource(newSatID)) {
        if (useVector) {
          beforeMap.current.addSource(newSatID, {
            type: "vector",
            url: "mapbox://korotu-conroy-trinh." + newSatID,
          });
          beforeMap.current.addLayer({
            id: newSatID,
            source: newSatID,
            "source-layer": "NDVI",
            type: "fill",
            filter: ["all", ["has", "DN"], ["!=", "DN", 0]],
            paint: dynamicStyle,
          });
        } else {
          beforeMap.current.addSource(newSatID, {
            type: "raster",
            url: "mapbox://korotu-conroy-trinh." + newSatID,
          });
          beforeMap.current.addLayer({
            id: newSatID,
            source: newSatID,
            type: "raster",
          });
        }
      }
      setOldNdviLeft(newSatID);
      movePropertiesToTop(beforeMap.current);
    }

  };

  const changeLeftNDVIAllProperties = async () => {
    let newSatIDs = []
    await removeLeftNDVI();
    await removeLeftNDVIAllProperties();
    ndviLeftImages.forEach(ndviLeft => {
    let newSatID = "";
    const dynamicStyle = {
      ...NDVI_COLOR_STYLE,
      'fill-opacity': transparency
    };
    if (ndviLeft != null) {
      newSatID = ndviLeft.replace(/-/g, "");
    }
    newSatIDs.push(newSatID);
    //Add the new layer
    if (!beforeMap.current.getSource(newSatID)) {
      if (useVector) {
        beforeMap.current.addSource(newSatID, {
          type: "vector",
          url: "mapbox://korotu-conroy-trinh." + newSatID,
        });
        beforeMap.current.addLayer({
          id: newSatID,
          source: newSatID,
          "source-layer": "NDVI",
          type: "fill",
          filter: ["all", ["has", "DN"], ["!=", "DN", 0]],
          paint: dynamicStyle,
        });
      } else {
        beforeMap.current.addSource(newSatID, {
          type: "raster",
          url: "mapbox://korotu-conroy-trinh." + newSatID,
        });
        beforeMap.current.addLayer({
          id: newSatID,
          source: newSatID,
          type: "raster",
        });
      }
    }
  });
    setOldNdviLeftImages(newSatIDs);
    movePropertiesToTop(beforeMap.current);

  }
  /**
   *  Adds NDVI image layer to right map
   * */
  const changeRightNDVI = async () => {
    console.log("changing right ndvi")
    if (selectedRegion && selectedRegion.regionID === "no_properties") {
      await removeRightNDVIAllProperties();
      await removeRightNDVI();
      return;
    }
    if (selectedRegion && selectedRegion.regionID === "all_properties") {
      await changeRightNDVIAllProperties();
    } else {
    //Remove old layer if exists
    await removeRightNDVIAllProperties();
    await removeRightNDVI();

    let newSatID = "";
    const dynamicStyle = {
      ...NDVI_COLOR_STYLE,
      'fill-opacity': transparency
    };
    if (ndviRight != null) {
      newSatID = ndviRight.replace(/-/g, "");
    }

    //Add the new layer
    if (!afterMap.current.getSource(newSatID)) {
      if (useVector) {
        afterMap.current.addSource(newSatID, {
          type: "vector",
          url: "mapbox://korotu-conroy-trinh." + newSatID,
        });
        afterMap.current.addLayer({
          id: newSatID,
          source: newSatID,
          "source-layer": "NDVI",
          type: "fill",
          filter: ["all", ["has", "DN"], ["!=", "DN", 0]],
          paint: dynamicStyle,
        });
      } else {
        afterMap.current.addSource(newSatID, {
          type: "raster",
          url: "mapbox://korotu-conroy-trinh." + newSatID,
        });
        afterMap.current.addLayer({
          id: newSatID,
          source: newSatID,
          type: "raster",
        });
      }
    }
    setOldNdviRight(newSatID);
    movePropertiesToTop(afterMap.current);
  }
  };

  const changeRightNDVIAllProperties = async () => { 
      let newSatIDs = []
      await removeRightNDVI();
      await removeRightNDVIAllProperties();
      ndviRightImages.forEach(ndviRight => {
      let newSatID = "";
      const dynamicStyle = {
        ...NDVI_COLOR_STYLE,
        'fill-opacity': transparency
      };
      if (ndviRight != null) {
        newSatID = ndviRight.replace(/-/g, "");
      }
      newSatIDs.push(newSatID);
      //Add the new layer
      if (!afterMap.current.getSource(newSatID)) {
        if (useVector) {
          afterMap.current.addSource(newSatID, {
            type: "vector",
            url: "mapbox://korotu-conroy-trinh." + newSatID,
          });
          afterMap.current.addLayer({
            id: newSatID,
            source: newSatID,
            "source-layer": "NDVI",
            type: "fill",
            filter: ["all", ["has", "DN"], ["!=", "DN", 0]],
            paint: dynamicStyle,
          });
        } else {
          afterMap.current.addSource(newSatID, {
            type: "raster",
            url: "mapbox://korotu-conroy-trinh." + newSatID,
          });
          afterMap.current.addLayer({
            id: newSatID,
            source: newSatID,
            type: "raster",
          });
        }
      }
    });
      setOldNdviRightImages(newSatIDs);
      movePropertiesToTop(afterMap.current);
  }

  /**
   * Removes the NDVI layer from the left map
   * */
  const removeLeftNDVI = async () => {
      await removeLeftNDVIAllProperties();
      if (beforeMap.current.getLayer(oldNdviLeft)) {
        beforeMap.current.removeLayer(oldNdviLeft);
        beforeMap.current.removeSource(oldNdviLeft);
        setOldNdviLeft(null);
      }
  }
  const removeLeftNDVIAllProperties = async () => {
      console.log("Left NDVI IDs", oldNdviLeftImages);
      for (let i = 0; i < oldNdviLeftImages.length; i++) {
        if (beforeMap.current.getLayer(oldNdviLeftImages[i])) {
          beforeMap.current.removeLayer(oldNdviLeftImages[i]);
          beforeMap.current.removeSource(oldNdviLeftImages[i]);
        }
      }
      setOldNdviLeftImages([]);
  }

  /**
   * Removes the NDVI layer from the right map
   * */
  const removeRightNDVI = async () => {
      await removeRightNDVIAllProperties();

      if (afterMap.current.getLayer(oldNdviRight)) {
        afterMap.current.removeLayer(oldNdviRight);
        afterMap.current.removeSource(oldNdviRight);
        setOldNdviRight(null);
      }

  }
  const removeRightNDVIAllProperties = async () => {
    console.log("Right NDVI IDs", oldNdviRightImages);
    for (let i = 0; i < oldNdviRightImages.length; i++) {
      if (afterMap.current.getLayer(oldNdviRightImages[i])) {
        afterMap.current.removeLayer(oldNdviRightImages[i]);
        afterMap.current.removeSource(oldNdviRightImages[i]);
      }
    }
    setOldNdviRightImages([]);
  }

  /**
   * This makes sure NDVI is fetched AFTER a satellite reportID is provided
   */
   useEffect(() => {
    //if ndvi then layer this on top
    const getNDVIImages = async () => {
    if (!trueColour) {
      if (selectedRegion && selectedRegion.regionID === "no_properties") {
        console.log("No Properties selected");
        await Promise.all([
          removeLeftSat(),
          removeLeftNDVI(),
          removeLeftSatAllProperties(),
          removeLeftNDVIAllProperties(),
        ]);
        return;
      }
      
      if (selectedRegion && selectedRegion.regionID === "all_properties" && leftSatImages.length > 0) {
        await fetchNDVIAllProperties(leftSatImages[0].reportID, "left")
      } else if (leftSatImage != null) {
        await removeLeftSat()
        await removeLeftNDVI()
        await removeLeftSatAllProperties()
        await removeLeftNDVIAllProperties()
        await fetchNDVI(leftSatImage.reportID, "left")
      }
    }
  };
  getNDVIImages().catch(console.error);
  return async () => {
    if (!trueColour && leftSatImage != null) {
      await removeLeftNDVI();
    }
  };
  }, [leftSatImage, leftSatImages, trueColour]);

  useEffect(() => {
    //if ndvi then layer this on top
    const getNDVIImages = async () => {
    if (!trueColour) {
      if (selectedRegion && selectedRegion.regionID === "no_properties") {
        console.log("No Properties selected");
        await Promise.all([
          removeRightSat(),
          removeRightNDVI(),
          removeRightSatAllProperties(),
          removeRightNDVIAllProperties(),
        ]);
        return;
      }

      if (selectedRegion && selectedRegion.regionID === "all_properties" && rightSatImages.length > 0) {
        await fetchNDVIAllProperties(rightSatImages[0].reportID, "right")
      } else if (rightSatImage != null) {
        await removeRightSat();
        await removeRightNDVI();
        await removeRightSatAllProperties()
        await removeRightNDVIAllProperties()
        await fetchNDVI(rightSatImage.reportID, "right")
      }
    }
  };
  getNDVIImages().catch(console.error);
  return async () => {
    if (!trueColour && rightSatImage != null) {
      await removeRightNDVI();
    }
  };
  }, [rightSatImage, rightSatImages, trueColour]);



  /** PROPERTY BOUNDARIES */

  /**
   * Once the component mounts, fetch landowners
   */
  useEffect(() => {
    const loadLandowners = () => {
      //setLoadingLandowners(true)
      getLandowners(params.projectID).then((allLandowners) => {
        setLandowners(allLandowners);
        setLoadedLandowners(true);
      });
    };

    loadLandowners();
  }, []);

  /**
   * Once the landowners are loaded, fetch all of their properties
   */
  useEffect(() => {
    const loadProperties = async () => {
      // getting the properties
      if (landowners) {
        let properties = [];
        for (let i = 0; i < landowners.length; i++) {
          let curr = landowners[i];
          let currProperties = await getPropertiesForLandowner(curr.profileID);
          properties = properties.concat(currProperties);
        }
        setRawProperties(properties);
      }
    };
    loadProperties()
      .then(() => {
        console.log("loaded properties");
      })
      .catch(console.log("WAS NOT ABLE TO LOAD PROPERTIES"));
    // return () => setExistingProperties(null)
  }, [loadedLandowners]);

  /**
   * Once the properties are fetched, obtain the property coordinates (parsedProperties)
   * */
  useEffect(() => {
    let properties = [];
    for (let i = 0; i < rawProperties.length; i++) {
      let currProperty = rawProperties[i];
      let regionInfo = getDataForRegion(currProperty.region);
      properties.push(regionInfo);
    }

    Promise.all(properties).then((res) => {
      setParsedProperties(res);
    });
  }, [rawProperties]);


  /**
   * Once property coordinates are loaded, display or hide property boundaries on map depending on showBoundaries flag
   * */
  useEffect(() => {
    if (showBoundaries) {
      generateBoundaries(beforeMap.current, parsedProperties);
      generateBoundaries(afterMap.current, parsedProperties);
    } else {
      removeBoundaries(beforeMap.current, parsedProperties);
      removeBoundaries(afterMap.current, parsedProperties);
    }
  }, [parsedProperties, showBoundaries]);

  // Helper functions for property boundaries

  /**
   * Generates the boundary layers for the properties
   * @param {*} map
   * @param {*} customPreloadedFeatures
   */
  function generateBoundaries(map, customPreloadedFeatures) {
    if (customPreloadedFeatures) {
      let neutralPropIDs = [];
      let highlightedPropIDs = [];
      let selectedPropIDs = [];
      let outlinePropIDs = [];
      customPreloadedFeatures.forEach((feature) => {
        createBoundaryLayers(
          map,
          feature,
          neutralPropIDs,
          outlinePropIDs,
          highlightedPropIDs,
          selectedPropIDs
        );

        const sourceID = map.getLayer(`${feature.data.id}-outline`).source;
        const source = map.getSource(sourceID);
        const type = source._data.geometry.type;
        const boundaryCoords =
          type === "MultiPolygon"
            ? source._data.geometry.coordinates[0]
            : source._data.geometry.coordinates;
        const center = polylabel(boundaryCoords, 1.0);

        createPopup(map, feature, new LngLat(center[0], center[1]), source);

        map.on("click", feature.data.id, (e) => {
          map.getCanvas().style.cursor = "pointer";

          let feature = e.features[0];
          // map.setFilter(`${feature.layer.id}-selected`, [
          //   "in",
          //   "ID",
          //   feature.properties.ID,
          // ]);

          map.flyTo({
            center: [center[0], center[1]],
            speed: 0.6,
            zoom: 15,
          });
        });
      });
      setNeutralIDs(neutralPropIDs);
      setOutlineIDs(outlinePropIDs);
      setHighlightedIDs(highlightedPropIDs);
      setSelectedIDs(selectedPropIDs);
    }
  }

  /** Helper for generateBoundaries() that creates the mapbox layers for the property boundaries */
  function createBoundaryLayers(
    map,
    feature,
    neutralPropIDs,
    outlinePropIDs,
    highlightedPropIDs,
    selectedPropIDs
  ) {
    // Helper that fetches the boundary color
    const getBoundaryColor = (opacity) => {
      return `rgba(7, 3, 255, ${opacity})`;
    };

    if (!map.getSource(feature.data.id)) {
      map.addSource(feature.data.id, {
        type: "geojson",
        data: feature.data,
      });
      map.addLayer({
        id: feature.data.id,
        type: "fill",
        source: feature.data.id,
        paint: {
          "fill-outline-color": getBoundaryColor(1.0),
          "fill-color": getBoundaryColor(0.0),
        },
      });
    }
    neutralPropIDs.push(feature.data.id);

    map.addLayer({
      id: `${feature.data.id}-outline`,
      type: "line",
      source: feature.data.id,
      paint: {
        "line-color": getBoundaryColor(1.0),
        "line-width": 2,
      },
    });
    outlinePropIDs.push(`${feature.data.id}-outline`);

    map.addLayer({
      id: `${feature.data.id}-highlighted`,
      type: "fill",
      source: feature.data.id,
      layout: {},

      paint: {
        "fill-outline-color": getBoundaryColor(1.0),
        "fill-color": getBoundaryColor(0.4),
      },
      filter: ["in", "ID", ""],
    });
    highlightedPropIDs.push(`${feature.data.id}-highlighted`);

    map.addLayer({
      id: `${feature.data.id}-selected`,
      type: "fill",
      source: feature.data.id,

      paint: {
        "fill-outline-color": getBoundaryColor(1.0),
        "fill-color": getBoundaryColor(0.7),
      },
      filter: ["in", "ID", ""] /** false by default */,
    });
    selectedPropIDs.push(`${feature.data.id}-selected`);

    map.on("mousemove", feature.data.id, function (e) {
      map.getCanvas().style.cursor = "pointer";

      let feature = e.features[0];

      map.setFilter(`${feature.layer.id}-highlighted`, [
        "in",
        "ID",
        feature.properties.ID,
      ]);
    });

    // TODO click functionality
    // map.on('click', feature.data.id, function (e) {
    //   map.getCanvas().style.cursor = 'pointer';
    //   let feature = e.features[0];
    //   map.setFilter(`${feature.layer.id}-selected`, [
    //     'in',
    //     'ID',
    //     feature.properties.ID
    //   ]);
    //   props.setRegionID(feature.properties.ID);
    // });
    map.on("mouseleave", feature.data.id, function () {
      map.getCanvas().style.cursor = "";
      map.setFilter(`${feature.data.id}-highlighted`, ["in", "ID", ""]);
    });
  }

  /** Helper for generateBoundaries() that creates popups for all the properties on the map */
  function createPopup(map, feature, center, mapSource) {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on("mouseenter", `${feature.data.id}-highlighted`, (e) => {
      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = "pointer";

      const propName = mapSource._data.name;
      const landownerName = mapSource._data.landowner;

      const tooltip = `
            <div style="pointer-events: none;">
              <span>
                <strong>Property Name:</strong>
                ${propName}
              </span>
              <br />
              <span>
                <strong>Landowner:</strong>
                ${landownerName}
              </span>
            </div>`;
      // Populate the popup and set its coordinates
      // based on the feature found.
      popup.setLngLat(center).setHTML(tooltip).addTo(map);
      // popup.setHTML(tooltip).addTo(map);
    });

    map.on("mouseleave", `${feature.data.id}-highlighted`, () => {
      map.getCanvas().style.cursor = "";
      popup.remove();
    });
  }

  /** Removes property boundaries from map */
  function removeBoundaries(map, customPreloadedFeatures) {
    customPreloadedFeatures.forEach((feature) => {
      if (map.getLayer(feature.data.id)) {
        map.removeLayer(feature.data.id);
      }
      if (map.getLayer(`${feature.data.id}-outline`)) {
        map.removeLayer(`${feature.data.id}-outline`);
      }
      if (map.getLayer(`${feature.data.id}-highlighted`)) {
        map.removeLayer(`${feature.data.id}-highlighted`);
      }
      if (map.getLayer(`${feature.data.id}-selected`)) {
        map.removeLayer(`${feature.data.id}-selected`);
      }
      if (map.getSource(feature.data.id)) {
        map.removeSource(feature.data.id);
      }
      setNeutralIDs([]);
      setOutlineIDs([]);
      setHighlightedIDs([]);
      setSelectedIDs([]);
    });
  }

  /** Displays property boundary layers as the top layers on map */
  const movePropertiesToTop = (map) => {
    const moveLayersToTop = (map, ids) => {
      ids.forEach((id) => {
        map.moveLayer(id);
      });
    };

    moveLayersToTop(map, neutralIDs);
    moveLayersToTop(map, outlineIDs);
    moveLayersToTop(map, highlightedIDs);
    moveLayersToTop(map, selectedIDs);
  };

  /** LINK FROM MOWING DISPLAY CHART */

  /** If user is coming from mowing display page chart, set the relevant state variables to the values passed from the link 
  */
  useEffect(() => {
    if (
      location.state &&
      location.pathname ==
      `/project/${params.projectID}/${params.mowingID}/ndvi-compare`
    ) {
      setClickedFromChart(true);
      setSatIdFromChart(location.state.satID);
      if (location.state.centroid) {
        setLong(location.state.centroid[0]);
        setLat(location.state.centroid[1]);
      }
    }
  }, [location]);

  /** Once clickedFromChart is set to true, center the map on the centroid of the clicked NDVI image
   * but only if the centroid has been computed and is not undefined
   */
  useEffect(() => {
    if (clickedFromChart && location.state.centroid) {
      beforeMap.current.flyTo({
        center: [long, lat],
        zoom: 13.5,
      });
      afterMap.current.flyTo({
        center: [long, lat],
        zoom: 13.5,
      });
      compare.current.setSlider(0);
    }
  }, [clickedFromChart]);

  /** Once satIdFromChart is set to true, set both the left and right satellite images to the clicked NDVI image */
  useEffect(() => {
    if (satIdFromChart != null && satelliteImages) {
      console.log(satIdFromChart);
      changeLeftSat(satIdFromChart);
      changeRightSat(satIdFromChart);
    }
  }, [satIdFromChart, satelliteImages]);

  /** HTML RENDERING */

  /** checking valid state */
  if (projectFailed) {
    return <Redirect to="/project" />;
  }

  /** renders the webpage components */
  return (
    <div className="page-margins ndvi-map-compare__content-wrapper">
      <div className="manual-change-detection__page__header">
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/summary-page`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Summary</h3>
        </Link>
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/ndvi-report-chart`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Chart</h3>
        </Link>
        <h3 className="manual-change-detection__page__header--active">Map</h3>
        <Link
            to={{
              pathname: `/project/${params.projectID}/${params.mowingID}/automatic-detection`, 
              state: {
                leftSatImg: leftSatImage ? leftSatImage : "", 
                rightSatImg: rightSatImage ? rightSatImage : "",
                selectedRgn: selectedRegion ? selectedRegion : "",
                leftDateSelected: leftSatImages?.length > 0 ? leftSatImages[0].image_date.substring(0, 7) : "",
                rightDateSelected: rightSatImages?.length > 0 ? rightSatImages[0].image_date.substring(0, 7) : ""
              }
            }}
            style={{ textDecoration: 'none'}} >
            <h3 className="manual-change-detection__page__header--inactive">Auto</h3>
        </Link>

        <div className="manual-detection-page__selections-container">
          {/** ##### Region/property selection ##### */}
          <div className="manual-detection-page__select-subcontainer">
            <p className="manual-detection-page__select-label">Property:</p>
            <div className="manual-detection-page__select">
              {projectRegions && projectRegions.length > 0 && satelliteImages && satelliteImages.length > 0 ? (
                <DropdownSearch
                  value={
                    selectedRegion ? selectedRegion.regionID : ""
                  }
                  setValue={handleRegionChange}
                  removeValue={removeSelectedRegion}
                  objectList={projectRegions ? projectRegions : []}
                  valueProperty="regionID"
                  dateProperty="name" // labels for the dropdown
                  onDisplayChange="regionID"
                  width="200px"
                />
              ) : (satelliteImages && satelliteImages.length > 0) && !projectRegions || 
                  ( projectRegions && projectRegions.length <= 0) ? (
                  <div className="loading-container">
                    <p>{props.region ? props.region.name.replace(/\.geojson$/, '') : "FIELD"}</p>
                  </div>
                ) : (
                  <div className="loading-container">
                    <p>Loading...</p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>

      {downloadStatus === "failed" && (
        <h3 className="processing-text">
          {" "}
          Oh no! Something went wrong, please try again later
        </h3>
      )}
      {downloadStatus === "pending" && (
        <h3 className="processing-text">
          {" "}
          The download you triggered is processing
        </h3>
      )}
      {downloadStatus === "success" && (
        <h3 className="processing-text">
          {" "}
          Your NDVI has finished downloading! Please reload the page.
        </h3>
      )}

      <div className="manual-change-detection__selection">
        {/** ##### LEFT SATELLITE IMAGE ##### */}
        <div className="manual-change-detection__select">
          <div className="manual-change-detection__select-subcontainer">
            {satelliteImages && satelliteImages.length > 0 ? (
              <DropdownSearch
                key={selectedRegion ? selectedRegion.regionID : ""}

                value={
                  selectedRegion && selectedRegion.regionID === "all_properties" && leftSatImages.length > 0
                    ? leftSatImages[0].reportID
                    : leftSatImage
                      ? leftSatImage.reportID
                      : satIdFromChart
                        ? satIdFromChart
                        : ""
                }
                setValue={changeLeftSat}
                removeValue={removeLeftSat}
                disabledItem={selectedRegion && selectedRegion.regionID === "all_properties" && rightSatImages.length > 0
                ? rightSatImages
                : rightSatImage}
                objectList={satImagesForSelectedRegion ? satImagesForSelectedRegion : []}
                valueProperty="reportID"
                nameProperty="name"
                dateProperty="image_date"
                onDisplayChange={dropdownDisplay}
                width="100%"
              />
            ) : (
              <div className="loading-container">
                <p>Loading...</p>
              </div>
            )}
            <button className="manual-change-detection__download-button">
              <img src={DownloadIcon} onClick={()=> downloadHandler("left")}/>
            </button>
          </div>
        </div>

        {/** ##### RIGHT SATELLITE IMAGE ##### */}
        <div className="manual-change-detection__select">
          <div className="manual-change-detection__select-subcontainer">
            {satelliteImages && satelliteImages.length > 0 ? (
              <DropdownSearch
                key={selectedRegion ? selectedRegion.regionID : ""}

                value={
                  selectedRegion && selectedRegion.regionID === "all_properties" && rightSatImages.length > 0
                    ? rightSatImages[0].reportID
                    : rightSatImage
                      ? rightSatImage.reportID
                      : satIdFromChart
                        ? satIdFromChart
                        : ""
                }
                setValue={changeRightSat}
                removeValue={removeRightSat}
                disabledItem={selectedRegion && selectedRegion.regionID === "all_properties" && leftSatImages.length > 0
                ? leftSatImages
                : leftSatImage}
                objectList={satImagesForSelectedRegion ? satImagesForSelectedRegion : []}
                valueProperty="reportID"
                nameProperty="name"
                dateProperty="image_date"
                onDisplayChange={dropdownDisplay}
                width="100%"
              />
            ) : (
              <div className="loading-container">
                <p>Loading...</p>
              </div>
            )}
            <button className="manual-change-detection__download-button">
              <img src={DownloadIcon} onClick={()=> downloadHandler("right")}/>
            </button>
          </div>
        </div>

        {showInitialPopup && (
            <div className="manual-change-detection__initial-popup">
              <p>Select two dates to view vegetation changes between those dates. <br></br>
                Note that overlay images of reports generated in 10m resolution are limited to 
                a high zoom level and cannot be viewed when zoomed out. </p>
              <button onClick={() => setShowInitialPopup(false)}></button>
            </div>
          )}
      </div>

      <MapboxCompareSlider
        // Project info
        projectName={props.project ? props.project.name : "Project name"}
        projectDescription={props.project ? props.project.description : "Project description"}
        // Mapbox centered on PEI
        longitude={-63.14}
        latitude={46.25}
        zoom={10}
        clickedFromChart={clickedFromChart}
        // Before
        beforeRaster={
          leftSatImage ? leftSatImage.reportID.replace(/-/g, "") : ""
        }
        beforeStyle={"mapbox://styles/mapbox/satellite-v9"}
        beforeMap={beforeMap}
        // After
        afterRaster={
          rightSatImage ? rightSatImage.reportID.replace(/-/g, "") : ""
        }
        afterStyle={"mapbox://styles/mapbox/satellite-v9"}
        afterMap={afterMap}
        // Compare (slider) componenet
        compare={compare}
        //NDVI & True Colour Toggle
        trueColour={trueColour}
        trueColourToggle={
          trueColour ? () => setTrueColour(false) : () => setTrueColour(true)
        }
        //Property Boundary Toggle
        showBoundaries={showBoundaries}
        propertyBoundaryToggle={
          showBoundaries
            ? () => setShowBoundaries(false)
            : () => setShowBoundaries(true)
        }
        transparency={transparency} onTransparencyChange={handleTransparencyChange}
      />
      <br />
    </div>
  );
}

function DropdownComponent(props) {
  const { satImageID, setValue, removeValue, satelliteImages, defaultVal } =
    props;
  return (
    <DropdownSearch
      value={satImageID}
      setValue={setValue}
      removeValue={removeValue}
      objectList={satelliteImages}
      valueProperty="reportID"
      nameProperty="name"
      dateProperty="image_date"
      onDisplayChange="image_date"
      width={316}
      height={32}
      defaultVal={defaultVal}
      selectedRegion={selectedRegion}
    // style={{height: "2rem", width: "19.75rem"}}
    />
  );
}
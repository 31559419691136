import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  Component,
} from "react";

import { Link, Redirect, useParams, useHistory } from "react-router-dom";
import "./satellite-image-display-page.css";
import "./mowing-display-page.css";
import DeltaIcon from "../images/icons/delta-icon-light.png";
import DownloadIcon from "../images/download-icon.png"
import { DropdownSearch } from "../components/modules/dropdown-search";
import { Bubble, Line } from "react-chartjs-2";

import {
  getMDReport,
  getMDReportCSV,
  getProjectRegions,
  getProjectProperties,
} from "../apis/landsteward/accounts";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  LineElement,
} from "chart.js";

import "./ndvi-report-chart.css";

const monthLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",];
const specialLabelColors = { Jan: '#adacac', Feb: '#adacac', Dec: '#adacac' };
const tickColor = monthLabels.map(label => specialLabelColors[label] || '#4D4D4D');
/**
 *
 * @param {*} props
 */
export function NDVIReportChart(props) {
  /** ##### URL Param Check ##### */
  let params = useParams();
  let [projectFailed, setProjectFailed] = useState(false);
  /** NDVI Chart Data */
  let [chartData, setChartData] = useState({});
  let [allDatasets, setAllDatasets] = useState([]);
  let [dataForSelectedRegion, setDataForSelectedRegion] = useState(null);
  /** Download */
  let [forestInfo, setForestInfo] = useState({});
  const [csvDownloadData, setCsvDownloadData] = useState(null);
  /** Regions */
  const [projectRegions, setProjectRegions] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  /** Properties */
  const [projectProperties, setProjectProperties] = useState(null);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    LineElement
  );

  const calculateSumOfAllAverageNDVI = (chartData) => {
    const allNDVIData = new Array(12).fill().map(() => ([]));
    const yearlyNDVIData = {}; // To store NDVI data for each year
    const lineColours = ['#4579FF', '#357960', '#FFC453', '#FF6960'];
  
    chartData.split('\n').forEach((row, index) => {
      if (index === 0) return;

      console.log("row: ", row)
      const columns = row.split(',');
      console.log("columns: ", columns)
      const date = new Date(columns[0]);
      const year = date.getFullYear();
      const month = date.getMonth();
      const averageNDVI = parseFloat(columns[5]);

      console.log("averageNDVI: ", averageNDVI)

      if (!isNaN(averageNDVI)) {
        if (!allNDVIData[month]) {
          allNDVIData[month] = [];
        }
        allNDVIData[month].push(averageNDVI);
      }

      // Store NDVI data for each year
      if (!yearlyNDVIData[year]) {
        yearlyNDVIData[year] = new Array(12).fill().map(() => []);
      }
      if (!yearlyNDVIData[year][month]) {
        yearlyNDVIData[year][month] = [];
      }
      yearlyNDVIData[year][month].push(averageNDVI);
    });

    // Remove extra NaN field from yearlyNDVIData
    delete yearlyNDVIData.NaN;

    console.log("averageNDVIArray: ", allNDVIData)
    console.log("yearlyNDVIData: ", yearlyNDVIData)

    const averageData = allNDVIData.map((monthValues) => {
      const sum = monthValues.reduce((acc, val) => acc + val, 0);
      return monthValues.length > 0 ? sum / monthValues.length : null;
    });
  
    // Calculate average NDVI for each year
    const yearlyAverageData = {};
    Object.keys(yearlyNDVIData).forEach(year => {
      const monthlyAverages = yearlyNDVIData[year].map(monthValues => {
        const sum = monthValues.reduce((acc, val) => acc + val, 0);
        return monthValues.length > 0 ? sum / monthValues.length : null;
      });
      yearlyAverageData[year] = monthlyAverages.reduce((acc, val) => acc + val, 0) / monthlyAverages.length;
    });
  
    // Construct datasets for each year
    const yearlyDatasets = Object.keys(yearlyAverageData).map((year, index) => {
      return {
        label: `${year}`,
        data: yearlyNDVIData[year].map((monthValues) => {
          const sum = monthValues.reduce((acc, val) => acc + val, 0);
          return monthValues.length > 0 ? sum / monthValues.length : null;
        }),
        borderColor: lineColours[index % lineColours.length],
        pointStyle: "circle",
        pointRadius: 6,
        borderWidth: 3,
      };
    });

    console.log("yearlyDatasets: ", yearlyDatasets)
  
    const allPropertiesDataset = {
      label: "Average",
      data: averageData,
      borderColor: "black",
      pointBorderColor: "black",
      pointStyle: "circle",
      pointRadius: 6,
      borderWidth: 3,
      borderDash: [10, 5],
    };
  
    return { yearlyDatasets, allPropertiesDataset };
  };

  // Hide the global back button on pages with local back button
  useEffect(() => {
    let globalBackButton = document.getElementsByClassName('back-button')[0];
    let localBackButton = document.getElementsByClassName('back-btn')[0];
    if (localBackButton != null) {
      globalBackButton.style.visibility = "hidden"
    } else {
      globalBackButton.style.visibility = "visible"
    }
  })

  // Handle new region selected on property dropdown
  const handleRegionChange = async (regionID) => {
    console.log("handleRegionChange...");
    if (!projectRegions) {
      console.error("No project regions loaded for the report.");
      return null;
    }

    // Set the selected region based on the regionID
    const firstMatchingRegion = projectRegions.find(region => region.regionID === regionID);
    if (firstMatchingRegion) {
      console.log("Found matching region: ", firstMatchingRegion)
      setSelectedRegion(firstMatchingRegion);
    } else {
      console.log("No matching region found")
    }

    // Handle All Properties selected
    if (regionID == "all_properties") {
      console.log("All Properties selected");
      const { yearlyDatasets, allPropertiesDataset } = calculateSumOfAllAverageNDVI(chartData);
      setAllDatasets([...yearlyDatasets, allPropertiesDataset]);

      const rows = String(chartData).split('\n');
      const csvArray = rows.map((row) => row.split(','));
      const headers = csvArray[0];
      const csvObjects = csvArray.slice(1).map((row) => {
        const csvObject = {};
        headers.forEach((header, index) => {
          csvObject[header] = row[index];
        });
        return csvObject;
      });
      setCsvDownloadData(csvObjects);
      
      return;
    }

    // Filter chart data to only include ones for the selected region/property
    const selectedProperty = projectProperties.find(property => property.region === regionID);
    if (!selectedProperty) {
      console.error("No property found for the selected region.");
      return null;
    }
    const propertyName = selectedProperty.property_name;
    console.log("propertyName: ", propertyName)

    const rows = chartData.split('\n');
    const headers = rows[0].split(',');
    const csvObjects = [];
    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(',');
      const obj = {};
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = row[j];
      }
      csvObjects.push(obj);
    }

    // Filter chartData to only include ones for the selected region/property
    let filteredData = [];
    filteredData = csvObjects.filter(entry => entry['Field Name'] === propertyName);
    console.log("filteredData: ", filteredData)

    // Reconstruct the filtered data back into a string with the same formatting as chartData
    let filteredString = "Date,Landowner,Field Name,No. of pixels in field,% of non-cloud data in field,Average NDVI,Max NDVI,Min NDVI,satID,ndviID,Data Source\n";
    filteredData.forEach(entry => {
      filteredString += Object.values(entry).join(",") + "\n";
    });

    console.log("filteredString: ", filteredString)
    setDataForSelectedRegion(filteredString);

    const dlRows = String(filteredString).split('\n');
    const dlCsvArray = dlRows.map((row) => row.split(','));
    const dlHeaders = dlCsvArray[0];
    const dlCsvObjects = dlCsvArray.slice(1).map((row) => {
      const csvObject = {};
      dlHeaders.forEach((header, index) => {
        csvObject[header] = row[index];
      });
      return csvObject;
    });
    setCsvDownloadData(dlCsvObjects);
  };

  const removeSelectedRegion = () => {
    setSelectedRegion(null);
  };

  function readCSVFile(response) {
    var lines = response.split("\n");
    let modifiedResponse = "";
    for (var i = 0; i < lines.length; i++) {
      var row = lines[i].split(","); // First column (Split on the separator!)
      if (row[0] == "Date") {
        modifiedResponse = modifiedResponse.concat(row + "\n");
        continue;
      } else if (row[0] != "Date" && row[0] != "") {
        let timeRemoved = row[0].slice(0, 10);
        row[0] = timeRemoved;
        let modifiedRow = row.join(",");
        modifiedResponse = modifiedResponse.concat(modifiedRow + "\n");
      }
    }
    return modifiedResponse;
  }

  // Initialize state and fetch data on page load
  useEffect(() => {
    getMDReportCSV(params.mowingID).then((response) => {
      let timeRemovedResponse = readCSVFile(response);
      setChartData(timeRemovedResponse);
      console.log("chartData: ", timeRemovedResponse);  
      console.log("typeof chartData: ", typeof timeRemovedResponse);    

      const rows = String(timeRemovedResponse).split('\n');
      const csvArray = rows.map((row) => row.split(','));
      const headers = csvArray[0];
      const csvObjects = csvArray.slice(1).map((row) => {
        const csvObject = {};
        headers.forEach((header, index) => {
          csvObject[header] = row[index];
        });
        return csvObject;
      });
      setCsvDownloadData(csvObjects);
    })
    
    getMDReport(params.mowingID, 'forest_detection').then((result) => {
      setForestInfo(result);
    });

    async function initializeState() {
      const projectID = params.projectID;
      let success = await props.changeProject(projectID);
      if (!success) {
        setProjectFailed(true);
        return;
      }

      getProjectRegions(params.projectID).then((fetchedRegions) => {
        let modifiedRegions = [...fetchedRegions];
        modifiedRegions.push({ regionID: "all_properties", name: "All Properties" });

        if (modifiedRegions && modifiedRegions.length > 0) {
            setProjectRegions(modifiedRegions);
            setSelectedRegion(modifiedRegions.find(region => region.regionID === "all_properties") || modifiedRegions[0]);
        } else {
            setProjectRegions(null);
            setSelectedRegion(null);
        }
        console.log('fetchedRegions: ', modifiedRegions);
        console.log('selectedRegion: ', selectedRegion);
      });
  
      getProjectProperties(params.projectID).then((fetchedProperties) => {
        console.log("fetchedProperties: ", fetchedProperties);
        setProjectProperties(fetchedProperties);
      });
    }

    props.changeCurrentState(params.projectID).then((isCorrectState) => {
      if (!isCorrectState.project) {
        console.error("Failing Projects", isCorrectState);
        setProjectFailed(true);
      }
    });

    initializeState().catch(console.error);
    // Clean state on unmount, use to avoid warning
    return () => setChartData({});
  }, []);

  //Once project data is loaded in, load in region data
  useEffect(() => {
    async function setCurrRegion() {
      if (props.project != null) {
        console.log(props.project);
        console.log("calling changeRegion()");
        let success = await props.changeRegion(props.project.region);
        if (!success) {
          console.log("Region failed to be set");
        }
      }
    }
    setCurrRegion().catch(console.error);
  }, [props.project]);

  // Populate chart data
  useEffect(() => {
    let data = chartData; // Default to chartData
    if (dataForSelectedRegion) {
      data = dataForSelectedRegion;
    }

    const rows = String(data).split('\n');
    const averageNDVIArray = [];

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(",");
      const date = row[0];
      const averageNDVI = parseFloat(row[5]);

      if (!isNaN(averageNDVI)) {
        averageNDVIArray.push({ date, averageNDVI });
      }
    }

    console.log("averageNDVIArray: ", averageNDVIArray);
    const lineColours = ['#4579FF', '#357960', '#FFC453', '#FF6960'];
    const years = [...new Set(averageNDVIArray.map(dataPoint => new Date(dataPoint.date).getFullYear()))];
    const datasets = [];
    years.forEach(year => {
      const dataForYear = new Array(12).fill(null);
      averageNDVIArray.forEach((dataPoint) => {
        const date = new Date(dataPoint.date);
        const dataYear = date.getFullYear();
        const month = date.getMonth();
        if (dataYear === year) {
          if (dataForYear[month] === null) {
            dataForYear[month] = [dataPoint.averageNDVI];
          } else {
            dataForYear[month].push(dataPoint.averageNDVI);
          }
        }
      });

      datasets.push({
        label: `${year}`,
        data: dataForYear.map((monthData) =>
          monthData ? monthData.reduce((a, b) => a + b, 0) / monthData.length : null
        ),
        borderColor: lineColours[years.indexOf(year)],
        pointBorderColor: lineColours[years.indexOf(year)],
        pointStyle: "circle",
        pointRadius: 6,
        borderWidth: 3,
        backgroundColor: lineColours[years.indexOf(year)],
      });
    });

    const averageData = new Array(12).fill(null).map((_, month) => {
      const valuesForMonth = datasets
        .map((dataset) => dataset.data[month])
        .filter((value) => typeof value === 'number');
      const averageValue =
        valuesForMonth.reduce((sum, value) => sum + value, 0) / valuesForMonth.length;
      return isNaN(averageValue) ? null : averageValue;
    });

    const averageDataset = {
      label: "Average",
      data: averageData,
      borderColor: "black",
      pointBorderColor: "black",
      pointStyle: "circle",
      pointRadius: 6,
      borderWidth: 3,
      borderDash: [10, 5],
    };

    setAllDatasets([...datasets, averageDataset]);
  }, [chartData, dataForSelectedRegion]);

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const csvContent = [
      headers.join(','),
      ...data.map((row) => headers.map((header) => row[header]).join(',')),
    ].join('\n');
    return csvContent;
  };

  const downloadHandler = () => {
    if (csvDownloadData) {
      const csvContent = convertToCSV(csvDownloadData);
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      const reportName = forestInfo.name ? forestInfo.name : "report-name";
      console.log("download selectedRegion: ", selectedRegion);
      const propertyName = selectedRegion ? selectedRegion.name : props.region ? props.region.name.replace(/\.geojson$/, "") : "FIELD";
      a.href = url;
      a.download = `${reportName}_${propertyName}_NDVI.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  if (projectFailed) {
    return <Redirect to="/project" />;
  }
  
  return (
    <div className="page-margins">
      <div className="manual-change-detection__page__header">
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/summary-page`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Summary</h3>
        </Link>
        <h3 className="manual-change-detection__page__header--active">Chart</h3>
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/ndvi-compare`}
          style={{ textDecoration: 'none' }}>
          <h3 className="manual-change-detection__page__header--inactive">Map</h3>
        </Link>
        <Link
          to={`/project/${params.projectID}/${params.mowingID}/automatic-detection`}
          style={{ textDecoration: 'none' }} >
          <h3 className="manual-change-detection__page__header--inactive">Auto</h3>
        </Link>

        <div className="automatic-detection-page__selections-container">
          {/** ##### Region/property selection ##### */}
          <div className="automatic-detection-page__select-subcontainer">
            <p className="automatic-detection-page__select-label">Property:</p>
            <div className="automatic-detection-page__select">
              {projectRegions && projectRegions.length > 0 ? (
                <DropdownSearch
                  value={
                    selectedRegion ? selectedRegion.regionID : ""
                  }
                  setValue={handleRegionChange}
                  removeValue={removeSelectedRegion}
                  objectList={projectRegions ? projectRegions : []}
                  valueProperty="regionID"
                  dateProperty="name" // labels for the dropdown
                  onDisplayChange="regionID"
                  width="200px"
                />
              ) : !projectRegions || ( projectRegions && projectRegions.length <= 0) ? (
                  <div className="loading-container">
                    <p>{props.region ? props.region.name.replace(/\.geojson$/, '') : "FIELD"}</p>
                  </div>
                ) : (
                  <div className="loading-container">
                    <p>Loading...</p>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="chart-title-container">
        <h2 className="chart-heading">NDVI by Month</h2>
        <h2 className="report-chart-delta"> <Link to={`/project/${params.projectID}/${params.mowingID}/ndvi-report-chart-delta`} className="report-chart-delta">Delta</Link>
          <Link to={`/project/${params.projectID}/${params.mowingID}/ndvi-report-chart-delta`} className="delta-link">
            <img src={DeltaIcon} alt="Delta Icon" />
          </Link>
        </h2>
        <button className="ndvi-chart-csv-download-button">
          <img
            src={DownloadIcon}
            onClick={() => downloadHandler()}
          />
        </button>
      </div>

      <div className="chart-container">
        <div className="centered-chart-container">
          <Line
            data={{
              labels: monthLabels,
              datasets: allDatasets,
            }}
            height={500}
            width={500}
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  title: {
                    display: true,
                    text: 'Month',
                    font: {
                      size: 19,
                      color: '#4D4D4D',
                    },
                  },
                  ticks: {
                    color: tickColor,
                  },
                },
                y: {
                  min: 0,
                  max: 1,
                  stepSize: 0.1,
                  title: {
                    display: true,
                    text: 'NDVI Value',
                    font: {
                      size: 19,
                      color: '#4D4D4D',
                    },
                  },
                },
              },
            }}
            plugins={[{
              beforeDatasetsDraw: (chart) => {
                const { ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
                ctx.fillStyle = 'rgba(0, 0, 0, 0.30)';
                ctx.fillRect(x.getPixelForValue(0), top, x.getPixelForValue(3) - x.getPixelForValue(1), height);
                ctx.fillRect(x.getPixelForValue(10), top, x.getPixelForValue(12) - x.getPixelForValue(11), height);
              }
            }]}
          />
        </div>
        <div className="chart-description">
          <p>Winter months (greyed out) are expected to be inconsistent due to variable snow cover, but snow cover may exist and affect consistency in other months too.</p>
        </div>
      </div>
    </div>
  );
}
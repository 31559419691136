import React, { useState, useEffect } from 'react';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';

import { SelectionGallery } from '../components/modules/selection-gallery';
import './report-selection-page.css';
import MaryCreek from '../images/sample_region_image.png';

/**
 *
 * @param {*} props
 *    <reports>:
 *    <region>:
 *    <project>:
 *
 * @returns
 */
export function ReportSelectionPage(props) {
  let match = useRouteMatch();
  let params = useParams();

  let [projectFailed, setProjectFailed] = useState(false);

  useEffect(async () => {
    let isCorrectState = await props.changeCurrentState({
      projectID: params.projectID,
    });
    if (!isCorrectState.project) {
      console.error('Failing Projects', isCorrectState);
      setProjectFailed(true);
    }
  }, []);

  function galleryFormatReports(reports) {
    let outputArray = [];

    let objCopy;
    for (let i = 0; i < reports.length; i++) {
      objCopy = { ...reports[i] };

      if (!objCopy.image) {
        objCopy.png = MaryCreek;
      }

      if (!objCopy.path) {
        objCopy.path = `/project/${params.projectID}/property/${params.propertyID}/report/${objCopy.reportID}`;
      }

      if (objCopy.reportType === 'floodrisk') {
        outputArray.push(objCopy);
      }
    }

    return outputArray;
  }

  if (projectFailed) {
    return <Redirect to={`/project`} />;
  }

  return (
    <div className="report-selection-page">
      <div className="report-gallery__title">
        <h1>Browse or Add Ecological Service Reports: Floodrisk</h1>
      </div>
      <SelectionGallery
        contentType="image"
        type="Floodrisk Report"
        collectionList={galleryFormatReports(
          props.reports ? props.reports : []
        )}
        IDAttribute="reportID"
        captionAttribute="name"
        imageAttribute="png"
        linkAttribute="path"
        createOption={true}
        createLink={`/project/${params.projectID}/property/${params.propertyID}/report/new`}
      />
    </div>
  );
}

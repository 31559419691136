import React, { useEffect, useState } from 'react';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';

import { SelectionGallery } from '../components/modules/selection-gallery';

import { getProjectSatelliteImages } from '../apis/landsteward/accounts';

import MaryCreek from '../images/sample_region_image.png';
import './satellite-image-selection-page.css';
import download from '../images/download-icon.png';

/**
 *
 * @param {*} props
 *    <satellteImages>:
 *    <region>:
 *    <project>:
 *
 * @returns
 */
export function SatelliteImageSelectionPage(props) {
  let match = useRouteMatch();
  let params = useParams();
  let [satelliteImages, setSatelliteImages] = useState([]);
  let [projectFailed, setProjectFailed] = useState(false);

  const fetchSatelliteImages = async () => {
    const satelliteImageData = await getProjectSatelliteImages(params.projectID)
    setSatelliteImages(satelliteImageData);
  }
  
  useEffect(() => {
    //Fetch all satellite images to display

    fetchSatelliteImages();

    let projectSuccess = props.changeProject(params.projectID);
    if (projectSuccess) {
    } else {
      setProjectFailed(true);
    }
    //clean state on unmount, use to avoid warning
    return () => setSatelliteImages([]);
  }, []);

  function galleryFormatSatelliteImages(images) {
    let outputArray = [];
    let objCopy;
    
    for (let i = 0; i < images.length; i++) {
      objCopy = { ...images[i] };
      // Set png_image to default if not exists
      if (!objCopy.png_image) {
        objCopy.png_image = MaryCreek;
      }
      // Redirect link to report display page
      if (!objCopy.path) {
        objCopy.path = `/project/${params.projectID}/property/${params.propertyID}/satellite_image/${objCopy.reportID}`;
      }

      outputArray.push(objCopy);
    }

    return outputArray;
  }
  console.log("satellite:");
  console.log(satelliteImages);
  if (projectFailed) {
    return <Redirect to={`/project`} />;
  } else {
    return (
      <div className="image-selection-page">
        <div className="image-gallery__title">
          <h2> Browse or Add Satellite Images </h2>
          
          <button className='image-gallery__title-import'
              


              >
                <img src={download}  />Import
              
            </button> 
          
        </div>
        <SelectionGallery
          contentType="image"
          type="Satellite Image"
          collectionList={galleryFormatSatelliteImages(satelliteImages)}
          IDAttribute="reportID"
          captionAttribute="name"
          imageAttribute="png_image"
          linkAttribute="path"
          createOption={true}
          createLink={`/project/${params.projectID}/property/${params.propertyID}/satellite_image/new`}
          showImage
        />
      </div>
    );
  }
}

import React, { useEffect, useState } from 'react'
import "./gedi-view-page.css"
import download from '../images/icons/info-icon.png'
import { useHistory, useParams, Link } from 'react-router-dom'
import { createCarbonReport, getCarbonReportCSV, getCarbonReportFiles } from '../apis/landsteward/valuation'
import useFetch, { useFetchBlob } from '../apis/landsteward/useFetch'
import { CORE_PORT, url } from '../apis/landsteward/urlconfig'
import { MapboxRegionSelection } from '../components/mapbox/mapbox-region-selection'
import useFetchCSV from '../apis/landsteward/useFetchCSV'
import { getReport } from '../apis/landsteward/accounts'


export function GEDIViewPage(props) {

    let params = useParams()
    // console.log("params", params)
    let projectPage = `/project/${params.projectID}/reports/`
    const history = useHistory()


    let date = new Date()
    date = date.toISOString().split('T')[0].replace(/-/g, '')

    const [reportName, setReportName] = useState("")
    const [projectName, setProjectName] = useState("")
    const [satelliteStartDate, setSatelliteStartDate] = useState("")
    const [satelliteEndDate, setSatelliteEndDate] = useState("")

    const [granulesCount, setGranulesCount] = useState(0)
    const [granulesDateRange, setGranulesDateRange] = useState(0)
    const [granulesSize, setGranulesSize] = useState(0)

    const [warning, setWarning] = useState("")
    const [warning2, setWarning2] = useState("")
    const [showSatellitePopUp, setShowSatellitePopUp] = useState(false)
    const [showInterpolationPopUp, setShowInterpolationPopUp] = useState(false)

    const [reportID, setReportID] = useState(null)
    const [carbonOutput, setCarbonOutput] = useState(null)
    const [gediOutput, setgediOutput] = useState(null)

    const [regionID, setRegionID] = useState('')

    let [formattedProperties, setFormattedProperties] = useState([]);
    let [testState, setTestState] = useState(null);
    let [reports, setReports] = useState(null);

    const [calculationMethod, setCalculationMethod] = useState("")

    const [isMount, setIsMount] = useState(true)

    useEffect(() => {
        // console.log("props", props)
        if (isMount) {
            // const proc = async () => await props.changeProject(params.projectID)
            // proc().then(() => { setProjectName(props.project.name) }).catch(console.error)

            const getReports = async () => {
                // console.log(params.projectID)
                let path = `${url}:${CORE_PORT}/projects/${params.projectID}/reports/`
                let httpMethod = "GET"
                let output = await useFetch(path, httpMethod, null, true) // when a get request fails, you get an empty object in the `data` field 
                // console.log(output)
                // console.log(output.data) // {} if request fails }
                // console.log(output.data.reports) //1. undefined if request fails //  2. [] is no reports associated with project
                // handle 1 here 

                if (output.data == {} || output.data.reports == undefined) {
                    console.error("Error retrieving report!")
                    return
                }

                return output.data.reports
            }

            const getGediData = async (projectReports) => {

                let selectedGediReport = {};

                if (projectReports.length === 0) {
                    // no reports in project
                    return {}
                } else if (params.reportName != undefined) { // if report name query parameter exists, get gedi report
                    let gediReports = projectReports.filter(function isReportGediCalculation(report) {
                        return (report.name === params.reportName && report.reportType == "gedi_calculation");
                    })
                    selectedGediReport = gediReports[0]
                } else { // else, get the latest gedi report
                    let gediReports = projectReports.filter(function isGediCalculation(report) {
                        return report.reportType === "gedi_calculation";
                    })
                    selectedGediReport = gediReports[gediReports.length - 1]
                }

                console.log("latest", selectedGediReport)

                let httpMethod = "GET"
                let path = `${url}:${CORE_PORT}/reports/gedi_calculation/${selectedGediReport.reportID}/`
                console.log("path", path)
                let output = await useFetch(path, httpMethod, null, true)


                console.log("output", output.data) // {} if request fails

                setGranulesCount(output.data.GEDI_granules_found)
                setGranulesDateRange(output.data.GEDI_granules_found_date_range)
                setGranulesSize(output.data.GEDI_granules_size)
                setReportName(output.data.name)
                setReportID(output.data.reportID)
                setSatelliteStartDate(output.data.start_date)
                setSatelliteEndDate(output.data.end_date)
                setgediOutput(output.data)
                return
            }

            const getCarbonData = async (projectReports) => {

                let selectedCarbonReport = {};
                console.log("project reports", projectReports)

                if (projectReports.length === 0) {
                    // no reports in project
                    return {}
                } else if (params.reportName != undefined) { // if report name query parameter exists, get gedi report
                    let carbonReports = projectReports.filter(function isReportCarbonCalculation(report) {
                        return (report.name === params.reportName && report.reportType == "carbon_calculation");
                    })
                    selectedCarbonReport = carbonReports[0]
                } else { // else, get the latest gedi report
                    let gediReports = projectReports.filter(function isCarbonCalculation(report) {
                        return report.reportType === "carbon_calculation";
                    })
                    selectedCarbonReport = carbonReports[carbonReports.length - 1]
                }


                /* if unable to find report, send back to all projects page */
                if (selectedCarbonReport === undefined) {
                    window.alert("Couldn't find report ID!")
                    history.push("/project")
                    return;
                }
                console.log("latest report!", selectedCarbonReport.reportID)

                let httpMethod = "GET"
                let path = `${url}:${CORE_PORT}/reports/carbon_calculation/${selectedCarbonReport.reportID}/`
                let output = await useFetch(path, httpMethod, null, true)

                // console.log('here is the output: ')
                // console.log(output.data)
                // console.log(output)
                setCarbonOutput(output.data)
                setCalculationMethod(output.data.calculationMethod)
                setWarning2(output.data.error_msg)
                return
            }

            const process = async () => {
                let projectReports = await getReports();
                getGediData(projectReports);
                getCarbonData(projectReports)

            }
            process()

            // hides the global back button on pages with local back button
            let globalBackButton = document.getElementsByClassName('back-button')[0];
            let localBackButton = document.getElementsByClassName('back-btn')[0];
            if (localBackButton != null) {
                globalBackButton.style.visibility = "hidden"
            } else {
                globalBackButton.style.visibility = "visible"
            }
            globalBackButton.onclick = () => {
                history.push("/")
            }
            globalBackButton.onclick = () => {
                console.log(projectPage)
            }
        
            
        }
    }, [])

    useEffect(() => {
        let backButton = document.getElementsByClassName('back-button')[0];
        backButton.style.visibility = "visible"
        backButton.onClick = () => { console.log("sent back") }
    })

    /*
        Clean up useEffect on unmount
    */
    useEffect(() => {
        return () => {
            setGranulesCount(0)
            setGranulesDateRange(0)
            setGranulesSize(0)
            setReportName("")
            setReportID(null)
            setSatelliteStartDate("")
            setSatelliteEndDate("")
            setgediOutput(null)
            setCarbonOutput(null)
            setCalculationMethod("")
            setIsMount(false)
        }
    }, []);


    const navigateToReport = () => {
        console.log("state", {
            reportName: reportName,
            carbonOutput: carbonOutput,
            gediOutput: gediOutput
        })
        history.push(`/project/${params.projectID}/carbon_report`, {
            state: {
                reportName: reportName,
                carbonOutput: carbonOutput,
                gediOutput: gediOutput
            }
        })
    }

    const downloadCSV = async () => {
        if (carbonOutput != null) {
            let CSVOutput = await useFetchCSV(carbonOutput.summary_csv, 'GET', null, false)
            if (CSVOutput !== null) {
                const blob = new Blob([CSVOutput.data], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.setAttribute('hidden', '');
                a.setAttribute('href', url);
                a.setAttribute('download', `carbon_report_csv_${date}`);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }
    }

    function handleDownload(event) {
        const value = event.target.value;
        if (value === "csv") {
            downloadCSV();
        } else if (value === "zip") {
            downloadAll();
        }
    }

    const downloadAll = async () => {
        // console.log(carbonOutput)
        if (carbonOutput !== null) {
            let allOutput = await useFetchBlob(carbonOutput['complete_zip'])
            // console.log(allOutput)
            // console.log(allOutput.blob)
            if (allOutput !== null) {
                const url = window.URL.createObjectURL(allOutput.blob);
                const a = document.createElement('a');
                a.setAttribute('hidden', '');
                a.setAttribute('href', url);
                a.setAttribute('download', `${reportName}_CarbonReport_${date}`);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }

        }
    }

    return (
        <div className='gedi-view-body'>
            <div className='gedi-view-form-col'>
                <div className="gedi-view-title">
                    <h1>View Carbon Report</h1>
                    <img
                        src={download}
                        onMouseOver={() => setShowSatellitePopUp(true)}
                        onMouseOut={() => setShowSatellitePopUp(false)}
                    ></img>
                    {showSatellitePopUp ?
                        <div className='gedi-view-interpolation-popup'>
                            Granules contain information on estimated AGB for the selected
                            region (from the GEDI instrument on the International Space Station)
                        </div>
                        : null
                    }
                </div>
                <div className="gedi-view-page-layout">
                    <div className="gedi-view-section-1">
                        <div className="gedi-view-form-body-1">
                            <div className='gedi-view-input-block'>
                                <label>Project:</label>
                                <label>{projectName}</label>
                            </div>
                            <div className='gedi-view-input-block'>
                                <label>Carbon Report Name:</label>
                                <label>{reportName}</label>
                            </div>
                            <div className='gedi-view-input-block'>
                                <label>Satellite Start Date:</label>
                                <label>{satelliteStartDate}</label>
                            </div>
                            <div className='gedi-view-input-block'>
                                <label>Satellite End Date:</label>
                                <label>{satelliteEndDate}</label>
                            </div>
                        </div>
                        <div className="gedi-view-form-body-2">
                            <h3 className="gedi-view-subtitle">GEDI Granules Were Found...</h3>
                            <div className='gedi-view-input-block'>
                                <label>Total granules found:</label>
                                <label name="granules-count">{granulesCount}</label>
                            </div>
                            <div className='gedi-view-input-block'>
                                <label>Total granules within date range:</label>
                                <label name="granules-date-range">{granulesDateRange}</label>
                            </div>
                            <div className='gedi-view-input-block'>
                                <label>Granules Size (MB):</label>
                                <label name="granules-size">{Math.round(granulesSize * 100) / 100}</label>
                            </div>
                            <br /> <p className="gedi-warning-text2">{warning2} <br></br></p>
                        </div>
                    </div>
                    <div className="gedi-view-section-2">
                        <div className='gedi-view-mapbox'>
                            {/* {false ? null :
                            <MapboxRegionSelection
                                longitude={-63.1311}
                                latitude={46.2382}
                                zoom={9}
                                style="mapbox://styles/korotu-conroy-trinh/ckwqnggaf0esg14odvehgi6hd"
                                setRegionID={setRegionID}
                                geoJsonID={regionID ? getGeojsonFromRegionID(regionID).id : ''}
                                setRegionName={setRegionName}
                                customPreloadedFeatures={formattedProperties}
                                selectedPreloadedFeatureRegionID={regionID}
                                mapWidth={'99%'}
                                mapHeight={'200px'}
                                drawEnabled={true}
                            />
                        }     */}
                        </div>
                        <div className="gedi-view-interpolation-section">
                            <div className="gedi-view-interpolation-result">
                                <label>Interpolation Method:</label>
                                <label>{calculationMethod == 'avg' ?
                                    'Inverse Distance Weighting by forest type' :
                                    'Inverse Distance Weighting with all forest'}</label>
                            </div>
                            <img src={download}
                                onMouseOver={() => setShowInterpolationPopUp(true)}
                                onMouseOut={() => setShowInterpolationPopUp(false)}
                            />
                            {showInterpolationPopUp ?
                                <div className='gedi-view-interpolation-popup'>
                                    Method of estimating data in areas that do not have any data
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
                <div className='gedi-view-button-section'>
                    <button
                        type="button"
                        className="gedi-view-button"
                        onClick={navigateToReport}
                    >
                        View on Map
                    </button>
                    <select className="gedi-view-dropdown" onChange={handleDownload}>
                        <option value="">Download</option>
                        <option value="csv">Download (.csv)</option>
                        <option value="zip">Download All (.zip)</option>
                    </select>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState, useRef } from "react";
import { Link, useParams} from "react-router-dom";
import {
  getObservationsForProjects,
  getObservationsForLandowner,
} from "../../apis/landsteward/accounts";
import {
  getObservationsForLandownerMock,
  getObservationsForProjectsMock,
  getObservationsForPropertyMock,
} from "../../apis/landsteward/mock_accounts";
import { MOCK_DATA } from "../../apis/landsteward/urlconfig";
import {archiveRegion, archivePropertyByRegion, renameRegion,renamePropertyByRegion, getAllMDReport, updateForestReportInfo} from "../../apis/landsteward/accounts.js"

import Clip from "../../images/icons/clip-icon.png";

import "./selection-gallery.css";
import order from "../../images/icons/order-icon.png";
import ellipses from "../../images/icons/project-ellipses.png";
import whiteEllipses from "../../images/icons/white-project-ellipses.png";
import useFetch from "../../apis/landsteward/useFetch";
import { terminateGeneratingReport } from "../../apis/landsteward/mowing.js"
import { RenamePopup } from "../modules/rename-popup"
import { DeletePopup } from "../modules/delete-popup"


/**
 *
 * @param {*} props
 *    <contentType>:
 *    <type>:
 *    <collectionList>:
 *    <createOption>:
 *    <createLink>:
 *    <IDAttribute>:
 *    <captionAttribute>:
 *    <imageAttribute>:
 *    <linkAttribute>:
 *    <defaultImage>:
 * @returns Selection Gallery which allows a user to select items which
 *  represent the objects in the provided collection list.
 */

export function SelectionGalleryTemp2(props) {
    
  

  return (
    <div className="selection-gallery">
      {/* <div className='selection-gallery-heading'>
      </div> */}
      <SelectionGalleryCollectionTemp2 {...props} />
      {/** *}
      <SelectionGalleryCollection
        contentType={props.contentType}
        collectionList={props.collectionList}
        createOption={props.createOption}
        createLink={props.createLink}
      />
      {/** */}
    </div>
  );
}

/**
 *
 * @param {*} props
 *    <contentType>: only 'image' and 'component' are supported
 *    <collectionList>: collection of objects which improve the code
 *
 *
 *    <createOption>: if true, a gallery item with a plus sign ('+') will be
 *      placed in the gallery. This will lead to another page to allows the
 *      user to create that item. In addition, the following must be defined:
 *    <createLink>: the link to which the user will go to create a gallery item
 */

export function SelectionGalleryCollectionTemp2(props) {

  return (
    <div className="selection-gallery__collection">
      {
        /** Gallery Items */
        
        props.collectionList.map((item) => (
          
          <SelectionGalleryItemTemp2
            key={item[props.IDAttribute]}
            projectID={item["projectID"]}
            // caption={item[props.captionAttribute]}
            regionID={item[props.IDAttribute]}
            regionName={item[props.captionAttribute]}
            link={item[props.linkAttribute]}
            showImage={props.showImage}
            isSelected={props.selectedItem === item[props.IDAttribute]}
            onToggleSelect={() => props.onItemSelect(item[props.IDAttribute])}
            onRegionDelete={props.onRegionDelete}
            onNameChange={props.onNameChange}

          >
            {props.showImage && ( //item.contentType === 'image' ?
              // Nov 19 design removes the image -Leo
              <img
                src={
                  item[props.imageAttribute]
                    ? item[props.imageAttribute]
                    : props.defaultImage
                }
              />
            )}

            {item[props.captionAttribute]}
          </SelectionGalleryItemTemp2>
        ))
      }
    </div>
  );
}

/**
 * Gallery Item component that can be hovered, selected and placed among
 * other items in a gallery. Contains a short title/caption describing what
 * the gallery item contains.
 * @param {*} props
 *    children: Components to display inside the gallery item to display
 *    caption: Necessary to describe each item separately
 *    add: Boolean to determin add button
 */

export function SelectionGalleryItemTemp2(props) {
  let params = useParams();
  let menuRef = useRef()
  let itemRef = useRef()
  const [isRenamePopupVisible, setRenamePopupVisible] = useState(false);
  const [isDeletePopupVisible, setDeletePopupVisible] = useState(false);
  const [isRenamingProperty, setRenamingProperty] = useState(false);
  const [isDeletingProperty, setDeletingProperty] = useState(false);
  const isMounted = useRef(true);

  const { isSelected } = props;
  useEffect(() => {
    document.addEventListener("mousedown", outsideClick);
    return () => {
      document.removeEventListener("mousedown", outsideClick);
    }
  })

  function outsideClick(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      menuRef.current.style.display = "none";
    }
  }

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  let itemStyles = isSelected ? { background: "#357960", color: "white" } : { background: "#D8EDD4", color: "#357960" };
  let contentStyles = isSelected ? { color: "white" } : { color: "#357960" };
  let contextMenuImgSrc = isSelected ? whiteEllipses : ellipses;


  let style = {};
  if (props.add) {
    style["background"] = "#357960";
    style["fontSize"] = "50";
  }
  if (props.showImage) {
    style["height"] = "16.5rem";
  }

  const handleChangeName = async (newName) => {
    let getReports = await getAllMDReport(params.projectID, 'forest_detection');
    console.log(getReports);
    for (const report of getReports) {
      if (report.report_info === "failed") {
        console.log('skipping failed report:', report.reportID);
        continue;
      }

      let reportInfoObj = JSON.parse(report.report_info);
      console.log(reportInfoObj)
      console.log(props.regionName);

      Object.keys(reportInfoObj).forEach(date => {
        reportInfoObj[date].forEach(entry => {
          if (entry.fieldName === props.regionName) {
            console.log(entry.fieldName);
            entry.fieldName = newName;
            console.log(entry.fieldName);
          }
        });
      });
      console.log(report.reportID);
      console.log(JSON.stringify(reportInfoObj));
      const updateResult = await updateForestReportInfo(report.reportID, JSON.stringify(reportInfoObj));
      if (updateResult === null) {
        console.error('Failed to update report info for reportID:', report.reportID);
      }
      console.log(updateResult)
    }
    

    const newReports = await getAllMDReport(params.projectID, 'forest_detection');
    const newReportInfos = newReports.map(report => report.report_info);
    console.log(newReports);
    console.log(newReportInfos);
    setRenamingProperty(true)
    const successRegion = await renameRegion(props.regionID, newName)
    console.log(successRegion)
    const successProperty = await renamePropertyByRegion(props.regionID, newName)
    setRenamingProperty(false)
    console.log(successProperty)


    if (successRegion && successProperty && newReports) {
      window.alert(`Successfully changed property name to: ${newName}`)
      props.onNameChange(newName, props.regionID);
    } else {
      console.error("Failed to change property name")
    }
  }

  // function showRegionDeletionConfirmation() {
  //   let result = confirm(`Are you sure you want to delete property: "${props.regionName}?"`);
  //   if (result) {
  //     archiveRegion(props.regionID).then(() => {
  //       archivePropertyByRegion(props.regionID).then(() => {
  //         props.onRegionDelete(props.regionID);
  //       });
  //     });
  //   }
  // }

  function showRegionDeletionConfirmation() {
      archiveRegion(props.regionID).then(() => {
        archivePropertyByRegion(props.regionID).then(() => {
          props.onRegionDelete(props.regionID);
        });
      });
  }

  return (
    <figure className="selection-gallery__item2" onClick={props.onToggleSelect} style={itemStyles}>
       
      <div style={{ all: "unset" }}>
        <div className="selection-gallery__item__content" style={contentStyles}>
          {props.children}

          <div className="selection-gallery__item__caption">
            {props.caption}

            {/* <div className='last-updated'>Last Updated: YYYY-MM-DD</div> */}
            {/* not currently implemented */}
          </div>
        </div>
      </div> 
    

      <div className="selection-gallery__item__context_menu" onClick={() => {
          let menu = menuRef.current;
          if (menu.style.display === "inline-block") {
            menu.style.display = "none";
          } else {
            menu.style.display = "inline-block";
          }
          // menu.addEventListener("mousedown", (event) => {menu.contains(event.target)}, () => {

          // })
      }}>
          <div className={`context-menu`} ref={menuRef}>
          <button onClick={() => setRenamePopupVisible(true)}>Rename Property</button>
                {isRenamePopupVisible && (
                  <RenamePopup
                    onRenameType={"Property"}
                    onClose={() => {
                      if (isMounted.current) {
                        setRenamePopupVisible(false)
                      }
                    }}
                    onChangeName={handleChangeName}
                    onRenamingProgress={isRenamingProperty}
                  />
                )}
                
          <button onClick={() => setDeletePopupVisible(true)}>Delete Property</button>
                {isDeletePopupVisible && (
                  <DeletePopup
                    onDeleteType={"Property"}
                    onClose={() => {
                      if (isMounted.current) {
                        setDeletePopupVisible(false)
                      }
                    }}
                    onDeleteConfirm={showRegionDeletionConfirmation}
                    onDeletingProgress={isDeletingProperty}
                    regionName={props.regionName}
                  />
                )}
            {/* <button onClick={async () => {
              console.log(props.regionID)
              showRegionDeletionConfirmation(props.regionID)
            }}>Delete Property{props.caption}</button>
             */}
          </div>
          <img className="selection-gallery__item__context_menu-img" src={contextMenuImgSrc}/>
          
      </div>
    </figure>
  );
}

import React, { useState } from 'react';
import HamburgerIcon from "../../images/hamburger.svg"
import CloseIcon from "../../images/close.svg"
import InfoIcon from "../../images/icons/info-icon-new.svg";

import './hamburger-menu.css'

const HamburgerMenu = (props) => {
  const [open, setOpen] = useState(false);
  const [showSensitivityTooltip, setShowSensitivityTooltip] = useState(false);


  const toggle = () => {
    setOpen(!open);
  };
  const handleTransparencyChange = (event) => {
    const newValue = event.target.value / 100;
    console.log(`New transparency value: ${newValue}`);

    if (props.onTransparencyChange) {
      props.onTransparencyChange(newValue);
    }
  };

  return (
    <div>
      <img className="hamburger-btn" src={HamburgerIcon} onClick={toggle}></img>
      {open && (
        <div className="sidebar">
          <img className="close-btn" src={CloseIcon} onClick={toggle}></img>
          <h3 className="bolded-text">{props.projectName}</h3>
          {/*
          <h3 className="bolded-text">Vegetation Index</h3>
          <h4 className="normal-text">NDVI</h4>
          <h4 className="normal-text">EVI</h4>
          <h4 className="normal-text">For more information on NDVI and EVI, visit our user guide.</h4>
          {/* <h4 className="normal-text">{props.projectDescription}</h4> */}
          <h4 className="bolded-text">Detection Threshold
            <img
              className='info-icon'
              alt='info-icon'
              src={InfoIcon}
              onMouseOver={() => setShowSensitivityTooltip(true)}
              onMouseOut={() => setShowSensitivityTooltip(false)}
            />
          </h4>
          {showSensitivityTooltip ? (
            <div className='popup' style={{ width: '70%' }}>
              ForestChangeWatch currently uses a 93% DNDVI threshold to identify large disturbances dates. 
              This means that when the amount of significant forest change (change in loss of NDVI) exceeds 
              93%, the date with said change will be flagged as a possible large disturbance.
            </div>
          ) : null}
          <h4 className="bolded-text">Image Transparency</h4>
          <div className="slider-container">
            <div className="slider-labels">
              <label>100%</label>
              <label>0%</label>
            </div>
            <input
              type="range"
              min="0"
              max="100"
              value={props.transparency * 100}
              onChange={handleTransparencyChange}
            />
          </div>
         {/* <h4 className="bolded-text">Other Settings</h4>
          <h4 className="normal-text">Show property boundaries on maps</h4>
        */}
        </div>
      )}
    </div>
  );
};
export default HamburgerMenu;
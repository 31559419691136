import React, { useEffect, useState } from "react";
import {
  Redirect,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  getDataForRegion,
  getLandownerDetails,
  getPropertiesForLandowner,
} from "../apis/landsteward/accounts";
import {
  getDataForRegionMock,
  getLandownerDetailsMock,
  getPropertiesForLandownerMock,
} from "../apis/landsteward/mock_accounts";
import { MOCK_DATA } from "../apis/landsteward/urlconfig";
import { MapboxRegionSelection } from "../components/mapbox/mapbox-region-selection";
import RecentActivityFrame from "../components/modules/recent-activity-frame";
import { PageHeader } from "../components/modules/page-header";
import { ExpandableFrame } from "../components/modules/expandable-frame";
import {
  ObservationsGalleryCollection,
  PropertyGalleryCollection,
} from "../components/modules/selection-gallery";
import { FieldObservationDisplayCard } from "../components/modules/field-detail-display-card";

import "./project-landowner-page.css";
import { PropertyDetailsDisplayCard } from "../components/modules/property-detail-display-card";
import LandownerIcon from "../images/landowner-icon.png";
import StarIcon from "../images/star.png";
import SortIcon from "../images/order-icon.png";
import Characteristics from "../components/modules/characteristics";
/**
 *
 * @param {object}    project
 * @param {function}  changeProject
 * @param {Array}     landowners
 */
export default function ProjectLandownerPage(props) {
  let params = useParams();
  let history = useHistory();
  let match = useRouteMatch();

  const [loadingFeatures, setLoadingFeatures] = useState(true);

  const [landowner, setLandowner] = useState(null);
  const [regionID, setRegionID] = useState("");
  const [, setRegionName] = useState("");
  const [customPreloadedFeatures, setCustomPreloadedFeatures] = useState([]);

  const [showObservations, setShowObservations] = useState(false);
  const [showProperties, setShowProperties] = useState(false);
  const [landownerProperties, setLandownerProperties] = useState([]);

  const [characteristics, setCharacteristics] = useState([]);
  const [focussedProperty, setFocussedProperty] = useState(null);
  const getPropertyFromRegionID = (regionId) => {
    let property = null;

    landownerProperties.forEach((item) => {
      if (item.region == regionId) {
        property = item;
        return;
      }
    });
    return property;
  };

  const getGeojsonFromRegionID = (regionID) => {
    let json = null;

    customPreloadedFeatures.forEach((item) => {
      if (item.regionID == regionID) {
        json = item.data;
        return;
      }
    });

    return json;
  };

  const onClickCallback = (id, name) => {
    return () => {
      setRegionName(name);
      setRegionID(id);
      let property = getPropertyFromRegionID(id);
      setFocussedProperty(property);
    };
  };

  const setRegionIDAndShow = (id) => {
    setRegionID(id);
    setShowProperties(true);
    setShowObservations(false);
  };

  /** Change to specific project at the start */
  let [projectDoesNotExist, setProjectFail] = useState(false);
  let [landownerDoesNotExist, setLandownerNotExist] = useState(false);
  useEffect(async () => {
    let success = await props.changeProject(params.projectID);

    if (!success) {
      setProjectFail(true);
      return;
    }

    // Get landowner info
    setLoadingFeatures(true);

    const landowner_details = MOCK_DATA
      ? getLandownerDetailsMock(params.projectID, params.landownerID)
      : await getLandownerDetails(params.projectID, params.landownerID);
    if (!landowner_details) {
      setLandownerNotExist(true);
      return;
    }

    setLandowner(landowner_details);

    const properties = MOCK_DATA
      ? getPropertiesForLandownerMock(params.landownerID)
      : await getPropertiesForLandowner(params.landownerID);
    setLandownerProperties(properties);

    let customFeatures = [];
    let regionInfo;

    for (let index = 0; index < properties.length; index++) {
      regionInfo = MOCK_DATA
        ? getDataForRegionMock(properties[index].region)
        : await getDataForRegion(properties[index].region);
      if (regionInfo) {
        customFeatures.push(regionInfo);
      } else {
        console.error(
          "Error getting region info for property",
          properties[index].id
        );
      }
    }
    setCustomPreloadedFeatures(customFeatures);

    setLoadingFeatures(false);

    return () => {
      setCustomPreloadedFeatures([]);
      setLandownerProperties([]);
      setLandowner(null);
    };
  }, []);

  useEffect(() => {
    function createCharacteristics() {
      if (landowner) {
        console.log(landowner);
        let charactersArr = [];
        charactersArr.push({
          header: "Properties",
          statistic: landowner.properties_num,
        });
        charactersArr.push({
          header: "Area (Hectares)",
          statistic: "N/A",
          // statistic: landowner.area_hectare,
        });

        charactersArr.push({ header: "Males", statistic: "N/A" });
        charactersArr.push({ header: "Females", statistic: "N/A" });
        charactersArr.push({ header: "Fledgelings", statistic: "N/A" });
        charactersArr.push({ header: "Audits", statistic: "N/A" });

        setCharacteristics(charactersArr);
      }
    }

    createCharacteristics();
    return () => {setCharacteristics([])};
  }, [landowner]);

  function galleryFormatProperties(properties) {
    let outputArray = [];

    let objCopy;
    properties.forEach((property) => {
      objCopy = { ...property };

      if (!objCopy.path) {
        objCopy.path = `/project/${params.projectID}/landowner/${params.landownerID}/property/${objCopy.propertyID}`;
      }

      objCopy.children = (
        <>
          <p>Size (ha): {objCopy.area_hectare}</p>
          <p>View Details</p>
        </>
      );

      outputArray.push(objCopy);
    });

    console.log(outputArray);
    return outputArray;
  }

  async function getPropertiesBasic() {
    const landownerID = params.landownerID;
    let properties = await getPropertiesForLandowner(landownerID);
    setLandownerProperties(properties);
    let landowner = await getLandownerDetails(params.projectID, landownerID);
    setLandowner(landowner);
  }


  if (projectDoesNotExist) {
    return <Redirect to="/project" />;
  } else if (landownerDoesNotExist) {
    return <Redirect to={`/project/${params.projectID}`}></Redirect>;
  } else if (!props.project || !landowner) {
    return <></>;
  } else {
    return (
      <div className="page-margins">
        <PageHeader
          headerText={landowner.name}
          buttonsNeeded={false}
          headerIconNeeded={false}
        />
        <div className="landowner-header-icon-container">
          <img className="user-type" src={LandownerIcon} />
          <img className="star" src={StarIcon} />
        </div>
        <div
          className="landowner-metadata-container"
          style={regionID ? { margin: "10px 0 45px 0" } : null}
        >
          <div
            className="landowner-page-info"
            style={
              regionID
                ? { flexDirection: "row-reverse", alignItems: "center" }
                : null
            }
          >
            {!regionID ? (
              <div className="landowner-info-name">Landowner Information</div>
            ) : null}
            <div className="landowner-info"> {landowner.email}</div>
            <div className="landowner-info">
              {landowner.address} <br />
              {[
                landowner.town_name,
                landowner.country_sub,
                landowner.country,
              ].join(", ")}
              <br />
              {landowner.post_code}
            </div>
          </div>
          {loadingFeatures || regionID ? null : (
            <div className="landowner-page-mapbox-container">
              <MapboxRegionSelection
                longitude={-63.129303}
                latitude={46.237283}
                zoom={9}
                style="mapbox://styles/korotu-conroy-trinh/ckwqnggaf0esg14odvehgi6hd"
                setRegionID={setRegionIDAndShow}
                geoJsonID={regionID ? getGeojsonFromRegionID(regionID).id : ""}
                setRegionName={setRegionName}
                customPreloadedFeatures={customPreloadedFeatures}
                selectedPreloadedFeatureRegionID={regionID}
                mapWidth={"100%"}
                mapHeight={"185px"}
                drawEnabled={false}
              />
            </div>
          )}
        </div>
        <div className="landowner-subheader-container">
          {landownerProperties && !regionID ? (
            <RecentActivityFrame
              projectID={params.projectID}
              landownerID={params.landownerID}
            >
              <div className="landowner-program-header-container">
                <PageHeader
                  headerText="Programs"
                  buttonsNeeded={false}
                  headerIconNeeded={false}
                />
                <div className="landowner-program-name">Delayed Hay</div>
              </div>
              <div className="landowner-property-info">
                <div className="project-characteristics">
                  {characteristics.length > 0 ? (
                    <Characteristics characteristics={characteristics} />
                  ) : null}
                </div>
                <div className="landowner-page-properties-container">
                  {/* <div className="landowner-page-properties-header">
                    <h4>Fields</h4>
                    <img className="sort-icon landowner-page" src={SortIcon} />
                    <button
                      className="text-button"
                      onClick={() => {
                        history.push(`${match.url}/new`);
                      }}
                    >
                      <h5>Create New</h5>
                    </button>
                    <button className="text-button">
                      <h5>View as List</h5>
                    </button>
                    <button className="text-button">
                      <h5>Export as List</h5>
                    </button>
                  </div> */}
                  <PropertiesHeader buttonsNeeded={true} headerText="Fields" />

                  <>
                    <PropertyGalleryCollection
                      IDAttribute="propertyID"
                      captionAttribute="property_name"
                      linkAttribute="path"
                      nameAttribute="property_name"
                      regionAttribute="region"
                      landownerID={params.landownerID}
                      collectionList={galleryFormatProperties(
                        landownerProperties
                      )}
                      setRegionID={setRegionID}
                      setRegionName={setRegionName}
                      onClickCallback={onClickCallback}
                      getPropertiesBasic={getPropertiesBasic}
                    />
                  </>

                  {!landownerProperties ? <>No properties found</> : null}
                </div>
              </div>
            </RecentActivityFrame>
          ) : regionID && landownerProperties ? (
            <div className="landowner-property-details-container">
              {/* <div className="landowner-property-stats">
                <div className="landowner-property-stats-container">
                  <h2>Delayed Hay</h2>
                  <div className="landowner-property-small-stats">
                    <div>
                      Parcels: <span>3</span>
                    </div>
                    <div>
                      Acres: <span>246</span>
                    </div>
                  </div>
                  <div className="landowner-property-large-stats">
                    <div>
                      Males: <span>3</span>
                    </div>
                    <div>
                      Females: <span>3</span>
                    </div>
                    <div>
                      Fledgelings: <span>1</span>
                    </div>
                    <div>
                      Audits: <span>0</span>
                    </div>
                  </div>
                </div>
                <div className="landowner-property-detail-action-container">
                  <p>Show all fields</p> <p>Hide all observations</p>
                </div>
              </div> */}
              <div className="landowner-property-card-container">
                <PropertiesHeader
                  buttonsNeeded={false}
                  headerText="Properties"
                  style={{
                    alignSelf: "baseline",
                    justifyContent: "flex-start",
                    margin: "0 0 15px 0",
                  }}
                  headerStyle={{ marginRight: "20px" }}
                />
                <PropertyDetailsDisplayCard
                  property={getPropertyFromRegionID(regionID)}
                  backBtnOnClick={() => setRegionID("")}
                />
              </div>
              <div className="landowner-property-details-map-container">
                {loadingFeatures ? null : focussedProperty ? (
                  <MapboxRegionSelection
                    longitude={focussedProperty.longitude}
                    latitude={focussedProperty.latitude}
                    zoom={13}
                    style="mapbox://styles/korotu-conroy-trinh/ckwqnggaf0esg14odvehgi6hd"
                    setRegionID={setRegionIDAndShow}
                    geoJsonID={
                      regionID ? getGeojsonFromRegionID(regionID).id : ""
                    }
                    setRegionName={setRegionName}
                    customPreloadedFeatures={customPreloadedFeatures}
                    selectedPreloadedFeatureRegionID={regionID}
                    mapWidth={"100%"}
                    mapHeight={"45.875rem"}
                    drawEnabled={false}
                  />
                ) : (
                  "Loading map..."
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export function ProjectLandownerObservations(props) {
  let params = useParams();
  let [selectedObservation, setSelectedObservation] = useState(null);
  if (selectedObservation === null) {
    return (
      <ExpandableFrame minHeight={320} expandTriggerCaption={"view"}>
        {/** replaced regionID with propertyID*/}
        <ObservationsGalleryCollection
          projectID={params.projectID}
          landownerID={params.landownerID}
          IDAttribute="reportID"
          captionAttribute="name"
          submittedByAttribute="submitted_by"
          dateAttribute="creation_date"
          typeAttribute="reportType"
          createOption={true}
          createLink={`/project/${params.projectID}/property/${props.property.propertyID}`}
          setSelectedObservation={setSelectedObservation}
          iconList={undefined}
        />
      </ExpandableFrame>
    );
  } else {
    return (
      <FieldObservationDisplayCard
        reportID={selectedObservation}
        backBtnOnClick={() => setSelectedObservation(null)}
      />
    );
  }
}

function PropertiesHeader(props) {
  const { buttonsNeeded, headerText, style, headerStyle } = props;
  let history = useHistory();
  let match = useRouteMatch();
  return (
    <div className="landowner-page-properties-header" style={style}>
      <h4 style={headerStyle}>{headerText}</h4>
      <img className="sort-icon landowner-page" src={SortIcon} />
      {buttonsNeeded ? (
        <>
          <button
            className="text-button"
            onClick={() => {
              history.push(`${match.url}/new`);
            }}
          >
            <h5>Create New</h5>
          </button>
          {/* <button className="text-button">
            <h5>View as List</h5>
          </button>
          <button className="text-button">
            <h5>Export as List</h5>
          </button>{" "} */}
        </>
      ) : null}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Redirect, useParams, Link } from "react-router-dom";
import {

  getLandownerDetails,
} from "../apis/landsteward/accounts";

import { MapboxRegionSelection } from "../components/mapbox/mapbox-region-selection";

import "./property-draw-page.css";

/**
 * TODO: Add functionality for "create and add another button"
 *
 * @param {Object} project  current project object
 *
 * @author [Leo Wang](https://github.com/Korotu-Leo-Wang)
 */
export default function PropertyDrawPage(props) {
  const params = useParams();

  // const [address, setAddress] = useState("");
  // const [long, setLong] = useState("");
  // const [lat, setLat] = useState("");
  // const [name, setName] = useState("");
  // const [regionID, setRegionID] = useState("");
  // const [, setRegionName] = useState("");
  const [landownerName, setLandownerName] = useState("");
  // const [features, setFeatures] = useState([]);

  const [warning, setWarning] = useState("");
  const [projectDoesNotExist, setProjectFail] = useState(false);

  useEffect(() => {
    getLandownerDetails(params.projectID, params.landownerID).then((data) => {
      setLandownerName(data["name"]);
    });
  }, []);

  const [success, setSuccess] = useState(false);
  if (projectDoesNotExist) {
    return <Redirect to={"/project"} />;
  } else if (success) {
    return (
      <Redirect
        to={`/project/${params.projectID}/landowner/${params.landownerID}`}
      />
    );
  } else {
    return (
      <div className="property-draw-body map-container">

        <div className="property-draw-mapbox-container">
          <MapboxRegionSelection
            longitude={-63.129303}
            latitude={46.237283}
            zoom={9}
            style="mapbox://styles/korotu-conroy-trinh/ckwqnggaf0esg14odvehgi6hd"
            setRegionID={props.setRegionID}
            setRegionName={props.setRegionName}
            drawnFeatures={props.features}
            setDrawnFeatures={props.setFeatures}
            mapWidth={"100%"}
            mapHeight={"35rem"}
            drawEnabled={true}
          />
        </div>
        <p className="property-new__warning-text">{warning}</p>

      </div>
    );
  }
}

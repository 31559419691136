import { url, VALUATION_PORT, CORE_PORT } from "./urlconfig";
import useFetch, { useFetchBlob } from "./useFetch";
import useFetchCSV from "./useFetchCSV";
/** Environmental Evaluation API Endpoint Consumption
 * 
 */


/**
 * 
 * @param {reportID of the report to run the model on} reportID
 * @returns a large data object containing URLs to various images and the
 *      headings/captions associated with those images.
 */
export async function runInvestFloodRiskModel(reportID) {
  let diagramData;
  const path = '/floodrisk/';
  let options = {
    method: 'PUT',
    mode: 'cors',
    body: JSON.stringify({ 'reportID': reportID })
  };

  let response = await fetch(url + ':' + VALUATION_PORT + path, options);
  console.log(response);

  /** Check for Errors and simply return data AFTER the JSON is processed */
  if (response.ok) {
    /**await is necessary */
    await response.json().then(data => {
      diagramData = data;
    });
  } else {
    diagramData = null;
  }
  console.log(diagramData);
  return diagramData;
}


/**
 * 
 * @param {reportID of the report to check for carbon sequestration} reportID 
 * @returns large data object containing URLs to images and titles/captions
 *      for those images
 */
export async function runCarbonSequestration(reportID) {
  let diagramData;
  const path = '/carbonsequestration/';
  let options = {
    method: 'GET',
    mode: 'cors',
    body: JSON.stringify({ 'reportID': reportID })
  };

  let response = await fetch(url + ':' + VALUATION_PORT + path, options);
  console.log(response);

  /** Check for Errors and simply return data AFTER the JSON is processed */
  if (response.ok) {
    /**await is necessary */
    await response.json().then(imageData => {
      diagramData = imageData;
    });
  } else {
    diagramData = null;
  }
  console.log(diagramData);
  return diagramData;
}


export async function createLandCover(projectID, regionID, imageID, name, building) {
  const path = '/lulc/';
  const httpMethod = 'POST';
  let responseData = '';
  let output = '';


  /** Create new project and  associates the geoJSON with it */
  let options = {
    method: httpMethod,
    mode: 'cors',
    'Content-Type': 'application/json',
    body: { projectID: projectID, propertyID: regionID, imageID: imageID, name: name, building: building }
  };

  let httpResponse = await useFetch(url + ':' + VALUATION_PORT + path, options.method, options.body, true)
    .catch(() => {
      console.error('LULC Creation Error');

    })
  console.log(httpResponse.ok)
  /** Fetch JSON response processed */
  if (httpResponse) {

    /** */

    responseData = httpResponse;
    console.log("CREATE REPORT ", responseData);
    output = responseData.data;

  } else {
    output = null;
  }
  console.log("CREATE REPORT OUTPUT", output);
  return output;
}

//TODO update api
export async function createNDVI(projectID, propertyID, imageID, name, cloudMask) {
  const path = '/ndvi/';
  const httpMethod = 'POST';
  let responseData = '';
  let output = '';


  /** Create new project and  associates the geoJSON with it */
  let options = {
    method: httpMethod,
    mode: 'cors',
    'Content-Type': 'application/json',
    body: { projectID: projectID, propertyID: propertyID, imageID: imageID, name: name, cloudMask: cloudMask }
  };

  let httpResponse = await useFetch(url + ':' + VALUATION_PORT + path, options.method, options.body, true)
    .catch(() => {
      console.error('NDVI Creation Error');
    })

  console.log(httpResponse.ok)
  /** Fetch JSON response processed */
  if (httpResponse) {

    /** */

    responseData = httpResponse;
    console.log("CREATE REPORT", responseData);
    output = responseData.data;

  } else {
    output = null;
  }
  console.log("CREATE REPORT OUTPUT", output);
  console.log("#######################")
  console.log(httpResponse)

  return output;
}

export async function searchGranules(projectID, startDate, endDate, regionID, name) {
  const path = '/gedicalculation/'
  const httpMethod = 'POST'
  let responseData = ''
  let output = ''

  let httpResponse = await useFetch(
    `${url}:${VALUATION_PORT}${path}`,
    httpMethod,
    { projectID: projectID, start_date: startDate, end_date: endDate, regionID: regionID, name: name }, true
  ).catch(() => console.error('Granule search error'))

  /** Fetch JSON response processed */
  if (!httpResponse.hasError) {
    responseData = httpResponse;
    // console.log("SEARCH GRANULES", responseData);
    output = responseData.data;
  } else {
    output = null;
  }
  // console.log("SEARCH GRANULE OUTPUT", output);
  // console.log("#######################")
  // console.log(httpResponse)
  return output;
}

export async function createCarbonReport(projectID, reportID, regionID, calculationMethod, email, useBuffer, cutlineEnabled) {
  const path = '/carboncalculation/'
  const httpMethod = 'POST'
  let responseData = ''
  let output = ''

  const lc_hardwood_value = 220
  const lc_softwood_value = 210
  const lc_mixedwood_value = 230
  const lc_wetland_value = 80
  const biomass_dist_coef = 2
  const biomass_size = 100

  let httpResponse = await useFetch(
    `${url}:${VALUATION_PORT}${path}`,
    httpMethod,
    {
      projectID: projectID,
      lc_hardwood_value: lc_hardwood_value,
      lc_softwood_value: lc_softwood_value,
      lc_mixedwood_value: lc_mixedwood_value,
      lc_wetland_value: lc_wetland_value,
      biomass_dist_coef: biomass_dist_coef,
      biomass_size: biomass_size,
      reportID: reportID,
      regionID: regionID,
      calculationMethod: calculationMethod,
      useBuffer: useBuffer, 
      email: email,
      cutlineEnabled: cutlineEnabled
    }, true
  ).catch(() => console.error('Create carbon report error'))

  /** Fetch JSON response processed */
  if (!httpResponse.hasError) {
    responseData = httpResponse;
    // console.log("CREATE CARBON REPORT", responseData);
    output = responseData.data;
  } else {
    output = null;
  }
  // console.log("CREATE CARBON REPORT OUTPUT", output);
  // console.log("#######################")
  // console.log(httpResponse)
  return output;
}

export async function getCarbonReportCSV(path) {
  let output = '';
  let response = await useFetchCSV(`${url}:${CORE_PORT}${path}`, 'GET', null, false);

  console.log("CARBON CSV: ")
  console.log(response)

  if (!response.hasError) {
    output = response.data;
  }

  return output
}

export async function getCarbonReportFiles(path) {
  let output = '';
  let response = await useFetchBlob(`${url}:${CORE_PORT}${path}`);

  if (!response.hasError) {
    output = response.blob;
  }

  return output
} 

import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import './link-table.css'
import Forward from '../../images/icons/forward-icon.png'

/**
 * 
 * @param {*} props
 *    <links>: array of links to display
 *    <linkAttribute>:
 *    <labelAttribute>:
 * @returns 
 */
export function LinkTable(props) {
  
  
  return (
    <div className="links">
      {props.links.map((link) => 
        <span key={link[props.labelAttribute]} >
          <Link  to={link[props.linkAttribute]}>
            <button className="nav-link">{link[props.labelAttribute]}
            {/* <img src={Forward} className="forward"/> */}
            </button>
          </Link>
          <br></br>
        </span>
      )}
    </div>
  )
}
import Select, { components } from "react-select";
import React from "react";
import { ReactComponent as DropdownArrow } from "../../images/dropdownIndicator.svg";

/**
 * Controlled Dropdown select search component using Select from 'react-select', replaces 'dropdown.js'
 * @param {*} this.props
 *    <value>: current value of the dropdown, set to '' if not selected
 *    <setValue>: function passed down to modify <value> state in ancestor
 *      component. Takes in a single new value to replace the old with
 *    <valueList>: array of possible dropdown choices that <value> can become
 *      through the setter function <setValue>. Each list item must have:
 *        - <name> displayed as value on dropdown
 *        - <property> (value) used for React as key and is what is passed
 *          into setValue
 *            this value
 *    <disabledItem>: the item that should be filtered out from the option list
 *    <valueProperty>: the string name of the 'value' property
 *    <nameProperty>: the string name of the 'label' property
 *    <onDisplayChange>: function to display the name of the dropdown item
 *    <width>: width of the dropdown
 */

export function DropdownSearch(props) {
  // dropdown styling (namely the width)
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: props.width,
      height: props.height,
    }),
    control: (provided) => ({
      ...provided,
      width: props.width,
      height: props.height,
    }),
    menu: (provided) => ({
      ...provided,
      width: props.width,
      zIndex: 10,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#4D4D4D",
    }),
  };

  // console.log("DropdownSearch props:", props);

  // converts the list of satellite images to a list of objects that can be used by the Select component
  let options = [];
  let inputVal;
  if (props.objectList !== null) {
    let labelProperty = props.dateProperty;
    let valueProperty = props.valueProperty;
    // if (props.onDisplayChange === props.valueProperty) {
    //     labelProperty = props.valueProperty;
    // } else if (props.onDisplayChange === props.dateProperty) {
    // labelProperty = props.dateProperty;
    // }
    // filter out null images
    let results = props.objectList.filter((obj) => {
      if (!obj) return false;
      
      if (Array.isArray(props.disabledItem)) {
        return !props.disabledItem.some(disabledObj => disabledObj && obj.regionID === disabledObj.regionID && obj.reportID === disabledObj.reportID);
      } else {
        return props.disabledItem ? obj.regionID !== props.disabledItem.regionID || obj.reportID !== props.disabledItem.reportID : true;
      }
    });
    // sort list alphabetically
    results.sort((a, b) => (a[labelProperty] > b[labelProperty] ? 1 : -1));
    let resultsCopy = [...results];
    console.log("resultsCopy: ", resultsCopy);
    console.log("props.value: ", props.value);

    const imageDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z$/;
    options = results.map((obj) => ({
      label: obj[labelProperty]
              ? imageDateRegex.test(obj[labelProperty])
                ? obj[labelProperty].substring(0, 10)
                : obj[labelProperty]
              : "N/A",
      value: obj[valueProperty],
    }));
    // console.log(
    //   "selected value:", resultsCopy.filter((obj) => obj[valueProperty] === props.value)[0]
    // );
    inputVal = resultsCopy.filter((obj) => obj[valueProperty] === props.value)[0]
      ? resultsCopy.filter((obj) => obj[valueProperty] === props.value)[0][labelProperty]
      : "Select";
    
    if (imageDateRegex.test(inputVal)) {
      inputVal = inputVal.substring(0, 10);
    };

    // console.log("labelProperty: ", labelProperty);
    // console.log("valueProperty: ", valueProperty);
    // console.log("inputVal: ", inputVal);
  }

  // the actual Select component
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      styles={customStyles}
      isSearchable={true}
      isClearable={true}
      options={options}
      placeholder={inputVal}
      components={{ DropdownIndicator }}
      onChange={(opt) => {
        if (opt != null) {
          props.setValue(opt.value);
          console.log(opt.label, opt.value);
        } else {
          props.removeValue();
        }
      }}
    />
  );
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow />
    </components.DropdownIndicator>
  );
};
import React, { useState } from "react";

import InfoIcon from "../../images/icons/info-icon-new.svg";
import "./info-icon-with-tooltip.css";

const InfoIconWithTooltip = ({ text }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div
            className="info-icon"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <img src={InfoIcon} alt="Info Icon" />
            {isHovered && <div className="tooltip"><p>{text}</p></div>}
        </div>
    )
};

export default InfoIconWithTooltip;
import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import { PaymentStatus } from '../../webpages/payment-management';

import './payment-progress-bar.css';
import Tick from '../../images/icons/tick-icon.png'
import Cross from '../../images/icons/cross-icon.png'
/**
 * This function returns a progress bar with the following 3 stages:
 * landowner approves request
 * landtrust approves request
 * buyer accept/ decline request
 * 
 * @param {string}      props.status    Status of this progress bar, can be any value in PaymentStatus enum
 */
export function PaymentProgressBar(props) {
  const [complete1, setComplete1] = useState(0);
  const [complete2, setComplete2] = useState(0);
  const [complete3, setComplete3] = useState(0);

  let colour1 = '#BAC7A7';
  let colour2 = '#A6B679';
  let colour3 = (props.status === PaymentStatus.Accepted) ? '#7F8C59': '#8F2727';
  setTimeout(function(){
      if (props.status === PaymentStatus.Incoming || props.status === PaymentStatus.InProgress || props.status === PaymentStatus.Accepted || props.status === PaymentStatus.Declined){
        setComplete1(100);
      }
    }, 0);
  setTimeout(function(){ 
      if (props.status === PaymentStatus.InProgress || props.status === PaymentStatus.Accepted || props.status === PaymentStatus.Declined){
        setComplete2(100);
      }
    }, 500);
  setTimeout(function(){
      if (props.status === PaymentStatus.Accepted || props.status === PaymentStatus.Declined){
        setComplete3(100);
      }
    }, 1000);

  return (
    <div className = 'full-progress-bar-container'>
      <PaymentProgressBarSection bgcolor={colour3} completed={complete3} stageDesc='Buyer approves request' />
      <PaymentProgressBarSection bgcolor={colour2} completed={complete2} stageDesc='Landtrust approves request'/>
      <PaymentProgressBarSection bgcolor={colour1} completed={complete1} stageDesc='Landowner approves request'/>
    </div>
  );
}

/**
 * This function returns a progress bar
 * 
 * @param {string}      props.stageDesc    description of this progress bar's goal
 * @param {number}      props.completed    % completed for this progress bar, range from 0 to 100
 * @param {string}      props.bgcolor    colour that fills this progress bar
 */
export function PaymentProgressBarSection(props) {
  
  const { bgcolor, completed, stageDesc } = props;
  let circleColour = '#c4c4c4';
  let icon;
  if (completed === 100){
    circleColour = bgcolor
    icon =(bgcolor === '#8F2727') ? Cross :Tick

  }
  const fillerStyles = {
    width: `${completed}%`,
    backgroundColor: bgcolor,
    transition: 'width 0.75s ease-in-out'
  }

  const circleStyles = {
    backgroundColor: circleColour,
    transition: 'all 1s ease-in-out'
  }

  return (
    <div className = 'progress_bar_container'>
      <div style={fillerStyles} className = 'progress_bar_filler'>
        
      </div>
      <div style={circleStyles} className = 'progress_stage_circle'>
          
          {completed === 100  &&(
            <img style={{maxWidth:'60%', height:'auto'}} src={icon}/>
          )}
          <p className = 'progress_stage_description'>{stageDesc}</p>
      </div>
    </div>
  );
}
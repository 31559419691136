import React, { useState, useEffect } from 'react';
import './automated-create-page.css';
import { useParams } from 'react-router';

import {
  getLULCSatelliteImage,
  getLULCLandcoverImageforACD,
  getAllLULCReport,
} from '../apis/landsteward/accounts';
import { runAutoChangeDetection } from '../apis/landsteward/change-detection';

/** ##### Components ##### */
import { Dropdown } from '../components/modules/acd-dropdown';
import { ImageContainer } from '../components/modules/image-container';

/** ##### Visual Assets ##### */
import MaryCreek from '../images/sample_region_image.png';

export function AutoCreatePage(props) {
  let params = useParams();
  let [initLULC, setInitLULC] = useState(null);
  let [finalLULC, setFinalLULC] = useState(null);
  // let [satelliteImage1, setSatelliteImage1] = useState(null);
  // let [satelliteImage2, setSatelliteImage2] = useState(null);
  // let [landcoverImage1, setLandcoverImage1] = useState(null);
  // let [landcoverImage2, setLandcoverImage2] = useState(null);

  let [autoName, setAutoName] = useState('');
  let [reportType, setReportType] = useState('landcover');
  let [statusMessage, setStatusMessage] = useState('');

  let [landcoverReports, setLandcoverReports] = useState([]);

  /* ##### URL Parameters ##### */
  let [projectFailed, setProjectFailed] = useState(false);
  // Get all LULC reports for the dropdown
  const fetchLULCReports = async () => {
    const lulcData = await getAllLULCReport(params.projectID);
    setLandcoverReports(lulcData);
  }

  useEffect(() => {
    fetchLULCReports()
    //clean state on unmount, use to avoid warning
    return () => setLandcoverReports({});
  }, []);

  // Select the first lulc report on change to landcoverReports
  useEffect(() => {
    if (landcoverReports && landcoverReports.length > 0) {
          setInitLULC(landcoverReports[0]);
          setFinalLULC(landcoverReports[0]);
        }
  }, [landcoverReports])

  /* Change first satellite image on Auto Change Detection Page given dropdown selection */
  async function changeInitLULC(reportID) {
    for (let i = 0; i < landcoverReports.length; i++) {
      if (landcoverReports[i].reportID === reportID) {
        setInitLULC(landcoverReports[i]);
        i = landcoverReports.length;
      }
    }
  }

  /* Change second satellite image on Auto Change Detection Page given dropdown selection */
  async function changeFinalLULC(reportID) {
    for (let i = 0; i < landcoverReports.length; i++) {
      if (landcoverReports[i].reportID === reportID) {
        setFinalLULC(landcoverReports[i]);
        i = landcoverReports.length;
      }
    }
  }

  /* Creation of ACD Report */
  async function triggerAutoCreation() {
    let valid =
      props.project &&
      props.region &&
      satelliteImage1 &&
      satelliteImage2 &&
      landcoverImage1 &&
      landcoverImage2 &&
      reportType &&
      autoName &&
      initLULC &&
      finalLULC;
    //no data validation
    if (true) {
      setStatusMessage('Generating changes... please check back later');
      let autoData = await runAutoChangeDetection(
        autoName,
        props.project.projectID,
        params.propertyID,
        initLULC.reportID,
        finalLULC.reportID,
        // reportType == 'satellite'
        //   ? satelliteImage1.imageID
        //   : landcoverImage1.imageID,
        // reportType == 'satellite'
        //   ? satelliteImage2.imageID
        //   : landcoverImage2.imageID,
        reportType
      );
      console.log('after await');
      if (autoData) {
        console.log('done acd');
        setStatusMessage('Your Automatic Change Detection report is ready!');
        autoData.name = autoName;
        autoData.reportType = 'automated';
        props.addAuto(autoData);
      } else {
        setStatusMessage('Could not generate!');
        console.error('ACD Creation Failed');
      }
    } else {
      console.error('USER HAS NOT SELECTED VALID INPUTS');
      alert('You have not selected all options to generate a report!');
    }
  }

  /* Updates the current state using URL parameters */
  useEffect(async () => {
    let isCorrectState = await props.changeCurrentState({
      projectID: params.projectID,
    });
    if (!isCorrectState.project) {
      console.error('Failing Projects', isCorrectState);
      setProjectFailed(true);
    }
  }, []);
  if (projectFailed) {
    return <Redirect to="/project" />;
  } else {
    /* ##### Render ##### */
    return (
      <div className="acd-creation">
        <div className="acd-creation__title">
          <h1>Create New Automatic Change Detection Report</h1>
        </div>

        {/* Headings + Auto Change Detection Name */}
        <p>
          <span className="name">Project:</span>{' '}
          {props.project ? props.project.name : ''}
        </p>
        <p>
          <span className="name">Region:</span>{' '}
          {''}
        </p>

        <label htmlFor="acd-creation-name" className="acd-creation__name-label">
          <span className="name">Automated Report Name:</span>
        </label>
        {'                  '}
        <input
          type="text"
          name="acd-creation-name"
          value={autoName}
          onChange={(event) => setAutoName(event.target.value)}
        />

        <br />
        {/* <p className='acd-creation__subtitle'><span className="name">Image Type To Display Changes:</span></p>
        <div className='acd-creation__types'>
          <div className="acd-type-buttons">
            <div onClick={() => setReportType('satellite')}>
              <button style={{backgroundColor: reportType === 'satellite' ? 'rgba(148, 148, 148, 0.19)' : 'rgba(210, 210, 210, 0.19)'}} >{'Satellite'}</button>
            </div>

            <div onClick={(e) => setReportType('landcover')}>
              <button style={{backgroundColor: reportType === 'landcover' ? 'rgba(148, 148, 148, 0.19)' : 'rgba(210, 210, 210, 0.19)'}}>{'Landcover'}</button>
            </div>
          
          </div>
        </div> */}
        <br />

        <div className="image-selection">
          <div className="image1">
            <div className="image1__selection">
              <p>
                <span className="name"> LULC Report #1:</span>
              </p>
              <Dropdown
                className="select-report-dropdown"
                value={initLULC ? initLULC.reportID : ''}
                setValue={(reportID) => changeInitLULC(reportID)}
                valueList={landcoverReports}
                valueProperty="reportID"
                nameProperty="name"
              />
            </div>
            <p>Satellite Image from LULC Report #1:</p>
            <ImageContainer
              imageSource={
                initLULC ? initLULC.satellite_image_url : MaryCreek
              }
              className="acd-creation__satellite-image"
              title={'Satellite Image'}
            />
            <br />
            <p>Date of Satellite Image #1: 03-18-2020</p>
          </div>

          <div className="image2">
            <div className="image2__selection">
              <p>
                <span className="name"> LULC Report #2:</span>
              </p>
              <Dropdown
                className="select-report-dropdown"
                value={finalLULC ? finalLULC.reportID : ''}
                setValue={(reportID) => changeFinalLULC(reportID)}
                valueList={landcoverReports}
                valueProperty="reportID"
                nameProperty="name"
              />
            </div>

            <p>Satellite Image from LULC Report #2:</p>
            <ImageContainer
              imageSource={
                finalLULC ? finalLULC.satellite_image_url : MaryCreek
              }
              className="acd-creation__satellite-image"
              title={'Satellite Image'}
            />
            <br />
            <p>Date of Satellite Image #2: 03-18-2020</p>
          </div>
        </div>
        <div className="report-new-button">
          <button onClick={triggerAutoCreation}>
          {' '}
          Show Changes{' '} 
          </button>
        </div>
      </div>
    );
  }
}

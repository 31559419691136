import React from 'react';

import './payment-dashboard-completed-page.css'
import PaymentDashboardFrame from './payment-dashboard-frame';
import {RequestsCollection} from '../components/modules/payment-request-card';
import { PaymentStatus } from './payment-management';

export default function PaymentDashboardCompletedPage(props) {
  function formatRequests (paymentRequests) {
    let outputArray = [];
  
    let objCopy
    for (let i=0; i < paymentRequests.length; i++) {
      //objCopy = { ...paymentRequests[i]};
      if  (paymentRequests[i].status !== PaymentStatus.Accepted && paymentRequests[i].status !== PaymentStatus.Declined){
        continue;
      }
      objCopy = {}
      for (var pri in paymentRequests[i] ){
        if (pri == 'landowner'){
          objCopy['owner_name'] = paymentRequests[i][pri]['name'];
          objCopy['owner_org'] = paymentRequests[i][pri]['organization'] ? paymentRequests[i][pri]['organization'] : null;  // possibly excluding org for now
        } else {
          objCopy[pri] = paymentRequests[i][pri];
        }
        
      }
      // console.log(objCopy)
      if(!objCopy.path) {
        objCopy.path = 'completed/' + paymentRequests[i]['id']
      }
      
      outputArray.push(objCopy);
    }

    return outputArray
  }
  let body = (
    <div className='tab-body'>
      <h1>Completed Requests</h1>
      <RequestsCollection
        collectionList={formatRequests(props.paymentRequests)}
        
        IDAttribute='id'
        statusAttribute='status'
        ownerAttribute='owner_name'
        ownerOrgAttribute='owner_org'
        linkAttribute='path'
        reqAmountAttribute='request_amount'

      />
    </div>
  )

  return (
    <PaymentDashboardFrame
      body={body}
      current={3}
    />
  )
}
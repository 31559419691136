import React, { useEffect, useState, useRef } from 'react'
import "./gedi-page-multiple.css"

import download from '../images/icons/info-icon.png'
import ArrowLeft from "../images/icons/arrow-icon-left.png";
import { useHistory, useParams, Prompt, useLocation } from 'react-router-dom'
import { CORE_PORT, url } from '../apis/landsteward/urlconfig'
import useFetch, { useFetchBlob } from '../apis/landsteward/useFetch'
import { getRegionData, getAllReportsType } from "../apis/landsteward/accounts"
import mapboxgl, { LngLat } from "!mapbox-gl";
import { createCarbonReport, getCarbonReportCSV, getCarbonReportFiles, searchGranules } from '../apis/landsteward/valuation'




export function GEDIPageMultiple(props) {
    const map = useRef(null)
    const mapContainer = useRef(0)
    let currentState = {}



    if (props.project == null) {
        let localStorageState = JSON.parse(localStorage.getItem("gedi-page-multiple"))
        currentState = localStorageState
        console.log("get current state from props", currentState)
    } else {
        console.log("set current state from props")
        currentState = props
        localStorage.setItem("gedi-page-multiple", JSON.stringify(props))
    }




    let params = useParams()
    const history = useHistory()
    let reset = new URLSearchParams(useLocation().search).get("reset")

    let date = new Date()
    date = date.toISOString().split('T')[0].replace(/-/g, '')

    const [regions, changeRegions] = useState([])
    const [page, changePage] = useState("page-1")
    const [reportName, setReportName] = useState("")
    const [satelliteStartDate, setSatelliteStartDate] = useState("")
    const [satelliteEndDate, setSatelliteEndDate] = useState("")

    const [granuleSearchInProgress, setGranuleSearchInProgress] = useState(false)
    const [reportCreationinProgress, setReportCreationInProgress] = useState(false)

    const [areGranulesFound, setareGranulesFound] = useState(false)
    const [granulesCount, setGranulesCount] = useState(0)
    const [granulesDateRange, setGranulesDateRange] = useState(0)
    const [granulesSize, setGranulesSize] = useState(0)

    const [warning, setWarning] = useState("")
    const [warning2, setWarning2] = useState("")
    const [warning3, setWarning3] = useState("")
    const [showSatellitePopUp, setShowSatellitePopUp] = useState(false)
    const [showInterpolationPopUp, setShowInterpolationPopUp] = useState(false)
    const [showGEDIPopUp, setShowGEDIPopUp] = useState(false)

    const [currReportIndex, setCurrReportIndex] = useState(0)
    const [reportID, setReportID] = useState(null)
    const [isReportCreated, setIsReportCreated] = useState(false)

    const [carbonOutput, setCarbonOutput] = useState(null)
    const [gediOutput, setgediOutput] = useState(null)

    const [calculationMethod, setCalculationMethod] = useState("idw")
    const [linkText, setLinkText] = useState("")
    const [link, setLink] = useState("")

    const [useBuffer, setUseBuffer] = useState(true)
    const [cutlineEnabled, setCutlineEnabled] = useState(true)

    const [reportsList, setReportsList] = useState([])
    // new changes
    let backButton = document.getElementsByClassName('back-button')[0];
    console.log("props", props)

    useEffect(() => {
        console.log("params", reset)

        console.log("props", props)
        

        // let globalBackButton = document.getElementsByClassName('back-button')[0];
        // globalBackButton.style.visibility = 'hidden'
        // if (reset) {
        //     // resetPage()
        // }

        // add warning on all possible ways to exit proggram
        async function quitDialog() {
            let userResponse = window.confirm("Are you sure you want quit? You will lose all progress!");
            console.log("response", userResponse)
            if (userResponse) {
                // let output = await props.removeProject(params.projectID);

                history.push("/projects")
            } else {
                console.log("exited")
                history.push("/projects")
            }
        }

        let logo = document.getElementsByClassName('logo-img')[0]
        logo.onclick = quitDialog
        let projectbtn = document.getElementsByClassName('projectbutton')[0]
        projectbtn.onclick = quitDialog

        const proc = async () => await currentState.changeProject(params.projectID)
        proc().catch(console.error)


        const getGediData = async (projectReports) => {
            // console.log(projectReports)
            if (projectReports.length === 0) return
            let gediReports = projectReports.filter(function isGediCalculation(report) {
                return report.reportType === "gedi_calculation";
            })

            let latestGediReport = gediReports[gediReports.length - 1]
            let httpMethod = "GET"
            let path = `${url}:${CORE_PORT}/reports/gedi_calculation/${latestGediReport.reportID}/`
            let output = await useFetch(path, httpMethod, null, true)

            console.log("output DATA", output.data) // {} if request fails

            setGranulesCount(output.data.GEDI_granules_found)
            setGranulesDateRange(output.data.GEDI_granules_found_date_range)
            setGranulesSize(output.data.GEDI_granules_size)
            setReportName(output.data.name)
            setReportID(output.data.reportID)
            setSatelliteStartDate(output.data.start_date)
            setSatelliteEndDate(output.data.end_date)
            setareGranulesFound(true)
            setWarning("")
            setgediOutput(output.data)
            return
        }
        const process = async () => {
            let projectReports = await getAllReportsType(currentState.project.projectID, "any");
            console.log("Props:", currentState.project)
            console.log("res", projectReports)
            if (reset) {
                setGranulesCount(0)
                setGranulesDateRange(0)
                setGranulesSize(0)
                setReportName("")
                setReportID(null)
                setSatelliteStartDate("")
                setSatelliteEndDate("")
                setareGranulesFound(false)
                setWarning("")
                setgediOutput(null)
                history.push()
            } else {
                // getGediData(projectReports);
            }

        }
        process()


        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/light-v11', // style URL
            zoom: 6,
            center: [79.3832, 43.6532],
            projection: "mercator"
        });
        console.log(mapContainer)

        console.log("Props1:", currentState)
        getAllReportsType(currentState.project.projectID, "any").then((reports) => setReportsList(reports))
    }, [])




    const delay = ms => new Promise(res => setTimeout(res, ms));

    async function createReports() {
        setReportCreationInProgress(true)
        console.log("regions num", regions.length)
        for (let i = 0; i < regions.length; i++) {
            setCurrReportIndex(i)
            let region = regions[i]
            setWarning("Working on region: " + region)
            console.log("doing region", region)
            setareGranulesFound(false)

            try {
                setWarning2(`Searching Gedi Report ${i + 1}!`)
                console.log(`Search gedi report ${i + 1}!`)
                let reportID = await search(region)
                // res = await delay(5000)
                setWarning2(`Gedi Report ${i + 1} finished!`)
                console.log("report ID", reportID)

                setWarning2(`Searching Carbon Report ${i + 1}!`)
                console.log(`Search carbon report ${i + 1}!`)
                let result = await createReport(reportID, region)
                setWarning2(`Carbon Report ${i + 1} finished!`)
                console.log("create report res", result)
                // res = await delay(5000)
                console.log("FINISHED! -- ", i + 1)

            } catch (e) {
                if (e.message == "User Error") {
                    console.log("Stopped Report Generating")
                    setReportCreationInProgress(false)
                    break
                }
                console.log(`Creating report ${currReportIndex + 1} failed: ${e.message}`)
                setWarning3((old) => { old + `\nCreating report ${currReportIndex + 1} failed: ${e.message}` })
            }

        }

        setWarning2("All reports run!")
        setReportCreationInProgress(false)
    }





    async function search(region) {
        let missing = []
        let start = new Date(satelliteStartDate)
        let end = new Date(satelliteEndDate)

        if (!reportName || !satelliteStartDate || !satelliteEndDate) {
            if (!reportName) missing.push("Carbon Report Name")
            if (!satelliteStartDate) missing.push("Satellite Start Date")
            if (!satelliteEndDate) missing.push("Satellite End Date")
            setWarning("Missing required field(s): " + missing.join(", ") + " ")
            throw new Error("User Error")
        } else if (start > end) {
            setWarning("Satellite End Date must occur after Satellite Start Date")
            throw new Error("User Error")
        } else if (reportsList.some((report) => report.name == reportName)) {
            setWarning("Report with this name already exists")
            throw new Error("User Error")
        } else {
            setWarning("")
            setGranuleSearchInProgress(true)
            let output = await searchGranules(params.projectID, satelliteStartDate, satelliteEndDate, region.regionID, `${reportName}-${region.name}`.substring(0, 30))
            setGranuleSearchInProgress(false)
            if (output !== null) {
                setGranulesCount(output.GEDI_arr.GEDI_granules_found)
                setGranulesDateRange(output.GEDI_arr.GEDI_granules_found_date_range)
                setGranulesSize(output.GEDI_arr.GEDI_granules_size)
                setReportID(output.reportID)
                setareGranulesFound(true)
                setgediOutput(output.GEDI_arr)
                setWarning("")
                return output.reportID
            } else {
                // console.log("GEDI Search Fail")
                throw new Error("Finding Gedi Granules Failed")
            }
        }
    }

    async function createReport(reportID, region) {
        // console.log("reportID", reportID)
        setReportCreationInProgress(true)
        setWarning2("")
        let email_storage = JSON.parse(sessionStorage.getItem('webState')).email

        let output = await createCarbonReport(params.projectID, reportID, region.regionID, calculationMethod, email_storage, useBuffer, cutlineEnabled)
        console.log("carbon output", output)
        if (output !== null) {
            setCarbonOutput(output)
            setIsReportCreated(true)
            setWarning2(output.error_msg)

            let logo = document.getElementsByClassName('logo-img')[0]
            logo.onclick = () => { console.log("do nothing") }
            let projectbtn = document.getElementsByClassName('projectbutton')[0]
            projectbtn.onclick = () => { console.log("do nothing") }
            return true

        } else {
            setReportCreationInProgress(false)
            console.log("Carbon Report Fail")
            setWarning2("Carbon Report Failed")
            throw new Error("Carbon Report Generation Failed")
        }
    }

    window.onbeforeunload = function () {
        return "Hey, you're leaving the site. Bye!";
    };

    console.log("current state", currentState)
    console.log("cutline", cutlineEnabled)


    function resetPage() {
        setReportName("")
        setSatelliteStartDate("")
        setSatelliteEndDate("")

        setGranuleSearchInProgress(false)
        setReportCreationInProgress(false)

        setareGranulesFound(false)
        setGranulesCount(0)
        setGranulesDateRange(0)
        setGranulesSize(0)

        setWarning("")
        setWarning2("")
        setShowSatellitePopUp(false)
        setShowInterpolationPopUp(false)
        setShowGEDIPopUp(false)

        setReportID(null)
        setIsReportCreated(false)

        setCarbonOutput(null)
        setgediOutput(null)

        setCalculationMethod("idw")
        setLinkText("")
        setLink("")
        console.log("reset")
    }


    return (
        <div>
            <div className='gedi-body'>
                <div className='gedi-back-col'>
                    {/* <button className='back-btn'>
                        <img src={ArrowLeft} style={{width: "30px"}}onClick={async () => {
                            // let userResponse = window.confirm("Are you sure you want quit? You will lose all progress!");
                            // if (userResponse) {
                                
                            // } else {
                            //     history.goBack()
                            // }
                            history.goBack()

                        }}></img>
                    </button> */}
                </div>
                {(page != "page-1") ? null :
                    <div className='gedi-form-col'>
                        <div className="gedi-title">
                            <h1>Multiple Property Selection Page</h1>
                            <img
                                src={download}
                                onMouseOver={() => setShowSatellitePopUp(true)}
                                onMouseOut={() => setShowSatellitePopUp(false)}
                            />
                            {showSatellitePopUp ?
                                <div className='color_box'>
                                    Granules contain information on estimated AGB for the selected
                                    region (from the GEDI instrument on the International Space Station)
                                </div> : null
                            }
                            <select id="region" name="region" multiple defaultValue={""} onChange={(event) => {
                                console.log("CHANGED REGION", event.target.value)
                                console.log(event.target.options)
                                let newRegions = []
                                for (let i = 0; i < event.target.options.length; i++) {
                                    if (event.target.options[i].selected) {
                                        // finds region with same id in reportRegions prop
                                        newRegions.push(currentState.reportRegions.filter((region) => {
                                            return region.regionID == event.target.options[i].value
                                        })[0])
                                    }
                                }
                                changeRegions(newRegions)

                            }}>

                                {
                                    currentState.reportRegions.map((region) => {
                                        console.log(region)
                                        return <option value={region.regionID}>{region.name}</option>
                                    })
                                }
                            </select>

                        </div>
                        {regions.map((region) => { return <div>{region.regionID}</div> })}
                        <div>
                            <button className={(regions.length != 0) ? "gedi-button" : "gedi-button-disabled"}
                                onClick={() => {
                                    changePage("page-2")
                                    //backButton = document.getElementsByClassName('back-button')[0]
                                    //console.log('backButton:', backButton)  
                                    //backButton.onclick = ('click', () => { changePage("page-1") })
                                    }}>
                                Next
                            </button>
                            <div className="map-elem" ref={mapContainer}>test</div>
                        </div>
                    </div>
                }
                {(page != "page-2") ? null :
                    <div className='gedi-form-col'>
                        <div className="gedi-title">
                            <h1>Multiple Property Selection Page</h1>
                            <img
                                src={download}
                                onMouseOver={() => setShowSatellitePopUp(true)}
                                onMouseOut={() => setShowSatellitePopUp(false)}
                            />
                            {showSatellitePopUp ?
                                <div className='color_box'>
                                    Granules contain information on estimated AGB for the selected
                                    region (from the GEDI instrument on the International Space Station)
                                </div> : null
                            }
                        </div>
                        
                        <form className='gedi-form' >
                            <div className="gedi-form-body">

                                <div className='gedi-input-block'>
                                    <label>Project:</label>
                                    <div className="form-input-const">
                                        {currentState.project ? currentState.project.name : ""}
                                    </div>

                                </div>

                                <div className='gedi-input-block'>
                                    <label>Properties:</label>

                                    <div className="form-input-const">
                                        {regions.length}
                                    </div>

                                </div>

                                <div className='gedi-input-block'>
                                    <label>Image Source:</label>

                                    <div className="form-input-const">
                                        GEDI Lidar Sensor
                                    </div>

                                </div>



                                <div className='gedi-input-block'>
                                    <label>Carbon Report Name:</label>
                                    <div className="form-input">
                                        <input
                                            name='gedi-carbon_report_name'
                                            value={reportName}
                                            onChange={(event => setReportName(event.target.value))}
                                            type='text'
                                            style={{ height: "1.5em" }}
                                        />
                                        <select>
                                            {
                                            regions.map((region) => {
                                                return <option value={region.regionID}>{region.name}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='gedi-input-block'>
                                    <label>Satellite Start Date:</label>
                                    <div className="form-input">
                                        <input
                                            name='gedi-satellite_start_date'
                                            value={satelliteStartDate}
                                            onChange={(event => setSatelliteStartDate(event.target.value))}
                                            type='date'
                                            style={{ height: "1.5em" }}
                                        />
                                    </div>
                                </div>

                                <div className='gedi-input-block'>
                                    <label>Satellite End Date:</label>
                                    <div className="form-input">
                                        <input
                                            name='gedi-satellite_end_date'
                                            value={satelliteEndDate}
                                            onChange={(event => setSatelliteEndDate(event.target.value))}
                                            type='date'
                                            style={{ height: "1.5em" }}
                                        />
                                    </div>
                                </div>

                                {/* <div className={`gedi-input-block`}>
                                <button
                                    type="button"
                                    className={(granuleSearchInProgress || areGranulesFound)
                                        ? "gedi-button-disabled"
                                        : "gedi-button"
                                    }
                                    onClick={search}
                                    disabled={granuleSearchInProgress || areGranulesFound}
                                >


                                    {!granuleSearchInProgress ?
                                        !areGranulesFound ? "Search GEDI Granules" : "Granules Found" :
                                        "Search in Progress..."
                                    }
                                </button>
                                <span className={`loader ${(granuleSearchInProgress) ? "" : "hidden"}`}></span>
                            </div> */}
                                {warning == "GEDI Search Failed" ?
                                    <p className="gedi-warning-text">
                                        {warning} <br></br>
                                        See <a href={link} target="_blank">{linkText}</a> for more details.
                                    </p>
                                    : <p className="gedi-warning-text">
                                        {warning} </p>
                                }
                                <p className="gedi-warning-text">
                                        {warning2} 
                                </p>
                                <p className="gedi-warning-text">
                                        {warning3} 
                                </p>
                            </div>


                            {areGranulesFound ?
                                <div className="gedi-form-body">
                                    <div className='gedi-input-block'>
                                        <label>GEDI Granules Were Found...</label>
                                    </div>

                                    <div className='gedi-input-block'>
                                        <label>Total granules found:</label>
                                        <div className="form-input-const">
                                            {granulesCount}
                                        </div>
                                    </div>

                                    <div className='gedi-input-block'>
                                        <label>Total granules within date range:</label>
                                        <div className="form-input-const">
                                            {granulesDateRange}
                                        </div>
                                    </div>

                                    <div className='gedi-input-block'>
                                        <label>Granules Size (MB):</label>
                                        <div className="form-input-const">
                                            {Math.round(granulesSize * 100) / 100}
                                        </div>
                                    </div>

                                </div>
                                : null}



                            <>
                                <div className="carbon-form-body">
                                    <div className='gedi-interpolation'>
                                        <label>Interpolation Method:</label>
                                        <img
                                            src={download}
                                            onMouseOver={() => setShowInterpolationPopUp(true)}
                                            onMouseOut={() => setShowInterpolationPopUp(false)}
                                        />
                                        {showInterpolationPopUp ?
                                            <div>
                                                Method of estimating data in areas that do not have any data. If unsure, use Inverse Distance Weighting with all forest.
                                            </div> : null
                                        }

                                    </div>
                                    <div className='gedi-input-block' style={{ left: 0 }}>
                                        <div style={{ height: "1.5em" }}>
                                            <select id="calculation_method" onChange={event => setCalculationMethod(event.target.value)}>
                                                <option value="idw">Inverse Distance Weighting with all forest</option>
                                                <option value="avg">Inverse Distance Weighting by forest type</option>
                                            </select >
                                        </div>
                                    </div>

                                    <div className='gedi-input-block'>
                                        <label>Advanced:</label>
                                    </div>

                                    <div className='gedi-input-block'>

                                        <div className="gedi-buffer">
                                            <input type="checkbox" id="rectangle" name="rectangle" value="rectangle"
                                                onChange={event => useBuffer ? setUseBuffer(false) : setUseBuffer(true)}></input>

                                            <label for="rectangle">Limit GEDI data to area of interest</label>
                                            <img
                                                src={download}
                                                onMouseOver={() => setShowGEDIPopUp(true)}
                                                onMouseOut={() => setShowGEDIPopUp(false)}
                                            />
                                            {showGEDIPopUp ?
                                                <div>
                                                    By default, our GEDI search looks slightly outside of your selected region (~200 meters) to help find more granules for accuracy. If this is impacting your search negatively, you may limit the search to only within your region.
                                                </div> : null
                                            }
                                            <p>We recommend you leave this toggle unselected if you are unsure</p>
                                        </div>
                                        <div className="gedi-buffer">
                                            <input type="checkbox" id="cutline" name="cutline" value="cutline" checked={cutlineEnabled ? "checked" : ""}
                                                onChange={event => cutlineEnabled ? setCutlineEnabled(false) : setCutlineEnabled(true)}></input>

                                            <label for="cutline">Enable cutline geometry</label>
                                            <p>If your report errors on creation, turn this off and try again</p>
                                        </div>
                                    </div>

                                </div>
                                <div className='gedi-button-box'>
                                    <button
                                        type="button"
                                        className={(reportCreationinProgress || isReportCreated)
                                            ? "gedi-button-disabled"
                                            : "gedi-button"
                                        }
                                        onClick={createReports}
                                        disabled={reportCreationinProgress}
                                    >
                                        {!reportCreationinProgress ?
                                            `Generate ${regions.length} Reports` :
                                            `Creating ${currReportIndex + 1} / ${regions.length} Reports`
                                        }
                                    </button>
                                    <span className={`loader-2 ${(reportCreationinProgress) ? "" : "hidden"}`}></span>
                                </div>
                            </>

                        </form>
                    </div>
                }
            </div >

        </div>

    )
}

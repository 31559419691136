import React, { useState, useEffect } from 'react';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';

import { SelectionGallery } from '../components/modules/selection-gallery';

import MaryCreek from '../images/sample_region_image.png';
import './land-cover-selection-page.css';
import { getAllLULCReport } from '../apis/landsteward/accounts';

/**
 *
 * @param {*} props
 *    <project>: current project
 *    <region>: current region
 *    <reports>: all reports for the current project, including land covers
 *    <changeCurrentState>: sets the current project, region etc...
 */
export function LandCoverSelectionPage(props) {
  let params = useParams();

  function galleryFormatLandCovers(reports) {
    let outputArray = [];
    
    let objCopy;
    for (let i = 0; i < reports.length; i++) {
      
      objCopy = { ...reports[i] };
      

      if (!objCopy.png_image) {
        objCopy.png_image = MaryCreek;
      }
      if (!objCopy.path) {
        objCopy.path = `/project/${params.projectID}/property/${params.propertyID}/lulc/${objCopy.reportID}`;
      }
      if (objCopy.reportType === 'lulc') {
        outputArray.push(objCopy);

      }
    }

    return outputArray;
  }

  /* ##### URL Parameters ##### */
  let [lulcreports, setlulcreports] = useState([]);
  let [projectFailed, setProjectFailed] = useState(false);

 
   const fetchLULCreports = async () => {
      const allreports = await getAllLULCReport(params.projectID)
      return allreports;
      }
    
    useEffect(async () => {
      let isCorrectState = await props.changeCurrentState({
        projectID: params.projectID,
      });
      if (!isCorrectState.project) {
      console.error('Failing Projects', isCorrectState);
      setProjectFailed(true);
    }
  },[]);

  useEffect( () => {
    fetchLULCreports().then((data)=>
    {
      setlulcreports(data)
     })
    },[]);
  

  if (projectFailed) {
    return <Redirect to="/project" />;
  } else {
    /* ##### Render ##### */
    return (
      <div className="lulc-selection-page">
        <div className="lulc-gallery__title">
          <h1>Browse or Add Land Use Land Cover Reports</h1>
        </div>
        
        <SelectionGallery
          title={
            props.project && props.region
              ? `${props.project.name} Land Covers for ${props.region.name}`
              : 'Land Covers'
          }
          titleClassName="land-cover-selection__title"
          contentType="image"
          collectionList={galleryFormatLandCovers(
            lulcreports ? lulcreports : []
          )}
         
          type="Landcover Report"
          IDAttribute="reportID"
          captionAttribute="name"
          imageAttribute="png_image"
          linkAttribute="path"
          createOption={true}
          createLink={`/project/${params.projectID}/property/${params.propertyID}/lulc/new`}
        />
      </div>
    );
  }
}

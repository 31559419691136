import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import { DEBUG } from '../apis/landsteward/urlconfig';

import { ProjectManagementRouting } from './project-management-flow';
import { PaymentStatus } from './payment-management';
import ObservationExample from '../images/icons/observation-example-icon.png'


import {getNotifs} from '../apis/landsteward/payments';

import PaymentDashboardHomePage from './payment-dashboard-home-page'
import PaymentDashboardDetailPage from './payment-dashboard-detail-page';
import PaymentDashboardIncomingPage from './payment-dashboard-incoming-page'
import PaymentDashboardCompletedPage from './payment-dashboard-completed-page';
import PaymentDashboardInProgressPage from './payment-dashboard-inprogress-page';
import PaymentFinishFormPage from './payment-dashboard-payment-finish-form-page';



export default function PaymentManagementRouting(props) {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/incoming/:paymentID/accept`} render = {(routeProps) => (
        <PaymentFinishFormPage
          currPaymentRequest={props.currPaymentRequest}
          changePaymentRequest={props.changePaymentRequest}
          changePaymentStatus = {props.changePaymentStatus}
          targetStatus = {PaymentStatus.Accepted}
        />
      )}/>
      <Route path={`${match.path}/incoming/:paymentID/decline`} render = {(routeProps) => (
        <PaymentFinishFormPage
          currPaymentRequest={props.currPaymentRequest}
          changePaymentRequest={props.changePaymentRequest}
          changePaymentStatus = {props.changePaymentStatus}
          targetStatus = {PaymentStatus.Declined}
        />
      )}/>
      <Route path={`${match.path}/incoming/:paymentID`} render = {(routeProps) => (
        <PaymentDashboardDetailPage
          currPaymentRequest={props.currPaymentRequest}
          changePaymentRequest={props.changePaymentRequest}
        />
      )}/> 



      


      <Route path={`${match.path}/incoming`} render = {(routeProps) => (
        <PaymentDashboardIncomingPage
          paymentRequests={props.partialPaymentRequests}
          currPaymentRequest={props.currPaymentRequest}
          changePaymentRequest={props.changePaymentRequest}
        />
      )}/>

    <Route path={`${match.path}/inprogress/:paymentID`} render = {(routeProps) => (
        <PaymentDashboardDetailPage
          currPaymentRequest={props.currPaymentRequest}
          changePaymentRequest={props.changePaymentRequest}
          processed = {true}
        />
      )}/>

      <Route path={`${match.path}/inprogress`} render = {(routeProps) => (
        <PaymentDashboardInProgressPage
          paymentRequests={props.partialPaymentRequests}
          currPaymentRequest={props.currPaymentRequest}
          changePaymentRequest={props.changePaymentRequest}
          changePaymentStatus = {props.changePaymentStatus}
        />
      )}/>

      <Route path={`${match.path}/completed/:paymentID`} render = {(routeProps) => (
        <PaymentDashboardDetailPage
          currPaymentRequest={props.currPaymentRequest}
          changePaymentRequest={props.changePaymentRequest}
          processed = {true}
        />
      )}/>

      <Route path={`${match.path}/completed`} render = {(routeProps) => (
        <PaymentDashboardCompletedPage
          paymentRequests={props.partialPaymentRequests}
          currPaymentRequest={props.currPaymentRequest}
          changePaymentRequest={props.changePaymentRequest}
          changePaymentStatus = {props.changePaymentStatus}
        />
      )}/>

      <Route path={`${match.path}/home`} render = {(routeProps) => (
        <PaymentDashboardHomePage
          currPaymentRequest={props.currPaymentRequest}
          changePaymentRequest={props.changePaymentRequest}

          paymentNotifs = {props.paymentNotifs}
          changeNotifRead = {props.changeNotifRead}
        />
      )}/>

      <Route path={`${match.path}/`} render = {(routeProps) => (
        <Redirect to="/payment/home/"></Redirect>
      )}/>

    </Switch>
  )
}





import React, { useEffect } from "react";

import { SelectionGallery2 } from "../components/modules/selection-gallery";

import "./project-selection-page.css";
import download from "../images/download-icon.png";

import order from "../images/icons/order-icon.png";
import { Link } from "react-router-dom";
// import { postProjectActivity } from "../apis/landsteward/accounts";

/**
 *
 * @param {*} props
 *    <projects>:
 *
 * @returns
 */
export function ProjectSelectionPage(props) {
  useEffect(() => {
    /* if on project selection page hides back button */
    let backButton = document.getElementsByClassName('back-button')[0];
    if (document.getElementsByClassName('project-selection-page').length > 0 ) {
      backButton.style.visibility = "hidden"
    }
  })

  function galleryFormatProjects(projects) {
    let outputArray = [];

    let objCopy;
    for (let i = 0; i < projects.length; i++) {
      objCopy = { ...projects[i] };
      if (!objCopy.path) {
        // console.log(objCopy);
        switch (objCopy.service) {
          case "carbon":
            objCopy.path = `/project/${objCopy.projectID}/reports`
            break
          case "forest-change":
            objCopy.path = `/project/${objCopy.projectID}/view-forestchange`
            break
          case "biodiversity":
          default:
            objCopy.path = `/project/${objCopy.projectID}`;
        }
      }

      outputArray.push(objCopy);
    }

    return outputArray;
  }

  return (
    <div className="project-selection-page">
      <br />
      <div className="page-heading">
      {/* <button onClick={() => postProjectActivity("newtest@gmail.com", "new_notif", "new_content", "54db9900-e1a8-4642-a0e0-d9cca070c9a9")}>
          testtting
      </button> */}
        <h3 className="selection-gallery__title">My Projects</h3>

        
        {/* <button className='image-gallery__title-import'>
          <img src={download} />Import
        </button> */}

        <img className="sort-icon" src={order}></img>

        <Link to="/project/new" className="new-proj-link">
          <button className="create-new-project-main-page">Create New</button>
        </Link>
      </div>
      <SelectionGallery2
        type="Project"
        className="selection-gallery"
        contentType="image"
        collectionList={galleryFormatProjects(props.projects)}
        IDAttribute="projectID"
        captionAttribute="name"
        imageAttribute="image"
        linkAttribute="path"
        createOption={true}
        createLink="/project/new"
        removeProject={props.removeProject}
        addProject={props.addProject}
      />
    </div>
  );
}

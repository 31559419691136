/** Change Detection API Consumption
 * 
 */
 
import { url, MONITORING_PORT } from "./urlconfig";
import useFetch from "./useFetch";

export async function runAutoChangeDetection(name, projectID, propertyID, init_lulcID, final_lulcID, imageType) {
    const path = '/changedetection/automatic/';
    const httpMethod = 'POST';
    let responseData = '';
    let output = '';
  
  
    /** Create new project and  associates the geoJSON with it */
    let options = {
      method: httpMethod,
      mode: 'cors',
      'Content-Type': 'application/json',
      body: {name: name, projectID: projectID, propertyID: propertyID, init_lulcID: init_lulcID, final_lulcID: final_lulcID, imageType: imageType}
    };
    console.log(options.body);
    
    let httpResponse = await useFetch(url + ":" + MONITORING_PORT + path, options.method, options.body, true)
      .catch(() => {
        console.error('Auto Change Detection Creation Error');
      })
  
    /** Fetch JSON response processed */
    if (httpResponse) {
  
      /** */
      responseData = httpResponse;
      output = responseData.data;
    } else {
      output = null;
    }
    console.log("CREATE REPORT OUTPUT", output);
    return output;
  }
  
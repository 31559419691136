import React, { useState, useEffect, useRef } from 'react';
import {Link} from 'react-router-dom';

import './expandable-frame.css';
import Down from '../../images/icons/simple-down-icon.png'
import Up from '../../images/icons/simple-up-icon.png'


/**
 * A frame that wraps the content in an expandable box
 * 
 * @param   minHeight    the height of the expandable box when not expanded, 
 *                        if minHeight is number type, the box will not expand beyond the maximum height of the content inside
 *
 * @param   {String} expandTriggerCaption the caption that will be shown on the expand/collapse btn
 * 
 * @author  [Eugene Wong]
 */
export function ExpandableFrame(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(props.minHeight);
  
  const boxRef = useRef();

  const handleOpening = () => {
    setIsOpen((prev) => !prev);
    boxRef.current.focus()
    var style = boxRef.current.currentStyle || window.getComputedStyle(boxRef.current);
  };

  useEffect(() => {
    console.log();
    if (isOpen) setHeight(boxRef.current.getBoundingClientRect().height);
    else if (typeof props.minHeight === 'string') setHeight(props.minHeight);
    else setHeight(Math.min(props.minHeight, boxRef.current.getBoundingClientRect().height));
  }, [isOpen, boxRef.current?.getBoundingClientRect().height]);


  return (
    <div className="expandable-frame-container">
      <div className="expandable-content" style={{ height:height }}>
        <div ref ={boxRef}>
          {props.children}
        </div>
      </div>
      <div className="expand-trigger" onClick={handleOpening}>
        
        {props.expandTriggerCaption && (
          <div className="expand-trigger-caption">
            {props.expandTriggerCaption}
          </div>
        )}
        
        {!isOpen ? (
          <img className='btn-icon' src = {Down}/>
        ) : (
          <img className='btn-icon' src = {Up}/>
        )}
      </div>
    </div>
  )
}

import React from 'react';
import {Link} from 'react-router-dom';

import { PaymentStatus } from '../../webpages/payment-management';

import './payment-request-card.css';

/**
 * This function handles the collection of the payment requests
 * @param {*}      props.collectionList    list of payment request
 * @param {string}      props.IDAttribute
 * @param {string}      props.statusAttribute
 * @param {string}      props.ownerAttribute
 * @param {string}      props.ownerOrgAttribute
 * @param {string}      props.linkAttribute
 * @param {string}      props.reqAmountAttribute 
 */
export function RequestsCollection(props) {
  return (
    <div className='request__collection'>

      { /** request Items */
        props.collectionList.map(
        (item) => (
          <RequestItem
            key={item[props.IDAttribute]}
            reqid = {item[props.IDAttribute]}
            status={item[props.statusAttribute]}
            owner={item[props.ownerAttribute]}
            ownerOrg={item[props.ownerOrgAttribute]}
            link={item[props.linkAttribute]}
            reqAmount = {item[props.reqAmountAttribute]}
          >

          </RequestItem>

        )
      )}
    </div>
  )
}

/**
 * Request Item component that can be clicked among
 * other items in a gallery. Contains the basic information of the landowner
 * and the amount they request.
 * @param {string} props.link the link that clinking this card will lead to
 * @param {string} props.reqId
 * @param {string} props.status
 * @param {string} props.owner
 * @param {string} props.ownerOrg 
 * @param {number} props.reqAmount
 * 
 */
export function RequestItem(props) {
  let colour
  if (props.status == PaymentStatus.Incoming){
    colour = 'rgba(135, 135, 135, 0.12)'
  }else if(props.status == PaymentStatus.InProgress){
    colour = 'rgba(224, 189, 97, 0.12)'
  }else if(props.status == PaymentStatus.Accepted){
    colour = 'rgba(175, 225, 111, 0.54)'
  }else if(props.status == PaymentStatus.Declined){
    colour = 'rgba(239, 52, 52, 0.35)'
  } else {
    colour = 'rgba(0,0,0,0)'
  }


  return (
    <div className='request-card__item' style={{background:colour}}>
      <Link to={props.link} style={{ all: 'unset' }}>
        <div className='request-card__id'>
          # {props.reqid}
        </div>
        <div className='request-card__content'>
          <div className='request-card__initial initial_circle'>
            {props.owner.split(" ").map((n)=>n[0]).join("")}
          </div>
          <div>
            <div className='request-card__org'>
              {props.ownerOrg}
            </div>
            <div className='request-card__owner'>
              from {props.owner}
            </div>
          </div>
          <div className='request-card__reqAmount'>
            $ {props.reqAmount.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
          </div>
        </div>
      </Link>
    </div>
  )
}

import { url, VALUATION_PORT } from "./urlconfig";
import useFetch from "./useFetch";
import { MakeInitialReport, ChangeToFailed } from "./accounts";

/** Satellite Downloads and Interations API
 * 
*/



/** Creates or updates a report, depending on the update parameter
 * 
 * @param {Name of the report to generate} name 
 * @param {ID of the project to associate the report and satellite image} projectID 
 * @param {Earliest Acceptable Date for a satellite image} startDate 
 * @param {Latest Acceptable Date for a satellite image} endDate 
 * @param {pixel resolution for the satellite image, side length of a pixel
 *    in the image measured in meters} spatialResolution 
 * @param {Spectrum of EM Waves to record for the satellite image} satelliteBandType 
 * @param {Maximum Percentage of Cloud Coverage over satellite image} maxCloudCoverage 
 * @param {Determines whether to create or update a report} update
 * @returns the reportID of the created report if the report is successfully queued
 * @returns null if the report creation failed either in the initial report creation of in queuing
 * 
 */
export async function CreateMowingReport(
    name,
    projectID,
    startDate,
    endDate,
    spatialResolution,
    cloudMask,
    mowingID,
    email,
    update

) {
    const reportType = 'MowingDetection'
    const reportID = await MakeInitialReport(
        name,
        projectID,
        startDate,
        endDate,
        spatialResolution,
        cloudMask,
        reportType,)
    if (reportID === null) {
        return null;
    }

    const path = "/mowing/";
    const body = ({
            'name': name,
            'projectID': projectID,
            'start_date': startDate,
            'end_date': endDate,
            'spatial_res': spatialResolution,
            'cloud_mask': cloudMask,
            'mowingID': mowingID,
            'email': email,
            'update': update, 
            'reportID': reportID
        });

            


    // FETCH
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'POST', body, true);
    console.log(response);

    let output;

    if (response.hasError == false) {
        console.log(response);
        output = reportID;

    } else {
        console.error('Attempt to queue mowing report failed');
        console.error('Setting report_info to "failed"');
        console.error(response.errorMessage);

        // if changing report_info to "failed" fails, an error log will be generated
        await ChangeToFailed(reportID);
        output = null;
    }
    console.log(output);
    return output;
}

export async function terminateGeneratingReport(reportID) {
    const path = "/mowing/";
    const body = ({'reportID': reportID});
    console.log("terminating")
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'DELETE', body, true);
    console.log(response)
  }

export async function getGeneratingReportNames() {
    const path = "/mowing/";
    console.log("Getting generating mowing report names.")
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'GET', null, true);
    console.log(response)

    if (response.hasError == false) {
        return response.data['generating_report_names']
    } else {
        console.error("Attempt to get currently-generating report names failed.")
        return null
    }
}
import React from 'react';
import CarbonDisplay from '../components/reports/carbon-display.js';
import './report-display-page.css'

export function CarbonDisplayPage(props) {
  return (
    <div className='report-display'>
      <CarbonDisplay searchState={props.searchState}/>
    </div>
  );
}
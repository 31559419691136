import React, { useState } from "react";
import { useRouteMatch, useParams, Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
    downloadSatelliteImage,
    getMDReport,
    getMDReportCSV,
    getSatelliteImageDetails,
} from "../apis/landsteward/accounts";
import {
    downloadHarmonized,
    downloadSentinelSat2,
} from "../apis/landsteward/satellite";
import { CreateMowingReport } from "../apis/landsteward/mowing";
import {CreateForestReport } from "../apis/landsteward/forest-change"

import { Dropdown } from "../components/modules/satellite-dropdown";

import "./forest-page.css";
import "./satellite-image-create-page.css";
import "./mowing-create-page.css";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import SimpleImageSlider from "react-simple-image-slider";
import { AutoDisplayPage } from "./automated-display-page";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    LineElement,
} from "chart.js";

export function ForestPageCustom(props) {
    let match = useRouteMatch();
    let params = useParams();
    let project = props.searchState("projects", "projectID", props.project.projectID);
    const [name, setName] = useState('');
    const reportNameMaxLength = 30;
    console.log(
        `URL params SatelliteCreate: ${props.project.projectID}||${params.propertyID}`
    );
    console.log(props.project)
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        PointElement,
        LineController,
        LineElement
    );

    // region is props.project.region

    return (
        <div className="satellite-image-create-page">
            <div className="satellite-image-create-page-title" id="mowing-title">
                <h2> Generate Forest Detection Report</h2>
            </div>

            <p className="metadata-headings" id="mowing-headings">
                {" "}
                <b>Project:</b> {project ? project.name : ""}
            </p>

            <label
                className="name-label metadata-headings"
                id="mowing-name"
                htmlFor="newName"
            >
                <span>Report Name:</span>
                <br></br>
            </label>
            <input
                id="input1"
                className="metadata-headings-report-name-input"
                name="newName"
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
                maxLength={reportNameMaxLength}
            />
            <p 
            className={name.length===reportNameMaxLength ? 'mowing_text-limit-colour' : undefined}
            style={{ marginLeft: '110px', marginTop: '10px' }}
            >
            {name.length}/{reportNameMaxLength}
            </p>

            <MowingReportForm
                newName={name}
                addSatelliteImage={props.addSatelliteImage}
                project={props.project}
            />
        </div>
    );
}
/**
 * Form to download a satellite image, allows user to specify satellite image
 * details (e.g. date, cloud coverage). Downloaded image is associate with
 * a specific region and project.
 * @param {*} props
 *    <region>: region metadata object, necessary for fetch API
 *    <newName>:
 *    <addSatelliteImage>:
 */
export function MowingReportForm(props) {
    let params = useParams();
    const history = useHistory();
    const [satBand, setSatBand] = useState("NDVI");
    const [yearOfInterest, setYearOfInterest] = useState("2022");
    const [reportStartYear, setReportStartYear] = useState("2019");
    const [reportStartDate, setReportStartDate] = useState("2019-01-01");
    const [reportEndDate, setReportEndDate] = useState("2022-12-31");
    //   const [pixelRes, setPixelRes] = useState('10m');
    const [newImageID, setNewImageID] = useState("");
    const [downloadStatus, setDownloadStatus] = useState("");
    async function triggerDownload(e) {
        e.preventDefault();
        setDownloadStatus("pending");
        let reportID;
        let cloudMask = true;
        let spatialResolution = 30;
        let mowingID = null;
        let update = false;
        let email = JSON.parse(sessionStorage.getItem('webState')).email 

        console.log('reportStartDate: ', reportStartDate)
        console.log('reportEndDate: ', reportEndDate)
        console.log(props.project.projectID)

        reportID = await CreateForestReport(
            props.newName,
            props.project.projectID,
            reportStartDate,
            reportEndDate,
            spatialResolution,
            cloudMask,
            mowingID,
            email, 
            update
        );
        if (reportID == null) {
            console.log('Mowing failed')
            setWarning('Report creation failed')
            setMowingReportInProgress(false)
        } else {
            // if everything goes well up until the job is queued, redirect to the project page
            history.push(`/project/${props.project.projectID}/view-forestchange`)
        }
        //  console.log(temp.split(',')[9 + 5])
        //  console.log(temp.split(',')[18 + 5])
    }

    function setDates(yoi) {
        setYearOfInterest(yoi);
        let startYear = Number(yoi) - 3;
        let startDate = String(startYear) + '-01-01';
        let endDate = Number(yoi) + '-12-31'
        setReportStartYear(startYear);
        setReportStartDate(startDate);
        setReportEndDate(endDate);
    }
    
    return (
        <form className="satellite-image-form">
            {/** Satellite Form Details */}
            <div className="satellite-image-form__details" id="mowing-details">

                <h4>Report Dates</h4>

                <label htmlFor="year-of-interest">
                    <p><b>Year of Interest:</b></p>
                </label>
                <input
                    className="selectable-input-box"
                    type="number" 
                    min="2018" 
                    max="2023" 
                    step="1" 
                    value={yearOfInterest}
                    name="year-of-interest"
                    onChange={(event) => setDates(event.target.value)}
                />
                <br />

                <label htmlFor="satellite-image-end-date">
                    <p><b>Report Range:</b></p>
                </label>
                <input
                    className="static-input-box"
                    type="text" 
                    value={reportStartYear + ' - ' + yearOfInterest}
                    name="satellite-image-end-date"
                    disabled 
                />
                <br />
                <p className="report-range-explanation">
                    Your report range is from your year of interest <br></br>
                    to 3 years before it. You can compare your year <br></br>
                    of interest with dates within this range.
                </p>

                <label htmlFor="satellite-image-band">
                    <p><b>Comparison Type:</b></p>
                </label>
                <input
                    className="static-input-box"
                    type="text"
                    value={satBand}
                    name="newName"
                    onChange={(event) => setSatBand(event.target.value)}
                    disabled
                />
                <br />

                {/* <label htmlFor='satellite-image-pixel-res'> <b>Resolution Preference (if any) :</b> </label>
                <Dropdown
                name='satellite-image-pixel-res'
                value={pixelRes}
                setValue={(value) => setPixelRes(value)}
                valueList={[{value:'10m', name:'10m'}, {value:'30m', name:'30m'}, {value:'60m', name:'60m'},]}
                valueProperty='value'
                nameProperty='name'
                /> */}
            </div>
            <div className="button-for-mowing">
                <button
                    onClick={triggerDownload}
                    style={{
                        visibility:
                            (downloadStatus === "pending" && "hidden") ||
                            (newImageID && "hidden"),
                    }}
                >
                    {" "}
                    Generate Analysis{" "}
                </button>
            </div>

            {downloadStatus === "failed" && (
                <p className="processing-text">
                    {" "}
                    Oh no! Something went wrong, please try again later
                </p>
            )}
            {/* {(downloadStatus === 'pending') && <p className="processing-text"> The download you triggered is processing</p>} */}
            {newImageID && (
                <span>
                    <p className="processing-text">
                        Your satellite image has finished downloading!
                    </p>
                    <Link
                        to={`/project/${props.project.projectID}/property/${params.propertyID}/satellite_image/${newImageID}`}
                    >
                        <div className="create-satellite-button">
                            <button> View Image </button>
                        </div>
                    </Link>
                </span>
            )}
        </form>
    );
}
import React from 'react';
import {Link} from 'react-router-dom';

import './payment-link-table.css'

/**
 * 
 * @param {*} props
 *    <links>: array of links to display
 *    <linkAttribute>:
 *    <labelAttribute>:
 * @returns 
 */
 export function PaymentLinkTable(props) {
  
    return (
      <div className="table-body">
        {props.links.map((link) => 
          <span key={link[props.labelAttribute]} >
            <Link to={link[props.linkAttribute]}>
              {
                link['current'] ? 
                <button className="current-tab tab-link">{link[props.labelAttribute]}
                  {/* <img src={Forward} className="forward"/> */}
                </button>
                :
                <button className="tab-link">{link[props.labelAttribute]}
                  {/* <img src={Forward} className="forward"/> */}
                </button>
              }
            </Link>
            <br/>
          </span>
        )}
      </div>
    )
}
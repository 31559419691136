import React, { useState, useEffect } from 'react';
import { Redirect, useParams, useRouteMatch } from 'react-router-dom';

import { projectAddRegion } from '../apis/landsteward/accounts';

import MapboxInterface from '../components/mapbox/mapbox-interface';
import { LinkTable } from '../components/modules/link-table';
import { Dropdown } from '../components/modules/dropdown';
import './project-region-page.css';

export function ProjectRegionPage(props) {
  /** Get routing variables */
  let match = useRouteMatch();
  let params = useParams();

  /** Change to specific project at the start */
  let [projectDoesNotExist, setProjectFail] = useState(false);

  /** *
  useEffect( async ()=> {
    let successProject = await props.changeProject(params.projectID);

    if (successProject === false) {
      await setProjectFail(true);
    } else {
      let successRegion = await props.changeRegion(params.regionID);
      console.log('successReigon', successRegion);
      if (successRegion === false) {
        await setRegionFail(true);
      }
    }
  }, []); /** */

  console.log(`URL params: ${params.projectID}||${params.regionID}`);

  let project = props.searchState('projects', 'projectID', params.projectID);

  if (projectDoesNotExist || project === null) {
    return <Redirect to="/project" />;
    //} else if (redirectRegion) {
    //  return <Redirect to={`${match.url}/region/${props.region.regionID}`}/>
  } else {
    return (
      // <div className="project-region-page">
      //   <div className="project-title">
      //     <h1> Project Navigation </h1>
      //   </div>
      //   <div className="labels">
      //     <p className="dropdown-label-project-details__project-label">
      //       <span className="name">Project Name:</span> {project.name}
      //     </p>
      //     <p className="dropdown-label-project-details__region-label">
      //       <span className="name">Region Name:</span>
      //     </p>
      //   </div>

      <div className="project-region-page">
        <div className='project-region-box'>
        <div className='project-region-heading'>
        <div className='page-heading-title'>
        <span className='page-heading-title-text'>Observation Toolbox</span>
        </div>
        <div className='project-name-box'>
        <div className='project-name'>
        <span className='project-name-text'>Project Name:</span>
        <span className='name-text'>{project.name} </span>
        </div>
       

        </div>
        </div>
        <div className='project-region-subheading'>
        <div className='subheading-title'>
        <span className='subheading-title-text'>What's Happening on the Ground?</span>
        </div>
        <div className='subheading-description'>
        <span className='subheading-description-text'>Pick a tool from the box below to generate project insights</span>
        </div>
        </div>
        <div className='project-region-list'>
        <LinkTable
          className="link-table"
          labelAttribute='label'
          linkAttribute='link'
          links={
            [
              {
                link: `${match.url}/satellite_image`,
                label:'Download Satellite Images'
              },
              {
                link:`${match.url}/lulc`,
                label:'Assess Land Use and Land Cover'
              },
              {
                link:`${match.url}/ndvi`,
                label:'Assess Vegetation Report'
              },
              {
                link:`${match.url}/report`,
                label:'View Ecological Services: Floodrisk'
              },           
              {
                link:`${match.url}/carbon-report`,
                label:'View Ecological Services: Carbon'
              },

            {
              link: `${match.url}/manual-detection`,
              label: 'Manually Compare Changes',
            },
            {
              link: `${match.url}/lulcautodetection`,
              label: 'Automatically Detect Changes',
            },
            ,
          ]}
        /> 
        
        </div>

        </div>

        
      </div>
    );
  }
}

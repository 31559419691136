import MaryCreek from '../../images/sample_region_image.png';

/**
 * Use this file to write any mock calls for testing without the Django backend,
 * all function names are identical to accounts.js + "Mock" keyword at the end
 */


/**
 * 
 * @param {string} name Project Name
 *      NOTE: geoJSON MUST have a "name":<string >   attribute
 * @returns projectID corresponding to such data
 */
export async function createProject(name) {
  const path = '/project/view/';
  const httpMethod = 'POST';
  let output = '';

  /** Create new project and  associates the geoJSON with it */

  const body = JSON.stringify({name: name})
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  if (!response.hasError) {
    output = response.data.projectID;
    console.log("CREATE PROJECT ", response.data);
  }
  else {
    output = null;
    console.log(response.errorMessage);
  }

  console.log("CREATE PROJECT OUTPUT", output);
  return output;
}


export function getProjectsMock() {
  let output = [
    {projectID: '1', name:'Delayed Hay', description: "Delayed hay desc", 
    characteristics: {"Properties": "130", "Acreage": "5280", "Ecosystem Services": "1", "Funds Raised": "16,500", "Observations": "1,409"},
    properties_num: 130, acreage: 5280, ecosystem_services: 1, funds_raised: 16000, observations_num: 1409},
    {projectID: '2', name:'Project 2', description: "Lorem ......",
    characteristics: {"Observations": "120"}},
    {projectID: '3', name:'Project 3', description: "Lorem ......",
    characteristics: {"Observations": "140"}},
  ];

  return output;
}


export function getProjectDetailsMock(projectID) {
  let output = {};

  output = {
    projectID: '1', 
    name:'Delayed Hay', 
    description: "Delayed hay desc", 
    properties_num: "130", 
    area_hectare: "5280", 
    ecosystems_services: "1", 
    funds_raised: "16,500", 
    observations_num: "1,409"
  }

  return output
}

export function addLandownerToProjectMock(projectID, email) {
  let output;

  output = {
    profileID: 1, email: "johndoe@email.com", name: "John Doe", address: "37 John Doe Street", properties_num: 3, area_hectare: 246,
    characteristics: {"Males": "3", "Females": "3", "Fledgings": "1", "Audits": "0"}, has_notif: true,
    building_num: 4, post_code: "L5A 9B1", town_name: "Toronto", country_sub: "ON", country: "Canada"
  }

  return output
}

export async function createReport (projectID, regionID, imageID, name, spatial_res) {
  const path = '/floodrisk/';
  const httpMethod = 'PUT';
  let output = '';


  /** Create new project and  associates the geoJSON with it */
  const body = {
    projectID: projectID, regionID: regionID, imageID: imageID, name: name, spatial_res: spatial_res
  }

  let response = await useFetch(url + ':' + VALUATION_PORT + path, httpMethod, body, true);
  console.log(response);

  /** Fetch JSON response processed */
  if (!response.hasError) {
    console.log("CREATE REPORT ", response.data);
    output = response.data;

  } else {
    output = null;
    console.log(response.errorMessage);
  }
  console.log("CREATE REPORT OUTPUT", output);
  return output;
}


/**
 * 
 * @param {ID of the project for which you want all reports} projectID 
 * @returns An object containing an array of report metadata objects,
 *      called 'report_array'
 */
export async function getReports() {
  const path = '/report/all/';
  const httpMethod = 'GET';
  let output = '';

  /** Retrieves all reports based on project ID provided  */
  const body = { "projectID": projectID }
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data;

  } else {
    output = null;
    console.log(reponse.errorMessage);
  }

  return output;
}


export async function deleteProject(projectID) {
  const path = '/project/delete/';
  const httpMethod = 'DELETE';
  let output = '';

  /** Retrieves all reports based on project ID provided  */
  const body = { "projectID": projectID }
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


export async function deleteReport(reportID) {
  const path = '/report/delete/';
  const httpMethod = 'DELETE';
  let output = '';

  /** Retrieves all reports based on project ID provided  */
  const body = { "reportID": reportID };
  let response = await fetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


export async function downloadSatelliteImage(metaObject) {
  const path = '/sentinel/download/';
  const httpMethod = 'POST';
  let responseData = '';
  let output = '';

  /** Retrieves all reports based on project ID provided  */
  let response = await useFetch(url + ':' + MONITORING_PORT + path, httpMethod, metaObject, true);

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.imageID;

  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

export async function getSatelliteImageDetails(imageID) {
  const path = '/image/satellite/get/';
  const httpMethod = 'PUT';
  let output = '';

  /** Retrieves all reports based on project ID provided  */
  const body = {imageID: imageID};
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data;

  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


export async function projectAddRegion(projectID, regionID) {
  const path = '/project/add/region/';
  const httpMethod = 'PUT';
  let output = '';

  const body = {projectID: projectID, regionID: regionID};
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = true;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


export async function getProjectRegions(projectID) {
  const path = '/project/get/region/';
  const httpMethod = 'PUT';
  let output = '';

  /** Retrieves all projects, will return based on user  */
  const body = {projectID: projectID}

  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);
  
  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.regions;

  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/** Custom Region Functions */

/** 
 * Creates a custom region by passing in the geojson formated object
 */
 export function getCustomRegionsMock() {

  let output = '';



  return output;
}


/** 
 * Creates a custom region by passing in the geojson formated object
 */
 export async function getCustomRegionDetail(id) {
  let path = '/region/custom/' + id + '/';
  const httpMethod = 'GET';
  let output = '';

  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, null, false);

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/** 
 * Creates a custom region by passing in the geojson formated object
 */
 export function createCustomRegionMock(geojson) {
  let output = '';

  output = {
    data: {
      id: "eec8102153d4a41b02453f8a76790f7c",
      type: "Feature",
      properties: {
        name: "John Doe Farms",
        ID: 1
      },
      geometry:{
        coordinates:
        [
          [
            [-79.16498067030338, 43.66255620353573],
            [-79.36826801573997, 43.56211256395099],
            [-79.15124503885514, 43.51332204930594],
            [-79.16498067030338, 43.66255620353573]
          ]
        ],
        type:"Polygon"}
    },
    regionID: 1
  }


  return output;
}


export async function getProjectSatelliteImages(projectID) {
  const path = '/project/get/image/';
  const httpMethod = 'PUT';
  let output = '';

  /** Retrieves all projects, will return based on user  */
  const body = {projectID: projectID}
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.images;

  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


export async function getProjectReports(projectID) {
  const path = '/project/get/report/';
  const httpMethod = 'PUT';
  let output = '';


  /** Retrieves all projects, will return based on user  */
  const body = {projectID: projectID};
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.reports;

  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


export async function getReportImages(reportObject) {
  let reportID = reportObject.reportID;
  let reportType = reportObject.reportType;
  let path = `/report/${reportType}/get/`;
  const httpMethod = 'PUT';
  let output = '';


  /** Retrieves all reports based on project ID provided  */
  const body = {reportID: reportID};
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data.images;

  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


export async function getImageDetails(imageObject) {
  let imageID = imageObject.imageID;
  let imageType = imageObject.imageType;
  let path = `/image/${imageType}/get/`;

  const httpMethod = 'PUT';
  let output = '';


  /** Retrieves all reports based on project ID provided  */
  const body = {imageID: imageID};
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  /** Fetch JSON response processed into a data object in JS*/
  if (!response.hasError) {
    output = response.data;

  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/** 
 * This is a function that fetches and retrieves the entire floodrisk 
 * report information (to be displayed to the user).
 */
export async function getFloodRiskDetails(reportID) {
  let path = '/report/floodrisk/get/';
  const httpMethod = 'PUT';
  let output = '';

  const body = {reportID: reportID};
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data.report_info;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/** 
 * This is a function that fetches and retrieves the array of images
 * associated with a floodrisk report, to be used in other functions
 * to retrieve a specific image from the array.
 */
export async function getFloodRiskImages(reportID) {
  let path = '/report/floodrisk/get/';
  const httpMethod = 'PUT';
  let responseDataArray = '';
  let outputArray = '';

  const body = {reportID: reportID};
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  // return the image array
  if (!response.hasError) {
    outputArray = response.data.images;
  } else {
    outputArray = null;
    console.log(response.errorMessage);
  }
  return outputArray;
}

/** 
 * This is a function that fetches and retrieves a particular map image
 * given the array of floodrisk images and the particular index the image
 * is located at.
 */
export async function getFloodRiskMapImageFromIDX(reportID, i) {
  let path = '/image/map/get/';
  const httpMethod = 'PUT';
  let output = '';
  // retrieve the image array
  const imageArray = await getFloodRiskImages(reportID);
  console.log(imageArray);

  // retrieve the image ID for the image at the requested index of the array
  let imageID = imageArray[i].imageID;

  // return the image
  const body = {imageID: imageID};
  let response = await useFetch(url + ':' + CORE_PORT + path, httpMethod, body, true);

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}

/** 
 * This is a function that fetches and retrieves the landcover image
 * associated with a floodrisk report, using the functions above
 * to retrieve the floodrisk image array and continue to fetch each of the images
 * in the array until the image returned's mapType is the one required (landcover).
 */
export async function getFloodRiskMapLandcoverImage(reportID) {
  // retrieve the image array
  const imageArray = await getFloodRiskImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until 
  // the image returned has a mapType of landcover
  for (let i = 0; i < imageArray.length; i++) {
    if (imageArray[i].imageType === 'map') {
      const image = await getFloodRiskMapImageFromIDX(reportID, i);
      if (image.mapType != 'landcover') continue;
      else return image;
    }
  }
  return null; // if image with required mapType cant be found
}

/** 
 * This is a function that fetches and retrieves the built infrastructure image
 * associated with a floodrisk report, using the functions above
 * to retrieve the floodrisk image array and continue to fetch each of the images
 * in the array until the image returned's mapType is the one required (infra).
 */
export async function getFloodRiskMapInfraImage(reportID) {
  // retrieve the image array
  const imageArray = await getFloodRiskImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until 
  // the image returned has a mapType of infrastructure
  for (let i = 0; i < imageArray.length; i++) {
    if (imageArray[i].imageType === 'map') {
      const image = await getFloodRiskMapImageFromIDX(reportID, i);
      if (image.mapType != 'infrastructure') continue;
      else return image;
    }
  }
  return null; // if image with required mapType cant be found
}

/** 
 * This is a function that fetches and retrieves the runoff image
 * associated with a floodrisk report, using the functions above
 * to retrieve the floodrisk image array and continue to fetch each of the images
 * in the array until the image returned's mapType is the one required (runoff).
 */
export async function getFloodRiskMapRunoffImage(reportID) {
  // retrieve the image array
  const imageArray = await getFloodRiskImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until 
  // the image returned has a mapType of runoff
  for (let i = 0; i < imageArray.length; i++) {
    if (imageArray[i].imageType === 'map') {
      const image = await getFloodRiskMapImageFromIDX(reportID, i);
      if (image.mapType != 'runoff_ret_idx') continue;
      else return image;
    }
  }
  return null; // if image with required mapType cant be found
}


export async function getSATImageFromIDX(projectID, i) {
  let path = '/image/satellite/get/';
  const httpMethod = 'PUT';
  let output = '';
  const imageArray = await getProjectSatelliteImages(projectID);
  let imageID = imageArray[i].imageID;
  
  const body = {imageID: imageID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


export async function getSatelliteImage(reportID) {
  let path = '/report/floodrisk/get/';
  const httpMethod = 'PUT';
  let output = '';

  const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data.satellite_image;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
} 


/** 
 * This is a function that fetches and retrieves the entire lulc 
 * report information (to be displayed to the user).
 */
export async function getLULCReport(reportID) {
  let path = '/report/lulc/get/';
  const httpMethod = 'PUT';
  let output = '';

  const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data.report;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/** 
 * This is a function that fetches and retrieves the entire lulc 
 * report information (to be displayed to the user).
 */
 export async function getLULCDetails(reportID) {
  let path = '/report/lulc/get/';
  const httpMethod = 'PUT';
  let output = '';

  const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data.report_info;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/** 
 * This is a function that fetches and retrieves the entire lulc 
 * report information (to be displayed to the user).
 */
 export async function getLULCSatelliteImage(reportID) {
  let path = '/report/lulc/get/';
  const httpMethod = 'PUT';
  let output = '';

  const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data.satellite_image;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/** 
 * This is a function that fetches and retrieves the array of images
 * associated with a lulc report, to be used in other functions
 * to retrieve a specific image from the array.
 */
export async function getLULCImages(reportID) {
  let path = '/report/lulc/get/';
  const httpMethod = 'PUT';
  let outputArray = '';

  const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);

  // return the image array
  if (!response.hasError) {
    console.log("LULC images ok");
    outputArray = response.data.images;
  } else {
    outputArray = null;
    console.log(response.errorMessage);
  }

  return outputArray;
}


/** 
 * This is a function that fetches and retrieves a particular map image
 * given the array of lulc images and the particular index the image
 * is located at.
 */
 export async function getLULCMapImageFromIDX(reportID, i) {
  let path = '/image/map/get/';
  const httpMethod = 'PUT';
  let output = '';
  // retrieve the image array
  const imageArray = await getLULCImages(reportID);
  console.log(imageArray);

  // retrieve the image ID for the image at the requested index of the array
  let imageID = imageArray[i].imageID;
  

  // return the image
  const body = {imageID: imageID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);

  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/** 
 * This is a function that fetches and retrieves the landcover image
 * associated with a lulc report, using the functions above
 * to retrieve the lulc image array and continue to fetch each of the images
 * in the array until the image returned's mapType is the one required (landcover).
 */
 export async function getLULCMapLandcoverImage(reportID) {
  // retrieve the image array
  const imageArray = await getLULCImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until 
  // the image returned has a mapType of landcover
  for (let i = 0; i < imageArray.length; i++) {
    if (imageArray[i].imageType === 'map') {
      const image = await getLULCMapImageFromIDX(reportID, i);
      if (image.mapType != 'landcover') continue;
      else return image;
    }
  }
  return null; // if image with required mapType cant be found
}


/** THE FOLLOWING FUNCTIONS ARE USED FOR THE AUTOMATIC CHANGE DETECTION DISPLAY PAGE */
/** 
 * This is a function that fetches and retrieves the array of images
 * associated with an automatic change detection report, to be used in other functions
 * to retrieve a specific image from the array.
 */
 export async function getAutoDetectionImages(reportID) {
  let path = '/report/autodetection/get/';
  const httpMethod = 'PUT';
  let outputArray = '';

  const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);

  // return the image array
  if (!response.hasError) {
    outputArray = response.data.images;
  } else {
    outputArray = null;
    console.log(response.errorMessage);
  }
  return outputArray;
}


/* This is a function that fetches and retrieves first/intial LULC reportID used
* to create the automatic change detection report.
*/
export async function getACDFirstLULCID(reportID) {
  let path = '/report/autodetection/get/';
  const httpMethod = 'PUT';
  let output = '';
 
  const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);
 
  // return the image array
  if (!response.hasError) {
    output = response.data.init_lulcID;
    console.log("entered report", output);
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/* This is a function that fetches and retrieves second/final LULC reportID used
* to create the automatic change detection report.
*/
export async function getACDSecondLULCID(reportID) {
  let path = '/report/autodetection/get/';
  const httpMethod = 'PUT';
  let output = '';
 
  const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);
 
  // return the image array
  if (!response.hasError) {
    output = response.data.final_lulcID;
    console.log("entered report", output);
  } else {
    output = null;
    console.log(response.errorMessage);
  }
  return output;
}


/* This is a function that fetches the first/intial LULC report used
* to create the automatic change detection report and retrieves the satellite image
* from that report.
*/
export async function getACDFirstSatImage(reportID) {
  let path = '/report/autodetection/get/';
  let responseData = '';
  const httpMethod = 'PUT';
  let output = '';
 
  const body = {reportID: reportID};
  let response = await useFetch(url + path, httpMethod, body, true);
 
  // return the image array
  if (!response.hasError) {
    responseData = response.data.init_lulcID;
    if (responseData) {
      const report = await getLULCSatelliteImage(responseData);
      if (report) responseData = report;
    }
    output = responseData;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}
 

/* This is a function that fetches the second/final LULC report used
* to create the automatic change detection report and retrieves the satellite image
* from that report.
*/
export async function getACDSecondSatImage(reportID) {
   let path = '/report/autodetection/get/';
   const httpMethod = 'PUT';
   let responseData = '';
   let output = '';

   const body = {reportID: reportID};
   let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);
  
   // return the image array
  if (!response.hasError) {
    responseData = response.data.final_lulcID;
    if (responseData) {
      const report = await getLULCSatelliteImage(responseData);
      if (report) responseData = report;
    }
    output = responseData;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}
 

/** 
 * This is a function that fetches and retrieves a particular map image
 * given the array of ACD images and the particular index the image
 * is located at.
 */
 export async function getAutoImageFromIDX(reportID, i) {
  let path = '/image/map/get/';
  const httpMethod = 'PUT';
  let output = '';
  // retrieve the image array
  const imageArray = await getAutoDetectionImages(reportID);
  // console.log(imageArray);

  // retrieve the image ID for the image at the requested index of the array
  let imageID = imageArray[i].imageID;
  
  const body = {imageID: imageID};

  // return the image
  let response = await useFetch(url + path, httpMethod, body, true);
  if (!response.hasError) {
    output = response.data;
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}


/** 
 * This is a function that fetches and retrieves the change image
 * associated with an ACD report, using the functions above
 * to retrieve the ACD image array and continue to fetch each of the images
 * in the array until the image returned's name is the one required (Initial Land Cover).
 */
 export async function getACDFirstImage(reportID) {
  // retrieve the image array
  const imageArray = await getAutoDetectionImages(reportID);
  // console.log(imageArray);
  // loop through the image array and continue fetching until 
  // the image returned has a name of Initial Land Cover
  for (let i = 0; i < imageArray.length; i++) {
    const image = await getAutoImageFromIDX(reportID, i);
    if (image.name != 'Initial Land Cover') continue;
    else return image;
  }
  return null; // if image with required name cant be found
}


/** 
 * This is a function that fetches and retrieves the change image
 * associated with an ACD report, using the functions above
 * to retrieve the ACD image array and continue to fetch each of the images
 * in the array until the image returned's name is the one required (Final Land Cover).
 */
 export async function getACDSecondImage(reportID) {
  // retrieve the image array
  const imageArray = await getAutoDetectionImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until 
  // the image returned has a name of Final Land Cover
  for (let i = 0; i < imageArray.length; i++) {
    const image = await getAutoImageFromIDX(reportID, i);
    if (image.name != 'Final Land Cover') continue;
    else return image;
  }
  return null; // if image with required name cant be found
}


/** 
 * This is a function that fetches and retrieves the change image
 * associated with an ACD report, using the functions above
 * to retrieve the ACD image array and continue to fetch each of the images
 * in the array until the image returned's name is the one required (Changes Detected).
 */
 export async function getACDChangeImage(reportID) {
  // retrieve the image array
  const imageArray = await getAutoDetectionImages(reportID);
  console.log(imageArray);
  // loop through the image array and continue fetching until 
  // the image returned has a name of Changes Detected
  for (let i = 0; i < imageArray.length; i++) {
    const image = await getAutoImageFromIDX(reportID, i);
    if (image.name != 'Changes Detected') continue;
    else return image;
  }
  return null; // if image with required name cant be found
}
/** -------------------------------------------------------------------------------- */


/** THE FOLLOWING FUNCTIONS ARE USED FOR THE AUTOMATIC CHANGE DETECTION CREATION PAGE */
/** 
 * This is a function that fetches and retrieves the entire lulc 
 * report information (to be displayed to the user).
 */
 export async function getLULCLandcoverImageforACD(reportID) {
  let path = '/report/lulc/get/';
  const httpMethod = 'PUT';
  let output = '';

  const body = {reportID: reportID};
  let response = await useFetch(url + ":" + CORE_PORT + path, httpMethod, body, true);

  // return the floodrisk report info
  if (!response.hasError) {
    output = response.data.images[0];
  } else {
    output = null;
    console.log(response.errorMessage);
  }

  return output;
}
/** -------------------------------------------------------------------------------- */
// Observations
/** -------------------------------------------------------------------------------- */
export function getObservationsForProjectsMock(projectID) {
  let cond1 = projectID==='1';

  let output =  cond1 ? [
    {reportID: 'eter', name:'ang', submitted_by: 'Aldo Leopold', reportType: 'floodrisk', creation_date: '2021-09-25T12:00:00Z'},
    {reportID: 'onroy', name:'rinh', submitted_by: 'Aldo Leopold', reportType: 'lulc', creation_date: '2021-09-25T12:00:00Z'},
    {reportID: 'achel', name:'hang', submitted_by: 'Aldo Leopold', reportType: 'autodetection', creation_date: '2021-09-25T12:00:00Z'},
    {reportID: 'ara', name: 'adwan', submitted_by: 'Aldo Leopold', reportType: 'carbon', creation_date: '2021-09-25T12:00:00Z'},
    {reportID: 'ugene', name: 'ong', submitted_by: 'Aldo Leopold', reportType: 'fieldobservation', creation_date: '2021-09-25T12:00:00Z'}
  ] : [];

  return output;
}

export function getObservationsForLandownerMock(projectID, landowner_pk) {
  let cond1 = landowner_pk==='1';

  let output =  cond1 ? [
    {reportID: 'eter', name:'ang', submitted_by: 'Aldo Leopold', reportType: 'floodrisk', creation_date: '2021-09-25T12:00:00Z'},
    {reportID: 'onroy', name:'rinh', submitted_by: 'Aldo Leopold', reportType: 'lulc', creation_date: '2021-09-25T12:00:00Z'},
    {reportID: 'ugene', name: 'ong', submitted_by: 'Aldo Leopold', reportType: 'field_observation', creation_date: '2021-09-25T12:00:00Z'},
    {reportID: 'ugene2', name: 'ong2', submitted_by: 'Aldo Leopold', reportType: 'field_observation', creation_date: '2021-09-25T12:00:00Z'}
  ] : [];

  return output;
}

export function getObservationsForPropertyMock(property_pk) {
  let cond1 = property_pk==='1';

  let output =  cond1 ? [
    {reportID: 'eter', name:'ang', submitted_by: 'Aldo Leopold', reportType: 'floodrisk', creation_date: '2021-09-25T12:00:00Z'},
    {reportID: 'onroy', name:'rinh', submitted_by: 'Aldo Leopold', reportType: 'lulc', creation_date: '2021-09-25T12:00:00Z'},
  ] : [];

  return output;
}

export function getFieldObservationDetailMock(reportID) {
  let output = null;
  if (reportID === 'ugene'){
    output =
      {reportID: 'ugene', name: 'ong', reportType: 'field_observation', 
        creation_date : '2021-09-25T12:00:00Z',
        submitted_by : 'placeholder',
        properties : 1,
        headline: 'Fledgling sighted',
        latitude : 1,
        longitude : 1,
        landowner_note : 'string note for landowner',
        landtrust_note : 'string note for landtrust',
        images : 'list of image urls', 

      report_info: `
        Headline: Fledgling sighted\n
        Location: 46.175989, -63.301168\n
        Submitted by: Aldo Leopold\n
        Landowner Notes: saw 4 bobolink fledglings in a nest at the edge of my field this morning
      ` , images:[MaryCreek]}
    
  }

  return output;
}


export function getProjectRecentActivityMock(projectID) {
  let cond1 = projectID==='1';

  let output =  cond1 ? [
        {notificationID:1, title: 'New Field Observation' 
                  , content:'Legacy Farms Incorporated', creation_date: '2021-09-25T12:00:00Z', read:true},
        {notificationID:2, title: 'New Field Observation' 
                  , content:'Doe Strawberry Farms', creation_date: '2021-09-25T12:00:00Z', read:true},
        {notificationID:3, title: 'Vegetation change detected' 
                  , content:'Legacy Farms Incorporated', creation_date: '2021-09-25T12:00:00Z', read:true},
        {notificationID:4, title: 'Property change submitted' 
                  , content:'Nectar Hills Biodynamic Farm', creation_date: '2021-09-25T12:00:00Z', read:false},
      ] : [];

  return output;
}


export function getLandownerRecentActivityMock(projectID, landownerID) {
  let cond1 = projectID==='1' && landownerID ==='1';

  let output =  cond1 ? [
        {notificationID:1, title: 'New Field Observation' 
                  , content:'Legacy Farms Incorporated', creation_date: '2021-09-25T12:00:00Z', read:true},
        {notificationID:3, title: 'Vegetation change detected' 
                  , content:'Legacy Farms Incorporated', dateTime: '2021-09-25T12:00:00Z', read:true},
        ] : [];

  return output;
}
/** -------------------------------------------------------------------------------- */
// Landowners and Properties
/** -------------------------------------------------------------------------------- */
export function getAllLandownersMock() {
  let output = [];

  output.push(
    {id: 1, email: "johndoe@email.com", name: "John Doe", address: "37 John Doe Street", parcels: 3, acres: 246,
      characteristics: {"Males": "3", "Females": "3", "Fledgings": "1", "Audits": "0"}, has_notif: true,
      building_num: 4, post_code: "L5A 9B1", town_name: "Toronto", country_sub: "ON", country: "Canada"
    }
  )
  output.push(
    {id: 2, email: "janedoe@email.com", name: "Jane Doe", address: "42 Jane Doe Street", parcels: 1, acres: 204,
      characteristics: {"Males": "1", "Females": "1", "Fledgings": "0", "Audits": "1"}, has_notif: false,
      building_num: 13, post_code: "L5A 9B1", town_name: "North York", country_sub: "ON", country: "Canada"
    }
  )

  return output;
}

export function getLandownersMock(projectID) {
  let output = [];
  if (projectID == 1) {
    
  }

  output.push(
    {profileID: 1, email: "johndoe@email.com", name: "John Doe", address: "37 John Doe Street", properties_num: 3, area_hectare: 246,
      characteristics: {"Males": "3", "Females": "3", "Fledgings": "1", "Audits": "0"}, has_notif: true,
      building_num: 4, post_code: "L5A 9B1", town_name: "Toronto", country_sub: "ON", country: "Canada"
    }
  )
  output.push(
    {profileID: 2, email: "janedoe@email.com", name: "Jane Doe", address: "42 Jane Doe Street", properties_num: 1, area_hectare: 204,
      characteristics: {"Males": "1", "Females": "1", "Fledgings": "0", "Audits": "1"}, has_notif: false,
      building_num: 13, post_code: "L5A 9B1", town_name: "North York", country_sub: "ON", country: "Canada"
    }
  )

  return output;
}


export function getLandownerDetailsMock(projectID, landownerID) {
  let output;

  if (landownerID == 1) {
    output = {
      profileID: 1, email: "johndoe@email.com", name: "John Doe", address: "37 John Doe Street", properties_num: 3, area_hectare: 246,
      characteristics: {"Males": "3", "Females": "3", "Fledgings": "1", "Audits": "0"}, has_notif: true,
      building_num: 4, post_code: "L5A 9B1", town_name: "Toronto", country_sub: "ON", country: "Canada"
    }
  }
  else if (landownerID == 2) {
    output = {
      profileID: 2, email: "janedoe@email.com", name: "Jane Doe", address: "42 Jane Doe Street", properties_num: 1, area_hectare: 204,
      characteristics: {"Males": "1", "Females": "1", "Fledgings": "0", "Audits": "1"}, has_notif: false,
      building_num: 13, post_code: "L5A 9B1", town_name: "North York", country_sub: "ON", country: "Canada"
    }
  }
  else {
    output = {
      profileID: landownerID, email: "anon@email.com", name: "Anon", address: "69 Anon Street", properties_num: 1, area_hectare: 420,
      characteristics: {"Males": "0", "Females": "0", "Fledgings": "0", "Audits": "0"}, has_notif: false,
      building_num: 69, post_code: "L5A 9B1", town_name: "Amogus Us", country_sub: "ON", country: "Canada"
    }
  }

  return output;
}

export function getPropertiesForLandownerMock(landownerID) {
  let output = [];

  output.push(
    {propertyID: 1, property_name: "John Doe Farms", address: "24 J Street West, ON", region: 1, area_hectares: 246,
      longitude: -79.16498067030338, latitude: 43.66255620353573, perimeter_meter: 100,
      characteristics: {"Males": "3", "Females": "3", "Fledgings": "1", "Audits": "0"}
    }
  )

  return output;
}

export function createPropertyForLandownerMock(landowner_pk, body) {
  let output = [];

  output.push(
    {propertyID: 1, property_name: "John Doe Farms", address: "24 J Street West, ON", region: 1, area_hectares: 246,
      longitude: -79.16498067030338, latitude: 43.66255620353573, perimeter_meter: 100,
    }
  )

  return output;
}

/** -------------------------------------------------------------------------------- */
// New regions
/** -------------------------------------------------------------------------------- */


export function getDataForRegionMock(regionID) {
  let output;

  // make output = {id, geometry, properties, type} (geojson format)
  output = {
    data: {
      id: "eec8102153d4a41b02453f8a76790f7c",
      type: "Feature",
      properties: {
        name: "John Doe Farms",
        ID: 1
      },
      geometry:{
        coordinates:
        [
          [
            [-79.16498067030338, 43.66255620353573],
            [-79.36826801573997, 43.56211256395099],
            [-79.15124503885514, 43.51332204930594],
            [-79.16498067030338, 43.66255620353573]
          ]
        ],
        type:"Polygon"}
    },
    regionID: 1
  }

  return output;
}

/** -------------------------------------------------------------------------------- */
// Profiles
/** -------------------------------------------------------------------------------- */

export async function createLandownerProfileMock(body) {
  let output = {...body};

  return output;
}
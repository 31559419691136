import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import './rename-popup.css';

export const DeletePopup = ({ onDeleteType, onClose, onDeleteConfirm, onDeletingProgress, regionName}) => {
    const [isDeletingProgress, setDeletingProgress] = useState(false);
    const [mounted, setMounted] = useState(true);


    const handleDeleteClick = async () => {
  
        try {
          setDeletingProgress(true);
          await onDeleteConfirm();
        } finally {
          if (mounted) {
            setDeletingProgress(false);
            onClose();
          }
        }
      };
    return (
        <Popup
            open={true}
            position="center center"
            closeOnDocumentClick={false}
            modal>
            <div className="popup-box">
                <h2 className="popup-heading">Delete {onDeleteType}</h2>
                <p>Are you sure you wish to delete {regionName}? <strong>This will affect previously generated reports featuring this property. This action cannot be undone.</strong></p>
                <div className="btn-group">
                    <button
                        className="delete-btn"
                        onClick={handleDeleteClick}
                        disabled={isDeletingProgress}
                    >
                        Delete
                    </button>
                    <button
                        className="cancel-btn"
                        onClick={onClose}
                        disabled={isDeletingProgress}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </Popup>
    );
};
import React from "react";
import mapboxgl from "!mapbox-gl";
import MapboxCompare from "mapbox-gl-compare";
import SliderArrows from "../../images/slider-arrows.svg";
import HamburgerMenu from '../modules/hamburger-menu';
import RemoteSensingIcon from "../../images/remote-sensing.png"
import TreeIcon from "../../images/Tree-icon.svg"
import "./mapbox-compare.css";
import "./mapbox-auto-detection.css";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia29yb3R1LWNvbnJveS10cmluaCIsImEiOiJja2pyeTh0cnU2aDViMnFwOTFrZHoxdDU3In0.VEuiNCwqBGL8CidAvTgdjA";

export class MapboxAutoDetection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayImages: true,
    };

    /** PROPS
     *  projectName
     *  projectDescription
     *  latitude
     *  longitude
     *  zoom
     *  setRegionID
     *  setRegionName
     *  project
     *  NOTE: Mapbox ID is the same as the UUID used in Django Backend
     *  beforeRaster: The Mapbox ID identifying the before image
     *  beforeStyle: The style on which we wish to place the before image
     */
  }

  componentDidMount() {
    {
      /** Before Side Map*/
    }
    const beforeMap = new mapboxgl.Map({
      container: "before", // 'before' is the id of a div container
      style: this.props.beforeStyle,
      // style: 'mapbox://styles/mapbox/streets-v9',
      // style: 'mapbox://styles/mapbox/dark-v10',
      center: [this.props.longitude, this.props.latitude],
      zoom: this.props.zoom,
      logoPosition: "bottom-left",
      attributionControl: true,
    });

    beforeMap.on("idle", function () {
      beforeMap.resize();
    });
    // beforeMap.addControl(new mapboxgl.AttributionControl(), 'top-left');

    this.props.beforeMap.current = beforeMap;

  }

  render() {
    return (
      <div id="before" className="map-container"
        style={{ height: this.props.mapHeight, width: this.props.mapWidth }}>
      </div>
    );
  }
}

import CreateIcon from "../../images/create-icon.png";
import UsersIcon from "../../images/users.png";
import DownloadIcon from "../../images/download-icon.png";
import "./page-header.css";

export const PageHeader = (props) => {
  const { headerText, buttonsNeeded, headerIconNeeded } = props;
  return (
    <div className="header-container">
      <h3>
        <span>{headerText}</span>
        {headerIconNeeded ? <img className="create-icon" src={CreateIcon} /> : null}
      </h3>
      {buttonsNeeded ? (
        <div className="btn-container">
          <button type="button" className="other-act-button btn">
            <img src={DownloadIcon} />
            <span>Import</span>
          </button>
          <button type="button" className="other-act-button btn">
            <img src={UsersIcon} />
            <span>Share</span>
          </button>
        </div>
      ) : null}
    </div>
  );
};

import Back from '../images/icons/back-icon.png'
import { createCustomRegion, getProjectRegions } from "../apis/landsteward/accounts"
import { useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import "./region-new.css"

export function RegionNew(props) {
    const history = useHistory()
    const regionsList = useRef(null)
    const params = useParams()
    const [warning, setWarning] = useState('');
    const [regionID, setRegionID] = useState('');
    const [regionName, setRegionName] = useState('');
    const [features, setFeatures] = useState([]);
    

    const fileValidation = () => {
        let fileInput = document.getElementById('uploadButton');
        let filePath = fileInput.value;
        let allowedExtensions = /(\.geojson)$/i
        let CRS = ''

        if (!allowedExtensions.exec(filePath)) {
            setWarning('Please select a geojson file')
            fileInput.value = '';

        } else {
            let file = fileInput.files[0]
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {

                const binaryStr = reader.result;
                let enc = new TextDecoder("utf-8");
                var data = JSON.parse(enc.decode(binaryStr));
                // console.log(data.features)

                if (typeof data.crs == 'undefined') {
                    CRS = "urn:ogc:def:crs:OGC:1.3:CRS84"
                } else {
                    CRS = data.crs.properties.name
                }



                if (data.features.length > 0) {
                    data.features.forEach((feature, index) => {
                        // if (data.crs === undefined) {
                        //     data.crs.properties =  {name: 'urn:ogc:def:crs:OGC:1.3:CRS84'}
                        //     data.crs.type = "name"
                        // }
                        if (feature.properties.Name === undefined) {
                            console.log("found undefined")
                            feature.properties.Name = `Feature ${index}`
                        }

                        feature.properties.Name = feature.properties.Name.replace("(","-");
                        feature.properties.Name = feature.properties.Name.replace(")","-")
                        console.log("feature.properties.Name", feature.properties.Name)
                    })
                }
                console.log("data --", data)
                setFeatures(data.features);
                console.log("FEATURES", features)

                if (CRS !== "urn:ogc:def:crs:OGC:1.3:CRS84") {
                    fileInput.value = '';
                    setWarning('Geojson file should be using a Geographic Coordinate System, where Latitude and Longitude are in decimal degrees')
                }

                else {
                    setWarning('')
                    setWarning(`Found ${data.features.length} properties in file`)
                    document.getElementById('fileName').innerHTML = fileInput.files[0].name
                    document.getElementById('submit-file-button').disabled = false
                    // clears inputs if user inputs new file
                    // Array.from(document.getElementsByClassName("region-name-input" )).forEach(input => input.value = "")

                    console.log("File name", fileInput.files[0].name)

                    
                    
                }
            }

            reader.readAsArrayBuffer(file);
            setRegionName(fileInput.files[0].name)
            console.log("Region name", regionName)

        }
    }

    async function createNewRegion(e) {
        e.preventDefault();
        let region;
        let success = false
        if (features && Object.keys(features).length == 1) {
            console.log("ONE FEATURE HERE: *****************************");
            console.log("single", features)

            features[0].properties.Name.replace("(","-");
            features[0].properties.Name.replace(")","-");
            console.log('removed ()')

            region = await createCustomRegion(features[0].properties.Name, features, params.projectID);
            if (!region) {
                setWarning('Please select a geojson file of choose an area on map')
                console.error("Region creation failed");

                return;
            }
            
            success = true
        } else if (features && Object.keys(features).length > 1) {
            console.log("MULTIPLE FEATURES HERE: *****************************");
            // console.log(features)

            for (let i = 0; i < features.length; i++) {
                console.log([features[i]])

                region = await createCustomRegion(features[i].properties.Name, [features[i]], params.projectID);
                if (!region) {
                    setWarning('Please select a geojson file of choose an area on map')
                    console.error(`Creating region '${features[i].properties.Name}' failed`);
                    
                    return;
                }
                setWarning(`UPLOADING REGIONS! PLEASE DO NOT LEAVE PAGE! \n
                            Uploaded region ${features[i].properties.Name}`)

            }         
            success = true

        }
        /** Add Project to website State */
        if (success) {
            /** Updated Website State and then Route to project */
            setWarning('Succesfully uploaded ALL regions')
            // props.currRegion = region
            // history.push("reports")

        } else {
            console.error('Project Creation Failed');
            alert("Region Creation Failed. Make sure you have a completed boundary!");
        }
        // console.log("region", props.currRegion)

    }

    // getProjectRegions(props.projectID).then((res) => {console.log("regions", res)})

    return (
        <div className='project-new__body'>
            <div className='project-new__back-col'>
                {/* <button className='back-btn project-new__back-btn'>
                <img src={Back}></img>
            </button> */}
            </div>
            <div className='project-new__form-col'>
                <div className="project-new-title">
                    <h1>Select a Region Area</h1>
                </div>
                <form className='project-new__form' onSubmit={createNewRegion}>
                    <div className="project-new__form__body">

                        <div className='project-new__input-block'>
                            <label htmlFor='project-upload'>Upload an existing shapefile</label>
                            <div className='upload-file-button'>
                                <input
                                    type="file"
                                    accept=".geojson"
                                    id="uploadButton"
                                    onChange={() => fileValidation()}
                                    hidden
                                />
                                <label htmlFor="uploadButton" id="fileName">Upload...</label>
                            </div>
                        </div>

                        <div className='project-new__input-block'>
                            <label htmlFor='project-map'>Choose on map</label>
                            <div className='upload-file-button'>
                                <input
                                    type="submit"
                                    id="map-button"
                                    onClick={() => { console.log('add region with map') }}
                                    hidden
                                />
                                <label htmlFor="map-button">Open Map...</label>
                            </div>
                        </div>

                        <p className='project-new__warning-text'>{warning}</p>

                        <div className='project-new__input-block'>
                            <label htmlFor='project-upload' style={{display: (features.length > 0) ? "block" : "none"}}>Rename Region</label>
                            <div className='regions-list' style={{display: (features.length > 0) ? "block" : "none"}}>
                                    {
                                        features.map((feature, index) => {
                                            if (feature.properties.Name === undefined) {
                                                feature.properties.Name = regionName + `-F${index}`
                                            }
                                            console.log("refreshed map")
                                            //console.log(feature.properties.Name)
                                            return (
                                            <input
                                                className="region-name-input"
                                                onChange={(e) => {
                                                    setFeatures((oldFeatures) => {
                                                        oldFeatures[index].properties.Name = e.target.value
                                                        return oldFeatures
                                                    })
                                                }}
                                                defaultValue={feature.properties.Name}
                                            /> )
                                        })
                                    }
                            </div>


                        <div className="project-new-button">
                            <input
                                name='submit-btn'
                                type='submit'
                                id='submit-file-button'
                                value='Confirm Area'
                                disabled
                            />
                        </div>

                    </div>
                    </div>
                </form>
            </div>
            <div className='project-new__back-col'></div>
        </div>
    )
}
import React from 'react';

import mapboxgl from '!mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import PolygonDraw from  "../../images/mapboxgljs-polygon-draw.png";
import Nav from  "../../images/mapboxgljs-nav.png";
import './mapbox-interface.css'


mapboxgl.accessToken = 'pk.eyJ1Ijoia29yb3R1LWNvbnJveS10cmluaCIsImEiOiJja2pyeTh0cnU2aDViMnFwOTFrZHoxdDU3In0.VEuiNCwqBGL8CidAvTgdjA'; 



class MapboxInterface extends React.Component {
  constructor (props){
    super(props);
    this.state = {
      lng: -79.575,
      lat: 43.653,
      zoom: 9
    };

    /** PROPS
     *  longitude
     *  lattitude
     *  zoom
     *  mapContainerRef
     *  style
     *  
     */
  }

  componentDidMount() {
    console.log("Start");
    /** Mapbox base setup */
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/korotu-conroy-trinh/cknugt5t30v7z17mlooj8tduv',
      // Older variant with incorrect IDs (database was wiped)
      //mapbox://styles/korotu-conroy-trinh/ckliyl6730epc17qlossubdlx
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom
    });

    /** Mapbox Draw Polygon Feature */
    let draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true,
        zoom: true
      }
    });
    map.addControl(draw);

    /** Mapbox Navigation Feature */
    let nav = new mapboxgl.NavigationControl(); map.addControl(nav);

    /** Mapbox Geolocate User Feature */
    // let search = new mapboxgl.GeolocateControl(); map.addControl(search);

    /** Mapbox Dynamic Scale Feature */
    let scale = new mapboxgl.ScaleControl({maxWidth: 100});
    map.addControl(scale,'top-right');

    


    /** Print Custom Polygons drawn by mapbox-gl-draw *
    map.on('draw.create', updateArea);
    map.on('draw.delete', updateArea);
    map.on('draw.update', updateArea);
    function updateArea(e) {
      console.log(e.features);
      console.log("second features");
      let featureCollection = draw.getSelected();
      let features = draw.getAll()
      console.log(features);
      let featuresJSON = JSON.stringify(features);
      let featureCollectionJSON = JSON.stringify(featureCollection);
      console.log(featuresJSON);
      console.log(featureCollectionJSON);
    } /** */
    
    
    //#################################################################
    /** adds a layer to interact with the watershed source layer */
    map.on('load', function() {
      // Outline for all regions in the 
      map.addLayer(
        {
          'id': 'outline',
          'type': 'fill',
          'source': 'composite',
          'source-layer': 'OWBQUAT-6j0j35',
          'paint': {
          'fill-outline-color': 'rgba(0,0,0, 0.1)',
          'fill-color': 'rgba(0,0,0,0.1)'
          }
        },
        'watershed-label'     // Place polygons under labels.
      ); 
      
      // 
      map.addLayer(
        {
          'id': 'watersheds-highlighted',
          'type': 'fill',
          'source': 'composite',
          'source-layer': 'OWBQUAT-6j0j35',
          'paint': {
          'fill-outline-color': '#484896',
          'fill-color': '#6e599f',
          'fill-opacity': 0.5
          },
          // Display none by adding a
          // filter with an empty string.
          'filter': ['in', 'NAME', '']
        },
        'watershed-label'      // Place polygons under labels.
      );

        map.addLayer(
          {
          'id': 'watersheds-selected',
          'type': 'fill',
          'source': 'composite',
          'source-layer': 'OWBQUAT-6j0j35',
          'paint': {
          'fill-outline-color': '#484896',
          'fill-color': '#6e599f',
          'fill-opacity': 1
          },
          // Display none by adding a
          // filter with an empty string.
          'filter': ['in', 'NAME', '']
          },
          'watershed-label'
        ); // Place polygons under labels.
        
        map.on('mousemove', 'outline', function (e) {
          // Change the cursor style as a UI indicator.
          map.getCanvas().style.cursor = 'pointer';
            
          // Use the first found feature.
          var feature = e.features[0];
            
          // Causes the Watershed being hovered to become highlighted
          map.setFilter('watersheds-highlighted', [
            'in',
            'NAME',
            feature.properties.NAME
          ]);
        });

        map.on('click', 'outline', function (e) {
          // Change the cursor style as a UI indicator.
          map.getCanvas().style.cursor = 'pointer';
            
          // Use the first found feature.
          var feature = e.features[0];
          // Add features with the same county name
          // to the highlighted layer.
          map.setFilter('watersheds-selected', [
            'in',
            'NAME',
            feature.properties.NAME
          ]);

          console.log(feature.properties.NAME)
          console.log(" Mapbox Region Feature Property", feature)
          
        });



        map.on('mouseleave', 'outline', function () {
          map.getCanvas().style.cursor = '';
          map.setFilter('watersheds-highlighted', ['in', 'NAME', '']);
        });
    });
    //#################################################################




      
      


    /** Display mapbox coordinate details and mapbox layers + sources on move */
    map.on('move', () => {
      this.setState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2)
      });
      
    }); /** */
  }

  render() {
    return (
    <div className="mapSection">
      <div ref={el => this.mapContainer = el} className='mapContainer'>
      <div className='sidebarStyle'>
        <div>Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom: {this.state.zoom}</div>
      </div>
      </div>
    </div>
    )
  }
}


export default MapboxInterface;
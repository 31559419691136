import React, {useState, useEffect} from 'react';
import { useParams } from "react-router"
import {Redirect} from 'react-router-dom';


/** ##### Components ##### */
import {Dropdown} from '../components/modules/satellite-dropdown';
import {ImageContainer} from '../components/modules/image-container';

/** ##### Visual Assets ##### */
import './ndvi-create-page.css';
import MaryCreek from '../images/sample_region_image.png';

/** ##### API ##### */
import { createNDVI } from '../apis/landsteward/valuation';
import { getProjectSatelliteImages } from '../apis/landsteward/accounts'



/**
 * 
 * @param {*} props
 *    <project>: current project
 *    <region>: current region
 *    <satelliteImages>: satellite Images of the current project
 *    <addNdvi>: function which adds the ndvi to the web state
 *    <changeCurrentState>: Function to set the website's current state.
 */
export function NDVICreationPage(props) {
  
  /** ##### Variables ##### */
  let params = useParams();
  let [satelliteImage, setSatelliteImage] = useState(null);
  let [satelliteImages, setSatelliteImages] = useState(null);
  let [ndviName, setNdviName] = useState('');
  let [cloudMask, setCloudMask] = useState(false); 
  let [createSuccess, setCreateSuccess] = useState(false); 


  const fetchSatelliteImages = async () => {
    const satelliteImageData = await getProjectSatelliteImages(params.projectID)
    setSatelliteImages(satelliteImageData);
  }


  useEffect(() => {
    fetchSatelliteImages()
    //clean state on unmount, use to avoid warning
    return () => setSatelliteImages({});
  }, []);


  /* ##### Initial Paramater Checks and Satellite Initialization ##### */
  /* Initial Selection of the Satellite Image */
  useEffect(() => {
    if (satelliteImages && satelliteImages.length > 0) {
      setSatelliteImage(satelliteImages[0]);
    }
  }, [satelliteImages]);
  
  /* Change Satellite on Land Cover Page given dropdown selection */
  async function changeSatelliteImage(reportID) {
    for (let i = 0; i < satelliteImages.length; i++) {
      if (satelliteImages[i].reportID === reportID) {
        setSatelliteImage(satelliteImages[i]);
        i = satelliteImages.length;
      }
    }
  }
  /* ##### Creation of vegetation Reports ##### */
  async function triggerNdviCreation () {
    console.log('trigger ndvi creation');
    let valid = props.project && props.region && satelliteImage;

    if (true) {
      let ndviData = await createNDVI(
        props.project.projectID,
        params.propertyID,
        satelliteImage.reportID,
        ndviName,
        cloudMask
      );
      
      

      if (ndviData !== null) {
        ndviData.name = ndviName;
        ndviData.reportType = 'ndvi';
        props.addNdvi(ndviData);
        console.log('ndvi creation success');
        setCreateSuccess(true);
      } else {
        console.error('NDVI Creation Failed');
      }
    }
  }

  



  /* ##### URL Parameters ##### */
  let [projectFailed, setProjectFailed] = useState(false);
 //let [regionFailed, setRegionFailed] = useState(false);
  /* Updates the current state using URL parameters */
   useEffect(async () => {
      let isCorrectState = await props.changeCurrentState({
        projectID: params.projectID,
      });
      if (!isCorrectState.project) {
      console.error('Failing Projects', isCorrectState);
      setProjectFailed(true);
    }
  },[]);
    /*
    console.log('use effect');
    props.changeProject(params.projectID);
    props.changeRegion(params.regionID);
    */
  if (projectFailed) {
    return (<Redirect to='/project'/>);
  //} else if (regionFailed) {
  //  return (<Redirect to={`/project/${params.projectID}`}/>);
  //} else if (createSuccess) {
    //return (<Redirect to={window.location.pathname.split('/').slice(0,-1).join('/')}/>);
  } else {
    /* ##### Render ##### */
    return (
      <div className='land-cover-creation'>
        <div className='lulc-creation__title'>
          <h1>Create Vegetation Report</h1>
        </div>

        {/* Headings + Land Cover Name */}
        <p><span className="name">Project:</span> {props.project ? props.project.name : ''}</p>
        <p><span className="name">Region:</span> {props.region ? props.region.name : ''}</p>

        <label htmlFor='ndvi-creation-name' className='ndvi-creation__name-label'><span className="name">Report Name:</span></label>
        <input
          type="text"
          name="ndvi-creation-name"
          value={ndviName}
          onChange={(event) => setNdviName(event.target.value)}
        />

        <br/>
        
        <div className="image-selection" style = {{marginTop:'3em'}}>
          {/* Satellite Selection Image + Dropdown */}
          <p className="ndvi-creation__subtitle"style = {{marginTop:'5px'}}><span className="name"> Satellite Image:</span></p>
          <Dropdown
            name="select-satellite-dropdown"
            value={satelliteImage ? satelliteImage.reportID : ''}
            setValue={(reportID) => changeSatelliteImage(reportID)}
            valueList={satelliteImages ? satelliteImages : []}
            valueProperty="reportID"
            nameProperty="name"
          />
        </div>
        <br/>
        <label className='ndvi-creation__mask'>
          <span className="name">Cloud Mask:</span>
        </label>
        <input
          type="checkbox"
          name="ndvi-creation-mask"
          value={cloudMask}
          onChange={cloudMask?() => setCloudMask(false):()=>setCloudMask(true)}
        />
        <br/>
        <ImageContainer
            imageSource={satelliteImage ? satelliteImage.png_image : MaryCreek}
            className="ndvi-creation__satellite-image"
            title={satelliteImage ? satelliteImage.name : 'Satellite Image:'}
        />

        <div className="report-new-button">
          <button onClick={triggerNdviCreation}> Create Report</button>
        </div>
      </div>
    );
  }
}
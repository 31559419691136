import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { RecentActivitiesCollection } from "./recent-activity-cards";
import {
  getProjectRecentActivity,
  getLandownerRecentActivity,
} from "../../apis/landsteward/accounts";
import {
  getProjectRecentActivityMock,
  getLandownerRecentActivityMock,
} from "../../apis/landsteward/mock_accounts";
import { MOCK_DATA } from "../../apis/landsteward/urlconfig";

import "./recent-activity.css";

/**
 * A frame that adds a recent activity tab on the right side of the content
 *
 * @param   {String}  projectID tab will only show activies in this project
 * @param   {String}  landownerID Optional, tab will only show activities related to this land owner
 *
 * @author  [Eugene Wong]
 */
export default function RecentActivityFrame(props) {
  let [getActivityFail, setGetActivityFail] = useState(false);
  let [recentActivities, setRecentActivities] = useState();

  useEffect(async () => {
    let result;
    if (props.landownerID) {
      result = MOCK_DATA
        ? getLandownerRecentActivityMock(props.projectID, props.landownerID)
        : await getLandownerRecentActivity(props.projectID, props.landownerID);
    } else {
      result = MOCK_DATA
        ? getProjectRecentActivityMock(props.projectID)
        : await getProjectRecentActivity(props.projectID);
    }

    console.log(result);
    if (!result) {
      setGetActivityFail(true);
    } else {
      setRecentActivities(result);
    }
  }, [props.projectID]);

  const boxRef = useRef();

  if (getActivityFail || !recentActivities) {
    return (
      <div className="recent-activity-frame">
        <div ref={boxRef}>{props.children}</div>
      </div>
    );
  }
  return (
    <div
      className="recent-activity-frame"
      style={{ height: boxRef.current.getBoundingClientRect().height }}
    >
      <div ref={boxRef} style={{ flexGrow: 1 }}>
        {props.children}
      </div>
    </div>
  );
}

import react from 'react';
import {Redirect} from "react-router-dom";
import emailjs from '@emailjs/browser';
import './login-page.css'
import {Link} from 'react-router-dom';
import LoginImage from '../images/Korotu-login-image.png'
import LoginText from '../images/korotu-login-text.png'
import Logo from "../images/Korotu_Logo.png"
import { url, CORE_PORT } from '../apis/landsteward/urlconfig'
import useFetch from '../apis/landsteward/useFetch';

/**
 * 
 * @param {function} updateState takes a object `state` to update the highest level state of LandSteward
 */


class LoginPage extends react.Component {
  constructor(props){
    super(props);
    this.state = {email: '', password: '', login: '', signup: false, first_name: '', last_name: ''};
    this.userLogin = this.userLogin.bind(this);
    this.userSignup = this.userSignup.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.updateFirstname = this.updateFirstname.bind(this);
    this.updateLastname = this.updateLastname.bind(this);
    this.toggleSignupPage = this.toggleSignupPage.bind(this);
  }

  async toggleSignupPage() {
    this.setState({signup: !this.state.signup});
    this.setState({login: ''});
  }

  async updatePassword(e) {
    const newPassword = e.target.value;
    this.setState({password: newPassword});
  }

  async updateEmail(e) {
    const newEmail = e.target.value;
    this.setState({email: newEmail})
  }

  async updateFirstname(e) {
    const newName = e.target.value;
    this.setState({first_name: newName})
  }

  async updateLastname(e) {
    const newName = e.target.value;
    this.setState({last_name: newName})
  }

  async userLogin(e) {
    e.preventDefault();
    let path = `/users/accounts/login/`

    const body = {
      username: this.state.email,
      password: this.state.password
    }

    const response = await useFetch(url + ':' + CORE_PORT + path, 'POST', body);

    if (!response.hasError) {
      this.setState({login: 'success'});
      this.props.changeState(response.data);
    }
    else {
      this.setState({login: 'failure'});
    }
  }

  async userSignup(e) {
    e.preventDefault();
    let path = '/users/accounts/'

    const body = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      user_type: 'TRUST'
    }

    const response = await useFetch(url + ':' + CORE_PORT + path, 'POST', body);

    if (!response.hasError){

      await emailjs.send("service_7gtrwhd","template_uo77ktx", {
        from_name: "NEW KOROTU SIGNUP ATTEMPT",
        to_name: "Korotu Signup",
        message: `The user ${this.state.first_name}  ${this.state.last_name} has attempted to signup at ${this.state.email} on server ${url}`
      }, "GzISf4tblEDFAKWR9")
          .then(function(response) {
          }, function(error) {
          });

      this.setState({signup: false});
      alert("Thank you for signing up! A person from the Korotu team will contact you.");
    } else {
      console.log('sign up failed');
      alert("Failed to signup (system error)");
    }
  }

  render() {
    if (this.state.login === 'success'){
      return(<Redirect to='/project'></Redirect>)
    } else {
    return (
    <div className="frame">
      <div className='logo-column'>
        <img src={LoginImage} className='korotu-bg'></img>
        <img src={Logo} className='korotu-text'></img>
      </div>
      
      <div className='login-column'>
        <div className='login-box' style={this.state.signup || this.state.login === 'failure' ? {height: '37em'} : {}}>
        <p className='signin-text'>Get Started with LandSteward</p>
          <form onSubmit={this.state.signup ? this.userSignup : this.userLogin} className='login-form'>
            <label for='email' className='input-text'>
              Email
              <input type='text' value={this.state.email}
                onChange={this.updateEmail}
                name='email'
                className='input-field'
                required
              />
            </label>
            
            <label for='password' className='input-text'>
              Password
              <input type='password' value={this.state.password}
                onChange={this.updatePassword}
                name='password'
                className='input-field'
                required
              />


            </label>

            {this.state.signup ?
            <form>
              <div className='name-container'>
                <label for='first-name' className='input-text'>
                  First Name
                  <input type='text' value={this.state.first_name}
                    onChange={this.updateFirstname}
                    name='first-name'
                    className='input-field'
                    required
                  />
                </label>
                <label for='last-name' className='input-text'>
                  Last Name
                  <input type='text' value={this.state.last_name}
                    onChange={this.updateLastname}
                    name='last-name'
                    className='input-field'
                    required
                  />
                </label>
              </div>
              </form>
               : null
            }

            <input type='submit' value={this.state.signup ? 'Sign up':'Log In'} id='loginButton' className='submit-btn'/>
            <form action="mailto:support@korotu.com?">
            <input type="submit" value="Forgot Password" className='forgot-btn'/>
            </form>

          </form>

          {this.state.signup ? null :
           <form>
           </form>
          }
          
          {this.state.login === 'failure' ?
            <p className='warning-text'>
              Failed to login (bad username or password)
            </p> : null
          }
          <hr/>

          {this.state.signup ?
            <div className='signup-container'>
              <p>Already have an account?</p>
              <button className='signup-btn' onClick={this.toggleSignupPage}>Sign in</button>
            </div>
            :
            <div className='signup-container'>
              <p>Don't have an account?</p>
              <button className='signup-btn' onClick={this.toggleSignupPage}>Sign up</button>
            </div>
          }
          
        </div>

        <p className='korotu-copyright'>&copy; Korotu Technology 2023</p>

      </div>
    </div>
    )}
  }
}

function validate() {
        let password = document.getElementById("password").value;
        var confirmPassword = document.getElementById("confirmpass").value;
        if (password != confirmPassword) {
            alert("Passwords do not match.");
            return false;
        }
        return true;
}

export default LoginPage;
import React from 'react';

import './App.css';

import { Landsteward } from './webpages/landsteward'

import 'normalize.css';


/**
 *  App is the uppermost container for all React and Javascript Assets,
 *  Website contains the UX/UI functionality for landsteward, but NOT
 *  any third party tools unrelated to landsteward features (e.g. Google
 *  Analytics and CSS reset)
 */
class App extends React.Component {
  // Code from the next few steps will go here
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Landsteward></Landsteward>
    )
  }
}

export default App;
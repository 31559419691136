import React, { useState, useRef, useEffect } from "react";
import {StylesControl} from "mapbox-gl-controls";
import "mapbox-gl-controls/lib/controls.css"
import mapboxgl from "!mapbox-gl";
import MapboxCompare from "mapbox-gl-compare";
import SliderArrows from "../../images/slider-arrows.svg";
import HamburgerMenu from '../modules/hamburger-menu';
import RemoteSensingIcon from "../../images/remote-sensing.png"
import TreeIcon from "../../images/Tree-icon.svg"
import MarkerIcon from "../../images/icons/map-pin-icon.png"
import "./mapbox-compare.css";

mapboxgl.accessToken =
  "pk.eyJ1Ijoia29yb3R1LWNvbnJveS10cmluaCIsImEiOiJja2pyeTh0cnU2aDViMnFwOTFrZHoxdDU3In0.VEuiNCwqBGL8CidAvTgdjA";

export class MapboxProperty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayImages: true,
    };
    /** PROPS
     *  projectName
     *  projectDescription
     *  latitude
     *  longitude
     *  zoom
     *  setRegionID
     *  setRegionName
     *  project
     *  NOTE: Mapbox ID is the same as the UUID used in Django Backend
     *  beforeRaster: The Mapbox ID identifying the before image
     *  beforeStyle: The style on which we wish to place the before image
     *  afterRaster: The Mapbox ID identifying the after image
     *  afterStyle: The style on which we wish to place the after image
     */
  }

  componentDidMount() {
    {
      /** Before Side Map*/
    }
    const beforeMap = new mapboxgl.Map({
      container: "before", // 'before' is the id of a div container
      style: this.props.beforeStyle,
      // style: 'mapbox://styles/mapbox/streets-v9',
      // style: 'mapbox://styles/mapbox/dark-v10',
      center: [this.props.longitude, this.props.latitude],
      zoom: this.props.zoom,
      logoPosition: "bottom-left",
      attributionControl: true,
    });

    beforeMap.on("load", () => {
    //   beforeMap.resize();
    beforeMap.addControl(new mapboxgl.NavigationControl());
    //   this.props.centroids.forEach((centroid, index) => {
    //     const customMarkerLngLat = centroid; // centroid coordinates
    
    //     const el = document.createElement('div');
    //     el.className = 'custom-marker';
    //     el.style.backgroundImage = `url(${MarkerIcon})`;
    
    //     new mapboxgl.Marker(el)
    //       .setLngLat(customMarkerLngLat)
    //       .addTo(beforeMap);
    //   });
     });

    beforeMap.on("idle", function () {
      beforeMap.resize();
    });
    // beforeMap.addControl(new mapboxgl.AttributionControl(), 'top-left');

    this.props.beforeMap.current = beforeMap;

  //   {
  //     /** After Side Map*/
  //   }
  //   const afterMap = new mapboxgl.Map({
  //     container: "after", // 'after' is the id of a div container
  //     style: this.props.afterStyle,
  //     // style: 'mapbox://styles/mapbox/streets-v9',
  //     // style: 'mapbox://styles/mapbox/dark-v10',
  //     center: [this.props.longitude, this.props.latitude],
  //     zoom: this.props.zoom,
  //     attributionControl: true,
  //   });
  //   // afterMap.addControl(new mapboxgl.AttributionControl(), "top-right");

  //   afterMap.on("idle", function () {
  //     afterMap.resize();
  //   });

  //   this.props.afterMap.current = afterMap;

  //   let container = ".sliderContainer"; // classname of the container

  //   let compare = new MapboxCompare(beforeMap, afterMap, container, {
  //     // Set this to enable comparing two maps by mouse movement:
  //     //mousemove: true,
  //     //orientation: 'vertical'
  //   });

  //   this.props.compare.current = compare;

  //   // styling the slider arrow
  //   let slider = compare._swiper;
  //   slider.style.backgroundColor = "white";
  //   slider.style.width = "74px";
  //   slider.style.height = "48px";
  //   slider.style.borderRadius = "40%";
  //   slider.style.left = "-34px";
  //   slider.style.backgroundImage = `url(${SliderArrows})`;
  //   slider.style.backgroundSize = "50px";
  //   slider.style.backgroundPosition = "center";
  //   slider.style.backgroundRepeat = "no-repeat";
  //   slider.parentElement.style.width = "6px";

   }

  render() {
    return (
      <div className="sliderContainer">
        <div id="before" className="sliderHalf"></div>
        <HamburgerMenu projectName={this.props.projectName} projectDescription={this.props.projectDescription} />
      </div>
    );
  }
}
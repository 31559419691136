import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  Component,
  useReducer,
} from "react";
import { useLocation, Link, Redirect, useParams, useHistory, useRouteMatch } from "react-router-dom";


import "./ndvi-property-page.css";


import { MapboxProperty } from "../components/mapbox/mapbox-property";
import { MapboxRegionSelection } from "../components/mapbox/mapbox-region-selection";
import mapboxgl, { LngLat } from "mapbox-gl";
import { ObservationsGalleryCollection } from "../components/modules/selection-gallery-forestchange";
import { SelectionGalleryProperty2 } from "../components/modules/selection-gallery-property";
import { SelectionGalleryTemp2 } from "../components/modules/selection-gallery-temp";
import Characteristics from "../components/modules/characteristics"
import MarkerIcon from "../images/icons/map-pin-icon.png"
import WhiteMarkerIcon from "../images/icons/white-map-pin-icon.png"


import {
  getAllForestProperties,
  getAllRegions,
  getCustomRegionDetail
} from "../apis/landsteward/accounts";



/**
*
* @param {*} props
*/
export function NDVIPropertyPage(props) {




  /** ##### URL Param Check ##### */
  let params = useParams(); // parameters from url, in this case projectId and propertyId
  const history = useHistory();
  const match = useRouteMatch();
  const [projectFailed, setProjectFailed] = useState(false); // flag to indicate if project failed to load
  /** Satellite Images */
  const [leftSatImage, setLeftSatImage] = useState(null); // satellite image report id in the left map
  /** Landowners Info */
  const [landowners, setLandowners] = useState(null); // all landowners for the project
  /** Region Info */
  const [rawProperties, setRawProperties] = useState([]); // property information without the coordinates
  const [parsedProperties, setParsedProperties] = useState([]); // property information with the coordinates
  /** Mapbox Reference to left and right maps */
  const beforeMap = useRef(null); // mapbox reference to left map
  const compare = useRef(null); // mapbox reference to compare component that shows the two maps side by side
  /** MISC. Status Checks*/
  const [trueColour, setTrueColour] = useState(false); // flag to indicate if the map is in true colour (satellite image) mode
  const [showBoundaries, setShowBoundaries] = useState(true); // flag to indicate if property boundaries are being shown
  const [loadedLandowners, setLoadedLandowners] = useState(false); // flag to indicate if landowners have been loaded
  /** Properties gleaned from link with mowing display page chart */
  const location = useLocation(); // location object that represents current url
  const [clickedFromChart, setClickedFromChart] = useState(false); // flag to indicate if the page was clicked from the mowing display page chart
  /** Fullscreen toggle */
  const [mowingReports, setMowingReports] = useState([]);
  const [characteristics, setCharacteristics] = useState([]);
  const [centroidCoordinates, setCentroidCoordinates] = useState([]);


  //new from region-new
  const [reports, setReports] = useState([]);
  const [warning, setWarning] = useState('');
  const [regionID, setRegionID] = useState('');
  const [, setRegionName] = useState('');


  const [regionsList, setRegionsList] = useState([]);
  const [propertiesList, setPropertiesList] = useState([]);


  const [centroids, setCentroids] = useState([]);
  const [centroidDetailsArray, setCentroidDetailsArray] = useState([]);
  const { features } = location.state || {};


  const [mergedRegionData, setMergedRegionData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);


  console.log(features);

  useEffect(() => {
    const calculateCentroid = (coordinates) => {
      let sumX = 0, sumY = 0;
      coordinates.forEach(coord => {
        sumX += coord[0];
        sumY += coord[1];
      });
      return [sumX / coordinates.length, sumY / coordinates.length];
    };
    let centroidDetailsArray = [];


    const allCoordinatesList = features.map(feature => {
      if (feature.geometry && feature.geometry.coordinates) {
        let coordinates = feature.geometry.type === 'MultiPolygon'
          ? feature.geometry.coordinates.flat(2) // for MultiPolygon
          : feature.geometry.coordinates.flat(1); // for Polygon
        coordinates = coordinates.map(coord => [coord[0], coord[1]]);


        const centroid = calculateCentroid(coordinates);


        centroidDetailsArray.push({
          centroid,
          name: feature.properties.Name
        });


        return coordinates;
      }
      return [];
    });


    const centroids = allCoordinatesList.filter(c => c.length > 0).map(calculateCentroid);
    setCentroids(centroids);
    setCentroidDetailsArray(centroidDetailsArray);


  }, [features]);


  console.log(centroids);
  console.log(centroidDetailsArray);


  // INITIALIZATION


  /**
  * Once the component mounts, initialize webstate of web application by loading in the project data
  *
  * */
  useEffect(() => {
    async function initializeState() {
      /*       let isCorrectState = await props.changeCurrentState(params.projectID);
            if (!isCorrectState.project) {
              console.error('Failing Projects', isCorrectState);
              setProjectFailed(true);
            } */
      let success = await props.changeProject(params.projectID);
      if (!success) {
        setProjectFailed(true);
        return;
      }
    }
    initializeState().catch(console.error);
  }, []);


  /*
  * gets all regions for a project
  */
  async function getRegions() {
    // only continue once props have loaded in
    if (props.project != null) {


      let allRegions = await getAllRegions()


      // Filter for regions linked to project and the initial region created by the project, if it exists
      let projectRegions = allRegions.filter((region) => {
        return (region.projectID == props.project.projectID || region.regionID == props.project.region)
      })


      setRegionsList(projectRegions)
      console.log("REGIONS LIST", projectRegions)
      //  props.changeReportRegions(projectRegions)


      const regionIDs = projectRegions.map((region) => region.regionID);

      console.log(regionIDs);
      getProperties(regionIDs);


      console.log("carbon-report-props", props)
    }



  }


  useEffect(() => {
    getRegions()

  }, [props.project])


  /*
  * gets all properties for a project
  */
  async function getProperties(regionIDs) {
    let allProperties = await getAllForestProperties();
    let filteredProperties = allProperties.filter(property =>
      regionIDs.includes(property.region) && !property.is_archived
    );
    setPropertiesList(filteredProperties)

    console.log("Filtered PROPERTIES LIST", filteredProperties);

  }



  useEffect(() => {
    const mergeRegionData = () => {
      const mergedData = regionsList.map(region => {
        const centroidDetail = centroidDetailsArray.find(centroid => centroid.name === region.name);
        return {
          regionID: region.regionID,
          name: region.name,
          centroid: centroidDetail ? centroidDetail.centroid : null,
        };
      });
      setMergedRegionData(mergedData);
    };
    if (regionsList.length > 0 && centroidDetailsArray.length > 0) {
      mergeRegionData();
    }
  }, [regionsList, centroidDetailsArray]);


  console.log(mergedRegionData);



  const markersRef = useRef({});

  useEffect(() => {
    const addMarkers = () => {
      if (beforeMap.current && beforeMap.current.isStyleLoaded() && propertiesList.length > 0) {

        Object.keys(markersRef.current).forEach((region) => {
          if (!propertiesList.find(property => property.region === region)) {
            markersRef.current[region].remove();
            delete markersRef.current[region];
          }
        });

        propertiesList.forEach(({ longitude, latitude, region }) => {
          if (longitude && latitude && !isNaN(longitude) && !isNaN(latitude)) {
            const el = document.createElement('div');
            el.className = 'custom-marker';
            el.style.width = '40px';
            el.style.height = '50px';
            el.style.backgroundSize = 'cover';
            el.style.backgroundImage = `url(${selectedItem === region ? WhiteMarkerIcon : MarkerIcon})`;


            if (!markersRef.current[region]) {
              const marker = new mapboxgl.Marker(el)
                .setLngLat([longitude, latitude])
                .addTo(beforeMap.current);

              markersRef.current[region] = marker;

              el.addEventListener('click', () => setSelectedItem(selectedItem === region ? null : region));
            }

          }
        });
      }
    };


    if (beforeMap.current) {
      if (beforeMap.current.isStyleLoaded()) {
        addMarkers();
      } else {
        beforeMap.current.on('load', addMarkers);
      }
    }


    return () => {
      if (beforeMap.current) {
        beforeMap.current.off('load', addMarkers);
        Object.values(markersRef.current).forEach(marker => marker.remove());
        markersRef.current = {};
      }
    };
  }, [propertiesList, selectedItem]);




  useEffect(() => {
    const total = centroids.reduce((accumulator, currentValue) => {
      accumulator[0] += currentValue[0];
      accumulator[1] += currentValue[1];
      return accumulator;
    }, [0, 0]);


    const averageCentroid = total.map(sum => sum / centroids.length);


    if (!isNaN(averageCentroid[0]) && !isNaN(averageCentroid[1])) {
      setCentroidCoordinates(averageCentroid);
    }
  }, [centroids]);


  console.log(centroidCoordinates);


  const toggleSelectedItem = (region) => {
    setSelectedItem(selectedItem === region ? null : region);
  };

  const handleRegionDeletion = (region) => {
    if (markersRef.current[region]) {
      markersRef.current[region].remove();
      delete markersRef.current[region];
    }
    const updatedRegions = propertiesList.filter(property => property.region !== region);
    setPropertiesList(updatedRegions);
  };


  const [propertiesVersion, setPropertiesVersion] = useState(0);

  const handleNameChange = (newName, region) => {
    // const property = propertiesList.find(r => r.region === region);

    const updatedProperties = propertiesList.map(property => {
      if (property.region === region) {
        return { ...property, property_name: newName };
      }
      return property;
    });
    setPropertiesList(updatedProperties);
    setPropertiesVersion(prevVersion => prevVersion + 1);
  };

  /** HTML RENDERING */


  /** checking valid state */
  if (projectFailed) {
    return <Redirect to="/project" />;
  }




  const Popup = ({ message, header, buttonLabel1, buttonLabel2, onClose, onConfirm }) => {

    const handleConfirmClick = () => {
      if (onConfirm) {
        onConfirm();
      }
    };
    return (
      <div className="popup-container">
        <div className="popup-content">
          <div className="popup-inner">
            <h2>{header}</h2>
            <p>{message}</p>
            <div className="popup-buttons">
              <button className="create-button" onClick={handleConfirmClick}>
                {buttonLabel1}
              </button>
              <button className="create-button-secondary" onClick={onClose}>
                {buttonLabel2}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };


  function SubHeading1(props) {
    const [showPopup, setShowPopup] = useState(false);


    const handleCancelClick = () => {
      setShowPopup(true);
    };


    const handleCloseClick = () => {
      setShowPopup(false);
    };



    return (
      <>
        <div className="project-observation-title-text">
          {props.text}
          {/* <img className="sort-icon" src={SortIcon} /> */}
        </div>
        <Link style={{ textDecoration: "none" }} to={props.href}>
          <button className="create-button">Confirm</button>
        </Link>

      </>
    );
  }


  function SubHeading2(props) {


    const [showPopup, setShowPopup] = useState(false);


    const handleCancelClick = () => {
      setShowPopup(true);
    };


    const handleCloseClick = () => {
      setShowPopup(false);
    };

    const handleConfirmRedirect = () => {
      history.push('/project');
    };

    return (
      <>
        <div className="project-observation-title-text">
          {props.text}
          {/* <img className="sort-icon" src={SortIcon} /> */}
        </div>
        <button className="create-button-secondary" onClick={handleCancelClick}>
          Cancel
        </button>


        {/* Popup */}
        {showPopup && (
          <Popup message="Are you sure you want to cancel? You will lose all your progress."
            header="Cancel"
            buttonLabel1="Yes I'm Sure"
            buttonLabel2="Don't Cancel"
            onClose={handleCloseClick}
            onConfirm={handleConfirmRedirect} />
        )}
      </>
    );
  }

  /** renders the webpage components */
  return (
    <div className="page-margins">
      <div className="manual-change-detection__page__header">
        <div className="report-view-select">
          <h3>Rename / Confirm Properties</h3>
        </div>


      </div>


      <div className="map-property-and-buttons-container">


        <div className="map-and-property-container">
          <div className="map-box-container">
            {centroidCoordinates.length > 0 && !isNaN(centroidCoordinates[0]) && !isNaN(centroidCoordinates[1]) && (
              <MapboxProperty
                // Project info
                projectName={props.project ? props.project.name : "Project name"}
                projectDescription={props.project ? props.project.description : "Project description"}
                // Mapbox centered on PEI or property centroid
                longitude={centroidCoordinates[0] || -63}
                latitude={centroidCoordinates[1] || 46}
                zoom={10}
                clickedFromChart={clickedFromChart}
                // Before
                beforeRaster={
                  leftSatImage ? leftSatImage.reportID.replace(/-/g, "") : ""
                }
                beforeStyle={"mapbox://styles/mapbox/satellite-v9"}
                beforeMap={beforeMap}

                //NDVI & True Colour Toggle
                trueColour={trueColour}
                trueColourToggle={
                  trueColour ? () => setTrueColour(false) : () => setTrueColour(true)
                }
                //Property Boundary Toggle
                showBoundaries={showBoundaries}
                propertyBoundaryToggle={
                  showBoundaries
                    ? () => setShowBoundaries(false)
                    : () => setShowBoundaries(true)
                }
              />
            )}

          </div>


          <div className="property-container">

            {propertiesList.length > 0 ? (

              <SelectionGalleryTemp2
                key={propertiesVersion}
                className="selection-gallery-property"
                collectionList={propertiesList}
                IDAttribute="region"
                captionAttribute="property_name"
                selectedItem={selectedItem}
                onItemSelect={toggleSelectedItem}
                onRegionDelete={handleRegionDeletion}
                onNameChange={handleNameChange}
                displayContextMenu={true}
              />

            ) : null}

          </div>
        </div>

        <div className="note-container">
          NOTE: All of the properties in this list will be included in your report
        </div>

        <div className="button-container">
          <SubHeading1
            href={`/project/${params.projectID}/forest`}
          />
          <SubHeading2
          />
        </div>


      </div>

      <br />
    </div>


  );


} 
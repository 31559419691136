import { url, VALUATION_PORT } from "./urlconfig";
import useFetch from "./useFetch";
import { useFetchBlobAPI } from "./useFetch";
import { MakeInitialReport, ChangeToFailed } from "./accounts";

/** Satellite Downloads and Interations API
 * 
*/



/**
 * 
 * @param {Name of the report to generate} name 
 * @param {ID of the project to associate the report and satellite image} projectID 
 * @param {Earliest Acceptable Date for a satellite image} startDate 
 * @param {Latest Acceptable Date for a satellite image} endDate 
 * @param {pixel resolution for the satellite image, side length of a pixel
 *    in the image measured in meters} spatialResolution 
 * @param {Spectrum of EM Waves to record for the satellite image} satelliteBandType 
 * @param {Maximum Percentage of Cloud Coverage over satellite image} maxCloudCoverage 
 * @returns 
 */
export async function CreateForestReport(
    name,
    projectID,
    startDate,
    endDate,
    spatialResolution,
    cloudMask,
    mowingID,
    email,
    update


) {
    const reportType = 'ForestDetection';
    const reportID = await MakeInitialReport(
        name,
        projectID,
        startDate,
        endDate,
        spatialResolution,
        cloudMask,
        reportType,)
    if (reportID === null) {
        return null;
    }
    const path = '/forestchange/';
    const body = ({
        'name': name,
        'projectID': projectID,
        'start_date': startDate,
        'end_date': endDate,
        'spatial_res': spatialResolution,
        'cloud_mask': cloudMask,
        'mowingID': mowingID,
        'email': email,
        'update': update,
        'reportID': reportID
    });


    // FETCH
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'POST', body, true);
    console.log(response);


    let output;

    if (response.hasError == false) {
        console.log(response);
        output = reportID;

    } else {
        console.error('Attempt to queue mowing report failed');
        console.error('Setting report_info to "failed"');
        console.error(response.errorMessage);

        // if changing report_info to "failed" fails, an error log will be generated
        // await ChangeToFailed(reportID);
        output = null;
    }
    console.log(output);
    return output;
}

export async function terminateGeneratingReport(reportID) {
    const path = "/forestchange/";
    const body = ({ 'reportID': reportID });
    console.log("terminating")
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'DELETE', body, true);
    console.log(response)
}

export async function getGeneratingReportNames() {
    const path = "/forestchange/";
    console.log("Getting generating mowing report names.")
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'GET', null, true);
    console.log(response)

    if (response.hasError == false) {
        return response.data['generating_report_names']
    } else {
        console.error("Attempt to get currently-generating report names failed.")
        return null
    }
}

/**
 * Retrieves a DNDVI image based on two NDVI report IDs.
 * 
 * @param {string}  ndviIDbefore    Report ID of the before NDVI image.
 * @param {string}  ndviIDafter     Report ID of the after NDVI image.
 * @param {number} isTiffRequest    1 to request raw DNDVI TIFF, 0 for RGB PNG.
 * 
 * @returns {image} RGB PNG or RGB DNDVI TIFF.
 */
export async function getDNDVIimage(ndviIDbefore, ndviIDafter, isTiffRequest) {
    const path = `/forestchange/dndvi/${ndviIDbefore}/${ndviIDafter}/${isTiffRequest}/`;
    console.log("Getting DNDVI image.")
    const response = await useFetchBlobAPI(url + ":" + VALUATION_PORT + path, 'GET', null, true);
    console.log(response)

    if (!response.hasError) {
        return response.blob
    } else {
        console.error("Attempt to get DNDVI image failed.")
        return null
    }
}

/**
 * Retrieves a DNDVI vector GeoJSON based on two NDVI report IDs.
 * 
 * @param {string}  ndviIDbefore    Report ID of the before NDVI image.
 * @param {string}  ndviIDafter     Report ID of the after NDVI image.
 * 
 * @returns {JSON} GeoJSON object.
 */
export async function getDNDVIVector(ndviIDbefore, ndviIDafter) {
    const path = `/forestchange/dndvi_vector/${ndviIDbefore}/${ndviIDafter}/`;
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'GET', null, true);

    if (!response.hasError) {
        return response.data;
    } else {
        console.error("Attempt to get DNDVI vector GeoJSON failed.")
        return null
    }
}

/**
 * Retrieves the report ID of a DNDVI report based on two NDVI images.
 * Creates a new DNDVI report if one does not exist.
 * 
 * @param {string}  projectID       Project ID.
 * @param {string}  reportName      Report name.
 * @param {string}  ndviIDbefore    Report ID of the before NDVI image.
 * @param {string}  ndviIDafter     Report ID of the after NDVI image.
 * 
 * @returns {string} Report ID of the DNDVI report.
 */
export async function getDNDVIreportID(projectID, reportName, ndviIDbefore, ndviIDafter) {
    const path = "/forestchange/dndvi/";
    const body = ({
        'projectID': projectID,
        'name': reportName,
        'ndviID_before': ndviIDbefore,
        'ndviID_after': ndviIDafter
    });
    console.log("Getting DNDVI report ID.")
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'POST', body, true);
    console.log(response)

    if (response.hasError == false) {
        return response.data
    } else {
        console.error("Attempt to get DNDVI report ID failed.")
        console.log(response.errorMessage);
        return null
    }
}

/**
 * Retrieves hectares impacted for each high, med, low vegetation gains and loss areas in a DNDVI image.
 * 
 * @param {string}  ndviIDbefore    Report ID of the before NDVI image.
 * @param {string}  ndviIDafter     Report ID of the after NDVI image.
 * 
 * @returns {object} json object of the number of hectares impacted per area.
 */
export async function getHectaresImpacted(ndviIDbefore, ndviIDafter) {
    const path = `/forestchange/hectares/${ndviIDbefore}/${ndviIDafter}/`;
    console.log("Getting hectares impacted.")
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'GET', null, true);
    console.log(response)

    if (!response.hasError) {
        return response
    } else {
        console.error("Attempt to get hectares impacted failed.")
        return null
    }
}

/**
 * Retrieves all DNDVI data for a forest report.
 * 
 * @param {string}  reportID    Report ID of the forest report.
 * 
 * @returns {dict} json object of all the DNDVI data.
 */
export async function getAllDNDVIData(reportID) {
    const path = `/forestchange/alldndvi/${reportID}/`;
    const response = await useFetch(url + ":" + VALUATION_PORT + path, 'GET', null, true);
    return response.data
}
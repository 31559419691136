import React from 'react';
import './image-container.css'

/**
 * Flexible Image Container which will display an image with the given
 * specified css class properties and can optional contain a caption
 * @param {*} props
 *    <className>: css class to assign to the image's figure container
 *    <imageSource>: source of the image
 *    ?<title>: title content above the image
 *    ?<titleClassName>: class of the title that sits above the image
 *    ?<captionText>: caption for the text, optional
 *    ?<captionClassName>: styling for the caption text
 *    ?<children>: additional children components can be added below the caption
 */
export function ImageContainer(props) {
  return (
    <div className="image">
    <figure className={props.className ? props.className : ''}>
      {props.title && <h1 className={props.titleClassName ? props.titleClassName : ''}>{props.title}</h1>}
      <img src={props.imageSource ? props.imageSource : ''}></img>
      {props.captionText && <figcaption className={props.captionClassName ? props.captionClassName : ''}>{props.captionText}</figcaption>}
      {props.children}
    </figure>
    </div>
  )
}
import React, { useState, useEffect, useReducer } from "react";
import {
  Link,
  Redirect,
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import RecentActivityFrame from "../components/modules/recent-activity-frame";
import { ExpandableFrame } from "../components/modules/expandable-frame";
import Characteristics from "../components/modules/characteristics";
import { ObservationsGalleryCollection } from "../components/modules/selection-gallery-forestchange";
import { PageHeader } from "../components/modules/page-header";
import MaryCreek from "../images/sample_region_image.png";
import {
  LandownerGallery,
  LandownerGalleryList,
} from "../components/modules/landowner-gallery";
import { MapboxRegionSelection } from "../components/mapbox/mapbox-region-selection";

import {
  getObservationsForProjectsMock,
  getLandownersMock,
} from "../apis/landsteward/mock_accounts";

// import "./project-details-page.css";
import "./view-forestchange-page.css";

import {
  getLandownerDetails,
  getLandowners,
  getPropertiesForLandowner,
  getDataForRegion,
  getAllMDReport,
} from "../apis/landsteward/accounts";

import {
  getDataForRegionMock,
  getLandownerDetailsMock,
  getPropertiesForLandownerMock,
} from "../apis/landsteward/mock_accounts";
import { MOCK_DATA } from "../apis/landsteward/urlconfig";

import SortIcon from "../images/icons/order-icon.png";

/**
 * @param {*} props
 *    <project>: current project metadata object.
 */
export function ViewForestChangePage(props) {
  /** Get routing variables */
  let match = useRouteMatch();
  let history = useHistory();
  let params = useParams();
  const location = useLocation();
  const [reports, setReports] = useState([]);
  const [regionsList, setRegionsList] = useState([]);


  async function getAllProperties(landowners) {
    let allProperties = [];
    for (const landowner of landowners) {
      // right now just worry about getting all of the properties on there, deal with landowner-specific info after
      let id = landowner.profileID;
      let propertiesForLandowner = MOCK_DATA
        ? getPropertiesForLandownerMock(id)
        : await getPropertiesForLandowner(id);
      let allConcatedProperties = allProperties.concat(propertiesForLandowner);
      allProperties = allConcatedProperties;
    }

    return allProperties;
  }

  async function formatPropertiesForMap(propertiesToFormat) {
    let formattedProperties = [];
    // it's indexing it by landowner, need to extract them and put them into an array that doesn't differentiate it
    let propertiesArr = propertiesToFormat;
    let regionInfo;

    for (let index = 0; index < propertiesArr.length; index++) {
      regionInfo = MOCK_DATA
        ? getDataForRegionMock(propertiesArr[index].region)
        : await getDataForRegion(propertiesArr[index].region);
      if (regionInfo) {
        formattedProperties.push(regionInfo);
      } else {
        console.error(
          "Error getting region info for property",
          propertiesArr[index].id
        );
      }
    }

    return formattedProperties;
  }

  const getGeojsonFromRegionID = (regionID) => {
    let json = null;

    formattedProperties.forEach((item) => {
      if (item.regionID == regionID) {
        json = item.data;
        return;
      }
    });

    return json;
  };

  /** Change to specific project at the start */
  let [projectDoesNotExist, setProjectFail] = useState(false);
  let [landowners, setLandowners] = useState([]);
  const [region, setRegionName] = useState("");
  const [regionID, setRegionID] = useState("");

  /* cards for the mowing reports */
  const [mowingReports, setMowingReports] = useState([]);

  // for showing all of the properties on the map
  let [properties, setProperties] = useState([]);
  let [formattedProperties, setFormattedProperties] = useState([]);
  const [loadingFeatures, setLoadingFeatures] = useState(true);

  const [characteristics, setCharacteristics] = useState([]);
  useEffect(() => {
    async function proc() {
      const projectID = params.projectID;
      let success = await props.changeProject(projectID);

      if (!success) {
        setProjectFail(true);
        return;
      }

      setLoadingFeatures(true);
      let landowners = MOCK_DATA
        ? getLandownersMock(projectID)
        : await getLandowners(projectID);
      let allProperties = await getAllProperties(landowners);
      let landowners_details = [];
      if (MOCK_DATA) {
        landowners_details = landowners;
      } else {
        for (let index = 0; index < landowners.length; index++) {
          let landowner_detail = await getLandownerDetails(
            projectID,
            landowners[index].profileID
          );
          if (landowner_detail) {
            landowners_details.push(landowner_detail);
          } else {
            console.error(
              "Failed to get landowner details for",
              landowners[index].profileID
            );
          }
        }
      }

      setProperties(allProperties);
      let allFormattedProperties = await formatPropertiesForMap(allProperties);
      setLandowners(landowners_details);
      setFormattedProperties(allFormattedProperties);

      setLoadingFeatures(false);

      //added
      // let res = await getAllReportsType(projectID, "forest_detection")
      // setReports(res)
      // console.log(res)
      
    }

    proc().catch(console.error);
  }, []);

  useEffect(() => {
    async function setCurrRegion() {
      if (props.project != null) {
        console.log(props.project);
        let success = await props.changeRegion(props.project.region);
        if (!success) {
          console.log("Region failed to be set");
        }
      }
    }
    function createCharacteristics() {
      if (props.project != null) {
        let charactersArr = [];

        // Formating the date in YYYY-MM-DD
        let formatedDate = "";
        if (mowingReports.length > 0) {
          let DateStr = mowingReports[0]["creation_date"].substring(0, 11).split("/")
          formatedDate = DateStr[2] + "-" + DateStr[1] + "-" + DateStr[0]
        }

        charactersArr.push({
          header: "Total Area",
          statistic: 
          props.project.area_hectare
          ? props.project.area_hectare.toFixed(0) + " Ha"
          : "N/A"

        });
        charactersArr.push({
          header: "Last Report",
          statistic:
          mowingReports.length > 0
          ? formatedDate
          : "N/A"
        });
        charactersArr.push({
          header: "Properties",
          statistic: 
          props.project.properties_num
          ? props.project.properties_num
          : "N/A"
        });
        setCharacteristics(charactersArr);
      }
    }
    setCurrRegion().catch(console.error);
    createCharacteristics();
  }, [props.project, mowingReports]);

  useEffect(() => {
    async function getMowingReports() {
      const projectID = params.projectID;
      let success = await props.changeProject(projectID);

      if (!success) {
        setProjectFail(true);
        return;
      }
      getMowingReportsBasic();
    }
    getMowingReports();

    const statusUpdateInterval = setInterval(getMowingReportsBasic, 10000);

    return () => {
      setMowingReports([]);
      clearInterval(statusUpdateInterval);
    };
  }, []);

  async function getMowingReportsBasic() {
    const projectID = params.projectID;
    let reports = await getAllMDReport(projectID, 'forest_detection');
    reports = reports.filter(report => !report.is_archived);
    console.log(reports.reverse());
    setMowingReports(reports);
  }

  //landownerID = '1'
  if (projectDoesNotExist) {
    return <Redirect to="/project" />;
  } else if (!props.project || props.project.projectID != params.projectID) {
    return <></>;
  } else {
    return (
      <div className="page-margins">
        <PageHeader
          buttonsNeeded={false}
          headerText={props.project ? props.project.name : ""}
          headerIconNeeded={false}
        />

        <RecentActivityFrame projectID={params.projectID}>
          <div className="project-details project-page">
            <div className="project-description">
              <h4>Project description</h4>
              <p>{props.project.description}</p>
            </div>

            <div className="project-characteristics">
              {characteristics.length > 0 ? (
                <Characteristics characteristics={characteristics} />
              ) : null}

              <div className="project-characteristic-child map">
                <div className="project-characteristic-child-btn">
                  {/* TODO: update the link the Project Properites page after it is done */}
                  <a href={`/project/${params.projectID}/ndvi-edit-property-page`}>View / Edit Properties</a>
                  {/* <p>View / Edit Properties</p> */}
                </div>
                
                {loadingFeatures ? null : (
                  <MapboxRegionSelection
                    longitude={-63.1311}
                    latitude={46.2382}
                    zoom={9}
                    style="mapbox://styles/korotu-conroy-trinh/ckwqnggaf0esg14odvehgi6hd"
                    setRegionID={setRegionID}
                    geoJsonID={
                      regionID ? getGeojsonFromRegionID(regionID).id : ""
                    }
                    setRegionName={setRegionName}
                    customPreloadedFeatures={formattedProperties}
                    selectedPreloadedFeatureRegionID={regionID}
                    mapWidth={"100%"}
                    mapHeight={"185px"}
                    drawEnabled={false}
                  />
                )}
              </div>
            </div>

            <div className="project-observation-title">
              <SubHeading
                text="Forest Change Reports"
                href={`/project/${params.projectID}/forest`}
              />
            </div>
            <div className="project-reports">
              {mowingReports.length > 0 ? (
                <ExpandableFrame minHeight={10} expandTriggerCaption={"VIEW ALL REPORTS"}>
                  <ObservationsGalleryCollection
                    projectID={params.projectID}
                    IDAttribute="reportID"
                    captionAttribute="name"
                    submittedByAttribute="submitted_by"
                    dateAttribute="creation_date"
                    createOption={false}
                    createLink={`/project/${params.projectID}`}
                    data={mowingReports}
                    mowingReport={true}
                    getMowingReportsBasic = {getMowingReportsBasic}
                  />
                </ExpandableFrame>
              ) : null}
            </div>
          </div>
        </RecentActivityFrame>
      </div>
    );
  }
}

function SubHeading(props) {
  return (
    <>
      <div className="project-observation-title-text">
        {props.text}
        {/* <img className="sort-icon" src={SortIcon} /> */}
      </div>
      <Link style={{ textDecoration: "none" }} to={props.href}>
        <button className="create-button">Create New</button>
      </Link>
    </>
  );
}

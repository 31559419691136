import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { MOCK_DATA } from "../../apis/landsteward/urlconfig";
import { archiveRegion, archivePropertyByRegion, renameRegion, renamePropertyByRegion, getAllMDReport } from "../../apis/landsteward/accounts.js"
import "./selection-gallery-property.css";
import order from "../../images/icons/order-icon.png";
import ellipses from "../../images/icons/project-ellipses.png";
import whiteEllipses from "../../images/icons/white-project-ellipses.png";
import useFetch from "../../apis/landsteward/useFetch";
import { terminateGeneratingReport } from "../../apis/landsteward/mowing.js"
import { RenamePopup } from "../modules/rename-popup"
import { DeletePopup } from "../modules/delete-popup"


/**
 *
 * @param {*} props
 *    <contentType>:
 *    <type>:
 *    <collectionList>:
 *    <createOption>:
 *    <createLink>:
 *    <IDAttribute>:
 *    <captionAttribute>:
 *    <imageAttribute>:
 *    <linkAttribute>:
 *    <defaultImage>:
 * @returns Selection Gallery which allows a user to select items which
 *  represent the objects in the provided collection list.
 */

export function SelectionGalleryProperty2(props) {


  return (
    <div className="selection-gallery">
      <SelectionGalleryCollectionProperty2 {...props} />
    </div>
  );
}

/**
 *
 * @param {*} props
 *    <contentType>: only 'image' and 'component' are supported
 *    <collectionList>: collection of objects which improve the code
 *
 *
 *    <createOption>: if true, a gallery item with a plus sign ('+') will be
 *      placed in the gallery. This will lead to another page to allows the
 *      user to create that item. In addition, the following must be defined:
 *    <createLink>: the link to which the user will go to create a gallery item
 */

export function SelectionGalleryCollectionProperty2(props) {

  return (
    <div className="selection-gallery__collection">
      {
        /** Gallery Items */

        props.collectionList.map((item) => (

          <SelectionGalleryItemProperty2
            key={item[props.IDAttribute]}
            projectID={item["projectID"]}
            regionID={item[props.IDAttribute]}
            regionName={item[props.captionAttribute]}
            dateCreated={item[props.dateCreatedAttribute]}
            areaHectares={item[props.areaAttribute]}
            isImpacted={item[props.isImpactedAttribute]}
            lowestNDVI={item[props.lowestNDVIAttribute]}
            lowestNDVIDate={item[props.lowestNDVIDateAttribute]}
            highestNDVI={item[props.highestNDVIAttribute]}
            highestNDVIDate={item[props.highestNDVIDateAttribute]}
            leastImpactedProperty={props.leastImpactedPropertyAttribute}
            mostImpactedProperty={props.mostImpactedPropertyAttribute}
            link={item[props.linkAttribute]}
            showImage={props.showImage}
            isSelected={props.selectedItem === item[props.IDAttribute]}
            onToggleSelect={() => props.onItemSelect(item[props.IDAttribute])}
            onRegionDelete={props.onRegionDelete}
            onNameChange={props.onNameChange}
            displayContextMenu={props.displayContextMenu}
          >
            <div className="selection-gallery-text-property-container">
              <div className="property-info">{item[props.captionAttribute]}</div>
              <div className="property-info">
                {item.hasOwnProperty(props.isImpactedAttribute) ?
                  props.leastImpactedPropertyAttribute && props.leastImpactedPropertyAttribute === item[props.captionAttribute] ? "Least Impacted" :
                    props.mostImpactedPropertyAttribute && props.mostImpactedPropertyAttribute === item[props.captionAttribute] ? <span style={{ color: '#e62402' }}>Most Impacted</span> :
                      (item[props.isImpactedAttribute] ? <span style={{ color: '#e62402' }}>Impacted</span> : "Not Impacted") :
                  ""}
              </div>
              <div className="property-info">
                {item.hasOwnProperty(props.lowestNDVIAttribute) ?
                  item[props.lowestNDVIAttribute].substring(0, 4) + " in " + item[props.lowestNDVIDateAttribute].substring(0, 7) : ""}
              </div>
              <div className="property-info">
                {item.hasOwnProperty(props.highestNDVIAttribute) ?
                  item[props.highestNDVIAttribute].substring(0, 4) + " in " + item[props.highestNDVIDateAttribute].substring(0, 7) : ""}
              </div>
              {/* <div className="property-info">{item[props.captionAttribute]}</div> */}
            </div>
          </SelectionGalleryItemProperty2>
        ))
      }
    </div>
  );
}

/**
 * Gallery Item component that can be hovered, selected and placed among
 * other items in a gallery. Contains a short title/caption describing what
 * the gallery item contains.
 * @param {*} props
 *    children: Components to display inside the gallery item to display
 *    caption: Necessary to describe each item separately
 *    add: Boolean to determin add button
 */

export function SelectionGalleryItemProperty2(props) {
  let menuRef = useRef()
  let itemRef = useRef()
  const params = useParams()
  const [isRenamePopupVisible, setRenamePopupVisible] = useState(false);
  const [isDeletePopupVisible, setDeletePopupVisible] = useState(false);
  const [isRenamingProperty, setRenamingProperty] = useState(false);
  const [isDeletingProperty, setDeletingProperty] = useState(false);
  const isMounted = useRef(true);

  const { isSelected } = props;
  useEffect(() => {
    document.addEventListener("mousedown", outsideClick);
    return () => {
      document.removeEventListener("mousedown", outsideClick);
    }
  })

  function outsideClick(event) {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      menuRef.current.style.display = "none";
    }
  }

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  let itemStyles = isSelected ? { background: "#357960", color: "white" } : { background: "#D8EDD4", color: "#357960" };
  let contentStyles = isSelected ? { color: "white" } : { color: "#357960" };
  let contextMenuImgSrc = isSelected ? whiteEllipses : ellipses;


  let style = {};
  if (props.add) {
    style["background"] = "#357960";
    style["fontSize"] = "50";
  }
  if (props.showImage) {
    style["height"] = "16.5rem";
  }

  const handleChangeName = async (newName) => {
    setRenamingProperty(true)
    const successRegion = await renameRegion(props.regionID, newName)
    console.log(successRegion)
    const successProperty = await renamePropertyByRegion(props.regionID, newName)
    setRenamingProperty(false)
    console.log(successProperty)

    let getReport = await getAllMDReport(params.projectID, 'forest_detection');
    const reportInfoOnly = getReport.map(report => report.report_info);

    console.log(getReport);
    console.log(reportInfoOnly);

    if (successRegion && successProperty) {
      window.alert(`Successfully changed property name to: ${newName}`)
      props.onNameChange(newName, props.regionID);
    } else {
      console.error("Failed to change property name")
    }
  }

  function showRegionDeletionConfirmation() {
    archiveRegion(props.regionID).then(() => {
      archivePropertyByRegion(props.regionID).then(() => {
        props.onRegionDelete(props.regionID);
      });
    });
  }

  return (
    <figure className="selection-gallery__item_property" onClick={props.onToggleSelect} style={itemStyles}>
      <div className="selection-gallery__item__content" style={contentStyles}>
        {props.children}
      </div>

      {props.displayContextMenu && (
        <div className="selection-gallery__item__context_menu" onClick={() => {
          let menu = menuRef.current;
          if (menu.style.display === "inline-block") {
            menu.style.display = "none";
          } else {
            menu.style.display = "inline-block";
          }
        }}>
          <div className={`context-menu`} ref={menuRef}>
            <button onClick={() => setRenamePopupVisible(true)}>Rename Property</button>
            {isRenamePopupVisible && (
              <RenamePopup
                onRenameType={"Property"}
                onClose={() => {
                  if (isMounted.current) {
                    setRenamePopupVisible(false)
                  }
                }}
                onChangeName={handleChangeName}
                onRenamingProgress={isRenamingProperty}
              />
            )}

            <button onClick={() => setDeletePopupVisible(true)}>Delete Property</button>
            {isDeletePopupVisible && (
              <DeletePopup
                onDeleteType={"Property"}
                onClose={() => {
                  if (isMounted.current) {
                    setDeletePopupVisible(false)
                  }
                }}
                onDeleteConfirm={showRegionDeletionConfirmation}
                onDeletingProgress={isDeletingProperty}
                regionName={props.regionName}
              />
            )}
          </div>
          <img className="selection-gallery__item__context_menu-img" src={contextMenuImgSrc} />
        </div>
      )}
    </figure>
  );
}
import React from "react";

import { NavigationBar } from "./navigation-bar";
import { FooterBar } from "./footer-bar";
import { PathBar } from "./path-bar";

import "./website-frame.css";

/**
 * Website frame that contains the top-most container and the regular
 * navigation/information bars for landsteward.
 *
 * @param {} props
 *    children: contains the webpage to be displayed within the frame of the
 *      website.
 */
export function WebsiteFrame(props) {
  return (
    <>
      <div className="website-body">
        <div className="nav-bar">
          <NavigationBar {...props} />
        </div>
        <PathBar pathList={[]} debug="true" />

        <div className="website-webpage">{props.children}</div>
      </div>
      <footer className="footer-bar">
        <FooterBar />
      </footer>
    </>
  );
}

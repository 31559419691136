import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import {getLULCReport} from '../../apis/landsteward/accounts';
import {TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import './lulc-display.css'


import MaryCreek from '../../images/sample_region_image.png';
/**
 * 
 * @param {*} props
 *  <searchState>
 * @returns 
 */
export default function LULCDisplay(props) {
    let params = useParams();

    let project = props.searchState('projects', 'projectID', params.projectID);
    let report = props.searchState('reports', 'reportID', params.landcoverID);
    let landcoverID = params.landcoverID
    let [landcoverLegendClosed, setLandcoverLegendClosed] = useState(true);
    const [reportInfo, setReportInfo] = useState({});
    const [landLegend, setLandLegend] = useState([]);
    const [satelliteImage, setSatelliteImage] = useState(null);
    const [landcoverImage, setLandcoverImage] = useState(null);

    function openLandLegend() {
        setLandcoverLegendClosed(false);
    }
      
    function closeLandLegend() {
        setLandcoverLegendClosed(true);
    }

    useEffect(() => {
        getLULCReport(landcoverID).then(
            response => {
                setReportInfo(response)
                setSatelliteImage(response.satellite_image_url)
                setLandcoverImage(response.image)
                setLandLegend(JSON.parse(response.colour_legend.replace(/'/g, '"')))
            }
        );
    }, [])

    function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
      
    function rgbToHex(r, g, b) {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    function colourLULCLegend(idx) {
        if (landLegend) {
            for (var i = 0; i < landLegend.length; i++) {
                if (idx === i) { 
                    //console.log(rgbToHex(landLegend[i].rgb[0], landLegend[i].rgb[1], landLegend[i].rgb[2]));
                    return rgbToHex(landLegend[i].rgb[0], landLegend[i].rgb[1], landLegend[i].rgb[2]);
                }
            }
        }
        return "red";
    }
    
    return (
        <div className='report-display-page'>
            <div className='report-display-page__title'>
                <h1>Land Use Land Cover Report</h1>
            </div>

            <div className="report-display-page__project__details">
                <p><span className="name">Project Name:</span> {project.name}</p>
                <p><span className="name">Region:</span> {''}</p>
                <p><span className="name">Report Name:</span> {reportInfo.name}</p>
            </div>

            <hr className="line"/>
            
            <div className="lulc-report">
                <h2>Satellite Image</h2>
                <figure className="lulc-report__satellite__details__image">
                    <TransformWrapper>
                        <TransformComponent>
                            <img src={satelliteImage ? satelliteImage : MaryCreek}/>
                        </TransformComponent>
                    </TransformWrapper>
                    <figcaption><span className="name">Description:</span> Summary of flood risk mitigation report for the selected watershed. <br/>
                    <span className="name">Source:</span> Copernicus  Sentinel-2 Level 2A </figcaption>
                </figure>
                
                <div className="lulc-report__satellite__details__image">
                    <h4>Image Details:</h4>
                    <p><span className="name">Spatial Resolution:</span> 10m </p>
                    <p><span className="name">Band:</span> True Colour</p>
                    <p><span className="name">Date Period:</span> May 2020 - June 2020</p>
                </div>

                <hr className="line"/>
                
                <div className="lulc-report__lulc">
                    <h2>Land Use Land Cover Image</h2>
                    <figure className="lulc-report__lulc__details__image">
                        <div className="open-legend">
                            <button style={{visibility: !landcoverLegendClosed ? 'hidden' : 'visible' }} onClick={e => openLandLegend(e)}>Legend</button>
                        </div>

                        <TransformWrapper>
                            <TransformComponent>
                                <img src={landcoverImage ? landcoverImage : MaryCreek }/>
                            </TransformComponent>
                        </TransformWrapper>
                        
                        <figcaption><span className="name">Description:</span> This map displays a classified landcover for the watershed segregated <br/>
                            into five classes: built-up, water, forestry, ground, and unknown.
                        </figcaption>
                    </figure>
 
                    <div className="lulc-report__lulc__details" style={{display: landcoverLegendClosed ? 'none' : 'block'}}>
                        <button className="close-btn" onClick={e => closeLandLegend(e)}>&times;</button> 
                        <h4>Legend:</h4>
                        <div className="legend-scale">
                            <ul className="legend-labels">
                                {landLegend.map((value, index) => (
                                <li> <span style={{backgroundColor: colourLULCLegend(index)}}></span> {value.classType} </li>
                                 ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    )
}
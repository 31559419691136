import { DetailDisplayCard } from "./field-detail-display-card";
import "./property-detail-display-card.css";
import Back from '../../images/icons/back-icon.png'

/**
 *
 * @param {object}    property property object with information to display
 * @param {function}  backBtnOnClick
 *
 * @author [Leo Wang](https://github.com/Korotu-Leo-Wang)
 */
export function PropertyDetailsDisplayCard(props) {
  return (
    <>
      <DetailDisplayCard
        title={props.property.property_name}
        desc={formatPropertyDetails(props.property)}
      >
        <img
          className="display-card-back-btn"
          onClick={props.backBtnOnClick}
          src={Back}
        />
        <div className="observation-btns">
          <button className="big-primary-button">Contact Landowner</button>
        </div>
      </DetailDisplayCard>
    </>
  );
}

function formatPropertyDetails(propertyObject) {
  let output = "";
  if (!propertyObject.address){
    output += `Address: N/A\n`;
  }
  else{
    output += `Address: ${propertyObject.address}\n`;
  }
  output += `Location (Latitude, Longitude): ${propertyObject.latitude}, ${propertyObject.longitude}\n`;
  // output += `Size (Hectares): ${propertyObject.area_hectares}\n`;
  // output += `Perimeter (Meters): ${propertyObject.perimeter_meter}`;

  return output;
}

import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";

import {TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import './carbon-display.css'


import PointCloud from '../../images/point-cloud.png';

/**
 * 
 * @param {*} props
 *  <searchState>
 * @returns 
 */
export default function CarbonDisplay(props) {
    return (
        <div className='report-display-page'>
            <div className='report-display-page__title'>
                <h1>Carbon Report</h1>
            </div>

            <div className="report-display-page__project__details">
                <p><span className="name">Project Name:</span> City of Toronto</p>
                <p><span className="name">Region:</span> Duffins Creek </p>
                <p><span className="name">Report Name:</span> Duffins Carbon Report 2021</p>
            </div>

            <hr className="line"/>
            
            <div className="carbon-report">
                <h2>Point Cloud Image</h2>
                <figure className="carbon-report__details__image">
                    <TransformWrapper>
                        <TransformComponent>
                            <img src={PointCloud}/>
                        </TransformComponent>
                    </TransformWrapper>
                </figure>
                
                <hr className="line"/>
                
                <h3>Carbon Summary * </h3>
                <p><span className="name">Total hectares:</span> 140 hectares</p>
                <p><span className="name">Total carbon stored:</span> 28000 tons of carbon (in above ground biomass)</p>
                <p><span className="name">Carbon per hectare:</span> 200 tons</p>
                <p>*Caveats: The calulation above only calculates carbon for forested areas, and the carbon per hectare will be 
                        impacted by including non-forested areas (e.g. lakes / water) </p>
            </div>
        </div>
    )
}
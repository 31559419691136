import React, { useEffect, useState } from "react";
import { useRouteMatch, useParams, Link } from "react-router-dom";

import {
    downloadSatelliteImage,
    getMDReport,
    getMDReportCSV,
    getSatelliteImageDetails,
} from "../apis/landsteward/accounts";
import {
    downloadHarmonized,
    downloadSentinelSat2,
} from "../apis/landsteward/satellite";
import { CreateMowingReport } from "../apis/landsteward/mowing";
import {UpdateMowingReport} from "../apis/landsteward/mowing";
import {getLandowners} from "../apis/landsteward/accounts";
import {getPropertiesForLandowner} from "../apis/landsteward/accounts";

import { Dropdown } from "../components/modules/satellite-dropdown";

import "./satellite-image-create-page.css";
import "./mowing-create-page.css";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import SimpleImageSlider from "react-simple-image-slider";
import { AutoDisplayPage } from "./automated-display-page";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineController,
    LineElement,
} from "chart.js";
import { Carousel } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export function MowingCreatePage(props) {
    let match = useRouteMatch();
    let params = useParams();
    const [name, setName] = useState("");
    let [cloudMask, setCloudMask] = useState(false);
    console.log(
        `URL params SatelliteCreate: ${params.projectID}||${params.propertyID}`
    );
    let project = props.searchState("projects", "projectID", params.projectID);
    const reportNameMaxLength = 30;

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        PointElement,
        LineController,
        LineElement
    );

    return (
        <div className="satellite-image-create-page">
            <div className="satellite-image-create-page-title" id="mowing-title">
                <h2> Generate a Mowing Report</h2>
            </div>

            <p className="metadata-headings" id="mowing-headings">
                {" "}
                <b>Project:</b> {project ? project.name : ""}
            </p>
            <label
                className="name-label metadata-headings"
                id="mowing-name"
                htmlFor="newName"
            >
                <span>Report Name:</span>
                <br></br>
            </label>
            <input
                id="input1"
                className="metadata-headings-name-input"
                name="newName"
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
                maxLength={reportNameMaxLength}
            />
            <p 
            className={name.length===reportNameMaxLength ? 'mowing_text-limit-colour' : undefined}
            style={{ marginLeft: '155px', marginTop: '10px' }}
            >
            {name.length}/{reportNameMaxLength}
            </p>

            <MowingReportForm
                newName={name}
                addSatelliteImage={props.addSatelliteImage}
            />
        </div>
    );
}
/**
 * Form to download a satellite image, allows user to specify satellite image
 * details (e.g. date, cloud coverage). Downloaded image is associate with
 * a specific region and project.
 * @param {*} props
 *    <region>: region metadata object, necessary for fetch API
 *    <newName>:
 *    <addSatelliteImage>:
 */
export function MowingReportForm(props) {
    let params = useParams();
    const history = useHistory();
    const [satBand, setSatBand] = useState("TCI");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    //   const [pixelRes, setPixelRes] = useState('10m');
    const [newImageID, setNewImageID] = useState("");
    const [downloadStatus, setDownloadStatus] = useState("");
    const [csvString, setcsvString] = useState("");
    const [mowingReportInProgress, setMowingReportInProgress] = useState(false);

    const [hasLandowner, setHasLandowner] = useState(false);
    const [hasField, setHasField] = useState(false);

    const [warning, setWarning] = useState("");

    useEffect(() => {
        async function checkForLandownerAndField() {
            let landowners = await getLandowners(params.projectID);
            if (landowners.length > 0){
                setHasLandowner(true);
                for (const landowner of landowners){
                    let id = landowner.profileID;
                    let propertiesForLandowner = await getPropertiesForLandowner(id);
    
                    if (propertiesForLandowner.length > 0){
                        setHasField(true);
                        break;
                    }
                }
            }
        }
        
        checkForLandownerAndField();
    }, []);

    async function triggerDownload(e) {

        let missing = []
        let start = new Date(startDate)
        let end = new Date(endDate)
        let currentDate = new Date().toJSON().slice(0,10);

        if (!hasLandowner) {
            setWarning("Please add at least one landowner and field to the project before generating a report");
        }

        else if(!hasField){
            setWarning("Please add at least one field to the project before generating a report")
        }
          
        else if (!props.newName ||!startDate || !endDate) {
            if (!props.newName) missing.push("Mowing Report Name")
            if (!startDate) missing.push("Satellite Image Start Date")
            if (!endDate) missing.push("Satellite Image End Date")
            setWarning("Missing required field(s): " + missing.join(", ") + " ")
        }

        else if (currentDate < startDate || currentDate < endDate){
            setWarning("Selected date is in the future")
        }

        else if (start > end) {
            setWarning("Satellite Image End Date must occur after Satellite Image Start Date")
        }

        else{
            setWarning("")
            setMowingReportInProgress(true)
            e.preventDefault();
            setDownloadStatus("pending");
            let reportID;
            let cloudMask = true;
            let spatialResolution = 30;
            let mowingID = null;
            let update = false; 

            // let element3 = document.getElementById("mowing-headings");
            // element3.style.display = "none"

            let email_storage = JSON.parse(sessionStorage.getItem('webState')).email
            console.log(email_storage)
            
            reportID = await CreateMowingReport(
                props.newName,
                params.projectID,
                startDate,
                endDate,
                spatialResolution,
                cloudMask,
                mowingID,
                email_storage,
                update
            );

            if (reportID == null) {
                console.log('Mowing failed')
                setWarning('Report creation failed')
                setMowingReportInProgress(false)
            } else {
                // if everything goes well up until the job is queued, redirect to the project details page
                // the project details page shows the live status of the report
                history.push(`/project/${params.projectID}`)
            }
            // }else{
            //   imageID = await downloadSentinelSat2(
            //     props.newName,
            //     params.propertyID,
            //     params.projectID,
            //     startDate,
            //     endDate,
            //     pixelRes,
            //     satBand,
            //     cloudMask,
            //     '10'
            //   );
            // }

            // commented out the following because it didn't seem to be doing anything but make unecessary GET requests

            // console.log(reportID);
            // var temp = await getMDReportCSV(reportID);
            // console.log(getMDReportCSV(reportID));
            // //  console.log(temp.split(',')[9 + 5])
            // //  console.log(temp.split(',')[18 + 5])
            // setcsvString(temp);
        }
    }

    return (
        <form className="satellite-image-form">
            {/**<label htmlFor='satellite-image-name'> Satellite Image Name: </label>
      <input 
        type='text'
        value={name}
        name='satellite-image-name'
        onChange={(event) => setName(event.target.value)}
      /> {/** */}

            {/** Satellite Form Details */}
            <div className="satellite-image-form__details" id="mowing-details">
                <label htmlFor="satellite-image-band">
                    {" "}
                    <b>Specify Image Bands:</b>
                    <br></br>

                </label>
                <input
                    className="ayo"
                    type="text"
                    value={satBand}
                    name="newName"
                    onChange={(event) => setSatBand(event.target.value)}
                    disabled
                />

                <br />

                <label htmlFor="satellite-image-band">
                    {" "}
                    <b>Resolution</b>
                    <br></br>

                </label>
                <input
                    className="ayo"
                    type="text"
                    value="30"
                    name="newName"
                    onChange={(event) => setSatBand(event.target.value)}
                    disabled
                />

                <br />

                <label htmlFor="satellite-image-start-date">
                    {" "}
                    <b>Satellite Image Start Date:</b>{" "}
                    <br></br>

                </label>
                <input
                    className="ayo"

                    type="date"
                    value={startDate}
                    name="satellite-image-start-date"
                    onChange={(event) => setStartDate(event.target.value)}
                />
                <br />

                <label htmlFor="satellite-image-end-date">
                    {" "}
                    <b>Satellite Image End Date:</b>{" "}
                    <br></br>

                </label>
                <input
                    className="ayo"

                    type="date"
                    value={endDate}
                    name="satellite-image-end-date"
                    onChange={(event) => setEndDate(event.target.value)}
                />
                <br />

                {/* <label htmlFor='satellite-image-pixel-res'> <b>Resolution Preference (if any) :</b> </label>
        <Dropdown
          name='satellite-image-pixel-res'
          value={pixelRes}
          setValue={(value) => setPixelRes(value)}
          valueList={[{value:'10m', name:'10m'}, {value:'30m', name:'30m'}, {value:'60m', name:'60m'},]}
          valueProperty='value'
          nameProperty='name'
        /> */}
            </div>
            <div className={(mowingReportInProgress)
            ? "button-for-mowing-disabled"
            : "button-for-mowing"
            }>
                <button
                    type="button"
                    onClick={triggerDownload}
                    disabled={mowingReportInProgress}
                    // style={{
                    //     visibility:
                    //         (downloadStatus === "pending" && "hidden") ||
                    //         (newImageID && "hidden"),
                    // }}
                >
                    {" "}
                    Generate Report{" "}
                </button>
            </div>
            <br></br>
            <span className={`loader ${(mowingReportInProgress) ? "" : "hidden"}`}></span>
            <br></br>
            <p className="mowing-warning-text">{warning}</p>
            {/* <p className='metadata-headings'> <b>Start Date:</b> {startDate}</p>
            <p className='metadata-headings'> <b>End Date:</b> {endDate}</p> */}
            <br />
            <br />
            {/* 
            <div className='satellite-image-create-page-title'id="mowing-title">
                <h1> NDVI Maps of Q-AL-1 </h1>
            </div> 
             <div>
      <SimpleImageSlider
      right={100}
        width={1420}
        height={904}
        images={slideImages2}
        showBullets={true}
        showNavs={true}
        
      />
    </div>  */}

            {downloadStatus === "failed" && (
                <p className="processing-text">
                    {" "}
                    Oh no! Something went wrong, please try again later
                </p>
            )}
            {/* {(downloadStatus === 'pending') && <p className="processing-text"> The download you triggered is processing</p>} */}
            {newImageID && (
                <span>
                    <p className="processing-text">
                        Your satellite image has finished downloading!
                    </p>
                    <Link
                        to={`/project/${params.projectID}/property/${params.propertyID}/satellite_image/${newImageID}`}
                    >
                        <div className="create-satellite-button">
                            <button> View Image </button>
                        </div>
                    </Link>
                </span>
            )}
        </form>
    );
}

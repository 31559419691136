/**
 * Fetch call abstraction to avoid repeated code on each httprequest
 * @param   {string}  url       Full url to fetch from
 * @param   {string}  method    Http request method
 * @param   {object}  body      Optional: Request body, leave out or set to null if a request body is not applicable
 * @param   {boolean} with_auth Optional: Gets the auth token from localstorage "webState" (state.token)
 * @returns {{object, boolean, string}} {data, hasError, errorMessage}
 * 
 * @author  [Leo Wang](https://github.com/Korotu-Leo-Wang)
 */
async function useFetch(url, method, body = null, with_auth = false) {
  let data = {};
  let hasError = false;
  let errorMessage = '';

  try {
    let options = {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',

      }
    }

    if (body !== null) {
      options.body = JSON.stringify(body);
    }

    if (with_auth) {
      const state = JSON.parse(sessionStorage.getItem("webState"));
      if (state && state.token) {
        options.headers["Authorization"] = "Token " + state.token;
      }
      else {
        // console.log("No auth token or no state:", state);
      }
    }

    console.log("API call to", url, ":", options);

    const res = await fetch(url, options);
    const json = await res.json();
    if (res.ok) {
      try {
        data = JSON.parse(json);
      } catch (parseE) {
        data = json
      }

    }
    else {
      console.log("Error response body:", JSON.stringify(json));
      hasError = true;
      errorMessage = res;
    }
  } catch (err) {
    hasError = true;
    errorMessage = err.message
  }

  return { data, hasError, errorMessage }
}

export default useFetch;

/**
 * Fetch call abstraction to avoid repeated code on each httprequest
 * @param   {string}  url       Full url to fetch from
 * @param   {string}  method    Http request method
 * @param   {object}  body      Optional: Request body, leave out or set to null if a request body is not applicable
 * @param   {boolean} with_auth Optional: Gets the auth token from localstorage "webState" (state.token)
 * @returns {{blob, boolean, string}} {blob, hasError, errorMessage}
 */
export async function useFetchBlobAPI(url, method, body = null, with_auth = false) {
  let blob = {};
  let hasError = false;
  let errorMessage = '';

  try {
    let options = {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      }
    }

    if (body !== null) {
      options.body = JSON.stringify(body);
    }

    if (with_auth) {
      const state = JSON.parse(sessionStorage.getItem("webState"));
      if (state && state.token) {
        options.headers["Authorization"] = "Token " + state.token;
      }
    }

    console.log("API call to", url, ":", options);

    const res = await fetch(url, options);
    console.log("res: ", res)
    if (!res.ok) {
      hasError = true;
      errorMessage = await res.text();
    } else {
      blob = await res.blob();
    }

    return { blob, hasError, errorMessage, headers: res.headers }

  } catch (err) {
    hasError = true;
    errorMessage = err.message

    return { blob, hasError, errorMessage, headers: {} }
  }
}

/**
 * Fetch call abstraction to avoid repeated code on each httprequest. Used for requesting files 
 * from the database. 
 * 
 * @param   {string}  url       Full url to fetch from
 * @returns {{blob, boolean, string}} {blob, hasError, errorMessage}
 *
*/
export async function useFetchBlob(url) {
  let blob = {};
  let hasError = false;
  let errorMessage = '';

  try {
    let options = {
      method: 'GET',
      mode: 'cors',
    }

    const res = await fetch(url, options);
    blob = await res.blob()

    if (!res.ok) {
      hasError = true;
      errorMessage = res;
    }
  } catch (err) {
    hasError = true;
    errorMessage = err.message
  }

  return { blob, hasError, errorMessage }
}



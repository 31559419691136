import React, { useEffect } from "react";
import "./dropdown.css";
/**
 * Controlled Dropdown select component
 * @param {*} this.props
 *    ?<name>: value to pass to dropdown field, optional
 *    <value>: current value of the dropdown, set to '' if not selected
 *    <setValue>: function passed down to modify <value> state in ancestor
 *      component. Takes in a single new value to replace the old with
 *    <valueList>: array of possible dropdown choices that <value> can become
 *      through the setter function <setValue>. Each list item must have:
 *        - <name> displayed as value on dropdown
 *        - <property> (value) used for React as key and is what is passed
 *          into setValue
 *            this value
 *    <valueProperty>: the string name of the 'value' property
 *    <nameProperty>: the string name of the 'label' property
 */

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { name: "dropdown" };
    this.truncIdDropdown = this.truncIdDropdown.bind(this);
    this.nameDropdown = this.nameDropdown.bind(this);
    this.chooseDropdown = this.chooseDropdown.bind(this);
  }

  truncIdDropdown(listItem) {
    return (
      <option
        key={listItem[this.props.valueProperty]}
        value={listItem[this.props.valueProperty]}
      >
        {listItem[this.props.valueProperty].substring(0, 8)}
      </option>
    );
  }

  nameDropdown(listItem) {
    return (
      <option
        key={listItem[this.props.valueProperty]}
        value={listItem[this.props.valueProperty]}
      >
        {listItem[this.props.nameProperty]}
      </option>
    );
  }

  chooseDropdown(listItem) {
    if (this.props.displayBy === "ID") {
      return (
        <option
          key={listItem[this.props.valueProperty]}
          value={listItem[this.props.valueProperty]}
        >
          {listItem[this.props.valueProperty].substring(0, 8)}
        </option>
      );
    } else if (this.props.displayBy === "Name") {
      return (
        <option
          key={listItem[this.props.valueProperty]}
          value={listItem[this.props.valueProperty]}
        >
          {listItem[this.props.nameProperty]}
        </option>
      );
    } else {
      return (
        <option
          key={listItem[this.props.valueProperty]}
          value={listItem[this.props.valueProperty]}
        >
          N/A
        </option>
      );
    }
  }

  render() {
    return (
      <select
        className="dropdown"
        name={this.props.name ? this.props.name : "dropdown"}
        value={this.props.value ? this.props.value : ""}
        onChange={(event) => {
          this.props.setValue(event.target.value);
          console.log(event.target.value, event.target.name);
        }}
      >
        <option key={"NONE"} value={""} hidden>
          {" "}
          {"Select an image"}
        </option>
        {this.props.valueList
          .sort((a, b) =>
            a[this.props.nameProperty] > b[this.props.nameProperty] ? 1 : -1
          )
          .map((listItem) => this.chooseDropdown(listItem))}
      </select>
    );
  }
}

export default Dropdown;
//Truncated ID View: listItem[this.props.valueProperty].substring(0,8)
//Name View: listItem[this.props.nameProperty]

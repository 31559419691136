import React, { useState, useEffect, useRef } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import './satellite-image-display-page.css';
import CreekSatellite from '../images/creditriver_satellite.png'

import { getSatelliteImage } from '../apis/landsteward/accounts';
import remotesensingicon from '../images/remote-sensing.png';
import magnifyingglass from '../images/magnifying-glass-icon.png';

/**
 * @param {*} props 
 *    <project>: currently selected project
 *    <region>:  currently selected region
 *    <satelliteImage>: currently selected satellite image
 */
export function SatelliteImageDisplayPage(props) {

  /** ##### URL Param Check ##### */
  let params = useParams();
  let [projectFailed, setProjectFailed] = useState(false);
  let [navClosed, setNavClosed] = useState(true);
  let [toolClosed, setToolClosed] = useState(true);
  let [satelliteData, setSatelliteData] = useState({});

  function openNav() {
    setNavClosed(false);
  }

  function closeNav() {
    setNavClosed(true);
  }

  function openTool() {
    setToolClosed(false);
  }

  function closeTool() {
    setToolClosed(true);
  }

  useEffect(() => {

    getSatelliteImage(params.satelliteImageID).then((response) => {
      setSatelliteData(response)
    })

    props.changeCurrentState(params.projectID).then(
      (isCorrectState) => {
        if (!isCorrectState.project) {
          console.error('Failing Projects', isCorrectState);
          setProjectFailed(true);
        }
      }
    )

    //clean state on unmount, use to avoid warning
    return () => setSatelliteData({});
  }, []);

  if (projectFailed) {
    return (<Redirect to='/project' />);
  }

  const imgRef = useRef();

  /** ##### RENDER #####  */
  return (
    // <div className='satellite-image-display-header'>
    //     <div className='image-bar'>
    //   <div className='regionbox'>
    //  <div className='imageheading'>
    //   <span className="imageheadingtext" >Image:</span>
    //   <div className="imagenameanddate" >
    //     <span className='imagenameanddate-text' >Example January 2022</span>
    //     </div>
    //     </div>
    //     </div>
    //   </div>
    // <div className="sat-image-display">
    //   <div className='satellite-image-display-title'>
    //   <span className="titletext" >View Satelitte Image{satelliteData ? ': ' + satelliteData.name : ''} </span>
    //   </div>

    // <div className='satellite-image-display-header'>
    //   <div className='satellite-image-display-title'>
    //    <span className="titletext">View Satelitte Image{satelliteData ? ': ' + satelliteData.name : ''} </span>
    //    </div>
    //   <div className='regionbox'>
    //    <div className='imageheadingbox'>
    //          <span className="imageheadingtext">Image:</span>
    //        </div>
    //    <div className="imagenameanddate" >
    //        <span className='imagenameanddate-text'>Example January 2022</span>
    //    </div>
    //   </div>

    //   <div className='mapbox'>
    //   <TransformWrapper>
    //     <TransformComponent>
    //       <div className="sat-image">
    //         <img src={satelliteData ? satelliteData.png_image : CreekSatellite}/>
    //       </div>
    //     </TransformComponent>
    //   </TransformWrapper>  

    //   </div>
    <div className='satpage'>
      <div className='satellite-image-display-header'>

        <div className='titlebox'>
          <span className="titletext">View Satellite Image </span>

        </div>
        <div className='image-bar'>
          <div className='imagedetailsbox'>

            <div className='openimage'>

              <button
              


                style={{ visibility: !navClosed ? 'hidden' : 'visible' }} onClick={e => openNav(e)}>
                  <img src={magnifyingglass} className='magnifying-glass-icon' />Open Image Details
                
              </button>

            </div>

            <div className='satellite-image-display__details' style={{ visibility: navClosed ? 'collapse' : 'visible' }}>
              <div className='satellite-image-display__details-frame'>

                
                <div className='cancel-box'>
                  <button className="canceltext"


                    style={{ visibility: !navClosed ? 'visible' : 'hidden' }} onClick={e => closeNav(e)}>Cancel

                  </button>

                  {/* <span className="canceltext">Cancel </span> */}
                </div>

                <div className='shapefileinfo-box'>
                  <button className='shapefileinfobutton'></button>
                  <div className='shapefileinfotextbox'>
                    <span className='shapefileinfotext'>Copy Shapefile Info </span>

                  </div>



                </div>
                <div className='details-box'>
                  <div className='satellite-image-display__details__properties'>
                    <span className="section">Image Source:</span> Sentinel-2
                  </div>
                </div>
                <div className='details-box'> <div className='satellite-image-display__details__properties'>
                  <span className="section">Date of Image:</span>
                </div></div>
                <div className='details-box'> <div className='satellite-image-display__details__properties'>
                  <span className="section">Image Type:</span> TCI (True Color)
                </div></div>
                <div className='details-box'> <div className='satellite-image-display__details__properties'>
                  <span className="section">Image Size:</span> 3M
                </div></div>
                <div className='details-box'> <div className='satellite-image-display__details__properties'>

                  <span className="section">Pixel Resolution:</span> 100
                </div></div>
                <div className='details-box'> <div className='satellite-image-display__details__properties'>

                  <span className="section">Cloud Coverage :</span> 2%
                </div></div>
                <div className='details-box'> <div className='satellite-image-display__details__properties'>

                  <span className="section">Area:</span> 98 Sq km
                </div></div>
                <div className='satellite-image-display__details__header'>
                  <span className='satellite-image-display-title'>Satellite Image Details</span>

                </div>
                <div className='details-picture-box'>
                  <div>
                    <img src={remotesensingicon} className='remote-sensing-icon' />
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div className='regionbox'>
            <div className='imageheadingbox'>
              <span className="imageheadingtext">Image:</span>
            </div>
            <div className='imagenameanddate'>
              <span className='imagenameanddate-text'>{satelliteData ? '' + satelliteData.name : ''} January 2022</span>
            </div>
          </div>
        </div>
      </div>

      <div className='mapbox'>

        <TransformWrapper>
          <TransformComponent>
            <div className="sat-image">
              <img src={satelliteData ? satelliteData.png_image : CreekSatellite} />
            </div>
          </TransformComponent>
        </TransformWrapper>

      </div>
      <div className='observation-toolbox-frame'>

        <button className='observation-toolbox-button'
          onClick={e => openTool(e)}>Observation ToolBox
        </button>
        </div>
        <div className='toolbox' style={{ visibility: toolClosed ? 'collapse' : 'visible' }}>
              <div className='toolbox-frame'>

                
                <div className='toolbox-cancel'>
                  <button className="canceltext"


                    style={{ visibility: !toolClosed ? 'visible' : 'hidden' }} onClick={e => closeTool(e)}>Cancel

                  </button>

                  {/* <span className="canceltext">Cancel </span> */}
                </div>
                <div className='toolbox-list'>
                  <button className='toolbox-options'>Carbon Mapping</button>
                  <button className='toolbox-options'>Flood Risk </button>
                  <button className='toolbox-options'>Manually Compare Changes </button>
                  <button className='toolbox-options'>Automatically Detect Changes </button>
                  <button className='toolbox-options'>Assess Land Use and Land Cover </button>
                </div>
                <div className='toolbox-header'>Observation ToolBox</div>
                <div className='toolbox-image'>
                <div>
                    <img src={remotesensingicon} className='remote-sensing-icon' />
                  </div>
                </div>


                
              </div>
            </div>
        
      



      





      {/* 
      <div className="openimage">
        <button  style={{visibility: !navClosed ? 'hidden' : 'visible' }} onClick={e => openNav(e)}>Open Image Details</button>  
      </div>
      <div className='satellite-image-display__details' style={{visibility: navClosed ? 'collapse' : 'visible' }}>
        <span className='satellite-image-display__details__header'> Project Details: </span>
        <button className="closebtn" onClick={e => closeNav(e)}>&times;</button> 
        <div className='satellite-image-display__details__properties'>
          <span className="section">Project:</span> {props.project ? props.project.name : ''}
          <br/>
          <span className="section">Region:</span> {props.region ?  props.region.name : ''}
          <br/>
          <span className="section">Satellite Image Name:</span> {satelliteData && satelliteData.name ? satelliteData.name : ''}
        </div>
        <span className='satellite-image-display__details__header'> Image Details: </span>
        <div className='satellite-image-display__details__properties'>
          <span className="section">Date of Image:</span> 
          <br/>
          <span className="section">Image Size:</span>
          <br/>
          <span className="section">Image Source:</span> Copernicus
          <br/>
          <span className="section">Satellite Band:</span> True Colour (TCI)
          <br/>
          <span className="section">Pixel Resolution:</span> 10m (Side Length)
          <br/>
          <span className="section">Cloud Coverage %:</span> 10
        </div>
        <span className='satellite-image-display__details__header'> Area Details: </span>
        <div className='satellite-image-display__details__properties'>
          <span className="section">Lattitude, Longitude:</span>
          <br/>
          <span className="section">Area (Km):</span>
        </div>
</div> */}



    </div>

  )
}

